import React from 'react'
import { IAnimalsOnFarm } from '../../../../Interfaces';

type Props = {
    equidaeOnFarm: IAnimalsOnFarm[];
    updateEquidaeOnFarm: (animalSubGroup: number, key: string, value: string | null | Date | number) => void
}

const AllEquidae = (props: Props) => {
    return (
        <>
            <tr>
                <td colSpan={7}><p className='mt-2'><strong><u>Equidae</u></strong></p></td>
            </tr>
            <tr>
                <td><p><strong>All equidae</strong></p></td>
                <td>
                    <label className='ui-table-cell-label' htmlFor='AverageNumPerMonthAllEquidae'>Average number per month</label>
                    <input
                        type="number"
                        value={props.equidaeOnFarm.find(element => element.animalSubGroup === 13)?.averageNumPerMonth}
                        onChange={(e) => props.updateEquidaeOnFarm(13, "averageNumPerMonth", e.target.valueAsNumber)}
                        name="AverageNumPerMonthAllEquidae"
                        className="form-control"
                        min="0"
                    />
                </td>
                <td>
                    <label className='ui-table-cell-label' htmlFor='NumSoldRearingAllEquidae'>Number sold</label>
                    <input
                        type="number"
                        value={props.equidaeOnFarm.find(element => element.animalSubGroup === 13)?.numSold}
                        onChange={(e) => props.updateEquidaeOnFarm(13, "numSold", e.target.valueAsNumber)}
                        name="NumSoldRearingAllEquidae"
                        className="form-control"
                        min="0"
                    />
                </td>
                <td>
                    <label className='ui-table-cell-label' htmlFor='MeanLiveWeightTakenAtSaleAllEquidae'>Mean live weight taken at sale</label>
                    <input
                        type="number"
                        value={props.equidaeOnFarm.find(element => element.animalSubGroup === 13)?.meanLiveWeightTakenAtSale}
                        onChange={(e) => props.updateEquidaeOnFarm(13, "meanLiveWeightTakenAtSale", e.target.valueAsNumber)}
                        name="MeanLiveWeightTakenAtSaleAllEquidae"
                        className="form-control"
                        min="0"
                    />
                </td>
                <td>
                    <label className='ui-table-cell-label' htmlFor='DaysAtGrassAllEquidae'>Days at grass</label>
                    <input
                        type="number"
                        value={props.equidaeOnFarm.find(element => element.animalSubGroup === 13)?.daysAtGrass}
                        onChange={(e) => props.updateEquidaeOnFarm(13, "daysAtGrass", e.target.valueAsNumber)}
                        name="DaysAtGrassAllEquidae"
                        className="form-control"
                        min="0"
                    />
                </td>
                <td>
                    <label className='ui-table-cell-label' htmlFor='HoursPerDayAtGrassAllEquidae'>Hours per day at grass</label>
                    <input
                        type="number"
                        value={props.equidaeOnFarm.find(element => element.animalSubGroup === 13)?.hoursPerDayAtGrass}
                        onChange={(e) => props.updateEquidaeOnFarm(13, "hoursPerDayAtGrass", e.target.valueAsNumber)}
                        name="HoursPerDayAtGrassAllEquidae"
                        className="form-control"
                        min="0"
                    />
                </td>
            </tr>
        </>
    )
}

export default AllEquidae