import React from 'react'
import { IAnimalsOnFarm } from '../../../../Interfaces';

type Props = {
    deerOnFarm: IAnimalsOnFarm[];
    updateDeerOnFarm: (animalSubGroup: number, key: string, value: string | null | Date | number) => void
}

const Deer = (props: Props) => {
    return (
        <>
            <tr>
                <td colSpan={7}><p className='mt-2'><strong><u>Deer</u></strong></p></td>
            </tr>
            <tr>
                <td><p><strong>All deer</strong></p></td>
                <td>
                    <label className='ui-table-cell-label' htmlFor='AverageNumPerMonthDeer'>Average number per month</label>
                    <input
                        type="number"
                        value={props.deerOnFarm.find(element => element.animalSubGroup === 24)?.averageNumPerMonth}
                        onChange={(e) => props.updateDeerOnFarm(24, "averageNumPerMonth", e.target.valueAsNumber)}
                        name="AverageNumPerMonthDeer"
                        className="form-control"
                        min="0"
                    />
                </td>
                <td>
                    <label className='ui-table-cell-label' htmlFor='NumSoldRearingDeer'>Number sold</label>
                    <input
                        type="number"
                        value={props.deerOnFarm.find(element => element.animalSubGroup === 24)?.numSold}
                        onChange={(e) => props.updateDeerOnFarm(24, "numSold", e.target.valueAsNumber)}
                        name="NumSoldRearingDeer"
                        className="form-control"
                        min="0"
                    />
                </td>
                <td>
                    <label className='ui-table-cell-label' htmlFor='MeanLiveWeightTakenAtSaleDeer'>Mean live weight taken at sale</label>
                    <input
                        type="number"
                        value={props.deerOnFarm.find(element => element.animalSubGroup === 24)?.meanLiveWeightTakenAtSale}
                        onChange={(e) => props.updateDeerOnFarm(24, "meanLiveWeightTakenAtSale", e.target.valueAsNumber)}
                        name="MeanLiveWeightTakenAtSaleDeer"
                        className="form-control"
                        min="0"
                    />
                </td>
                <td>
                    <label className='ui-table-cell-label' htmlFor='DaysAtGrassDeer'>Days at grass</label>
                    <input
                        type="number"
                        value={props.deerOnFarm.find(element => element.animalSubGroup === 24)?.daysAtGrass}
                        onChange={(e) => props.updateDeerOnFarm(24, "daysAtGrass", e.target.valueAsNumber)}
                        name="DaysAtGrassDeer"
                        className="form-control"
                        min="0"
                    />
                </td>
                <td>
                    <label className='ui-table-cell-label' htmlFor='HoursPerDayAtGrassDeer'>Hours per day at grass</label>
                    <input
                        type="number"
                        value={props.deerOnFarm.find(element => element.animalSubGroup === 24)?.hoursPerDayAtGrass}
                        onChange={(e) => props.updateDeerOnFarm(24, "hoursPerDayAtGrass", e.target.valueAsNumber)}
                        name="HoursPerDayAtGrassDeer"
                        className="form-control"
                        min="0"
                    />
                </td>
            </tr>
        </>
    )
}

export default Deer