import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { InputButton, InputButtonsContainer } from '../../styles/input/Input.styled';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { ToastContainer, toast } from 'react-toastify';
import { clearErrors, resetUpdate, getFullFarmDataById, putSignsOfErosion, putGrassSwardData, resetFarmDataId } from '../../../features/farmData/farmDataSlice';
import MetaData from '../../layout/MetaData';

type Props = {}

const GrassSward = (props: Props) => {

    const [pulledData, setPulledData] = useState(false);
    const [herbsPresentInSward, setHerbsPresentInSward] = useState(false);
    const [legumesPresentInSward, setLegumesPresentInSward] = useState(false);
    const [nonePresentInSward, setNonePresentInSward] = useState(false);
    const [proportionOfLegumesInSward, setProportionOfLegumesInSward] = useState(0);
    const [reseedFrequency, setReseedFrequency] = useState(-1);
    const [reseedForStandardManagement, setReseedForStandardManagement] = useState(false);
    const [reseedToImproveSwardCover, setReseedToImproveSwardCover] = useState(false);
    const [reseedForCompaction, setReseedForCompaction] = useState(false);
    const [reseedToIncreaseDiversity, setReseedToIncreaseDiversity] = useState(false);
    const [noneOfReasonsToReseed, setNoneOfReasonsToReseed] = useState(false);
    const [legumesPresentInReseed, setLegumesPresentInReseed] = useState(3);
    const [backToReport, setBackToReport] = useState(false);

    const { report } = useAppSelector((state) => state.report);
    const { fullFarmData, success, error } = useAppSelector((state) => state.farmData)

    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!pulledData) {
            if (fullFarmData?.farmManagement.grassSwardData) {
                var data = fullFarmData.farmManagement.grassSwardData
                setHerbsPresentInSward(data.herbsPresentInSward ?? false)
                setLegumesPresentInSward(data.legumesPresentInSward ?? false)
                setNonePresentInSward(!data.herbsPresentInSward && !data.legumesPresentInSward)
                setProportionOfLegumesInSward(data.proportionOfLegumesInSward ?? 0)
                setReseedFrequency(data.reseedFrequency ?? -1)
                setReseedForStandardManagement(data.reseedForStandardManagement ?? false)
                setReseedToImproveSwardCover(data.reseedToImproveSwardCover ?? false)
                setReseedForCompaction(data.reseedForCompaction ?? false)
                setReseedToIncreaseDiversity(data.reseedToIncreaseDiversity ?? false)
                if (fullFarmData?.farmManagement.grassSwardData.legumesPresentInReseed === true) setLegumesPresentInReseed(1);
                if (fullFarmData?.farmManagement.grassSwardData.legumesPresentInReseed === false) setLegumesPresentInReseed(0);
            }
            setPulledData(false);
        }

        if (success) {
            let farmDataId = report?.farmData;
            dispatch(getFullFarmDataById({ id: farmDataId! }))
            toast.dismiss();
            toast.success("Input saved successfully");
            dispatch(resetUpdate());
            if (backToReport) {
                dispatch(resetFarmDataId());
                navigate(`/report/${report?.id}`);
            }
        }

    }, [dispatch, error, pulledData, success, backToReport, navigate, report?.farmData, report?.id, fullFarmData?.farmManagement.grassSwardData])

    const saveHandler = (e?: React.MouseEvent) => {
        e?.preventDefault();

        // validation
        if (reseedFrequency === -1) {
            toast.info("Please select how often you reseed");
            return;
        }

        if (legumesPresentInSward) {
            if (proportionOfLegumesInSward > 100 || proportionOfLegumesInSward < 0) {
                toast.dismiss();
                toast.error("The proportion of legumes in the sward must be between 0 and 100");
                return;
            }
        }

        if (legumesPresentInReseed === 3) {
            toast.dismiss();
            toast.error("Please indicate whether legumes are present in the reseed");
            return
        }

        toast.dismiss();
        toast.info("Saving progress...");
        dispatch(putGrassSwardData(
            {
                id: fullFarmData?.farmData.id!,
                grassSwardData: {
                    herbsPresentInSward,
                    legumesPresentInSward,
                    proportionOfLegumesInSward,
                    reseedFrequency,
                    reseedForStandardManagement,
                    reseedToImproveSwardCover,
                    reseedForCompaction,
                    reseedToIncreaseDiversity,
                    legumesPresentInReseed: Boolean(legumesPresentInReseed)
                }
            })
        );
    }

    return (
        <>
            <MetaData title='Grass Sward' />
            <div>
                <div><h4><strong>FARM MANAGEMENT - YOUR GRASS SWARD</strong></h4></div>

                <div className='my-3'>
                    <p>In this section, we want to find out about  management on your farm, mainly focussed on inputs. This includes concentrates bought to feed to animals, pesticides applied and fertiliser (both organic and inorganic) applied to grassland</p>
                </div>

                <div>
                    <p><strong>Which of these groups are present in addition to grass?</strong></p>

                    <div className='d-flex col-3'>
                        <input data-role='none' type="checkbox" name="groupsPresentInSward" checked={herbsPresentInSward} onChange={() => {
                            setHerbsPresentInSward(!herbsPresentInSward)
                            setNonePresentInSward(false)
                        }} />
                        <label className='ms-3'>Herbs</label>
                    </div>
                    <div className='d-flex col-3'>
                        <input data-role='none' type="checkbox" name="groupsPresentInSward" checked={legumesPresentInSward} onChange={() => {
                            setLegumesPresentInSward(!legumesPresentInSward)
                            setNonePresentInSward(false)
                        }} />
                        <label className='ms-3'>Legumes</label>
                    </div>
                    <div className='d-flex col-3'>
                        <input data-role='none' type="checkbox" name="groupsPresentInSward" checked={nonePresentInSward} onChange={() => {
                            setNonePresentInSward(!nonePresentInSward)
                            setHerbsPresentInSward(false)
                            setLegumesPresentInSward(false)
                        }} />
                        <label className='ms-3'>None</label>
                    </div>
                </div>


                {legumesPresentInSward && (
                    <>
                        <div className='my-4 row d-flex align-items-center'>
                            <div className='col-12 col-sm-6'>
                                <label><strong>What is the share of legumes present in the sward?(%)</strong></label>
                            </div>
                            <div className='col-6'>
                                <input
                                    type='number'
                                    className='form-control'
                                    value={proportionOfLegumesInSward}
                                    onChange={(e) => setProportionOfLegumesInSward(Number(e.target.value))}
                                />
                            </div>
                        </div>
                    </>
                )}

                <div className='radio my-3'>
                    <label><strong>How often do you reseed your improved permanent grassland?</strong><br /></label>
                    <label>
                        <input data-role='none' type="radio" name="reseedFrequency" checked={reseedFrequency === 0} onChange={() => setReseedFrequency(0)} />
                        <span className='radio-span'>Less than or equal to every 5 years</span>
                    </label>
                    <label>
                        <input data-role='none' type="radio" name="reseedFrequency" checked={reseedFrequency === 1} onChange={() => setReseedFrequency(1)} />
                        <span className='radio-span'>Between every 5 to 9 years</span>
                    </label>
                    <label>
                        <input data-role='none' type="radio" name="reseedFrequency" checked={reseedFrequency === 2} onChange={() => setReseedFrequency(2)} />
                        <span className='radio-span'>Between every 10 to 14 years</span>
                    </label>
                    <label>
                        <input data-role='none' type="radio" name="reseedFrequency" checked={reseedFrequency === 3} onChange={() => setReseedFrequency(3)} />
                        <span className='radio-span'>Every 15 or more years</span>
                    </label>
                </div>

                <div>
                    <p><strong>Why do you reseed?</strong></p>

                    <div className='d-flex'>
                        <input data-role='none' type="checkbox" name="reasonsForReseed" checked={reseedForStandardManagement} onChange={() => {
                            setReseedForStandardManagement(!reseedForStandardManagement)
                            setNoneOfReasonsToReseed(false)
                            setReseedForCompaction(false)
                            setReseedToImproveSwardCover(false)
                            setReseedToIncreaseDiversity(false)
                        }} />
                        <label className='ms-3'>Standard management/routine reseeding</label>
                    </div>
                    <div className='d-flex'>
                        <input data-role='none' type="checkbox" name="reasonsForReseed" disabled={reseedForStandardManagement} checked={reseedForCompaction} onChange={() => {
                            setReseedForCompaction(!reseedForCompaction)
                            setNoneOfReasonsToReseed(false)
                        }} />
                        <label className='ms-3'>Compaction of soil</label>
                    </div>
                    <div className='d-flex'>
                        <input data-role='none' type="checkbox" name="reasonsForReseed" disabled={reseedForStandardManagement} checked={reseedToImproveSwardCover} onChange={() => {
                            setReseedToImproveSwardCover(!reseedToImproveSwardCover)
                            setNoneOfReasonsToReseed(false)
                        }} />
                        <label className='ms-3'>Improving sward cover</label>
                    </div>
                    <div className='d-flex'>
                        <input data-role='none' type="checkbox" name="reasonsForReseed" disabled={reseedForStandardManagement} checked={reseedToIncreaseDiversity} onChange={() => {
                            setReseedToIncreaseDiversity(!reseedToIncreaseDiversity)
                            setNoneOfReasonsToReseed(false)
                        }} />
                        <label className='ms-3'>Reintroduction of species/increase sward diversity</label>
                    </div>
                    <div className='d-flex'>
                        <input data-role='none' type="checkbox" name="reasonsForReseed" disabled={reseedForStandardManagement} checked={noneOfReasonsToReseed} onChange={() => {
                            setNoneOfReasonsToReseed(!noneOfReasonsToReseed)
                            setReseedForCompaction(false)
                            setReseedForStandardManagement(false)
                            setReseedToImproveSwardCover(false)
                            setReseedToIncreaseDiversity(false)
                        }} />
                        <label className='ms-3'>None of the above</label>
                    </div>
                </div>

                <div className='radio my-3'>
                    <label><strong>Are legumes included in reseed?</strong><br /></label>
                    <label>
                        <input data-role='none' type="radio" name="legumesPresentInReseed" checked={legumesPresentInReseed === 1} onChange={() => setLegumesPresentInReseed(1)} />
                        <span className='radio-span'>Yes</span>
                    </label>
                    <label>
                        <input data-role='none' type="radio" name="legumesPresentInReseed" checked={legumesPresentInReseed === 0} onChange={() => setLegumesPresentInReseed(0)} />
                        <span className='radio-span'>No</span>
                    </label>
                </div>

                <InputButtonsContainer className='my-2'>
                    <InputButton onClick={() => navigate(`/report/${report?.id}`)}>BACK</InputButton>
                    <InputButton onClick={() => {
                        toast.dismiss()
                        toast.info("Saving...")
                        saveHandler()
                    }}>SAVE</InputButton>
                    <InputButton onClick={() => {
                        toast.dismiss()
                        toast.info("Saving...");
                        setBackToReport(true);
                        saveHandler();
                    }}>SAVE & CONTINUE</InputButton>
                </InputButtonsContainer>

            </div>
        </>
    )
}

export default GrassSward