import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { InputButton, InputButtonsContainer } from '../../styles/input/Input.styled';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { ToastContainer, toast } from 'react-toastify';
import { clearErrors, resetUpdate, getFullFarmDataById, putLandscapeAesthetics, resetFarmDataId } from '../../../features/farmData/farmDataSlice';
import MetaData from '../../layout/MetaData';



type Props = {}

const LandscapeAesthetics = (props: Props) => {

    const [pulledData, setPulledData] = useState(false);
    const [percentageConservedForNature, setPercentageConservedForNature] = useState(0);
    const [naturalHeritagePresent, setNaturalHeritagePresent] = useState(3);
    const [natureTrailsPresnt, setNatureTrailsPresnt] = useState(3);
    const [hedgerowsPresentInBoundary, setHedgerowsPresentInBoundary] = useState(false);
    const [boundaryPercentageHedgerows, setBoundaryPercentageHedgerows] = useState<number | undefined>(0);
    const [traditionalPresentInBoundary, setTraditionalPresentInBoundary] = useState(false);
    const [boundaryPercentageTraditional, setBoundaryPercentageTraditional] = useState<number | undefined>(0);
    const [sheepWirePresentInBoundary, setSheepWirePresentInBoundary] = useState(false);
    const [boundaryPercentageSheepWire, setBoundaryPercentageSheepWire] = useState<number | undefined>(0);
    const [ditchesPresentInBoundary, setDitchesPresentInBoundary] = useState(false);
    const [boundaryPercentageDitches, setBoundaryPercentageDitches] = useState<number | undefined>(0);
    const [otherPresentInBoundary, setOtherPresentInBoundary] = useState(false);
    const [boundaryPercentageOther, setBoundaryPercentageOther] = useState<number | undefined>(0);
    const [otherPresentInBoundarySpecified, setOtherPresentInBoundarySpecified] = useState("");
    const [thickHedgerowPercentage, setThickHedgerowPercentage] = useState<number | undefined>(0);
    const [trimmedHedgerowPercentage, setTrimmedHedgerowPercentage] = useState<number | undefined>(0);
    const [sparseHedgerowPercentage, setSparseHedgerowPercentage] = useState<number | undefined>(0);
    const [backToReport, setBackToReport] = useState(false);

    const { report } = useAppSelector((state) => state.report);
    const { fullFarmData, success, error } = useAppSelector((state) => state.farmData)

    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!pulledData) {
            if (fullFarmData?.farmBreakdown.landscapeAesthetics) {
                var details = fullFarmData.farmBreakdown.landscapeAesthetics
                setPercentageConservedForNature(details.percentageConservedForNature)
                if (details.naturalHeritagePresent === true) setNaturalHeritagePresent(1);
                if (details.naturalHeritagePresent === false) setNaturalHeritagePresent(0);
                if (details.natureTrailsPresnt === true) setNatureTrailsPresnt(1);
                if (details.natureTrailsPresnt === false) setNatureTrailsPresnt(0);
                setHedgerowsPresentInBoundary(details.hedgerowsPresentInBoundary)
                setBoundaryPercentageHedgerows(details.boundaryPercentageHedgerows)
                setTraditionalPresentInBoundary(details.traditionalPresentInBoundary)
                setBoundaryPercentageTraditional(details.boundaryPercentageTraditional)
                setSheepWirePresentInBoundary(details.sheepWirePresentInBoundary)
                setBoundaryPercentageSheepWire(details.boundaryPercentageSheepWire)
                setDitchesPresentInBoundary(details.ditchesPresentInBoundary)
                setBoundaryPercentageDitches(details.boundaryPercentageDitches)
                setOtherPresentInBoundary(details.otherPresentInBoundary)
                setBoundaryPercentageOther(details.boundaryPercentageOther)
                setOtherPresentInBoundarySpecified(details.otherPresentInBoundarySpecified)
                setThickHedgerowPercentage(details.thickHedgerowPercentage)
                setTrimmedHedgerowPercentage(details.trimmedHedgerowPercentage)
                setSparseHedgerowPercentage(details.sparseHedgerowPercentage)
            }
            setPulledData(false);
        }

        if (success) {
            let farmDataId = report?.farmData;
            dispatch(getFullFarmDataById({ id: farmDataId! }))
            toast.dismiss();
            toast.success("Input saved successfully");
            dispatch(resetUpdate());
            if (backToReport) {
                dispatch(resetFarmDataId());
                navigate(`/report/${report?.id}`);
            }
        }

    }, [dispatch, error, pulledData, success, backToReport, navigate, report?.farmData, report?.id, fullFarmData?.farmBreakdown.landscapeAesthetics])

    const saveHandler = (e?: React.MouseEvent) => {
        e?.preventDefault();

        // validation
        if (naturalHeritagePresent === 3) {
            toast.dismiss();
            toast.error("Please indicate whether there is natural heritage on your farm");
            return
        }
        if (natureTrailsPresnt === 3) {
            toast.dismiss();
            toast.error("Please indicate whether there are nature trails on your farm");
            return
        }


        toast.info("Saving progress...");
        dispatch(putLandscapeAesthetics(
            {
                farmData: fullFarmData?.farmData.id!,
                percentageConservedForNature,
                naturalHeritagePresent: Boolean(naturalHeritagePresent),
                natureTrailsPresnt: Boolean(natureTrailsPresnt),
                hedgerowsPresentInBoundary,
                boundaryPercentageHedgerows: boundaryPercentageHedgerows === 0 ? undefined : boundaryPercentageHedgerows,
                traditionalPresentInBoundary,
                boundaryPercentageTraditional: boundaryPercentageTraditional === 0 ? undefined : boundaryPercentageTraditional,
                sheepWirePresentInBoundary,
                boundaryPercentageSheepWire: boundaryPercentageSheepWire === 0 ? undefined : boundaryPercentageSheepWire,
                ditchesPresentInBoundary,
                boundaryPercentageDitches: boundaryPercentageDitches === 0 ? undefined : boundaryPercentageDitches,
                otherPresentInBoundary,
                boundaryPercentageOther: boundaryPercentageOther === 0 ? undefined : boundaryPercentageOther,
                otherPresentInBoundarySpecified,
                thickHedgerowPercentage: thickHedgerowPercentage === 0 ? undefined : thickHedgerowPercentage,
                trimmedHedgerowPercentage: trimmedHedgerowPercentage === 0 ? undefined : trimmedHedgerowPercentage,
                sparseHedgerowPercentage: sparseHedgerowPercentage === 0 ? undefined : sparseHedgerowPercentage
            }));
    }

    return (
        <>
            <MetaData title='Landscape Aesthetics' />
            <div>
                <div><h4><strong>ABOUT YOUR FARM - LANDSCAPE AESTHETICS</strong></h4></div>

                <div className='my-3'>
                    <p>In this sub section, we want to find out about how your farm looks and how it enriches the landscape, both visually and environmentally</p>
                </div>

                <div className='my-4 row d-flex align-items-center'>
                    <div className='col-12 col-sm-6'>
                        <label><strong>What proportion(%) of your farm is conserved for natue (including buffer strips, trees hedges, and specific non agricultural use?</strong></label>
                    </div>
                    <div className='col-6'>
                        <input
                            max={100}
                            min={0}
                            type='number'
                            className='form-control'
                            value={percentageConservedForNature}
                            onChange={(e) => setPercentageConservedForNature(Number(e.target.value))}
                        />
                    </div>
                </div>

                <div className='my-4 row d-flex flex-column align-items-center'>
                    <div className='col-12'>
                        <label><strong>Describe field boundaries on your farm:</strong></label>
                    </div>
                    <div className='my-2 row d-flex align-items-center'>
                        <div className='col-12 col-sm-6 d-flex justify-content-between'>
                            <label><strong>Hedgerows</strong></label>
                            <div className='col-3 align-items-center'>
                                <input
                                    type='checkbox'
                                    checked={hedgerowsPresentInBoundary}
                                    onChange={(e) => setHedgerowsPresentInBoundary(!hedgerowsPresentInBoundary)}
                                />
                            </div>
                        </div>
                        {hedgerowsPresentInBoundary && (
                            <div className='d-flex justify-content-between align-items-center col-12 col-sm-6'>
                                <div className='col-9'>Proportion of field boundary (%)</div>
                                <div className='col-3 align-items-center'>
                                    <input
                                        max={100}
                                        min={0}
                                        type='number'
                                        className='form-control'
                                        value={boundaryPercentageHedgerows}
                                        onChange={(e) => setBoundaryPercentageHedgerows(Number(e.target.value))}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                    <div className='my-2 row d-flex align-items-center'>
                        <div className='col-12 col-sm-6 d-flex justify-content-between'>
                            <label><strong>Traditional (eg dry stone wall)</strong></label>
                            <div className='col-3 align-items-center'>
                                <input
                                    type='checkbox'
                                    checked={traditionalPresentInBoundary}
                                    onChange={(e) => setTraditionalPresentInBoundary(!traditionalPresentInBoundary)}
                                />
                            </div>
                        </div>
                        {traditionalPresentInBoundary && (
                            <div className='d-flex justify-content-between align-items-center col-12 col-sm-6'>
                                <div className='col-9'>Proportion of field boundary (%)</div>
                                <div className='col-3 align-items-center'>
                                    <input
                                        max={100}
                                        min={0}
                                        type='number'
                                        className='form-control'
                                        value={boundaryPercentageTraditional}
                                        onChange={(e) => setBoundaryPercentageTraditional(Number(e.target.value))}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                    <div className='my-2 row d-flex align-items-center'>
                        <div className='col-12 col-sm-6 d-flex justify-content-between'>
                            <label><strong>Sheep wire fencing</strong></label>
                            <div className='col-3 align-items-center'>
                                <input
                                    type='checkbox'
                                    checked={sheepWirePresentInBoundary}
                                    onChange={(e) => setSheepWirePresentInBoundary(!sheepWirePresentInBoundary)}
                                />
                            </div>
                        </div>
                        {sheepWirePresentInBoundary && (
                            <div className='d-flex justify-content-between align-items-center col-12 col-sm-6'>
                                <div className='col-9'>Proportion of field boundary (%)</div>
                                <div className='col-3 align-items-center'>
                                    <input
                                        max={100}
                                        min={0}
                                        type='number'
                                        className='form-control'
                                        value={boundaryPercentageSheepWire}
                                        onChange={(e) => setBoundaryPercentageSheepWire(Number(e.target.value))}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                    <div className='my-2 row d-flex align-items-center'>
                        <div className='col-12 col-sm-6 d-flex justify-content-between'>
                            <label><strong>Ditches</strong></label>
                            <div className='col-3 align-items-center'>
                                <input
                                    type='checkbox'
                                    checked={ditchesPresentInBoundary}
                                    onChange={(e) => setDitchesPresentInBoundary(!ditchesPresentInBoundary)}
                                />
                            </div>
                        </div>
                        {ditchesPresentInBoundary && (
                            <div className='d-flex justify-content-between align-items-center col-12 col-sm-6'>
                                <div className='col-9'>Proportion of field boundary (%)</div>
                                <div className='col-3 align-items-center'>
                                    <input
                                        max={100}
                                        min={0}
                                        type='number'
                                        className='form-control'
                                        value={boundaryPercentageDitches}
                                        onChange={(e) => setBoundaryPercentageDitches(Number(e.target.value))}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                    <div className='my-2 row d-flex align-items-center'>
                        <div className='col-12 col-sm-6 d-flex justify-content-between'>
                            <label><strong>Other</strong></label>
                            <div className='col-3 align-items-center'>
                                <input
                                    type='checkbox'
                                    checked={otherPresentInBoundary}
                                    onChange={(e) => setOtherPresentInBoundary(!otherPresentInBoundary)}
                                />
                            </div>
                        </div>
                        {otherPresentInBoundary && (
                            <div className='d-flex justify-content-between align-items-center col-12 col-sm-6'>
                                <div className='col-9'>Proportion of field boundary (%)</div>
                                <div className='col-3 align-items-center'>
                                    <input
                                        max={100}
                                        min={0}
                                        type='number'
                                        className='form-control'
                                        value={boundaryPercentageOther}
                                        onChange={(e) => setBoundaryPercentageOther(Number(e.target.value))}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                    {otherPresentInBoundary && (
                        <div className='my-2 row d-flex align-items-center'>
                            <div className='col-12 col-sm-6'>
                                <label><strong>Please describe other types of field boundary</strong></label>
                            </div>
                            <div className='d-flex justify-content-between col-12 col-sm-6'>
                                <div className='col-12 align-items-center'>
                                    <input
                                        type='text'
                                        className='form-control'
                                        checked={otherPresentInBoundary}
                                        onChange={(e) => setOtherPresentInBoundary(!otherPresentInBoundary)}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                </div>

                {hedgerowsPresentInBoundary && <div className='mb-3'>
                    <label><strong>You have stated that there are hedgerows on your farm, what percentage (%) of all the hedges on your farm fall into the following categories?</strong></label>
                    <div className='my-2 row d-flex align-items-center'>
                        <div className='col-12 col-sm-6'>
                            <label>Tall, thick hedges, with a mix of species:</label>
                        </div>
                        <div className='col-6'>
                            <input
                                className='form-control'
                                value={thickHedgerowPercentage}
                                type='number'
                                onChange={(e) => setThickHedgerowPercentage(e.target.valueAsNumber)}
                            />
                        </div>
                    </div>
                    <div className='my-2 row d-flex align-items-center'>
                        <div className='col-12 col-sm-6'>
                            <label>Trimmed monoculture hedges:</label>
                        </div>
                        <div className='col-6'>
                            <input
                                className='form-control'
                                value={trimmedHedgerowPercentage}
                                type='number'
                                onChange={(e) => setTrimmedHedgerowPercentage(e.target.valueAsNumber)}
                            />
                        </div>
                    </div>
                    <div className='my-2 row d-flex align-items-center'>
                        <div className='col-12 col-sm-6'>
                            <label>Sparse hedges:</label>
                        </div>
                        <div className='col-6'>
                            <input
                                className='form-control'
                                value={sparseHedgerowPercentage}
                                type='number'
                                onChange={(e) => setSparseHedgerowPercentage(e.target.valueAsNumber)}
                            />
                        </div>
                    </div>
                </div>}

                <div className='my-3'><a href="https://culture.ec.europa.eu/cultural-heritage/cultural-heritage-in-eu-policies/natura-2000-and-cultural-landscapes" target='_blank' rel="noreferrer">Natura 2000 and cultural landscapes | Culture and Creativity (europa.eu) </a></div>

                <div className='radio my-3'>
                    <label><strong>Are there natural heritage or sacred objects on farm?</strong><br /></label>
                    <label>
                        <input type="radio" name="naturalHeritagePresent" checked={naturalHeritagePresent === 1} onChange={() => setNaturalHeritagePresent(1)} />
                        <span className='radio-span'>Yes</span>
                    </label>
                    <label>
                        <input type="radio" name="naturalHeritagePresent" checked={naturalHeritagePresent === 0} onChange={() => setNaturalHeritagePresent(0)} />
                        <span className='radio-span'>No</span>
                    </label>
                </div>

                <div className='radio my-3'>
                    <label><strong>Are there nature trails, public footpaths or bridleways on your farm?</strong><br /></label>
                    <label>
                        <input type="radio" name="natureTrailsPresnt" checked={natureTrailsPresnt === 1} onChange={() => setNatureTrailsPresnt(1)} />
                        <span className='radio-span'>Yes</span>
                    </label>
                    <label>
                        <input type="radio" name="natureTrailsPresnt" checked={natureTrailsPresnt === 0} onChange={() => setNatureTrailsPresnt(0)} />
                        <span className='radio-span'>No</span>
                    </label>
                </div>

                <InputButtonsContainer className='my-2'>
                    <InputButton onClick={() => navigate(`/report/${report?.id}`)}>BACK</InputButton>
                    <InputButton onClick={() => {
                        toast.dismiss()
                        toast.info("Saving...")
                        saveHandler()
                    }}>SAVE</InputButton>
                    <InputButton onClick={() => {
                        toast.dismiss()
                        toast.info("Saving...");
                        setBackToReport(true);
                        saveHandler();
                    }}>SAVE & CONTINUE</InputButton>
                </InputButtonsContainer>

            </div>
        </>
    )
}

export default LandscapeAesthetics