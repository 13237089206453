import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { InputButton, InputButtonsContainer } from '../../styles/input/Input.styled';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { ToastContainer, toast } from 'react-toastify';
import { putSoilDegradation, clearErrors, resetUpdate, resetFarmDataId } from '../../../features/farmData/farmDataSlice';
import { SoilDegradationPhoto } from '../../styles/Img.styled';
import MetaData from '../../layout/MetaData';

type Props = {}

const SoilDegradation = (props: Props) => {

    const [pulledData, setPulledData] = useState(false);
    const [proportionHigh, setProportionHigh] = useState(0);
    const [proportionSevere, setProportionSevere] = useState(0);
    const [proportionModerate, setProportionModerate] = useState(0);
    const [proportionLow, setProportionLow] = useState(0);
    const [backToReport, setBackToReport] = useState(false);

    const { report } = useAppSelector((state) => state.report);
    const { fullFarmData, success, error } = useAppSelector((state) => state.farmData)

    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!pulledData) {
            if (fullFarmData?.farmBreakdown.soilProfile.soilDegradation) {
                setProportionSevere(fullFarmData.farmBreakdown.soilProfile.soilDegradation.proportionOfSevere ?? 0);
                setProportionHigh(fullFarmData.farmBreakdown.soilProfile.soilDegradation.proportionOfHigh ?? 0);
                setProportionModerate(fullFarmData.farmBreakdown.soilProfile.soilDegradation.proportionOfModerate ?? 0);
                setProportionLow(fullFarmData.farmBreakdown.soilProfile.soilDegradation.proportionOfLow ?? 0);
            }
            setPulledData(true);
        }

        if (success) {
            toast.dismiss();
            toast.success("Input saved successfully");
            dispatch(resetUpdate());
            if (backToReport) {
                dispatch(resetFarmDataId());
                navigate(`/report/${report?.id}`);
            }
        }

        if (error && typeof error === 'string') {
            toast.dismiss();
            toast.error(error);
            dispatch(clearErrors())
        }

    }, [dispatch, error, pulledData, success, backToReport, navigate, report?.id])


    const saveHandler = (e?: React.MouseEvent) => {
        e?.preventDefault();

        // validation
        if (proportionSevere < 0 || proportionSevere > 100) {
            toast.dismiss();
            toast.error("The proportion of severe soil degradation must be between 0 and 100");
            return;
        }

        if (proportionHigh < 0 || proportionHigh > 100) {
            toast.dismiss();
            toast.error("The proportion of high soil degradation must be between 0 and 100");
            return;
        }

        if (proportionModerate < 0 || proportionModerate > 100) {
            toast.dismiss();
            toast.error("The proportion of moderate soil degradation must be between 0 and 100");
            return;
        }

        if (proportionLow < 0 || proportionLow > 100) {
            toast.dismiss();
            toast.error("The proportion of low soil degradation must be between 0 and 100");
            return;
        }

        var sum = proportionSevere+proportionHigh+proportionLow+proportionModerate

        if (sum !== 100) {
            toast.dismiss()
            toast.error("The sum of your proportions must add up to 100");
            return;
        }

        dispatch(putSoilDegradation(
            {
                id: fullFarmData?.farmData.id!,
                // degradationLevel: selectedSoilDegradation,
                degradation: {
                    proportionOfSevere: proportionSevere,
                    proportionOfHigh: proportionHigh,
                    proportionOfModerate: proportionModerate,
                    proportionOfLow: proportionLow
                }
            }
        ))
    }


    return (
        <>
            <MetaData title='Soil Degradation' />
            <div>
                <div><h4><strong>ABOUT YOUR FARM - SOIL DEGRADATION</strong></h4></div>

                <div className='my-3'>
                    <p>Please enter the proportion of your permanent grassland at each soil degradation class</p>
                </div>

                <table data-role="table" data-mode="reflow" className="ui-table ui-responsive ui-table-reflow my-custom-breakpoint my-4">
                    <thead>

                    </thead>

                    <tbody>
                        <tr className='hide-on-slim-screens'>
                            <td></td>
                            <td>
                                <div className='w-100 d-flex justify-content-center align-items-center'>
                                    <SoilDegradationPhoto src='/assets/soil-degradation-severe.png' alt='Severe Soil Degradation' />
                                </div>
                            </td>
                            <td>
                                <div className='w-100 d-flex justify-content-center align-items-center'>
                                    <SoilDegradationPhoto src='/assets/soil-degradation-high.png' alt='High Soil Degradation' />
                                </div>
                            </td>
                            <td>
                                <div className='w-100 d-flex justify-content-center align-items-center'>
                                    <SoilDegradationPhoto src='/assets/soil-degradation-moderate.png' alt='Moderate Soil Degradation' />
                                </div>

                            </td>
                            <td>
                                <div className='w-100 d-flex justify-content-center align-items-center'>
                                    <SoilDegradationPhoto src='/assets/soil-degradation-low.png' alt='Low Soil Degradation' />
                                </div>

                            </td>
                        </tr>
                        <tr className='hide-on-slim-screens'>
                            <th>Soil Degradation Class</th>
                            <th className='text-center'>
                                Severe Soil Degradation
                            </th>
                            <th className='text-center'>
                                High Soil Degradation
                            </th>
                            <th className='text-center'>
                                Moderate Soil Degradation
                            </th>
                            <th className='text-center'>
                                Low Soil Degradation
                            </th>
                        </tr>

                        <tr>
                            <td></td>
                            <td>

                                <div className='col-12 d-flex justify-content-center flex-column align-items-center'>

                                    <label className='ui-table-cell-label my-2'>
                                        <div className='d-flex flex-column justify-content-center align-items-center'>
                                            <strong>Severe Soil Degradation</strong>
                                            <SoilDegradationPhoto src='/assets/soil-degradation-severe.png' alt='Severe Soil Degradation' />
                                        </div>
                                    </label>
                                    <input
                                        type='number'
                                        value={proportionSevere}
                                        className='form-control'
                                        onChange={(e) => setProportionSevere(e.target.valueAsNumber)}
                                    />
                                </div>
                            </td>
                            <td>
                                <div className='col-12 d-flex flex-column justify-content-center align-items-center'>

                                    <label className='ui-table-cell-label my-2'>
                                        <div className='d-flex flex-column justify-content-center align-items-center'>
                                            <strong>High Soil Degradation</strong>
                                            <SoilDegradationPhoto src='/assets/soil-degradation-high.png' alt='High Soil Degradation' />
                                        </div>
                                    </label>
                                    <input
                                        type='number'
                                        value={proportionHigh}
                                        className='form-control'
                                        onChange={(e) => setProportionHigh(e.target.valueAsNumber)}
                                    />
                                </div>
                            </td>
                            <td>
                                <div className='col-12 d-flex flex-column justify-content-center align-items-center'>

                                    <label className='ui-table-cell-label my-2'>
                                        <div className='d-flex flex-column justify-content-center align-items-center'>
                                            <strong>Moderate Soil Degradation</strong>
                                            <SoilDegradationPhoto src='/assets/soil-degradation-moderate.png' alt='Moderate Soil Degradation' />
                                        </div>
                                    </label>
                                    <input
                                        type='number'
                                        value={proportionModerate}
                                        className='form-control'
                                        onChange={(e) => setProportionModerate(e.target.valueAsNumber)}
                                    />
                                </div>

                            </td>
                            <td>
                                <div className='col-12 d-flex flex-column justify-content-center align-items-center'>
                                    <label className='ui-table-cell-label my-2'>
                                        <div className='d-flex flex-column justify-content-center align-items-center'>
                                            <strong>Low Soil Degradation</strong>
                                            <SoilDegradationPhoto src='/assets/soil-degradation-low.png' alt='Low Soil Degradation' />
                                        </div>
                                    </label>
                                    <input
                                        type='number'
                                        value={proportionLow}
                                        className='form-control'
                                        onChange={(e) => setProportionLow(e.target.valueAsNumber)}
                                    />
                                </div>
                            </td>
                        </tr>
                        <tr >
                            <th>Key soil and landscape features</th>
                            <td><label className='ui-table-cell-label'>Severe:</label>Extensive rills and gullies on slopes, and depositional fans on footslopes. Degraded soil structure either on the surface, topsoil, or upper subsoil layers.</td>
                            <td><label className='ui-table-cell-label'>High:</label>Capped surface soil; wheelings often {'>'}5 cm deep; structural change in topsoil or immediate subsurface layer; compaction causing dense angular or platy structures, locally structure less massive matrix; plough pans frequently present; abnormal vertical wetness gradients. Grasslands surfaces compacted by trampling or silage making; poaching often severe.</td>
                            <td><label className='ui-table-cell-label'>Moderate:</label>Cultivated fields often have slaked or partly slaked topsoils, some prominent wheelings ({'>'}5 cm deep); localised compaction or weakening and coarsening of structure. Grassland usually poached (locally severe). Degraded subsoil structure.</td>
                            <td><label className='ui-table-cell-label'>Low:</label>Localised enhanced surface-water runoff restricted to parts of fields (e.g. tramlines and headlands).</td>
                        </tr>
                        <tr >
                            <th>Generation of surface run off</th>
                            <td><label className='ui-table-cell-label'>Severe:</label>Enhanced surface-water runoff across the whole of the field</td>
                            <td><label className='ui-table-cell-label'>High:</label>Enhanced surface-water runoff across the whole of the field indicated by wash lines, flattened crops and grass.</td>
                            <td><label className='ui-table-cell-label'>Moderate:</label>Soil profile is well structured and typical of the texture and soil water regime of the soil series. Subsoils can be naturally dense, coarsely structured and slowly permeable.</td>
                            <td><label className='ui-table-cell-label'>Low:</label>Insignificant enhanced surface-water runoff generation. Infiltration into topsoils is adequate and through-flow in well structured upper horizons is sufficient to preclude surface-water runoff on slopes.</td>
                        </tr>

                    </tbody>
                </table>

                <div>Reference: Palmer, R. C., and R. P. Smith. "Soil structural degradation in SW England and its impact on surface‐water runoff generation." Soil Use and Management 29.4 (2013): 567-575.</div>

                <InputButtonsContainer className='my-2'>
                    <InputButton onClick={() => navigate(`/report/${report?.id}`)}>BACK</InputButton>
                    <InputButton onClick={() => {
                        toast.dismiss()
                        toast.info("Saving...")
                        saveHandler()
                    }}>SAVE</InputButton>
                    <InputButton onClick={() => {
                        toast.dismiss()
                        toast.info("Saving...");
                        setBackToReport(true);
                        saveHandler();
                    }}>SAVE & CONTINUE</InputButton>
                </InputButtonsContainer>

            </div>
        </>
    )
}

export default SoilDegradation