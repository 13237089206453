import styled from "styled-components";

export const MainButton = styled.button`
    background: #FFFFFF !important;
    border: 1px solid #000000 !important;
    border-radius: 30px !important;
    padding 0.5rem 1rem !important;
    color: black;
    min-width: 180px;

    &:hover {
    transform: scale(0.98);
  }
`;

export const ReportMenuBarButton = styled.button`
    background: #fff !important;
    border: 1px solid #000000 !important;
    border-radius: 5px !important;
    padding 0.25rem 0.5rem !important;
    color: black;

    &:hover {
      transform: scale(0.98);
    }
`;

export const MainButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 1rem;
`

interface ESButtonProps {
  activeES?: number | null;
  ES: number;
}

export const ReportESButton = styled.button<ESButtonProps>`
  border: 1px solid #000000;
  padding 0.25rem 0.5rem;
  border-radius: 10px;
  color: ${props => props.ES === props.activeES ? "white" : "black"};
  background: ${props => props.ES === props.activeES ? "black" : "white"};
`;

export const AuthButton = styled.button`
    outline: none;
    border: 1px solid rgb(145 200 50);
    margin: 8px 0;
    display: block;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    background-color: rgb(97 194 80) !important;
    color: #ffffff;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 500;
    width: 100%;
    border-radius: 0.5rem;
    text-transform: uppercase;

    &:hover {
        transform: scale(0.98);
`;