import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { InputButton, InputButtonsContainer } from '../../styles/input/Input.styled';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { ToastContainer, toast } from 'react-toastify';
import { clearErrors, resetUpdate, getFullFarmDataById, putOrganicFertilisers } from '../../../features/farmData/farmDataSlice';
import MetaData from '../../layout/MetaData';
import { IOrganicFertiliserApplied } from '../../../Interfaces';
import FertTable from './dynamicTables/organicFertilisers/Table';

type Props = {}

const OrganicFertilisers = (props: Props) => {
	const [pulledData, setPulledData] = useState(false);
	const [fertilisers, setFertilisers] = useState<IOrganicFertiliserApplied[]>([{
		fertiliser: 0,
		kgNPerTonnesAnalysed: 0,
		totalTonnesProduced: 0,
		monthsOfStorage: 0,
		tonnesAppliedInSpring: 0,
		tonnesAppliedInSummer: 0,
		tonnesAppliedInAutumn: 0,
		tonnesAppliedInWinter: 0,
		isAnalysed: false,
	}]);
	const [nextPage, setNextPage] = useState(false);

	const { report } = useAppSelector((state) => state.report);
	const { fullFarmData, success, error } = useAppSelector((state) => state.farmData)

	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	useEffect(() => {
		if (!pulledData) {
			if (fullFarmData?.farmManagement.fertiliserApplications?.organicFertilisersApplied) {
				var data = fullFarmData.farmManagement.fertiliserApplications.organicFertilisersApplied
				if (data.length > 0) setFertilisers(data)
			}
			setPulledData(true);
		}

		if (success) {
			let farmDataId = report?.farmData;
			dispatch(getFullFarmDataById({ id: farmDataId! }))
			toast.dismiss();
			toast.success("Input saved successfully");
			dispatch(resetUpdate());
			if (nextPage) navigate(`/input/fertiliserDetails`);
		}

	}, [dispatch, error, pulledData, success, nextPage, navigate, report?.farmData, report?.id, fullFarmData?.farmManagement?.fertiliserApplications?.organicFertilisersApplied])

	const saveHandler = (e?: React.MouseEvent) => {
		e?.preventDefault();

		// validation
		if (!fertilisers.every(element => {
			var spring = element.tonnesAppliedInSpring ?? 0
            var summer = element.tonnesAppliedInSummer ?? 0
            var autumn = element.tonnesAppliedInAutumn ?? 0
            var winter = element.tonnesAppliedInWinter ?? 0

			return Number(element.totalTonnesProduced) === (Number(spring)+Number(summer)+Number(autumn)+Number(winter))
		})) {
			toast.dismiss()
			toast.error("Tonnes applied must equal sum of tonnes applied in all seasons")
			return;
		}

		toast.dismiss();
		toast.info("Saving progress...");
		dispatch(putOrganicFertilisers(
			{
				id: fullFarmData?.farmData.id!,
				fertilisers
			}));
	}

	return (
		<>

			<MetaData title='Organic Fertilisers' />

			<div>
				<div><h4><strong>FARM MANAGEMENT - ORGANIC MANURES</strong></h4></div>

				<div className='my-3'>
                    <p>In this section, we want to find out about  management on your farm, mainly focussed on inputs. This includes concentrates bought to feed to animals, pesticides applied and fertiliser (both organic and inorganic) applied to grassland</p>
                </div>

				<FertTable rowsData={fertilisers} setRowsData={setFertilisers} />

				<InputButtonsContainer className='my-2'>
					<InputButton onClick={() => navigate(`/report/${report?.id}`)}>BACK</InputButton>
					<InputButton onClick={() => {
						toast.dismiss()
						toast.info("Saving...")
						saveHandler()
					}}>SAVE</InputButton>
					<InputButton onClick={() => {
						toast.dismiss()
						toast.info("Saving...");
						setNextPage(true);
						saveHandler();
					}}>SAVE & CONTINUE</InputButton>
				</InputButtonsContainer>

			</div>
		</>
	)
}

export default OrganicFertilisers