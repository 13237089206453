import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { InputButton, InputButtonsContainer } from '../../styles/input/Input.styled';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { ILandUse } from '../../../Interfaces';
import { putLandUse, resetFarmDataId, resetUpdate } from '../../../features/farmData/farmDataSlice';
import { ToastContainer, toast } from 'react-toastify';

type Props = {}

const LandUse = (props: Props) => {

    const [permanentGrass, setPermanentGrass] = useState(0);
    const [temporaryGrass, setTemporaryGrass] = useState(0);
    const [forestry, setForestry] = useState(0);
    const [otherCrops, setOtherCrops] = useState(0);
    const [buildingsYardsAndRoadWays, setBuildingsYardsAndRoadWays] = useState(0);
    const [pulledData, setPulledData] = useState(false);

    const { report } = useAppSelector((state) => state.report);
    const { fullFarmData, success, error } = useAppSelector((state) => state.farmData)

    const navigate = useNavigate();
    const dispatch = useAppDispatch();


    useEffect(() => {
        if (!pulledData) {
            if (fullFarmData?.farmBreakdown.landUse) {
                setPermanentGrass(fullFarmData?.farmBreakdown.landUse.permanentGrass);
                setTemporaryGrass(fullFarmData?.farmBreakdown.landUse.temporaryGrass);
                setForestry(fullFarmData?.farmBreakdown.landUse.forestry);
                setOtherCrops(fullFarmData?.farmBreakdown.landUse.otherCrops);
                setBuildingsYardsAndRoadWays(fullFarmData?.farmBreakdown.landUse.buildingsYardsAndRoadWays);
            }
            setPulledData(false);
        }

        if (success) {
            toast.dismiss();
            toast.success("Input saved successfully");
            dispatch(resetUpdate());
            dispatch(resetFarmDataId());
            navigate(`/report/${report?.id}`);
        }

    }, [dispatch, fullFarmData?.farmBreakdown.animalsOnFarm, fullFarmData?.farmBreakdown.landUse, navigate, pulledData, report?.id, success])

    const nextHandler = () => {

        // validation
        toast.info("Saving progress...");
        dispatch(putLandUse({
            id: fullFarmData?.farmData.id!, landUse: {
                permanentGrass,
                temporaryGrass,
                forestry,
                otherCrops,
                buildingsYardsAndRoadWays
            }
        }))
    }

    return (
        <>
            <div><h4><strong>ABOUT YOUR FARM - LAND USE CHANGE</strong></h4></div>

            <div className='my-3'>
                <p>In this section, we want to learn about the breakdown of your farm, your land use, any changes in landuse, soil type and farm appearance</p>
            </div>

            <div className='my-4 row d-flex align-items-center'>
                <div className='col-12 col-sm-6'>
                    <label htmlFor='permanentGrass'><strong>Permanent Grass (Ha) - Land which has been grassland for five years or more</strong></label>
                </div>
                <div className='col-6'>
                    <input
                        name="permanentGrass"
                        type='number'
                        value={permanentGrass}
                        onChange={(e) => setPermanentGrass(Number(e.target.value))}
                        className="form-control"
                        min={0}
                    />
                </div>
            </div>
            <div className='my-4 row d-flex align-items-center'>
                <div className='col-12 col-sm-6'>
                    <label htmlFor='temporaryGrass'><strong>Temporary Grass (Ha) - Land which has been grassland for less than five years</strong></label>
                </div>
                <div className='col-6'>
                    <input
                        name="temporaryGrass"
                        type='number'
                        value={temporaryGrass}
                        onChange={(e) => setTemporaryGrass(Number(e.target.value))}
                        className="form-control"
                        min={0}
                    />
                </div>
            </div>
            <div className='my-4 row d-flex align-items-center'>
                <div className='col-12 col-sm-6'>
                    <label htmlFor='forestry'><strong>Forestry (Ha)</strong></label>
                </div>
                <div className='col-6'>
                    <input
                        name="forestry"
                        type='number'
                        value={forestry}
                        onChange={(e) => setForestry(Number(e.target.value))}
                        className="form-control"
                        min={0}
                    />
                </div>
            </div>
            <div className='my-4 row d-flex align-items-center'>
                <div className='col-12 col-sm-6'>
                    <label htmlFor='otherCrops'><strong>Other Crops (Ha)</strong></label>
                </div>
                <div className='col-6'>
                    <input
                        name="otherCrops"
                        type='number'
                        value={otherCrops}
                        onChange={(e) => setOtherCrops(Number(e.target.value))}
                        className="form-control"
                        min={0}
                    />
                </div>

            </div>
            <div className='my-4 row d-flex align-items-center'>
                <div className='col-12 col-sm-6'>
                    <label htmlFor='buildingsYardsAndRoadWays'><strong>Buildings, Yards and RoadWays (Ha)</strong></label>
                </div>
                <div className='col-6'>
                    <input
                        name="buildingsYardsAndRoadWays"
                        type='number'
                        value={buildingsYardsAndRoadWays}
                        onChange={(e) => setBuildingsYardsAndRoadWays(Number(e.target.value))}
                        className="form-control"
                        min={0}
                    />
                </div>
            </div>

            {/* <div className='my-3'>
                <label>Total Grassland Area (Ha)</label>
                <div>{permanentGrass + temporaryGrass}</div>
            </div>
            <div className='my-3'>
                <label>Total Farm Area (Ha)</label>
                <div>{permanentGrass + temporaryGrass + forestry + otherCrops + buildingsYardsAndRoadWays}</div>
            </div>
            <div className='my-3'>
                <label>UAA</label>
                <div>{permanentGrass + temporaryGrass + forestry}</div>
            </div> */}

            <InputButtonsContainer>
                <InputButton onClick={() => navigate(`/report/${report?.id}`)}>BACK</InputButton>
                <InputButton onClick={nextHandler}>NEXT</InputButton>
            </InputButtonsContainer>
        </>
    )
}

export default LandUse