import React from 'react'
import { IAnimalsOnFarm } from '../../../../Interfaces';

type Props = {
    sheepOnFarm: IAnimalsOnFarm[];
    updateSheepOnFarm: (animalSubGroup: number, key: string, value: string | null | Date | number) => void
}

const Sheep = (props: Props) => {
    return (
        <>
            <tr>
                <td colSpan={7}><p className='mt-2'><strong><u>Sheep for meat</u></strong></p></td>
            </tr>
            <tr>
                <td><p><strong>Ewes</strong></p></td>
                <td>
                    <label className='ui-table-cell-label' htmlFor='AverageNumPerMonthEwesForMeat'>Average number per month</label>
                    <input
                        type="number"
                        value={props.sheepOnFarm.find(element => element.animalSubGroup === 16)?.averageNumPerMonth}
                        onChange={(e) => props.updateSheepOnFarm(16, "averageNumPerMonth", e.target.valueAsNumber)}
                        name="AverageNumPerMonthEwesForMeat"
                        className="form-control"
                        min="0"
                    />
                </td>
                <td>
                    <label className='ui-table-cell-label' htmlFor='NumSoldRearingEwesForMeat'>Number sold</label>
                    <input
                        type="number"
                        value={props.sheepOnFarm.find(element => element.animalSubGroup === 16)?.numSold}
                        onChange={(e) => props.updateSheepOnFarm(16, "numSold", e.target.valueAsNumber)}
                        name="NumSoldRearingEwesForMeat"
                        className="form-control"
                        min="0"
                    />
                </td>
                <td>
                    <label className='ui-table-cell-label' htmlFor='MeanLiveWeightTakenAtSaleEwesForMeat'>Mean live weight taken at sale</label>
                    <input
                        type="number"
                        value={props.sheepOnFarm.find(element => element.animalSubGroup === 16)?.meanLiveWeightTakenAtSale}
                        onChange={(e) => props.updateSheepOnFarm(16, "meanLiveWeightTakenAtSale", e.target.valueAsNumber)}
                        name="MeanLiveWeightTakenAtSaleEwesForMeat"
                        className="form-control"
                        min="0"
                    />
                </td>
                <td>
                    <label className='ui-table-cell-label' htmlFor='DaysAtGrassEwesForMeat'>Days at grass</label>
                    <input
                        type="number"
                        value={props.sheepOnFarm.find(element => element.animalSubGroup === 16)?.daysAtGrass}
                        onChange={(e) => props.updateSheepOnFarm(16, "daysAtGrass", e.target.valueAsNumber)}
                        name="DaysAtGrassEwesForMeat"
                        className="form-control"
                        min="0"
                    />
                </td>
                <td>
                    <label className='ui-table-cell-label' htmlFor='HoursPerDayAtGrassEwesForMeat'>Hours per day at grass</label>
                    <input
                        type="number"
                        value={props.sheepOnFarm.find(element => element.animalSubGroup === 16)?.hoursPerDayAtGrass}
                        onChange={(e) => props.updateSheepOnFarm(16, "hoursPerDayAtGrass", e.target.valueAsNumber)}
                        name="HoursPerDayAtGrassEwesForMeat"
                        className="form-control"
                        min="0"
                    />
                </td>
            </tr>
            <tr>
                <td><p><strong>Lambs</strong></p></td>
                <td>
                    <label className='ui-table-cell-label' htmlFor='AverageNumPerMonthLambsForMeat'>Average number per month</label>
                    <input
                        type="number"
                        value={props.sheepOnFarm.find(element => element.animalSubGroup === 17)?.averageNumPerMonth}
                        onChange={(e) => props.updateSheepOnFarm(17, "averageNumPerMonth", e.target.valueAsNumber)}
                        name="AverageNumPerMonthLambsForMeat"
                        className="form-control"
                        min="0"
                    />
                </td>
                <td>
                    <label className='ui-table-cell-label' htmlFor='NumSoldRearingLambsForMeat'>Number sold</label>
                    <input
                        type="number"
                        value={props.sheepOnFarm.find(element => element.animalSubGroup === 17)?.numSold}
                        onChange={(e) => props.updateSheepOnFarm(17, "numSold", e.target.valueAsNumber)}
                        name="NumSoldRearingLambsForMeat"
                        className="form-control"
                        min="0"
                    />
                </td>
                <td>
                    <label className='ui-table-cell-label' htmlFor='MeanLiveWeightTakenAtSaleLambsForMeat'>Mean live weight taken at sale</label>
                    <input
                        type="number"
                        value={props.sheepOnFarm.find(element => element.animalSubGroup === 17)?.meanLiveWeightTakenAtSale}
                        onChange={(e) => props.updateSheepOnFarm(17, "meanLiveWeightTakenAtSale", e.target.valueAsNumber)}
                        name="MeanLiveWeightTakenAtSaleLambsForMeat"
                        className="form-control"
                        min="0"
                    />
                </td>
                <td>
                    <label className='ui-table-cell-label' htmlFor='DaysAtGrassLambsForMeat'>Days at grass</label>
                    <input
                        type="number"
                        value={props.sheepOnFarm.find(element => element.animalSubGroup === 17)?.daysAtGrass}
                        onChange={(e) => props.updateSheepOnFarm(17, "daysAtGrass", e.target.valueAsNumber)}
                        name="DaysAtGrassLambsForMeat"
                        className="form-control"
                        min="0"
                    />
                </td>
                <td>
                    <label className='ui-table-cell-label' htmlFor='HoursPerDayAtGrassLambsForMeat'>Hours per day at grass</label>
                    <input
                        type="number"
                        value={props.sheepOnFarm.find(element => element.animalSubGroup === 17)?.hoursPerDayAtGrass}
                        onChange={(e) => props.updateSheepOnFarm(17, "hoursPerDayAtGrass", e.target.valueAsNumber)}
                        name="HoursPerDayAtGrassLambsForMeat"
                        className="form-control"
                        min="0"
                    />
                </td>
            </tr>
            <tr>
                <td><p><strong>Breeding rams</strong></p></td>
                <td>
                    <label className='ui-table-cell-label' htmlFor='AverageNumPerMonthBreedingBullsForMeat'>Average number per month</label>
                    <input
                        type="number"
                        value={props.sheepOnFarm.find(element => element.animalSubGroup === 18)?.averageNumPerMonth}
                        onChange={(e) => props.updateSheepOnFarm(18, "averageNumPerMonth", e.target.valueAsNumber)}
                        name="AverageNumPerMonthBreedingBullsForMeat"
                        className="form-control"
                        min="0"
                    />
                </td>
                <td>
                    <label className='ui-table-cell-label' htmlFor='NumSoldRearingBreedingBullsForMeat'>Number sold</label>
                    <input
                        type="number"
                        value={props.sheepOnFarm.find(element => element.animalSubGroup === 18)?.numSold}
                        onChange={(e) => props.updateSheepOnFarm(18, "numSold", e.target.valueAsNumber)}
                        name="NumSoldRearingBreedingBullsForMeat"
                        className="form-control"
                        min="0"
                    />
                </td>
                <td>
                    <label className='ui-table-cell-label' htmlFor='MeanLiveWeightTakenAtSaleBreedingBullsForMilk'>Mean live weight taken at sale</label>
                    <input
                        type="number"
                        value={props.sheepOnFarm.find(element => element.animalSubGroup === 18)?.meanLiveWeightTakenAtSale}
                        onChange={(e) => props.updateSheepOnFarm(18, "meanLiveWeightTakenAtSale", e.target.valueAsNumber)}
                        name="MeanLiveWeightTakenAtSaleBreedingBullsForMeat"
                        className="form-control"
                        min="0"
                    />
                </td>
                <td>
                    <label className='ui-table-cell-label' htmlFor='DaysAtGrassBreedingBullsForMilk'>Days at grass</label>
                    <input
                        type="number"
                        value={props.sheepOnFarm.find(element => element.animalSubGroup === 18)?.daysAtGrass}
                        onChange={(e) => props.updateSheepOnFarm(18, "daysAtGrass", e.target.valueAsNumber)}
                        name="DaysAtGrassBreedingBullsForMeat"
                        className="form-control"
                        min="0"
                    />
                </td>
                <td>
                    <label className='ui-table-cell-label' htmlFor='HoursPerDayAtGrassBreedingBullsForMilk'>Hours per day at grass</label>
                    <input
                        type="number"
                        value={props.sheepOnFarm.find(element => element.animalSubGroup === 18)?.hoursPerDayAtGrass}
                        onChange={(e) => props.updateSheepOnFarm(18, "hoursPerDayAtGrass", e.target.valueAsNumber)}
                        name="HoursPerDayAtGrassBreedingBullsForMeat"
                        className="form-control"
                        min="0"
                    />
                </td>
            </tr>
            <tr>
                <td colSpan={7}><p className='mt-2'><strong><u>Sheep for milk</u></strong></p></td>
            </tr>
            <tr>
                <td><p><strong>Ewes</strong></p></td>
                <td>
                    <label className='ui-table-cell-label' htmlFor='AverageNumPerMonthEwesForMilk'>Average number per month</label>
                    <input
                        type="number"
                        value={props.sheepOnFarm.find(element => element.animalSubGroup === 19)?.averageNumPerMonth}
                        onChange={(e) => props.updateSheepOnFarm(19, "averageNumPerMonth", e.target.valueAsNumber)}
                        name="AverageNumPerMonthEwesForMilk"
                        className="form-control"
                        min="0"
                    />
                </td>
                <td>
                    <label className='ui-table-cell-label' htmlFor='NumSoldEwesForMilk'>Number sold</label>
                    <input
                        type="number"
                        value={props.sheepOnFarm.find(element => element.animalSubGroup === 19)?.numSold}
                        onChange={(e) => props.updateSheepOnFarm(19, "numSold", e.target.valueAsNumber)}
                        name="NumSoldEwesForMilk"
                        className="form-control"
                        min="0"
                    />
                </td>
                <td>
                    <label className='ui-table-cell-label' htmlFor='MeanLiveWeightTakenAtSaleEwesForMilk'>Mean live weight taken at sale</label>
                    <input
                        type="number"
                        value={props.sheepOnFarm.find(element => element.animalSubGroup === 19)?.meanLiveWeightTakenAtSale}
                        onChange={(e) => props.updateSheepOnFarm(19, "meanLiveWeightTakenAtSale", e.target.valueAsNumber)}
                        name="MeanLiveWeightTakenAtSaleEwesForMilk"
                        className="form-control"
                        min="0"
                    />
                </td>
                <td>
                    <label className='ui-table-cell-label' htmlFor='DaysAtGrassEwesForMilk'>Days at grass</label>
                    <input
                        type="number"
                        value={props.sheepOnFarm.find(element => element.animalSubGroup === 19)?.daysAtGrass}
                        onChange={(e) => props.updateSheepOnFarm(19, "daysAtGrass", e.target.valueAsNumber)}
                        name="DaysAtGrassEwesForMilk"
                        className="form-control"
                        min="0"
                    />
                </td>
                <td>
                    <label className='ui-table-cell-label' htmlFor='HoursPerDayAtGrassEwesForMilk'>Hours per day at grass</label>
                    <input
                        type="number"
                        value={props.sheepOnFarm.find(element => element.animalSubGroup === 19)?.hoursPerDayAtGrass}
                        onChange={(e) => props.updateSheepOnFarm(19, "hoursPerDayAtGrass", e.target.valueAsNumber)}
                        name="HoursPerDayAtGrassEwesForMilk"
                        className="form-control"
                        min="0"
                    />
                </td>
            </tr>
            <tr>
                <td><p><strong>Lambs</strong></p></td>
                <td>
                    <label className='ui-table-cell-label' htmlFor='AverageNumPerMonthLambsForMilk'>Average number per month</label>
                    <input
                        type="number"
                        value={props.sheepOnFarm.find(element => element.animalSubGroup === 20)?.averageNumPerMonth}
                        onChange={(e) => props.updateSheepOnFarm(20, "averageNumPerMonth", e.target.valueAsNumber)}
                        name="AverageNumPerMonthLambsForMilk"
                        className="form-control"
                        min="0"
                    />
                </td>
                <td>
                    <label className='ui-table-cell-label' htmlFor='NumSoldLambsForMilk'>Number sold</label>
                    <input
                        type="number"
                        value={props.sheepOnFarm.find(element => element.animalSubGroup === 20)?.numSold}
                        onChange={(e) => props.updateSheepOnFarm(20, "numSold", e.target.valueAsNumber)}
                        name="NumSoldLambsForMilk"
                        className="form-control"
                        min="0"
                    />
                </td>
                <td>
                    <label className='ui-table-cell-label' htmlFor='MeanLiveWeightTakenAtSaleLambsForMilk'>Mean live weight taken at sale</label>
                    <input
                        type="number"
                        value={props.sheepOnFarm.find(element => element.animalSubGroup === 20)?.meanLiveWeightTakenAtSale}
                        onChange={(e) => props.updateSheepOnFarm(20, "meanLiveWeightTakenAtSale", e.target.valueAsNumber)}
                        name="MeanLiveWeightTakenAtSaleLambsForMilk"
                        className="form-control"
                        min="0"
                    />
                </td>
                <td>
                    <label className='ui-table-cell-label' htmlFor='DaysAtGrassLambsForMilk'>Days at grass</label>
                    <input
                        type="number"
                        value={props.sheepOnFarm.find(element => element.animalSubGroup === 20)?.daysAtGrass}
                        onChange={(e) => props.updateSheepOnFarm(20, "daysAtGrass", e.target.valueAsNumber)}
                        name="DaysAtGrassLambsForMilk"
                        className="form-control"
                        min="0"
                    />
                </td>
                <td>
                    <label className='ui-table-cell-label' htmlFor='HoursPerDayAtGrassLambsForMilk'>Hours per day at grass</label>
                    <input
                        type="number"
                        value={props.sheepOnFarm.find(element => element.animalSubGroup === 20)?.hoursPerDayAtGrass}
                        onChange={(e) => props.updateSheepOnFarm(20, "hoursPerDayAtGrass", e.target.valueAsNumber)}
                        name="HoursPerDayAtGrassLambsForMilk"
                        className="form-control"
                        min="0"
                    />
                </td>
            </tr>
            <tr>
                <td><p><strong>Breeding rams</strong></p></td>
                <td>
                    <label className='ui-table-cell-label' htmlFor='AverageNumPerMonthBreedingBullsForMilk'>Average number per month</label>
                    <input
                        type="number"
                        value={props.sheepOnFarm.find(element => element.animalSubGroup === 21)?.averageNumPerMonth}
                        onChange={(e) => props.updateSheepOnFarm(21, "averageNumPerMonth", e.target.valueAsNumber)}
                        name="AverageNumPerMonthBreedingBullsForMilk"
                        className="form-control"
                        min="0"
                    />
                </td>
                <td>
                    <label className='ui-table-cell-label' htmlFor='NumSoldRearingBreedingBullsForMilk'>Number sold</label>
                    <input
                        type="number"
                        value={props.sheepOnFarm.find(element => element.animalSubGroup === 21)?.numSold}
                        onChange={(e) => props.updateSheepOnFarm(21, "numSold", e.target.valueAsNumber)}
                        name="NumSoldRearingBreedingBullsForMilk"
                        className="form-control"
                        min="0"
                    />
                </td>
                <td>
                    <label className='ui-table-cell-label' htmlFor='MeanLiveWeightTakenAtSaleBreedingBullsForMilk'>Mean live weight taken at sale</label>
                    <input
                        type="number"
                        value={props.sheepOnFarm.find(element => element.animalSubGroup === 21)?.meanLiveWeightTakenAtSale}
                        onChange={(e) => props.updateSheepOnFarm(21, "meanLiveWeightTakenAtSale", e.target.valueAsNumber)}
                        name="MeanLiveWeightTakenAtSaleBreedingBullsForMilk"
                        className="form-control"
                        min="0"
                    />
                </td>
                <td>
                    <label className='ui-table-cell-label' htmlFor='DaysAtGrassBreedingBullsForMilk'>Days at grass</label>
                    <input
                        type="number"
                        value={props.sheepOnFarm.find(element => element.animalSubGroup === 21)?.daysAtGrass}
                        onChange={(e) => props.updateSheepOnFarm(21, "daysAtGrass", e.target.valueAsNumber)}
                        name="DaysAtGrassBreedingBullsForMilk"
                        className="form-control"
                        min="0"
                    />
                </td>
                <td>
                    <label className='ui-table-cell-label' htmlFor='HoursPerDayAtGrassBreedingBullsForMilk'>Hours per day at grass</label>
                    <input
                        type="number"
                        value={props.sheepOnFarm.find(element => element.animalSubGroup === 21)?.hoursPerDayAtGrass}
                        onChange={(e) => props.updateSheepOnFarm(21, "hoursPerDayAtGrass", e.target.valueAsNumber)}
                        name="HoursPerDayAtGrassBreedingBullsForMilk"
                        className="form-control"
                        min="0"
                    />
                </td>
            </tr>
        </>
    )
}

export default Sheep