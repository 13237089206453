import { createGlobalStyle } from 'styled-components';



const montExtraLight = require('../../fonts/Mont-ExtraLightDEMO.otf');
const hkGroteskLight = require('../../fonts/HKGrotesk-Light.ttf')
const hkGroteskBold = require('../../fonts/hk-grotesk.bold.ttf');

const GlobalStyles = createGlobalStyle`
    // imports here

    @font-face {
        font-family: 'Mont Extra Light';
        src: url(${montExtraLight}) format('opentype');
    }

    @font-face {
        font-family: 'Hk Grotesk Light';
        src: url(${hkGroteskLight}) format('truetype');
    }

    @font-face {
        font-family: 'Hk Grotesk Bold';
        src: url(${hkGroteskBold}) format('truetype');
    }
    
    * {
        box-sizing: border-box;
        font-family: 'Hk Grotesk Light' 
    }

    body {
        margin: 0;
    }
    
    p {
        line-height: 1.5;
    }

    span {
        font-size: 14px;
    }

    img {
        max-width: 100%;
    }

    td {
        vertical-align: middle !important;
    }
`

export default GlobalStyles