import React from 'react'
import { IAnimalsOnFarm } from '../../../../Interfaces';

type Props = {
  goatsOnFarm: IAnimalsOnFarm[];
  updateGoatsOnFarm: (animalSubGroup: number, key: string, value: string | null | Date | number) => void
}

const Goats = (props: Props) => {
  return (
    <>
      <tr>
        <td colSpan={7}><p className='mt-2'><strong><u>Goats</u></strong></p></td>
      </tr>
      <tr>
        <td><p><strong>Goats for meat</strong></p></td>
        <td>
          <label className='ui-table-cell-label' htmlFor='AverageNumPerMonthGoatsForMeat'>Average number per month</label>
          <input
            type="number"
            value={props.goatsOnFarm.find(element => element.animalSubGroup === 22)?.averageNumPerMonth}
            onChange={(e) => props.updateGoatsOnFarm(22, "averageNumPerMonth", e.target.valueAsNumber)}
            name="AverageNumPerMonthGoatsForMeat"
            className="form-control"
            min="0"
          />
        </td>
        <td>
          <label className='ui-table-cell-label' htmlFor='NumSoldGoatsForMeat'>Number sold</label>
          <input
            type="number"
            value={props.goatsOnFarm.find(element => element.animalSubGroup === 22)?.numSold}
            onChange={(e) => props.updateGoatsOnFarm(22, "numSold", e.target.valueAsNumber)}
            name="NumSoldIntensiveGoatsForMeat"
            className="form-control"
            min="0"
          />
        </td>
        <td>
          <label className='ui-table-cell-label' htmlFor='MeanLiveWeightTakenAtSaleGoatsForMeat'>Mean live weight taken at sale</label>
          <input
            type="number"
            value={props.goatsOnFarm.find(element => element.animalSubGroup === 22)?.meanLiveWeightTakenAtSale}
            onChange={(e) => props.updateGoatsOnFarm(22, "meanLiveWeightTakenAtSale", e.target.valueAsNumber)}
            name="MeanLiveWeightTakenAtSaleGoatsForMeat"
            className="form-control"
            min="0"
          />
        </td>
        <td>
          <label className='ui-table-cell-label' htmlFor='DaysAtGrassGoatsForMeat'>Days at grass</label>
          <input
            type="number"
            value={props.goatsOnFarm.find(element => element.animalSubGroup === 22)?.daysAtGrass}
            onChange={(e) => props.updateGoatsOnFarm(22, "daysAtGrass", e.target.valueAsNumber)}
            name="DaysAtGrassGoatsForMeat"
            className="form-control"
            min="0"
          />
        </td>
        <td>
          <label className='ui-table-cell-label' htmlFor='HoursPerDayAtGrassGoatsForMeat'>Hours per day at grass</label>
          <input
            type="number"
            value={props.goatsOnFarm.find(element => element.animalSubGroup === 22)?.hoursPerDayAtGrass}
            onChange={(e) => props.updateGoatsOnFarm(22, "hoursPerDayAtGrass", e.target.valueAsNumber)}
            name="HoursPerDayAtGrassGoatsForMilk"
            className="form-control"
            min="0"
          />
        </td>
      </tr>
      <tr>
        <td><p><strong>Goats for milk</strong></p></td>
        <td>
          <label className='ui-table-cell-label' htmlFor='AverageNumPerMonthGoatsForMilk'>Average number per month</label>
          <input
            type="number"
            value={props.goatsOnFarm.find(element => element.animalSubGroup === 23)?.averageNumPerMonth}
            onChange={(e) => props.updateGoatsOnFarm(23, "averageNumPerMonth", e.target.valueAsNumber)}
            name="AverageNumPerMonthGoatsForMilk"
            className="form-control"
            min="0"
          />
        </td>
        <td>
          <label className='ui-table-cell-label' htmlFor='NumSoldGoatsForMilk'>Number sold</label>
          <input
            type="number"
            value={props.goatsOnFarm.find(element => element.animalSubGroup === 23)?.numSold}
            onChange={(e) => props.updateGoatsOnFarm(23, "numSold", e.target.valueAsNumber)}
            name="NumSoldGoatsForMilk"
            className="form-control"
            min="0"
          />
        </td>
        <td>
          <label className='ui-table-cell-label' htmlFor='MeanLiveWeightTakenAtSaleGoatsForMilk'>Mean live weight taken at sale</label>
          <input
            type="number"
            value={props.goatsOnFarm.find(element => element.animalSubGroup === 23)?.meanLiveWeightTakenAtSale}
            onChange={(e) => props.updateGoatsOnFarm(23, "meanLiveWeightTakenAtSale", e.target.valueAsNumber)}
            name="MeanLiveWeightTakenAtSaleGoatsForMilk"
            className="form-control"
            min="0"
          />
        </td>
        <td>
          <label className='ui-table-cell-label' htmlFor='DaysAtGrassGoatsForMilk'>Days at grass</label>
          <input
            type="number"
            value={props.goatsOnFarm.find(element => element.animalSubGroup === 23)?.daysAtGrass}
            onChange={(e) => props.updateGoatsOnFarm(23, "daysAtGrass", e.target.valueAsNumber)}
            name="DaysAtGrassGoatsForMilk"
            className="form-control"
            min="0"
          />
        </td>
        <td>
          <label className='ui-table-cell-label' htmlFor='HoursPerDayAtGrassGoatsForMilk'>Hours per day at grass</label>
          <input
            type="number"
            value={props.goatsOnFarm.find(element => element.animalSubGroup === 23)?.hoursPerDayAtGrass}
            onChange={(e) => props.updateGoatsOnFarm(23, "hoursPerDayAtGrass", e.target.valueAsNumber)}
            name="HoursPerDayAtGrassGoatsForMilk"
            className="form-control"
            min="0"
          />
        </td>
      </tr>
    </>
  )
}

export default Goats