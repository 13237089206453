import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { IAnimalsOnFarm } from '../../../../Interfaces';
import { ToastContainer, toast } from 'react-toastify';
import { getFullFarmDataById, putAnimalsOnFarm, resetUpdate, updateCurrentAnimalOnFarmIndex } from '../../../../features/farmData/farmDataSlice';
import { InputButton, InputButtonsContainer } from '../../../styles/input/Input.styled';
import AllEquidaeRows from '../animalsOnFarm/AllEquidae';

type Props = {}

const AllEquidae = (props: Props) => {


    const [equidaeOnFarm, setEquidaeOnFarm] = useState<IAnimalsOnFarm[]>([]);
    const [localAnimalsOnFarmIndex, setLocalAnimalsOnFarmIndex] = useState(0);
    const [pulledData, setPulledData] = useState(false);

    const { report } = useAppSelector((state) => state.report);
    const { fullFarmData, success, error, selectedAnimalsOnFarm, currentAnimalOnFarmIndex } = useAppSelector((state) => state.farmData)

    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!pulledData) {
            if (fullFarmData?.farmBreakdown.animalsOnFarm) {
                var data = fullFarmData?.farmBreakdown.animalsOnFarm.filter(element => element.animalSubGroup === 13)
                if (data.length === 0) {
                    var defaultData = [];
                    for (let i = 13; i < 14; i++) {
                        defaultData.push({
                            animalSubGroup: i,
                            averageNumPerMonth: 0,
                            numSold: 0,
                            meanLiveWeightTakenAtSale: 0,
                            daysAtGrass: 0,
                            hoursPerDayAtGrass: 0
                        })
                    }
                    setEquidaeOnFarm(defaultData);
                } else {
                    setEquidaeOnFarm(data);
                }
            }
            if (currentAnimalOnFarmIndex) setLocalAnimalsOnFarmIndex(currentAnimalOnFarmIndex);
            setPulledData(false);
        }

        if (success) {
            let farmDataId = report?.farmData;
            dispatch(getFullFarmDataById({ id: farmDataId! }))
            toast.dismiss();
            toast.success("Input saved");
            dispatch(resetUpdate());
            if (selectedAnimalsOnFarm!.length <= currentAnimalOnFarmIndex! + 1) {
                navigate('/input/landUse')
            } else {
                dispatch(updateCurrentAnimalOnFarmIndex());
                navigate(`/input/stockingRate/${selectedAnimalsOnFarm![localAnimalsOnFarmIndex + 1]}`);
            }
        }

    }, [currentAnimalOnFarmIndex, dispatch, fullFarmData?.farmBreakdown.animalsOnFarm, localAnimalsOnFarmIndex, navigate, pulledData, report?.farmData, selectedAnimalsOnFarm, success])

    const updateEquidaeOnFarm = (animalSubGroup: number, key: string, value: string | null | Date | number) => {
        var animalRecordExists = equidaeOnFarm.filter(element => element.animalSubGroup === animalSubGroup).length > 0
        var newEquidaeOnFarm;

        if (animalRecordExists) {
            newEquidaeOnFarm = equidaeOnFarm.map(item =>
                item.animalSubGroup === animalSubGroup ? { ...item, [key]: value } : item
            );
        } else {
            newEquidaeOnFarm = [...equidaeOnFarm, { animalSubGroup, [key]: value }]
        }
        // console.log(newEquidaeOnFarm);
        setEquidaeOnFarm(newEquidaeOnFarm);
    }

    const nextHandler = () => {

        // validation
        if (equidaeOnFarm.length !== 1) {
            toast.dismiss();
            toast.error("Please complete inputs for all sub groups")
            return
        }

        equidaeOnFarm.forEach(element => {
            if (element.averageNumPerMonth === undefined) {
                toast.dismiss();
                toast.error("Please complete inputs for the Average Num Per Month column")
                return;
            }
            if (element.numSold === undefined) {
                toast.dismiss();
                toast.error("Please complete inputs for the Num Sold column")
                return;
            }
            if (element.meanLiveWeightTakenAtSale === undefined) {
                toast.dismiss();
                toast.error("Please complete inputs for the Mean Live Weight Taken At Sale column")
                return;
            }
            if (element.daysAtGrass === undefined) {
                toast.dismiss();
                toast.error("Please complete inputs for the Days At Grass column")
                return;
            }
            if (element.hoursPerDayAtGrass === undefined) {
                toast.dismiss();
                toast.error("Please complete inputs for the Hours Per Day At Grass column")
                return;
            }
        })

        toast.info("Saving progress...");
        dispatch(putAnimalsOnFarm({ id: fullFarmData?.farmData.id!, animalsOnFarm: equidaeOnFarm }))
    }

    return (
        <>
            <div><h4><strong>ABOUT YOUR FARM - ANIMALS ON FARM</strong></h4></div>

            <div className='my-3'>
                <p>In this section, we want to learn about the breakdown of your farm, your land use, any changes in landuse, soil type and farm appearance</p>
            </div>

            <table data-role="table" data-mode="reflow" className="ui-table ui-table-reflow my-custom-breakpoint my-4">
                <thead>
                    <tr >
                        <th data-priority="1"></th>
                        <th data-priority="2">Average number per month</th>
                        <th data-priority="3">Number sold</th>
                        <th data-priority="4">Mean live weight at sale (kg)</th>
                        <th data-priority="5">Days at grass</th>
                        <th data-priority="6">Hours per day at grass</th>
                    </tr>
                </thead>
                <tbody>
                    <AllEquidaeRows equidaeOnFarm={equidaeOnFarm} updateEquidaeOnFarm={updateEquidaeOnFarm} />
                </tbody>
            </table>

            <InputButtonsContainer>
                <InputButton onClick={() => navigate(`/report/${report?.id}`)}>CANCEL</InputButton>
                <InputButton onClick={nextHandler}>NEXT</InputButton>
            </InputButtonsContainer>
        </>
    )
}

export default AllEquidae