import React, { useEffect, useState } from 'react';
import { AuthForm, AuthFormLink, AuthFormTitle, StyledAuthFormLink } from '../styles/Form.styled';
import { AuthInput } from '../styles/input/Input.styled';
import { AuthButton } from '../styles/Button.styled';
import { AuthSpan, AuthSvg } from '../styles/Span.styled';
import { AuthInputContainer } from '../styles/Container.styled';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { clearErrors, clearMessage, forgotPassword, loginUser, resetPassword } from '../../features/auth/authSlice';

type Props = {}

const ResetPassword = (props: Props) => {

    const [password, setPassword] = useState("");

    const [searchParams] = useSearchParams();

    const { isAuthenticated, user, loading, message,  errors } = useAppSelector((state) => state.auth);

    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (message) {
            toast.dismiss();
            toast.success(message);
            dispatch(clearMessage());
            navigate("/");
        }

        if (typeof errors === 'string') {
            toast.dismiss();
            toast.error(errors);
            dispatch(clearErrors());
        }else if (errors) {
            toast.dismiss();
            if (Array.isArray(errors)) {
                errors.forEach(element => {
                    toast.error(element.description);
                }) 
            } else {
                (errors as { Password?: string[] }).Password?.forEach(element => {
                    toast.error(element);
                });
                
                (errors as { Token?: string[] }).Token?.forEach(element => {
                    toast.error(element);
                })
            }
            dispatch(clearErrors());
        }
    }, [dispatch, errors, isAuthenticated, navigate, message])

    const togglePassword = () => {
        var x = document.getElementById("password") as HTMLInputElement | null;
        if (x != null) {
            if (x.type === "password") {
                x.type = "text";
            } else {
                x.type = "password";
            }
        }
    }

    const submitHandler = (e: React.FormEvent) => {
        e.preventDefault();

        const email = searchParams.get("email") ?? "";
        const token = searchParams.get("token") ?? "";
        if (email === "" || token === "") {
            toast.error("Check your reset link")
            return
        }

        if (password === "") {
            toast.error("Enter your password")
            return
        }

        toast.info("Resetting password...", {
            autoClose: false,
        });
        dispatch(resetPassword({ email, password, token }))
    }

    return (
        <>
            <div>
                <AuthForm>
                    <AuthFormTitle>New Password</AuthFormTitle>
                    <AuthInputContainer>
                        <AuthInput placeholder="Enter password" type="password" value={password} onChange={(e) => setPassword(e.target.value)} id='password' />
                        <AuthSpan onClick={togglePassword} style={{ cursor: "pointer" }}>
                            <AuthSvg stroke="currentColor" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" strokeWidth="2" strokeLinejoin="round" strokeLinecap="round"></path>
                                <path d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" strokeWidth="2" strokeLinejoin="round" strokeLinecap="round"></path>
                            </AuthSvg>
                        </AuthSpan>
                    </AuthInputContainer>
                    <AuthButton type="submit" onClick={submitHandler}>
                        Reset Password
                    </AuthButton>
                </AuthForm>

            </div>
        </>
    )
}

export default ResetPassword