import React from 'react'
import { Helmet } from 'react-helmet'

type Props = {
    title: string
}

const MetaData = (props: Props) => {
    return (
        <Helmet>
            <title>{`${props.title} - Super G DST`}</title>
        </Helmet>
    )
}

export default MetaData