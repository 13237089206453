import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosError } from "axios";
import { IFarmState } from "../../Interfaces";

axios.defaults.withCredentials = true;

const initialState: IFarmState = {
    loading: false,
}

export const getFarmerFarms = createAsyncThunk(
    "farm/getFarms",
    async (_, { rejectWithValue }) => {
        try {
            const { data } = await axios.get("/api/farm/farmer");
            return data;
        } catch (error) {
            if (error instanceof AxiosError) {
                return rejectWithValue(error.response?.data.message);
            }
            // unhandled non-AxiosError goes here
            throw error;
        }
    }
);

const farmSlice = createSlice({
    name: "farm",
    initialState,
    reducers: {
        clearErrors(state) {
            state.error = undefined;
        }
    },
    extraReducers(builder) {
        builder.addCase(getFarmerFarms.pending, (state, action) => {
            state.loading = true;
        })
        builder.addCase(getFarmerFarms.fulfilled, (state, action) => {
            state.farms = action.payload.farms;
            state.loading = false;
        })
        builder.addCase(getFarmerFarms.rejected, (state, action) => {
            state.error = action.payload;
            state.loading = false;
        })
    }
});

export const { clearErrors } = farmSlice.actions;
export default farmSlice.reducer;