import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosError } from "axios";
import { IIndicatorState } from "../../Interfaces";

axios.defaults.withCredentials = true;

const initialState: IIndicatorState = {
    loading: false,
}

export const getAllIndicators = createAsyncThunk(
    "indicator/all",
    async (_, { rejectWithValue }) => {
        try {
            const { data } = await axios.get("/api/indicator/all");
            return data;
        } catch (error) {
            if (error instanceof AxiosError) {
                return rejectWithValue(error.response?.data.message);
            }
            // unhandled non-AxiosError goes here
            throw error;
        }
    }
)

const indicatorSlice = createSlice({
    name: "indicator",
    initialState,
    reducers: {
        clearErrors(state) {
            state.error = undefined;
        }
    },
    extraReducers(builder) {
        builder.addCase(getAllIndicators.pending, (state, action) => {
            state.loading = true;
        })
        builder.addCase(getAllIndicators.fulfilled, (state, action) => {
            state.indicators = action.payload.indicators;
            state.loading = false;
        })
        builder.addCase(getAllIndicators.rejected, (state, action) => {
            state.error = action.payload;
            state.loading = false;
        })
    },
})

export const { clearErrors } = indicatorSlice.actions;
export default indicatorSlice.reducer;