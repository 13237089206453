import React, { ChangeEvent } from 'react'
import { IOrganicFertiliserApplied } from '../../../../../Interfaces'
import TableRows from './TableRows'
import { InputButton } from '../../../../styles/input/Input.styled'

type Props = {
    rowsData: IOrganicFertiliserApplied[],
    setRowsData: (e: IOrganicFertiliserApplied[]) => void
}

const Table = (props: Props) => {

    const addTableRows = () => {

        const rowsInput = {
            fertiliser: 0,
            kgNPerTonnesAnalysed: 0,
            totalTonnesProduced: 0,
            monthsOfStorage: 0,
            tonnesAppliedInSpring: 0,
            tonnesAppliedInSummer: 0,
            tonnesAppliedInAutumn: 0,
            tonnesAppliedInWinter: 0,
            isAnalysed: false,
        }
        props.setRowsData([...props.rowsData, rowsInput])

    }

    const deleteTableRows = (index: number) => {
        const rows = [...props.rowsData]
        rows.splice(index, 1)
        props.setRowsData(rows)
    }

    const handleChange = (index: number, e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value, checked } = e.target as { name: keyof IOrganicFertiliserApplied, value?: number | string, checked: boolean }
        const rowsInput = [...props.rowsData]
        if (name === "isAnalysed") {
            rowsInput[index] = { ...rowsInput[index], [name]: checked }
            // if(checked) {
            //     rowsInput[index] = { ...rowsInput[index], "nitrogenContentPercentage": 46, "phosphateContentPercentage": 0, "potashContentPercentage": 0 }
            // }
        } else {
            rowsInput[index] = { ...rowsInput[index], [name]: value }
        }
        props.setRowsData(rowsInput)
    }

    return (
        <>
            <div className='m-3 col-6 d-flex align-items-center'><InputButton className="text-center dynamic-table-btn mx-2" onClick={addTableRows} >+</InputButton> Add another fertiliser type</div>
            <div className="container col-12 d-flex justify-content-center">
                <div className="row">
                    <div>
                        <table data-role="table" data-mode="reflow" className="ui-table ui-table-reflow dynamic-table my-custom-breakpoint">

                            <thead>
                                <tr>
                                    <th data-priority="1">Is this fertiliser analysed?</th>
                                    <th data-priority="persist">Fertiliser</th>
                                    <th data-priority="2">Total tonnes produced</th>
                                    <th data-priority="3">Tonnes applied in spring</th>
                                    <th data-priority="4">Tonnes applied in summer</th>
                                    <th data-priority="5">Tonnes applied in autumn</th>
                                    <th data-priority="6">Tonnes applied in winter</th>
                                    <th data-priority="7">Months in storage</th>
                                    <th data-priority="8">Kg N per tonnes analysed</th>
                                    <th></th>
                                </tr>

                            </thead>
                            <tbody>
                                <TableRows rowsData={props.rowsData} deleteTableRows={deleteTableRows} handleChange={handleChange} />
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Table