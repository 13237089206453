import styled from "styled-components";
import ReactMapGl from 'react-map-gl';
import { fadeInFwd } from "./Container.styled";

export const ReportsContainer = styled.div`
    padding: 1rem;
    width: 80%;
    animation: ${fadeInFwd} 1s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
`

export const ReportsHeader = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
`

export const ReportsTitleWrapper = styled.div`
    text-align: left;
`

export const ReportsTitle = styled.h4`
    font-size: 32px;
    font-weight: 700;
    line-height: 39px;
    letter-spacing: 0em;
`

export const AddReportsButtonWrapper = styled.div`
    display: flex;
    justify-content: end;
`

export const AddReportsButton = styled.img`
    width: 50px;
    height: 50px;

    @media (max-width: 992px) {
        width: 35px;
        height: 35px;
    }
`;

export const ReportsBody = styled.div`
    margin: 2rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;

`;

export const ReportListItemContainer = styled.div`
    width: 100%;
    background: #FFFFFF;
    border: 1px solid #000000;
    border-radius: 15px;
    margin: 0.5rem 0;
    padding: 1rem 2rem;
    display: flex;
    align-items: center;
    min-height: 70px;

    @media (max-width: 550px) {
        padding: 1rem 1rem;
        flex-wrap: wrap;
        align-content: space-between;
    }

`;

export const ReportListItemInner = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
`;

export const ReportListItemInnerLeft = styled.div`
    text-align: center;
    width: 90%;

    @media (max-width: 992px) {
        width: 85%;
    }

    @media (max-width: 500px) {
        width: 70%;
    }
`;

export const ReportListItemInnerLeftTitle = styled.h5`
    font-weight: 400;
    font-size: 30px;
    line-height: 36px;
    margin: 0;

    @media (max-width: 500px) {
        font-size: 20px;
    }
`;

export const ReportListItemInnerLeftSubTitle = styled.h5`
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
`;

export const ReportListItemInnerRight = styled.div`
    width: 10%;
    display: flex;
    justify-content: space-between;

    @media (max-width: 992px) {
        width: 15%;
    }

    @media (max-width: 500px) {
        width: 30%;
    }
`

export const ReportDescriptionWrapper = styled.div`
    font-size: 18px;
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    text-wrap: balance;
`;

export const ReportDescriptionIcon = styled.img`
    width: 15px;
    height: 15px;
    margin-left: 3px;
    margin-right: 3px;
    display: in-line;
`;

export const ReportListItemInnerRightIcon = styled.img`
    width: 25px;
    height: 25px;
    cursor: pointer;
`

export const ReportMenuBarIcon = styled.img`
    width: 30px;
    height: 30px;
    margin: 10px;
    cursor: pointer;
    &:hover {
        transform: scale(0.98);
    }
`

export const NewReportContainer = styled.div`
    width: 80%;
    animation: ${fadeInFwd} 1s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
`

export const NewReportFormGroup = styled.div`
    margin: 1rem 0;
    padding: 0 1rem;
`

export const NewReportCheckboxInput = styled.input`
    margin: 0 2rem;
`
export const NewReportNewFarmGrid = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;

    @media (max-width: 768px) {
        grid-template-columns: 1fr;
    }
`;

export const StyledReactMapGl = styled(ReactMapGl)`
    width: 600px;
    height: 400px;
`;


export const NewReportHalfWidthFormGroup = styled.div`
    width: 50%;
    display: inline-block;
    padding: 0 1rem;
`

export const ReportTopBar = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 1rem 0;
    @media (max-width: 500px) {
        flex-direction: column;
    }
`

export const ReportMenuBar = styled.div`
    display: flex;
    justify-content: flex-end;
    margin: 1rem 0;
`;

export const ReportTopBarTextWrapper = styled.div`
    display: flex;
    justify-content: center;
`;

export const ReportContainer = styled.div`
    width: 80%;
    animation: ${fadeInFwd} 1s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
`;

export const ReportEcosystemServicesBar = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 2rem 0;

    @media (max-width: 992px) {
        display: none;
    }
`;

export const ReportEcosystemServicesDropdownContainer = styled.div`

    @media (min-width: 992px) {
        display: none;
    }
`;

export const ReportESBar = styled.div`
    display: flex;
    align-items: center;
    margin: 1.5rem 0;

    @media (max-width: 992px) {
        display: none;
    }
`;

export const ReportESBarMobile = styled.div`
    display: flex;
    align-items: center;
    margin: 1.5rem 0;

    h3 {
        margin: 0;
    }

    @media (min-width: 992px) {
        display: none;
    }
`;
export const SuggestionWrapper = styled.div`
  background: white;
  position: absolute;
  width: 400px;
  padding: 10px 20px;
  border-radius: 0px 0px 10px 10px;
`;

export const Suggestion = styled.p`
  cursor: pointer;
  max-width: 400px;
`;

