import React, { useState, useRef, useCallback, useEffect, FormEvent, ChangeEvent } from 'react'
import { MainButton, MainButtonWrapper } from '../styles/Button.styled'
import { useAppDispatch, useAppSelector } from '../../hooks';
import { NewReportCheckboxInput, NewReportContainer, NewReportFormGroup, NewReportHalfWidthFormGroup, NewReportNewFarmGrid, Suggestion, SuggestionWrapper, } from '../styles/Report.styled';
import { toast } from 'react-toastify';
import ReactMapGl, { Marker, NavigationControl } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import type { MarkerDragEvent, LngLat } from 'react-map-gl';
import Pin from './Pin';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { createReport, resetNewReport, clearErrors } from '../../features/report/reportSlice';
import { getFarmerFarms } from '../../features/farm/farmSlice';
import { INewReportData } from '../../Interfaces';
import MetaData from '../layout/MetaData';

type Props = {}

const initialMapViewport = {
    width: '100%',
    height: '100%',
    latitude: 40.78343,
    longitude: -73.96625,
    zoom: 11
}

const NewReport = (props: Props) => {

    const [name, setName] = useState("");
    const [year, setYear] = useState(new Date().getFullYear());
    const [isNewFarm, setIsNewFarm] = useState(false);
    const [farmId, setFarmId] = useState("");
    const [farmName, setFarmName] = useState("");
    const [location, setLocation] = useState("");
    const [viewport, setViewPort] = useState(initialMapViewport);
    const [latitude, setLatitude] = useState(0);
    const [longitude, setLongitude] = useState(0);
    const [mapLatitude, setMapLatitude] = useState(0);
    const [mapLongitude, setMapLongitude] = useState(0);
    const [fetchedGeolocation, setFetchedGeolocation] = useState(false);
    const [fetchedFarms, setFetchedFarms] = useState(false);
    const [events, logEvents] = useState<Record<string, LngLat>>({});
    const [locationSuggestions, setLocationSuggestions] = useState<any[]>([]);

    const { loading, success, error } = useAppSelector((state) => state.report);
    const { farms, loading: farmLoading } = useAppSelector((state) => state.farm);

    const dispatch = useAppDispatch();
    const navigate = useNavigate();


    useEffect(() => {

        if (success) {
            navigate("/reports");
            toast.dismiss();
            toast.success("Report created successfully");
            dispatch(resetNewReport());
        }

        if (!fetchedFarms) {
            dispatch(getFarmerFarms());
            setFetchedFarms(true);
        }

        const addPositionToMap = (position: GeolocationPosition) => {
            setViewPort({ ...viewport, latitude: position.coords.latitude, longitude: position.coords.longitude })
            setLatitude(position.coords.latitude);
            setLongitude(position.coords.longitude);
        }

        if (!fetchedGeolocation) {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(addPositionToMap);
            } else {
                toast.error("Geo location is not supported on this browser");
            }
            setFetchedGeolocation(true);
        }

        if (error && typeof error === 'string') {
            toast.dismiss();
            toast.error(error);
            dispatch(clearErrors())
        }

    }, [dispatch, error, fetchedFarms, fetchedGeolocation, navigate, success, viewport])

    const onMarkerDragStart = useCallback((event: MarkerDragEvent) => {
        logEvents(_events => ({ ..._events, onDragStart: event.lngLat }));
    }, []);

    const onMarkerDrag = useCallback((event: MarkerDragEvent) => {
        logEvents(_events => ({ ..._events, onDrag: event.lngLat }));

        setLatitude(event.lngLat.lat);
        setLongitude(event.lngLat.lng);
    }, []);

    const onMarkerDragEnd = useCallback((event: MarkerDragEvent) => {
        logEvents(_events => ({ ..._events, onDragEnd: event.lngLat }));
    }, []);

    const handleLocationChange = async (event: ChangeEvent<HTMLInputElement>) => {
        setLocation(event.target.value)

        try {
            const endpoint = `https://api.mapbox.com/geocoding/v5/mapbox.places/${location}.json?country=ru%2Cde%2Cgb%2Cfr%2Cit%2Ces%2Cua%2Cpl%2Cro%2Cnl%2Cbe%2Ccz%2Cgr%2Cpt%2Cse%2Chu%2Cby%2Cat%2Crs%2Cch%2Cbg%2Cdk%2Cfi%2Csk%2Cno%2Cie%2Chr%2Cmd%2Cba%2Cal%2Clt%2Cmk%2Csi%2Clv%2Cme%2Clu%2Cmt%2Cis%2Cad%2Cmc%2Cli%2Csm%2Cim%2Cgi&access_token=${process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}&autocomplete=true`;
            const response = await fetch(endpoint);
            const results = await response.json();
            setLocationSuggestions(results?.features);
        } catch (error) {
            console.log("Error fetching data, ", error);
            toast.dismiss();
            toast.error("Error fetching data");
        }
    }


    const submitHandler = (e: FormEvent) => {

        e.preventDefault()

        if (name === "") {
            toast.error("Enter a name for this report");
            return
        }

        if (!isNewFarm && farmId === "") {
            toast.error("Choose a farm or enter details for a new farm");
            return
        }

        if (isNewFarm && farmName === "") {
            toast.error("Choose a name for your farm");
            return
        }

        var data = {
            name,
            year,
            isNewFarm,
            farmId,
            farmBackground: {
                name: farmName,
                location,
                latitude,
                longitude
            }
        }

        dispatch(createReport(data));
        toast.dismiss();
        toast.info("Creating report...");
    }

    return (
        <>
            <MetaData title='New Report' />
            <NewReportContainer>
                <div><h1>NEW REPORT</h1></div>
                <div>
                    <form className="form" noValidate onSubmit={submitHandler}>
                        <NewReportFormGroup className="form-group needs-validation">
                            <label htmlFor="name_field">Name</label>
                            <input
                                type="text"
                                className="form-control"
                                id="name_field"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                required
                            />
                            <span className="invalid-feedback"></span>
                        </NewReportFormGroup>
                        <NewReportFormGroup className="form-group needs-validation">
                            <label htmlFor="year_field">Reporting Year</label>
                            <input
                                type="number"
                                className="form-control"
                                id="year_field"
                                value={year}
                                onChange={(e) => setYear(e.target.valueAsNumber)}
                                required
                            />
                            <span className="invalid-feedback"></span>
                        </NewReportFormGroup>


                        {!isNewFarm && (
                            <>
                                <NewReportFormGroup>
                                    <label>Choose existing farm/location</label>
                                    <select className='form-select' onChange={(e) => setFarmId(e.target.value)}>
                                        <option>Not Selected</option>
                                        {!farmLoading && (
                                            <>
                                                {farms?.map(farm => {
                                                    return (
                                                        <option key={farm.id} value={farm.id}>{farm.name}</option>
                                                    )
                                                })}
                                            </>
                                        )}
                                    </select>
                                </NewReportFormGroup>
                            </>
                        )}

                        <NewReportFormGroup className="form-group needs-validation d-flex align-items-center">
                            <div className='radio my-3'>
                                <label>
                                    <input type="radio" name="isNewFarm" checked={isNewFarm} onClick={() => setIsNewFarm(!isNewFarm)} />
                                    <span className='radio-span'>Add new farm/location</span>
                                </label>
                            </div>
                        </NewReportFormGroup>

                        {isNewFarm && (
                            <>
                                <h2>FARM BACKGROUND</h2>
                                <NewReportNewFarmGrid>
                                    <div>
                                        <NewReportFormGroup className="form-group needs-validation">
                                            <label htmlFor="farm_name_field">Name</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="farm_name_field"
                                                value={farmName}
                                                onChange={(e) => setFarmName(e.target.value)}
                                                required
                                            />
                                            <span className="invalid-feedback"></span>
                                        </NewReportFormGroup>
                                        <NewReportFormGroup className="form-group needs-validation">
                                            <label htmlFor="farm_location_field">Location</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="farm_location_field"
                                                value={location}
                                                onChange={handleLocationChange}
                                                required
                                            />
                                            {locationSuggestions?.length > 0 && (
                                                <SuggestionWrapper>
                                                    {locationSuggestions.map((suggestion, index) => {
                                                        return (
                                                            <Suggestion
                                                                key={index}
                                                                onClick={() => {
                                                                    setLocation(suggestion.place_name);
                                                                    setLocationSuggestions([]);
                                                                    setLongitude(suggestion.geometry.coordinates[0])
                                                                    setLatitude(suggestion.geometry.coordinates[1])
                                                                    setViewPort({ ...viewport, latitude: suggestion.geometry.coordinates[1], longitude: suggestion.geometry.coordinates[0] })
                                                                }}
                                                            >
                                                                {suggestion.place_name}
                                                            </Suggestion>
                                                        );
                                                    })}
                                                </SuggestionWrapper>
                                            )}
                                            <span className="invalid-feedback"></span>
                                        </NewReportFormGroup>
                                        <div>
                                            <NewReportHalfWidthFormGroup className="form-group needs-validation">
                                                <label htmlFor="farm_latitude_field">Latitude</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="farm_latitude_field"
                                                    value={latitude}
                                                    onChange={(e) => setFarmName(e.target.value)}
                                                    disabled
                                                />
                                                <span className="invalid-feedback"></span>
                                            </NewReportHalfWidthFormGroup>
                                            <NewReportHalfWidthFormGroup className="form-group needs-validation">
                                                <label htmlFor="farm_longitude_field">Longitude</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="farm_longitude_field"
                                                    value={longitude}
                                                    onChange={(e) => setFarmName(e.target.value)}
                                                    disabled
                                                />
                                                <span className="invalid-feedback"></span>
                                            </NewReportHalfWidthFormGroup>
                                        </div>
                                    </div>
                                    <div>
                                        <ReactMapGl
                                            mapboxAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
                                            initialViewState={viewport}
                                            style={{ height: 300 }}
                                            mapStyle="mapbox://styles/mapbox/streets-v9"
                                            longitude={longitude}
                                            latitude={latitude}

                                        >
                                            <Marker
                                                longitude={longitude}
                                                latitude={latitude}
                                                anchor="bottom"
                                                draggable
                                                onDragStart={onMarkerDragStart}
                                                onDrag={onMarkerDrag}
                                                onDragEnd={onMarkerDragEnd}
                                            >
                                                <Pin size={20} />
                                            </Marker>
                                            <NavigationControl />
                                        </ReactMapGl>
                                        <p>Drag the pin to farm location. Zoom in for smaller drags</p>
                                    </div>
                                </NewReportNewFarmGrid>
                            </>
                        )}
                        <MainButtonWrapper><MainButton id="submit_btn" type="submit" disabled={loading}>Submit</MainButton></MainButtonWrapper>
                    </form>
                </div>
            </NewReportContainer>
        </>
    )
}

export default NewReport