import styled from 'styled-components';
import { Link } from 'react-router-dom';



export const StyledHeader = styled.header`
    display: flex;
    justify-content: center;
    font-family: 'Hk Grotesk Bold';
`

export const HeaderContainer = styled.div`
    width: 89%;
    margin-top: 10px;
    margin-bottom: 10px;
`;

export const LogoImg = styled.img`
    width: auto;
    height: 4rem;

    @media (max-width: 992px) {
        height: 2rem;
    }
`;

export const HeaderLayout = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`

export const HeaderNav = styled.nav`
    display: flex;
    justify-content: space-between;
    padding-top: 40px;
    padding-bottom: 40px;

    @media (max-width: 992px) {
        padding-top: 20px;
        padding-bottom: 40px;
    }
`

export const StyledLink = styled(Link)`
    color: black !important;
    text-decoration: none;
    margin-left: 1em;
    margin-right: 1em;
    &:hover {
        transform: scale(0.98);
        color: black;
    }
`

export const HeaderLinks = styled.ul`
    list-style-type: none;
    margin-top: 15px;
    width: auto;

    @media (max-width: 992px) {
        display: none;
    }
`

export const HeaderRightSection = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 992px) {
        justify-content: flex-end;
    }

    @media (min-width: 1200px) {
        width: 25%;
    }

    @media (min-width: 1400px) {
        width: 20%;
    }
`

export const HeaderCenterSection = styled.div`
    text-align: start;
    display: flex;
    align-items: center;

    @media (max-width: 992px) {
        text-align: center;
    }
`

export const HeaderLeftSection = styled.nav`
    display: none;

    ${StyledLink} {
        padding-top: 10px;
        padding-bottom: 10px;
    }

    @media (max-width: 992px) {
        display: block;
    }
`

export const UserIconWrapper = styled.div`
    height: 50px;
    width: 50px;
    border: solid 2px black;
    background-color: white;
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
        transform: scale(0.98);
    }
`

export const UserIcon = styled.img`
    height: 30px;
    width: 30px;
`;