import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { InputButton, InputButtonsContainer } from '../../styles/input/Input.styled';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { ToastContainer, toast } from 'react-toastify';
import { clearErrors, resetUpdate, getFullFarmDataById, putSignsOfErosion, putWatercoursesAndTopography, resetFarmDataId } from '../../../features/farmData/farmDataSlice';

type Props = {}

const WatercoursesAndTopography = (props: Props) => {

    const [pulledData, setPulledData] = useState(false);
    const [watercoursesPresent, setWatercoursePresent] = useState(3);
    const [riverPresent, setRiverPresent] = useState(false);
    const [streamPresent, setStreamPresent] = useState(false);
    const [ditchPresent, setDitchPresent] = useState(false);
    const [lakePresent, setLakePresent] = useState(false);
    const [noneTypePresent, setNoneTypePresent] = useState(false);
    const [otherWatercourseTypePresent, setOtherWatercourseTypePresent] = useState(false);
    const [otherWatercourseTypeSpecified, setOtherWatercourseTypeSpecified] = useState("");
    const [closenessOfManureSpread, setClosenessOfManureSpread] = useState(0);
    const [closenessOfFertiliserSpread, setClosenessOfFertiliserSpread] = useState(0);
    const [watercoursesAreFenced, setWatercoursesAreFenced] = useState(3);
    const [thicknessOfBufferStrips, setThicknessOfBufferStrips] = useState(0);
    const [proportionOfWaterCourseProtected, setProportionOfWaterCourseProtected] = useState(0);
    const [flat, setFlat] = useState(0);
    const [moderatelySloped, setModeratelySloped] = useState(0);
    const [steep, setSteep] = useState(0);
    const [backToReport, setBackToReport] = useState(false);

    const { report } = useAppSelector((state) => state.report);
    const { fullFarmData, success, error } = useAppSelector((state) => state.farmData)

    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!pulledData) {
            if (fullFarmData?.farmBreakdown.watercourses.watercourseDetails) {
                var details = fullFarmData.farmBreakdown.watercourses.watercourseDetails
                if (details.watercoursesPresent === true) setWatercoursePresent(1);
                if (details.watercoursesPresent === false) setWatercoursePresent(0);
                setRiverPresent(details.riverPresent)
                setStreamPresent(details.streamPresent)
                setDitchPresent(details.ditchPresent)
                setLakePresent(details.lakePresent)
                setOtherWatercourseTypePresent(details.otherWatercourseTypePresent)
                setOtherWatercourseTypeSpecified(details.otherWatercourseTypeSpecified)
                setClosenessOfManureSpread(details.closenessOfManureSpread)
                setClosenessOfFertiliserSpread(details.closenessOfFertiliserSpread)
                if (details.watercoursesAreFenced === true) setWatercoursesAreFenced(1);
                if (details.watercoursesAreFenced === false) setWatercoursesAreFenced(0);
                setThicknessOfBufferStrips(details.thicknessOfBufferStrips)
                setProportionOfWaterCourseProtected(details.proportionOfWaterCourseProtected)
            }

            if (fullFarmData?.farmBreakdown.topography) {
                var topography = fullFarmData.farmBreakdown.topography
                setFlat(topography.flat)
                setModeratelySloped(topography.moderatelySloped)
                setSteep(topography.steep)
            }
            setPulledData(false);
        }

        if (success) {
            let farmDataId = report?.farmData;
            dispatch(getFullFarmDataById({ id: farmDataId! }))
            toast.dismiss();
            toast.success("Input saved successfully");
            dispatch(resetUpdate());
            if (backToReport) {
                dispatch(resetFarmDataId());
                navigate(`/report/${report?.id}`);
            }
        }

    }, [dispatch, error, pulledData, success, backToReport, navigate, report?.farmData, report?.id, fullFarmData?.farmBreakdown.watercourses.watercourseDetails, fullFarmData?.farmBreakdown.topography])

    const saveHandler = (e?: React.MouseEvent) => {
        e?.preventDefault();

        // validation
        if (watercoursesPresent === 3) {
            toast.dismiss();
            toast.error("Please indicate whether there are watercourses on your farm");
            return
        }
        if (watercoursesAreFenced === 3) {
            toast.dismiss();
            toast.error("Please indicate whether watercourses are fenced on your farm");
            return
        }

        if (flat < 0 || flat > 100) {
            toast.dismiss();
            toast.error("The percentage of flat topography must be between 0 and 100");
            return;
        }
        if (moderatelySloped < 0 || moderatelySloped > 100) {
            toast.dismiss();
            toast.error("The percentage of moderately sloped topography must be between 0 and 100");
            return;
        }
        if (steep < 0 || steep > 100) {
            toast.dismiss();
            toast.error("The percentage of steep topography must be between 0 and 100");
            return;
        }

        var topographySum = flat+moderatelySloped+steep;
        if (topographySum !== 100) {
            toast.dismiss();
            toast.error("The percentages of topography types must sum to 100");
            return
        }

        toast.dismiss();
        toast.info("Saving progress...");
        dispatch(putWatercoursesAndTopography(
            {
                farmData: fullFarmData?.farmData.id!,
                watercoursesPresent: Boolean(watercoursesPresent),
                riverPresent,
                streamPresent,
                ditchPresent,
                lakePresent,
                otherWatercourseTypePresent,
                otherWatercourseTypeSpecified,
                closenessOfManureSpread,
                closenessOfFertiliserSpread,
                watercoursesAreFenced: Boolean(watercoursesAreFenced),
                thicknessOfBufferStrips,
                proportionOfWaterCourseProtected,
                flat,
                moderatelySloped,
                steep
            }));
    }

    return (
        <>
            <div>
                <div><h4><strong>ABOUT YOUR FARM - WATERCOURSES & TOPOGRAPHY</strong></h4></div>

                <div className='radio my-3'>
                    <label><strong>Are there any watercourses on your farm?</strong><br /></label>
                    <label>
                        <input type="radio" data-role="none" name="watercoursesPresent" checked={watercoursesPresent === 1} onChange={() => setWatercoursePresent(1)} />
                        <span className='radio-span'>Yes</span>
                    </label>
                    <label>
                        <input type="radio" data-role="none" name="watercoursesPresent" checked={watercoursesPresent === 0} onChange={() => setWatercoursePresent(0)} />
                        <span className='radio-span'>No</span>
                    </label>
                </div>

                <div className='my-3'>
                    <label><strong>Please describe types of watercourse:</strong><br /></label>
                    <label>
                        <input data-role='none' type="checkbox" name="watercourseTypesPresent" checked={riverPresent} onChange={() => {
                            setRiverPresent(!riverPresent)
                            setNoneTypePresent(false)
                        }} />
                        <span className='radio-span'>River</span>
                    </label>
                    <label>
                        <input data-role='none' type="checkbox" name="watercourseTypesPresent" checked={lakePresent} onChange={() => {
                            setLakePresent(!lakePresent)
                            setNoneTypePresent(false)
                        }} />
                        <span className='radio-span'>Lake</span>
                    </label>
                    <label>
                        <input data-role='none' type="checkbox" name="watercourseTypesPresent" checked={streamPresent} onChange={() => {
                            setStreamPresent(!streamPresent)
                            setNoneTypePresent(false)
                        }} />
                        <span className='radio-span'>Stream</span>
                    </label>
                    <label>
                        <input data-role='none' type="checkbox" name="watercourseTypesPresent" checked={ditchPresent} onChange={() => {
                            setDitchPresent(!ditchPresent)
                            setNoneTypePresent(false)
                        }} />
                        <span className='radio-span'>Ditch</span>
                    </label>
                    <label>
                        <input data-role='none' type="checkbox" name="watercourseTypesPresent" checked={otherWatercourseTypePresent} onChange={() => {
                            setOtherWatercourseTypePresent(!otherWatercourseTypePresent)
                            setNoneTypePresent(false)
                        }} />
                        <span className='radio-span'>Other</span>
                    </label>
                    <label>
                        <input data-role='none' type="checkbox" name="watercourseTypesPresent" checked={noneTypePresent} onChange={() => {
                            setNoneTypePresent(!noneTypePresent)
                            setRiverPresent(false)
                            setLakePresent(false)
                            setStreamPresent(false)
                            setDitchPresent(false)
                            setOtherWatercourseTypePresent(false)
                        }} />
                        <span className='radio-span'>None</span>
                    </label>
                </div>

                {otherWatercourseTypePresent &&
                    <div className='my-4 row d-flex align-items-center'>
                        <div className='col-12 col-sm-6'>
                            <label><strong>If other type of water course present, please specify:</strong></label>
                        </div>
                        <div className='col-6'>
                            <input
                                value={otherWatercourseTypeSpecified}
                                type='text'
                                className='form-control'
                                onChange={(e) => setOtherWatercourseTypeSpecified(e.target.value)}
                            />
                        </div>
                    </div>
                }

                <div className='my-4 row d-flex align-items-center'>
                    <div className='col-12 col-sm-6'>
                        <label><strong>How close do you spread slurry/manure to the water course?</strong></label>
                    </div>
                    <div className='col-6'>
                        <select
                            className='form-control'
                            value={closenessOfManureSpread}
                            onChange={(e) => setClosenessOfManureSpread(Number(e.target.value))}
                        >
                            {fullFarmData?.farmBreakdown.watercourses.closenessOfSpread.filter((element) => element.slurrySpread).map(element => {
                                return <option key={element.id} value={element.id}>{element.distance}</option>
                            })}
                        </select>
                    </div>
                </div>

                <div className='my-4 row d-flex align-items-center'>
                    <div className='col-12 col-sm-6'>
                        <label><strong>How close do you spread fertiliser to the water course?</strong></label>
                    </div>
                    <div className='col-6'>
                        <select
                            className='form-control'
                            value={closenessOfFertiliserSpread}
                            onChange={(e) => setClosenessOfFertiliserSpread(Number(e.target.value))}
                        >
                            {fullFarmData?.farmBreakdown.watercourses.closenessOfSpread.filter((element) => element.fertSpread).map(element => {
                                return <option key={element.id} value={element.id}>{element.distance}</option>
                            })}
                        </select>
                    </div>
                </div>

                <div className='radio my-3'>
                    <label><strong>Are watercourses fenced to prevent livestock access?</strong><br /></label>
                    <label>
                        <input type="radio" name="watercoursesAreFenced" checked={watercoursesAreFenced === 1} onChange={() => setWatercoursesAreFenced(1)} />
                        <span className='radio-span'>Yes</span>
                    </label>
                    <label>
                        <input type="radio" name="watercoursesAreFenced" checked={watercoursesAreFenced === 0} onChange={() => setWatercoursesAreFenced(0)} />
                        <span className='radio-span'>No</span>
                    </label>
                </div>

                <div className='my-4 row d-flex align-items-center'>
                    <div className='col-12 col-sm-6'>
                        <label><strong>If used, how wide are riparian/buffer strips? (m) - measured from the top of the watercourse bank</strong></label>
                    </div>
                    <div className='col-6'>
                        <select
                            className='form-control'
                            value={thicknessOfBufferStrips}
                            onChange={(e) => setThicknessOfBufferStrips(Number(e.target.value))}
                        >
                            {fullFarmData?.farmBreakdown.watercourses.thicknessOfStrips.map(element => {
                                return <option key={element.id} value={element.id}>{element.description}</option>
                            })}
                        </select>
                    </div>
                </div>

                <div className='my-4 row d-flex align-items-center'>
                    <div className='col-12 col-sm-6'>
                        <label><strong>Approximate proportion of watercourse protected by riparian strip (%)</strong></label>
                    </div>
                    <div className='col-6'>
                        <input
                            className='form-control'
                            value={proportionOfWaterCourseProtected}
                            type='number'
                            onChange={(e) => setProportionOfWaterCourseProtected(e.target.valueAsNumber)}
                        />
                    </div>
                </div>

                <div className='mb-3'>
                    <label><strong>What percentage of each type of topography is on your farm?</strong></label>
                    <div className='my-2 row d-flex align-items-center'>
                        <div className='col-12 col-sm-6'>
                            <label>Flat:</label>
                        </div>
                        <div className='col-6'>
                            <input
                                className='form-control'
                                value={flat}
                                type='number'
                                onChange={(e) => setFlat(e.target.valueAsNumber)}
                            />
                        </div>
                    </div>
                    <div className='my-2 row d-flex align-items-center'>
                        <div className='col-12 col-sm-6'>
                            <label>Moderately steep:</label>
                        </div>
                        <div className='col-6'>
                            <input
                                className='form-control'
                                value={moderatelySloped}
                                type='number'
                                onChange={(e) => setModeratelySloped(e.target.valueAsNumber)}
                            />
                        </div>
                    </div>
                    <div className='my-2 row d-flex align-items-center'>
                        <div className='col-12 col-sm-6'>
                            <label>Steep:</label>
                        </div>
                        <div className='col-6'>
                            <input
                                className='form-control'
                                value={steep}
                                type='number'
                                onChange={(e) => setSteep(e.target.valueAsNumber)}
                            />
                        </div>
                    </div>
                </div>


                <InputButtonsContainer className='my-2'>
                    <InputButton onClick={() => navigate(`/report/${report?.id}`)}>BACK</InputButton>
                    <InputButton onClick={() => {
                        toast.dismiss()
                        toast.info("Saving...")
                        saveHandler()
                    }}>SAVE</InputButton>
                    <InputButton onClick={() => {
                        toast.dismiss()
                        toast.info("Saving...");
                        setBackToReport(true);
                        saveHandler();
                    }}>SAVE & CONTINUE</InputButton>
                </InputButtonsContainer>

            </div>
        </>
    )
}

export default WatercoursesAndTopography