import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../hooks'
import Indicator from './Indicator';
import { IAnimalDiet, IAnimalsOnFarm, IIndicator, IManufacturedFertiliserApplied, IProportionOfAnimalsHousedEachMonth } from '../../Interfaces';
import { toast } from 'react-toastify';
import { setScore } from '../../features/ecosystemService/ecosystemServiceSlice';
import { filter } from 'rxjs';

type Props = {
    activeES?: number | null,
    calculateIndicatorValue: (indicatorId: string) => number | null,
    getManagementAdvice: (result: number | null, indicatorId: string) => string | null | undefined,
    editHandler: (indicatorId: string) => void
}

const IndicatorGroup = (props: Props) => {

    const { indicators } = useAppSelector((state) => state.indicator);
    const { fullFarmData, success, error, loading } = useAppSelector((state) => state.farmData)


    const calculateIndicatorProgressColor = (result: number) => {
        if (result === 100) return "#63be7b"
        if (result >= 80) return "#a1d07e"
        if (result >= 60) return "#dfe282"
        if (result >= 40) return "#fed280"
        if (result >= 20) return "#fb9d75"
        return "#f8696b"
    }

    return (
        <>
            {loading ? (
                <>
                    {/* Dummy indicators as loading skeletons */}
                    <Indicator indicator={{
                        id: "x",
                        name: "x",
                        inputScreenPath: "x",
                        weighting: 0,
                        maximum: 0,
                        minimum: 0,
                        ecosystemServiceCode: 1,
                    }} calculateIndicatorValue={props.calculateIndicatorValue} calculateIndicatorProgressColor={calculateIndicatorProgressColor} editHandler={props.editHandler} getManagementAdvice={props.getManagementAdvice} />
                    <Indicator indicator={{
                        id: "x",
                        name: "x",
                        inputScreenPath: "x",
                        weighting: 0,
                        maximum: 0,
                        minimum: 0,
                        ecosystemServiceCode: 1,
                    }} calculateIndicatorValue={props.calculateIndicatorValue} calculateIndicatorProgressColor={calculateIndicatorProgressColor} editHandler={props.editHandler} getManagementAdvice={props.getManagementAdvice} />
                    <Indicator indicator={{
                        id: "x",
                        name: "x",
                        inputScreenPath: "x",
                        weighting: 0,
                        maximum: 0,
                        minimum: 0,
                        ecosystemServiceCode: 1,
                    }} calculateIndicatorValue={props.calculateIndicatorValue} calculateIndicatorProgressColor={calculateIndicatorProgressColor} editHandler={props.editHandler} getManagementAdvice={props.getManagementAdvice} />
                    <Indicator indicator={{
                        id: "x",
                        name: "x",
                        inputScreenPath: "x",
                        weighting: 0,
                        maximum: 0,
                        minimum: 0,
                        ecosystemServiceCode: 1,
                    }} calculateIndicatorValue={props.calculateIndicatorValue} calculateIndicatorProgressColor={calculateIndicatorProgressColor} editHandler={props.editHandler} getManagementAdvice={props.getManagementAdvice} />
                </>
            ) :
                <>
                    {indicators && props.activeES && indicators.filter((item) => item.ecosystemServiceCode === props.activeES).sort((a, b) => {
                        const nameA = a.name.toUpperCase(); // ignore upper and lowercase
                        const nameB = b.name.toUpperCase(); // ignore upper and lowercase
                        if (nameA < nameB) {
                          return 1;
                        }
                        if (nameA > nameB) {
                          return -1;
                        }
                      
                        // names must be equal
                        return 0;
                    }).map((item) => {
                        return (
                            <Indicator indicator={item} key={item.id} calculateIndicatorValue={props.calculateIndicatorValue} calculateIndicatorProgressColor={calculateIndicatorProgressColor} editHandler={props.editHandler} getManagementAdvice={props.getManagementAdvice} />
                        )
                    })}
                </>

            }
        </>
    )
}

export default IndicatorGroup;