import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ReportListItemContainer, ReportListItemInner, ReportListItemInnerLeft, ReportListItemInnerLeftTitle, ReportListItemInnerRight, ReportListItemInnerLeftSubTitle, ReportListItemInnerRightIcon } from '../styles/Report.styled';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { deleteReportById } from '../../features/report/reportSlice';
import { toast } from 'react-toastify';
import Skeleton from 'react-loading-skeleton';
import tippy from 'tippy.js';

type Props = {
    name?: string,
    year?: number,
    location?: string,
    id: string,
    selectReportForDelete: (id: string) => void
}

const ReportListItem = (props: Props) => {

    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const { loading } = useAppSelector(state => state.report)

    tippy('.edit-report-button', {
        content: "Edit",
    });

    tippy('.delete-report-button', {
        content: "Delete",
    });

    return (
        <>
            <ReportListItemContainer>
                <ReportListItemInner>
                    <ReportListItemInnerLeft>
                        {loading ? (<div className='mx-3'><Skeleton /></div>) : (<div><ReportListItemInnerLeftTitle>{props.name} ({props.year}/{props.location})</ReportListItemInnerLeftTitle></div>)}
                    </ReportListItemInnerLeft>
                    <ReportListItemInnerRight>
                        {loading ? (
                            <>
                                <Skeleton circle={true} width={25} height={25} />
                                <Skeleton circle={true} width={25} height={25} />
                            </>
                        ) : (
                            <>
                                <ReportListItemInnerRightIcon className='edit-report-button' src="/icons/pen-to-square.svg" onClick={() => navigate(`/report/${props.id}`)} />
                                <ReportListItemInnerRightIcon className='delete-report-button' src="/icons/trash.svg" onClick={() => props.selectReportForDelete(props.id)} data-bs-toggle="modal" data-bs-target="#exampleModalCenter" />
                            </>
                        )}
                    </ReportListItemInnerRight>
                </ReportListItemInner>
            </ReportListItemContainer >
        </>
    )
}

export default ReportListItem