import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ReportContainer, ReportESBar, ReportESBarMobile, ReportEcosystemServicesBar, ReportEcosystemServicesDropdownContainer, ReportMenuBar, ReportMenuBarIcon, ReportTopBar } from '../styles/Report.styled';
import { ReportESButton, ReportMenuBarButton } from '../styles/Button.styled';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { getReportById, setActiveES } from '../../features/report/reportSlice';
import { getAllIndicators } from '../../features/indicator/indicatorSlice';
import IndicatorGroup from '../indicator/IndicatorGroup';
import { clearErrors, getFullFarmDataById } from '../../features/farmData/farmDataSlice';
import { getAllEcosystemServices, setScore } from '../../features/ecosystemService/ecosystemServiceSlice';
import Loader from '../layout/Loader';
import jsPDF from "jspdf";
import autoTable from 'jspdf-autotable';
import 'jspdf-autotable';
import { IAnimalDiet, IAnimalsOnFarm, IManufacturedFertiliserApplied } from '../../Interfaces';
import { toast } from 'react-toastify';
import Skeleton from 'react-loading-skeleton';
import MetaData from '../layout/MetaData';

type Props = {}

const Report = (props: Props) => {

  const [pulledData, setPulledData] = useState(false);
  const [selectedInputPath, setSelectedInputPath] = useState("");
  const [selectedIndicatorName, setSelectedIndicatorName] = useState("");

  const { id } = useParams();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { report, activeES } = useAppSelector((state) => state.report);
  const { fullFarmData, loading, error } = useAppSelector((state) => state.farmData);
  const { ecosystemServices, score } = useAppSelector((state) => state.ecosystemService);
  const { indicators } = useAppSelector((state) => state.indicator);


  useEffect(() => {

    window.onpopstate = () => {
      navigate('/');
    }

    // get report by ID
    if (id) dispatch(getReportById({ id }));
    // get ecosystem services?
    dispatch(getAllEcosystemServices())
    // get indicators
    dispatch(getAllIndicators());

    if (!pulledData && report?.farmData) {
      dispatch(getFullFarmDataById({ id: report?.farmData! }))
      setPulledData(true)
    }
    if (pulledData) {
      if (error && typeof error === 'string') {
        toast.dismiss();
        toast.error(error);
        dispatch(clearErrors())
      }
    }

    if (id !== fullFarmData?.farmData.id) {
      dispatch(getFullFarmDataById({ id: report?.farmData! }))
    }

    // calculate es score
    var newScore = 0

    indicators && indicators.filter((item) => item.ecosystemServiceCode === activeES).map((element) => {
      var temp = calculateIndicatorValue(element.id) ?? 0

      if (["F599CF2C-EBCC-4D68-921E-531944A2D933", "3ED5ECE7-3E03-4F1F-B01A-337E3408D477", "39032A7C-4C47-4771-92C9-F35448E139DA", "036FC1F2-88C1-461C-A707-4C619CC170D5"].includes(element.id.toLocaleUpperCase())) {
        if (temp === 0) {
          newScore = newScore + 0
        }
        else if (temp >= 3 || temp <= 1) {
          newScore = newScore + (1 - (Math.abs(2 - temp) / 5)) * element.weighting
        } else {
          newScore = newScore + element.weighting
        }

      } else {
        var userInput = (temp / 100) * element.maximum
        var normalizedUserInput = (userInput - element.minimum) / (element.maximum - element.minimum)
        if (normalizedUserInput > 0) newScore = newScore + (normalizedUserInput * element.weighting)
      }
      return null;
    })

    dispatch(setScore(newScore))

  }, [dispatch, id, activeES, pulledData, report?.farmData, fullFarmData?.farmData.id, navigate])

  const pullFarmData = () => {
    // get corresponding farm data
    let farmDataId = report?.farmData;
    dispatch(getFullFarmDataById({ id: farmDataId! }))
  }

  const updateActiveES = (index: number) => {
    pullFarmData();
    dispatch(setActiveES(index));
  }

  const generateReportPDF = () => {
    const doc = new jsPDF();

    var posY = 10;

    // Default export is a4 paper, portrait, using millimeters for units
    doc.text("Super G Permanent Grassland DST Report", 10, posY);
    var img = new Image()
    img.src = '/assets/logo.png'
    doc.addImage(img, 'png', 160, 5, 40, 10)

    posY = posY + 10;

    doc.text(`${report?.name}`, 10, posY);

    posY = posY + 10;

    // loop through
    for (let i = 1; i < 7; i++) {
      if (i === 2) continue;
      doc.setFontSize(13);
      doc.text(`${ecosystemServices?.find(element => element.id === i)?.name}`, 10, posY);
      doc.setFontSize(9);
      posY = posY + 10;
      doc.text(ecosystemServices?.find(element => element.id === i)?.summary ?? "", 10, posY, { maxWidth: 180 });


      posY = posY + 20;
      doc.text(`Score : ${calculateEcosystemServiceScore(i).toFixed(2)}%`, 10, posY);
      posY = posY + 5;
      doc.text("Indicators", 10, posY);

      posY = posY + 10;
      const columns = ["Name", "Progress (%)", "Management Advice"];
      const ind = indicators?.filter(element => element.ecosystemServiceCode === i) ?? [];
      const rows = ind.map(element => {
        var result = calculateProgressValue(element.id);
        return [element.name, result?.toFixed(2) ?? 0, getManagementAdvice(result, element.id) ?? ""];
      })

      autoTable(doc, {
        theme: "plain",
        head: [columns],
        body: rows,
        startY: posY,
        styles: { fontSize: 9 }
      })

      if (i !== 6) {
        doc.addPage();
        posY = 10;
      }
    }

    doc.save(`${report?.name}.pdf`);

  }

  const calculateEcosystemServiceScore = (es: number) => {
    var newScore = 0;
    indicators && indicators.filter((item) => item.ecosystemServiceCode === es).map((element) => {
      var temp = calculateIndicatorValue(element.id) ?? 0
      // console.log(temp)
      if (["F599CF2C-EBCC-4D68-921E-531944A2D933", "3ED5ECE7-3E03-4F1F-B01A-337E3408D477", "39032A7C-4C47-4771-92C9-F35448E139DA", "036FC1F2-88C1-461C-A707-4C619CC170D5"].includes(element.id.toLocaleUpperCase())) {
        if (temp === 0) {
          newScore = newScore + 0
        }
        else if (temp >= 3 || temp <= 1) {
          newScore = newScore + (1 - (Math.abs(2 - temp) / 5)) * element.weighting
        } else {
          newScore = newScore + element.weighting
        }

      } else {
        var userInput = (temp / 100) * element.maximum
        var normalizedUserInput = (userInput - element.minimum) / (element.maximum - element.minimum)
        if (normalizedUserInput > 0) newScore = newScore + (normalizedUserInput * element.weighting)
      }
      return null;
    })
    return newScore
  }

  const calculateProgressValue = (indicatorId: string) => {
    if (["F599CF2C-EBCC-4D68-921E-531944A2D933", "3ED5ECE7-3E03-4F1F-B01A-337E3408D477", "39032A7C-4C47-4771-92C9-F35448E139DA", "036FC1F2-88C1-461C-A707-4C619CC170D5"].includes(indicatorId.toLocaleUpperCase())) {
      var temp = calculateIndicatorValue(indicatorId) ?? 0;
      if (temp === 0) {
        return null;
      }
      else if (temp >= 3 || temp <= 1) {
        return (1 - (Math.abs(2 - temp) / 5)) * 100
      } else {
        return 100
      }
    } else {
      return calculateIndicatorValue(indicatorId);
    }
  }

  // indicator calculations
  const calculateIndicatorValue = (indicatorId: string) => {
    // biodiversity
    if (indicatorId.toLocaleUpperCase() === "7C54F3DA-80CF-4E6C-8559-903F9D4B7D18") return calculateBiodiversityPresenceOfPollinatedCrops();
    if (indicatorId.toLocaleUpperCase() === "82DCFB2F-BF2C-4D71-9854-C4672E8470F9") return calculateBiodiversityWildlifeMeasures();
    if (indicatorId.toLocaleUpperCase() === "DC4BB24C-F6B0-44D6-9185-D0BD41B2A0C6") return calculateBiodiversityBotanicalComposition();
    if (indicatorId.toLocaleUpperCase() === "2429E794-4DC1-46A6-BA0F-F3D317DE68B5") return calculateBiodiversityPresenceOfThreatenedSpecies();

    // food production
    if (indicatorId.toLocaleUpperCase() === "3ED5ECE7-3E03-4F1F-B01A-337E3408D477") return calculateStockingRateUAA();
    if (indicatorId.toLocaleUpperCase() === "39032A7C-4C47-4771-92C9-F35448E139DA") return calculateStockingRateGrazing();
    if (indicatorId.toLocaleUpperCase() === "66111EC6-A3D0-43B5-926B-AC0D06AD64E8") return calculateForageEfficiency();
    if (indicatorId.toLocaleUpperCase() === "E1801DB4-B441-40B1-924B-04D6952A4702") return calculateDryMatterProductivity();
    if (indicatorId.toLocaleUpperCase() === "662A89FA-F935-4C5D-8C1B-2B41CA15C974") return calculateNitrogenUseEfficiency();
    if (indicatorId.toLocaleUpperCase() === "3FB43BAF-3BCF-4EE7-A354-23761E8C5D44") return calculateLivestockNitrogenUseEfficiency();


    // flood erosion
    if (indicatorId.toLocaleUpperCase() === "036FC1F2-88C1-461C-A707-4C619CC170D5") return calculateStockingRateUAA();
    if (indicatorId.toLocaleUpperCase() === "7AC2856B-E56F-476C-A92E-053A50B348C7") return calculateHousingPotential();
    if (indicatorId.toLocaleUpperCase() === "99CBD9D4-D698-4304-8C95-863E1BD94DC3") return calculateSoilStructure();
    if (indicatorId.toLocaleUpperCase() === "D0851B36-D082-4A05-80BC-8D903A2ED69D") return calculateVegetationCover();
    if (indicatorId.toLocaleUpperCase() === "27626459-97EF-45F2-B703-07375382752E") return calculateTotalPorosity();

    // climate regulation
    if (indicatorId.toLocaleUpperCase() === "F599CF2C-EBCC-4D68-921E-531944A2D933") return calculateStockingRateUAA();
    if (indicatorId.toLocaleUpperCase() === "78286A05-F36A-489B-8DB8-84D58889AA6D") return calculateSoilDegradation();
    if (indicatorId.toLocaleUpperCase() === "F940377C-1C14-4B42-9A64-4683095D5ACD") return calculateMineralNApplicationLevel();

    // water quality
    if (indicatorId.toLocaleUpperCase() === "D065169F-7541-4213-B9E7-A8269E9D1D5D") return calculateQuantitiesOfManure();
    if (indicatorId.toLocaleUpperCase() === "0D25C789-B717-4B08-B284-2F48C7F7BB02") return calculateNInputRate();
    if (indicatorId.toLocaleUpperCase() === "83C1BE5C-CA46-4CAC-B2D8-1B996B159E7F") return CalculateWaterwayProtection();
    if (indicatorId.toLocaleUpperCase() === "165E0B1D-2457-4027-BC00-F0E90E5621E0") return CalculateRiskOfWaterPollutants();

    // landscape aesthetics
    if (indicatorId.toLocaleUpperCase() === "84B011E2-98BF-4B80-BEB5-EA5886BDD51B") return CalculateAreaConservedForNature();
    if (indicatorId.toLocaleUpperCase() === "91FDA0A9-98A7-40B4-810D-C912123F4CA2") return CalculateNaturalHeritage();
    if (indicatorId.toLocaleUpperCase() === "A0B2E773-03E9-4BA2-AC06-C1C573217F78") return CalculateNatureTrails();
    if (indicatorId.toLocaleUpperCase() === "EFA8BBF3-F715-4DE4-A6FD-7F5E6A1DB85D") return CalculateFarmAppeal();

    return null;
  }

  const calculateBiodiversityPresenceOfPollinatedCrops = () => {
    if (fullFarmData?.biodiversityInputs.data) {
      var cropsGrown = fullFarmData?.biodiversityInputs.data.pollinatingCropsGrown

      if (!cropsGrown) return 1;
      return 100
    }
    return null
  }

  const calculateBiodiversityPresenceOfThreatenedSpecies = () => {
    if (fullFarmData?.biodiversityInputs.data) {
      var threatenedSpecies = fullFarmData?.biodiversityInputs.data.threatenedSpecies

      if (!threatenedSpecies) return 1;
      return 100
    }
    return null
  }

  const calculateBiodiversityWildlifeMeasures = () => {
    if (fullFarmData?.biodiversityInputs.data) {
      var data = fullFarmData?.biodiversityInputs.data;

      var result = 0;

      if (data.partsOfSwardLeftUncut) {
        result++;
      } else {
        result = result + 0.01;
      }
      if (data.mowingGrasslandCenterToOutside) {
        result++;
      } else {
        result = result + 0.01;
      }
      if (data.reducedManagementIntensityOfFieldMargins) {
        result++;
      } else {
        result = result + 0.01;
      }
      if (data.protectionOfPatchesWithBirds) {
        result++;
      } else {
        result = result + 0.01;
      }
      result = result * 25
      // if (result >= 25) return 100;
      return result
    }
    return null;
  }

  const calculateBiodiversityBotanicalComposition = () => {
    if (fullFarmData?.biodiversityInputs.grasslands.length !== 0 && fullFarmData?.biodiversityInputs.grasslands) {
      var data = fullFarmData?.biodiversityInputs.grasslands;

      var intensity3data = data.filter(item => item.intensity === 3)[0];
      var intensity2data = data.filter(item => item.intensity === 2)[0];
      var intensity1data = data.filter(item => item.intensity === 1)[0];

      var proportion3 = intensity3data.proportion / 100 * 3;
      var proportion2 = intensity2data.proportion / 100 * 2;
      var proportion1 = intensity1data.proportion / 100 * 1;

      var result = proportion3 + proportion2 + proportion1

      var temp = indicators?.filter(item => item.id.toLocaleUpperCase() === "DC4BB24C-F6B0-44D6-9185-D0BD41B2A0C6")[0].maximum

      // green threshold
      if (result > 2) return 100;
      return result / temp! * 100;
    }
    return null;
  }

  const calculateSingleLSU = (accumulator: number, currentValue: IAnimalsOnFarm) => {
    try {
      var lsuCoeeff = fullFarmData?.farmBreakdown.lsuCoefficients.find(element => element.subGroupId === currentValue.animalSubGroup)?.value ?? 0
      var avgNumPerMonth = fullFarmData?.farmBreakdown.animalsOnFarm.find(element => element.animalSubGroup === currentValue.animalSubGroup)?.averageNumPerMonth ?? 0
      var value = lsuCoeeff * avgNumPerMonth

      return accumulator + value
    } catch (error) {
      console.log(error)
      return 0;
    }
  }

  const calculateSingleEnergyIntakeAtGrass = (accumulator: number, currentValue: IAnimalDiet) => {
    try {
      var tonnesFedAtGrass = currentValue.totalTonnesFedAtGrass ?? 0
      var dmContentPercent = currentValue.dmContentPercentage ?? 0
      var meContent = currentValue.meContent ?? 0

      var value = (tonnesFedAtGrass * (dmContentPercent / 100) * 1000) * meContent

      return accumulator + value
    } catch (error) {
      console.log(error)
      return 0;
    }
  }

  const calculateSingleEnergyIntakeHoused = (accumulator: number, currentValue: IAnimalDiet) => {
    try {
      var tonnesFedHoused = currentValue.totalTonnesFedHoused ?? 0
      var dmContentPercent = currentValue.dmContentPercentage ?? 0
      var meContent = currentValue.meContent ?? 0

      var value = (tonnesFedHoused * (dmContentPercent / 100) * 1000) * meContent

      return accumulator + value
    } catch (error) {
      console.log(error)
      return 0;
    }
  }

  const calculateStockingRateUAA = () => {
    if (fullFarmData?.farmBreakdown.animalsOnFarm && fullFarmData.farmBreakdown.landUse && fullFarmData.farmBreakdown.lsuCoefficients) {
      // get sum of UAA
      var UAA = fullFarmData.farmBreakdown.landUse.permanentGrass + fullFarmData.farmBreakdown.landUse.temporaryGrass + fullFarmData.farmBreakdown.landUse.otherCrops
      // reduce array to Total nLSU / m
      var TotalLSUperM = fullFarmData?.farmBreakdown.animalsOnFarm.reduce(calculateSingleLSU, 0);
      // console.log(TotalLSUperM)
      // console.log(UAA)
      if (UAA === 0) return 0;
      return TotalLSUperM / UAA;
    }
    return null;
  }

  const calculateSingleLSUperMPerTimeAtGrass = (accumulator: number, currentValue: IAnimalsOnFarm) => {

    try {
      var lsuCoeeff = fullFarmData?.farmBreakdown.lsuCoefficients.find(element => element.subGroupId === currentValue.animalSubGroup)?.value
      var avgNumPerMonth = fullFarmData?.farmBreakdown.animalsOnFarm.find(element => element.animalSubGroup === currentValue.animalSubGroup)?.averageNumPerMonth
      var daysAtGrass = fullFarmData?.farmBreakdown.animalsOnFarm.find(element => element.animalSubGroup === currentValue.animalSubGroup)?.daysAtGrass
      var hoursPerDayAtGrass = fullFarmData?.farmBreakdown.animalsOnFarm.find(element => element.animalSubGroup === currentValue.animalSubGroup)?.hoursPerDayAtGrass

      var LSUperm = avgNumPerMonth! * lsuCoeeff!
      // console.log(`LSU per month: ${LSUperm}`);
      var TimeAtGrassPerYear = (daysAtGrass! / 30.5) * (hoursPerDayAtGrass! / 24)
      // console.log(`Time At Grass Per Year: ${TimeAtGrassPerYear}`);
      // console.log(`Subgroup ${currentValue.animalSubGroup}: ${LSUperm / TimeAtGrassPerYear}`);
      
      var value = LSUperm / TimeAtGrassPerYear

      if (TimeAtGrassPerYear === 0) value = 0
      return accumulator + value
    } catch (error) {
      console.log(error)
      return 0;
    }
  }

  const calculateStockingRateGrazing = () => {
    if (fullFarmData?.farmBreakdown.animalsOnFarm && fullFarmData.farmBreakdown.landUse && fullFarmData.farmBreakdown.lsuCoefficients) {
      // get sum of grassland area
      var Grassland = fullFarmData.farmBreakdown.landUse.temporaryGrass + fullFarmData.farmBreakdown.landUse.permanentGrass;
      // reduce array to get total nLSU * months * hours per grass/24
      var TotalLSUper = fullFarmData?.farmBreakdown.animalsOnFarm.reduce(calculateSingleLSUperMPerTimeAtGrass, 0);
      console.log(TotalLSUper);
      if (Grassland === 0) return 0;
      return TotalLSUper / Grassland;
    }
    return null;
  }

  const calculateForageEfficiency = () => {
    if (fullFarmData?.farmBreakdown.animalDiet && fullFarmData?.farmBreakdown.animalDiet.length > 0 && fullFarmData?.farmBreakdown.animalsOnFarm) {
      var energyRequiredByAnimals = fullFarmData?.farmBreakdown.animalsOnFarm.reduce(calculateSingleLSU, 0) * 124.9;
      // console.log(energyRequiredByAnimals)

      var totalEnergyAtGrass = fullFarmData.farmBreakdown.animalDiet.reduce(calculateSingleEnergyIntakeAtGrass, 0)
      var totalEnergyHoused = fullFarmData.farmBreakdown.animalDiet.reduce(calculateSingleEnergyIntakeHoused, 0)
      // console.log(totalEnergyAtGrass)
      // console.log(totalEnergyHoused)
      if ((totalEnergyAtGrass + totalEnergyHoused) > energyRequiredByAnimals) return 0
      // console.log((energyRequiredByAnimals - (totalEnergyAtGrass + totalEnergyHoused)) / energyRequiredByAnimals)
      return (energyRequiredByAnimals - (totalEnergyAtGrass + totalEnergyHoused)) / energyRequiredByAnimals * 100
    }
    return null;
  }

  const calculateSoilDegradation = () => {
    if (fullFarmData?.farmBreakdown.soilProfile.soilDegradation) {
      var degradation = fullFarmData.farmBreakdown.soilProfile.soilDegradation;

      var result = 0;

      result = result + (degradation.proportionOfSevere ?? 0) * 0.01;
      result = result + (degradation.proportionOfHigh ?? 0) * 0.02;
      result = result + (degradation.proportionOfModerate ?? 0) * 0.03;
      result = result + (degradation.proportionOfLow ?? 0) * 0.04;

      var max = indicators?.filter(item => item.id.toLocaleUpperCase() === "78286A05-F36A-489B-8DB8-84D58889AA6D")[0].maximum ?? 4
      return (result / max) * 100;
    }
    return null;
  }

  const calculateHousingPotential = () => {
    if (fullFarmData?.farmBreakdown.animalsOnFarm && fullFarmData.farmBreakdown.housingPotential && fullFarmData.farmBreakdown.proportionOfAnimalsHousedEachMonth) {

      if (fullFarmData.farmBreakdown.housingPotential.sufficientHousingAllYear) return 100;

      return fullFarmData.farmBreakdown.housingPotential.proportionWhichCanBeHoused;

      // var averageHousingSum = 0;
      // var aveLSUPerMonthSum = 0;

      // for (let i = 1; i < 7; i++) {
      //   // extract animal subgroups within this group
      //   var currentGroup = fullFarmData?.farmBreakdown.animalSubGroups.filter(a => a.animalCode === i);
      //   var currentSubGroup = currentGroup.map(a => a.id);
      //   currentSubGroup = currentSubGroup.filter((element, index) => {
      //     return currentSubGroup.indexOf(element) === index;
      //   });
      //   // get LSU per month sum for each
      //   var LSUpermonth = fullFarmData?.farmBreakdown.animalsOnFarm.filter(x => currentSubGroup.includes(x.animalSubGroup)).reduce(calculateSingleLSU, 0);
      //   // console.log(LSUpermonth)
      //   aveLSUPerMonthSum += LSUpermonth ?? 0

      //   // get the sum for each month
      //   var proportion = fullFarmData?.farmBreakdown.proportionOfAnimalsHousedEachMonth.find(x => x.animalCode === i)
      //   if (proportion === undefined) continue;

      //   var housingSum = ((proportion?.january! + proportion?.february! + proportion?.march! + proportion?.april! + proportion?.may! + proportion?.june! + proportion?.july! + proportion?.august! + proportion?.september! + proportion?.november! + proportion?.december!) / 12) * LSUpermonth

      //   averageHousingSum += housingSum

      // }


      // return averageHousingSum / aveLSUPerMonthSum;
    }
    return null
  }

  const calculateSoilStructure = () => {
    if (fullFarmData?.farmBreakdown.soilProfile.signsOfErosion) {
      var signs = fullFarmData?.farmBreakdown.soilProfile.signsOfErosion;
      var result = 0;

      if (!signs.rills) result++
      if (!signs.poaching) { result++ }
      else {
        if (signs.severePoaching) {
          result = result + 2;
        } else {
          result++
        }
      }
      if (!signs.pathsCreatedByLivestock) result++
      if (!signs.exposedSoil) result++
      if (!signs.surfaceCapping) result++
      if (!signs.wheeling) { result++ }
      else {
        if (signs.veryFewWheeling || signs.occasionalWheeling) result++
        if (signs.regularWheeling) result = result + 2
      }
      if (!signs.poorSwardQuality) { result++ }

      var max = indicators?.filter(item => item.id.toLocaleUpperCase() === "99CBD9D4-D698-4304-8C95-863E1BD94DC3")[0].maximum ?? 9
      return (result / max) * 100;

    }
    return null;
  }

  const calculateVegetationCover = () => {
    if (fullFarmData?.farmBreakdown.soilProfile.soilAssessment) {
      return fullFarmData.farmBreakdown.soilProfile.soilAssessment.soilSurfaceAssessment ?? 0
    }
    return null;
  }

  const calculateTotalPorosity = () => {
    if (fullFarmData?.farmBreakdown.soilProfile.soilAssessment) {
      let reverseIndexArr = []

      var max = indicators?.filter(item => item.id.toLocaleUpperCase() === "27626459-97EF-45F2-B703-07375382752E")[0].maximum ?? 5;

      //fill out array of scores
      for (let i = 1; i < max + 1; i++) {
        reverseIndexArr.push(i);
      }

      // get score by reverse indexing

      const assessment = reverseIndexArr.length - (fullFarmData.farmBreakdown.soilProfile.soilAssessment.soilAssessmentScore ?? 0);

      let rvrsIndxAssessment = reverseIndexArr[assessment];

      return (rvrsIndxAssessment / 5) * 100
    }
    return null;
  }

  const calculateDryMatterProductivity = () => {
    if (fullFarmData?.farmManagement.grassSwardData) {
      var data = fullFarmData?.farmManagement.grassSwardData;
      var result = 0;

      result = result + ((data.proportionOfLegumesInSward ?? 0) * 0.01)
      if (data.herbsPresentInSward) result++
      if (data.legumesPresentInSward) result++
      if (data.legumesPresentInReseed) result++
      if (data.reseedFrequency === 1) result++
      if (data.reseedToIncreaseDiversity) {
        if (!data.reseedForCompaction && !data.reseedToImproveSwardCover) { result = result + 2 }
        else { result++ }
      }
      if (data.reseedForCompaction) result = result + 0.5
      if (data.reseedToImproveSwardCover) result = result + 0.5

      var max = indicators?.filter(item => item.id.toLocaleUpperCase() === "E1801DB4-B441-40B1-924B-04D6952A4702")[0].maximum ?? 7
      return (result / max) * 100;

    }
    return null;
  }

  const calculateMineralNApplicationLevel = () => {
    if (fullFarmData?.farmBreakdown.landUse && fullFarmData.farmManagement.fertiliserApplications?.manufacturedFertilisersApplied) {
      var fertilisers = fullFarmData?.farmManagement.fertiliserApplications.manufacturedFertilisersApplied;
      var landUse = fullFarmData?.farmBreakdown.landUse;
      var UAA = landUse.permanentGrass + landUse.temporaryGrass + landUse.forestry + landUse.otherCrops
      var result = fertilisers.reduce((accumulator, currentValue) => {
        var spring = currentValue.tonnesAppliedInSpring ?? 0
        var summer = currentValue.tonnesAppliedInSummer ?? 0
        var autumn = currentValue.tonnesAppliedInAutumn ?? 0
        var winter = currentValue.tonnesAppliedInWinter ?? 0
        var value = (spring + summer) + (autumn + winter) * 1.1

        value = (value / 100) * (currentValue.nitrogenContentPercentage ?? 0) / 100 * 1000
        return accumulator + value
      }, 0);

      result = result / UAA

      var max = indicators?.filter(item => item.id.toLocaleUpperCase() === "F940377C-1C14-4B42-9A64-4683095D5ACD")[0].maximum ?? 600

      if (result > max) return 100;
      return (max - result) / max * 100;

    }
    return null;
  }

  const calculateNitrogenUseEfficiency = () => {
    if (fullFarmData?.farmManagement.fertiliserApplications?.fertiliserApplicationDetails && fullFarmData.farmManagement.fertiliserApplications.organicFertilisersApplied) {
      var details = fullFarmData.farmManagement.fertiliserApplications.fertiliserApplicationDetails
      var organicFerts = fullFarmData.farmManagement.fertiliserApplications.organicFertilisersApplied

      var result = 0
      if (details.followedNutrientManagementPlan) result++

      var totalTonnesAnalysed = organicFerts.filter(element => element.isAnalysed).reduce((accumulator, currentValue) => {
        var totalTonnes = currentValue.totalTonnesProduced ?? 0
        return accumulator + totalTonnes
      }, 0)

      var tonesAppliedSpring = organicFerts.reduce((accumulator, currentValue) => {
        var spring = currentValue.tonnesAppliedInSpring ?? 0
        return accumulator + spring
      }, 0)

      var tonesAppliedSummer = organicFerts.reduce((accumulator, currentValue) => {
        var summer = currentValue.tonnesAppliedInSummer ?? 0
        return accumulator + summer
      }, 0)

      var totalTonnes = organicFerts.reduce((accumulator, currentValue) => {
        var totalTonnes = currentValue.totalTonnesProduced ?? 0
        return accumulator + totalTonnes
      }, 0)

      if (totalTonnes === 0) return 0;

      result = result + (totalTonnesAnalysed / totalTonnes)

      result = result + ((tonesAppliedSpring + tonesAppliedSummer) / totalTonnes)

      var broadcast = (details.proportionAppliedUsingBroadcast ?? 0) / 100
      var bandSpreading = (details.proportionAppliedUsingBandSpreading ?? 0) / 100
      var liquid = (details.proportionAppliedUsingLiquid ?? 0) / 100
      var shallowInjection = (details.proportionApppliedUsingShallowInjection ?? 0) / 100

      result = result + broadcast * 0 + bandSpreading * 0.5 + liquid * 0.5 + shallowInjection
      var max = indicators?.filter(item => item.id.toLocaleUpperCase() === "662A89FA-F935-4C5D-8C1B-2B41CA15C974")[0].maximum ?? 4
      return (result / max) * 100;
    }
    return null;
  }

  const calculateLivestockNitrogenUseEfficiency = () => {
    if (fullFarmData?.farmBreakdown.animalDiet && fullFarmData.farmBreakdown.animalProduction && fullFarmData.farmManagement.fertiliserApplications?.organicFertilisersApplied && fullFarmData.farmBreakdown.animalsOnFarm && fullFarmData?.farmManagement?.fertiliserApplications?.manufacturedFertilisersApplied && fullFarmData?.farmBreakdown.landUse) {
      var animals = fullFarmData.farmBreakdown.animalsOnFarm
      var production = fullFarmData.farmBreakdown.animalProduction
      var diet = fullFarmData.farmBreakdown.animalDiet
      var ferts = fullFarmData.farmManagement.organicFertilisers
      var organicFerts = fullFarmData.farmManagement.fertiliserApplications.organicFertilisersApplied
      var artificialFerts = fullFarmData.farmManagement.fertiliserApplications.manufacturedFertilisersApplied
      var landUse = fullFarmData.farmBreakdown.landUse

      var uaa = landUse.permanentGrass + landUse.temporaryGrass + landUse.otherCrops

      var weightSold = animals.reduce((accumulator, currentValue) => {
        var weight = currentValue.meanLiveWeightTakenAtSale ?? 0
        var numberSold = currentValue.numSold ?? 0
        return accumulator + weight * numberSold
      }, 0)
      // console.log(weightSold)

      weightSold = weightSold + production.dairyMilkOutput + production.goatMilkOutput + production.horseMilkOutput + production.sheepMilkOutput + production.poultryEggs
      var totalWeight = weightSold / 1000

      // console.log(totalWeight)

      var proteinFromFeed = diet.reduce((accumulator, currentValue) => {
        var totalFedAtGrass = currentValue.totalTonnesFedAtGrass ?? 0
        var dmContent = currentValue.dmContentPercentage ?? 0
        var cpContent = currentValue.cpContent ?? 0

        var result = totalFedAtGrass * (dmContent / 100)
        result = result * (cpContent / 100)
        return accumulator + result
      }, 0)

      // console.log(proteinFromFeed)

      var organickgN = organicFerts.reduce((accumulator, currentValue) => {
        var result = 0
        var spring = currentValue.tonnesAppliedInSpring ?? 0
        var autumn = currentValue.tonnesAppliedInAutumn ?? 0
        var summer = currentValue.tonnesAppliedInSummer ?? 0
        var winter = currentValue.tonnesAppliedInWinter ?? 0
        var totalTonnes = spring + autumn + summer + winter
        var fertId = currentValue.fertiliser ?? 0

        var fert = ferts?.find(element => element.id === fertId)
        var kgNt = fert?.kgNPerTonne ?? 0

        result = 2 * totalTonnes * kgNt
        return accumulator + result
      }, 0)

      // console.log(organickgN)

      var artificialkgN = artificialFerts.reduce((accumulator, currentValue) => {
        var result = 0
        var spring = currentValue.tonnesAppliedInSpring ?? 0
        var autumn = currentValue.tonnesAppliedInAutumn ?? 0
        var summer = currentValue.tonnesAppliedInSummer ?? 0
        var winter = currentValue.tonnesAppliedInWinter ?? 0
        var totalTonnes = spring + autumn + summer + winter
        if (currentValue.isProtectedUrea) {
          result = totalTonnes * 0.46 * 1000
        } else {
          result = totalTonnes * (currentValue.nitrogenContentPercentage ?? 0) / 100 * 10
        }
        return accumulator + result
      }, 0)
      // console.log(artificialkgN)

      var nInputFromFerts = (artificialkgN + organickgN) / uaa
      // console.log(nInputFromFerts)

      // console.log(proteinFromFeed+nInputFromFerts)
      // console.log((totalWeight/(proteinFromFeed+nInputFromFerts)) * 100)

      if ((proteinFromFeed + nInputFromFerts) < totalWeight) return 100
      return (totalWeight / (proteinFromFeed + nInputFromFerts)) * 100
    }
    return null;
  }

  const calculateQuantitiesOfManure = () => {
    if (fullFarmData?.farmManagement.fertiliserApplications?.organicFertilisersApplied && fullFarmData.farmBreakdown.landUse) {
      var organicFerts = fullFarmData.farmManagement.fertiliserApplications.organicFertilisersApplied
      var ferts = fullFarmData.farmManagement.organicFertilisers
      var landUse = fullFarmData.farmBreakdown.landUse
      var grasslandArea = landUse.permanentGrass + landUse.temporaryGrass;
      var organickgN = organicFerts.reduce((accumulator, currentValue) => {
        var result = 0
        var spring = currentValue.tonnesAppliedInSpring ?? 0
        var autumn = currentValue.tonnesAppliedInAutumn ?? 0
        var summer = currentValue.tonnesAppliedInSummer ?? 0
        var winter = currentValue.tonnesAppliedInWinter ?? 0
        var totalTonnes = spring + autumn + summer + winter
        var fertId = currentValue.fertiliser ?? 0

        var fert = ferts?.find(element => element.id === fertId)
        var kgNt = fert?.kgNPerTonne ?? 0

        result = 2 * totalTonnes * kgNt
        return accumulator + result
      }, 0)
      var result = organickgN / grasslandArea
      var max = indicators?.filter(item => item.id.toLocaleUpperCase() === "D065169F-7541-4213-B9E7-A8269E9D1D5D")[0].maximum ?? 300
      // console.log(result)
      if (result > max) return 100;
      return ((max - result) / max) * 100;
    }
    return null;
  }

  const calculateNInputRate = () => {
    if (fullFarmData?.farmManagement.fertiliserApplications?.organicFertilisersApplied && fullFarmData.farmManagement.fertiliserApplications.manufacturedFertilisersApplied && fullFarmData.farmManagement.fertiliserApplications?.fertiliserApplicationDetails) {
      var organicFerts = fullFarmData.farmManagement.fertiliserApplications.organicFertilisersApplied
      var artificialFerts = fullFarmData.farmManagement.fertiliserApplications.manufacturedFertilisersApplied

      // var organicTotal = organicFerts.reduce((accumulator, currentValue) => {
      //   var spring = currentValue.tonnesAppliedInSpring ?? 0
      //   var autumn = currentValue.tonnesAppliedInAutumn ?? 0
      //   var summer = currentValue.tonnesAppliedInSummer ?? 0
      //   var winter = currentValue.tonnesAppliedInWinter ?? 0
      //   var totalTonnes = spring + autumn + summer + winter

      //   return accumulator + totalTonnes
      // }, 0)
      // var organicSpringAndSummer = organicFerts.reduce((accumulator, currentValue) => {
      //   var spring = currentValue.tonnesAppliedInSpring ?? 0
      //   var summer = currentValue.tonnesAppliedInSummer ?? 0

      //   return accumulator + spring + summer
      // }, 0)
      // var artificialTotal = artificialFerts.reduce((accumulator, currentValue) => {
      //   var spring = currentValue.tonnesAppliedInSpring ?? 0
      //   var autumn = currentValue.tonnesAppliedInAutumn ?? 0
      //   var summer = currentValue.tonnesAppliedInSummer ?? 0
      //   var winter = currentValue.tonnesAppliedInWinter ?? 0
      //   var totalTonnes = spring + autumn + summer + winter

      //   return accumulator + totalTonnes
      // }, 0)
      // var artificialSpringAndSummer = artificialFerts.reduce((accumulator, currentValue) => {
      //   var spring = currentValue.tonnesAppliedInSpring ?? 0
      //   var summer = currentValue.tonnesAppliedInSummer ?? 0

      //   return accumulator + spring + summer
      // }, 0)

      // if ((organicTotal + artificialTotal) === 0) return 0;

      // var result = (artificialSpringAndSummer + organicSpringAndSummer) / (organicTotal + artificialTotal) * 100

      var artFertN = artificialFerts.reduce((accumulator, currentValue) => {
        var spring = currentValue.tonnesAppliedInSpring ?? 0
        var summer = currentValue.tonnesAppliedInSummer ?? 0
        var autumn = currentValue.tonnesAppliedInAutumn ?? 0
        var winter = currentValue.tonnesAppliedInWinter ?? 0
        var value = (spring + summer) + (autumn + winter) * 1.1

        value = (value / 100) * (currentValue.nitrogenContentPercentage ?? 0) / 100 * 1000
        return accumulator + value
      }, 0);

      var ferts = fullFarmData.farmManagement.organicFertilisers
      var organicN = organicFerts.reduce((accumulator, currentValue) => {
        var result = 0
        var spring = currentValue.tonnesAppliedInSpring ?? 0
        var autumn = currentValue.tonnesAppliedInAutumn ?? 0
        var summer = currentValue.tonnesAppliedInSummer ?? 0
        var winter = currentValue.tonnesAppliedInWinter ?? 0
        var totalTonnes = spring + autumn + summer + winter
        var fertId = currentValue.fertiliser ?? 0

        var fert = ferts?.find(element => element.id === fertId)
        var kgNt = fert?.kgNPerTonne ?? 0

        result = 2 * totalTonnes * kgNt
        return accumulator + result
      }, 0)

      var result = artFertN + organicN

      // return result
      var max = indicators?.filter(item => item.id.toLocaleUpperCase() === "0D25C789-B717-4B08-B284-2F48C7F7BB02")[0].maximum ?? 600
      // return (result / max) * 100;
      if (result > max) return 100
      return ((max - result) / max) * 100;
    }
    return null;
  }

  const CalculateWaterwayProtection = () => {
    if (fullFarmData?.farmBreakdown.watercourses.watercourseDetails) {
      var details = fullFarmData.farmBreakdown.watercourses.watercourseDetails

      var propProtectedByRiparianStrip = details.proportionOfWaterCourseProtected
      var thicknessOfRiparianStrips = details.thicknessOfBufferStrips * 2

      if (thicknessOfRiparianStrips === 0) thicknessOfRiparianStrips = 0.1

      var slurrySpread = 0;

      if (details.closenessOfManureSpread === 2) {
        slurrySpread = 1;
      }
      if (details.closenessOfManureSpread > 4) {
        slurrySpread = details.closenessOfManureSpread - 3;
      }

      var waterwayProtectionScore = slurrySpread + (details.closenessOfFertiliserSpread / 2);

      var result = (propProtectedByRiparianStrip * thicknessOfRiparianStrips) + waterwayProtectionScore;

      if (details.watercoursesAreFenced) result++
      else result += 0.1

      var max = indicators?.filter(item => item.id.toLocaleUpperCase() === "83C1BE5C-CA46-4CAC-B2D8-1B996B159E7F")[0].maximum ?? 2021
      // console.log((result / max) * 100)
      return (result / max) * 100;
    }
    return null;
  }

  const CalculateRiskOfWaterPollutants = () => {
    if (fullFarmData?.farmBreakdown.topography && fullFarmData.farmBreakdown.watercourses.watercourseDetails && fullFarmData?.farmBreakdown.landUse && fullFarmData.farmManagement.fertiliserApplications?.organicFertilisersApplied) {
      var details = fullFarmData.farmBreakdown.watercourses.watercourseDetails
      var topography = fullFarmData.farmBreakdown.topography
      var landUse = fullFarmData?.farmBreakdown.landUse;
      var grasslandArea = landUse.permanentGrass + landUse.temporaryGrass

      var topographyScore = (topography.flat * 0.03) + (topography.moderatelySloped * 0.02) + (topography.steep * 0.01)

      var result = topographyScore + details.closenessOfManureSpread

      if (details.watercoursesAreFenced) result++

      if (details.thicknessOfBufferStrips > 0) result++

      var organicFerts = fullFarmData.farmManagement.fertiliserApplications.organicFertilisersApplied

      var organicTotal = organicFerts.reduce((accumulator, currentValue) => {
        var spring = currentValue.tonnesAppliedInSpring ?? 0
        var autumn = currentValue.tonnesAppliedInAutumn ?? 0
        var summer = currentValue.tonnesAppliedInSummer ?? 0
        var winter = currentValue.tonnesAppliedInWinter ?? 0
        var totalTonnes = spring + (autumn * 1.1) + summer + (winter * 1.1)

        return accumulator + totalTonnes

      }, 0)

      // var manureApplicationScore = 10 / (organicTotal/grasslandArea)
      var manureApplicationScore;

      if ((organicTotal / grasslandArea) < 1) {
        manureApplicationScore = 10
      } else {
        manureApplicationScore = 10 / (organicTotal / grasslandArea)
      }

      result = result + manureApplicationScore

      var max = indicators?.filter(item => item.id.toLocaleUpperCase() === "165E0B1D-2457-4027-BC00-F0E90E5621E0")[0].maximum ?? 25
      // console.log(((max-result) / max) * 100)
      // return ((max-result) / max) * 100;
      return (result / max) * 100;
    }
    return null;
  }

  const CalculateAreaConservedForNature = () => {
    if (fullFarmData?.farmBreakdown.landscapeAesthetics) {
      var details = fullFarmData.farmBreakdown.landscapeAesthetics;

      var result = details.percentageConservedForNature;

      if (result > 20) result = 20;

      var max = indicators?.filter(item => item.id.toLocaleUpperCase() === "84B011E2-98BF-4B80-BEB5-EA5886BDD51B")[0].maximum ?? 20
      return (result / max) * 100;
    }
    return null;
  }

  const CalculateNaturalHeritage = () => {
    if (fullFarmData?.farmBreakdown.landscapeAesthetics) {
      var details = fullFarmData.farmBreakdown.landscapeAesthetics;

      var result = 0;

      if (details.naturalHeritagePresent) result = 1;

      var max = indicators?.filter(item => item.id.toLocaleUpperCase() === "91FDA0A9-98A7-40B4-810D-C912123F4CA2")[0].maximum ?? 1
      return (result / max) * 100;
    }
    return null;
  }

  const CalculateNatureTrails = () => {
    if (fullFarmData?.farmBreakdown.landscapeAesthetics) {
      var details = fullFarmData.farmBreakdown.landscapeAesthetics;

      var result = 0;

      if (details.natureTrailsPresnt) result = 1;

      var max = indicators?.filter(item => item.id.toLocaleUpperCase() === "A0B2E773-03E9-4BA2-AC06-C1C573217F78")[0].maximum ?? 1
      return (result / max) * 100;
    }
    return null;
  }

  const CalculateFarmAppeal = () => {
    if (fullFarmData?.farmBreakdown.landscapeAesthetics && fullFarmData.farmBreakdown.landUse && fullFarmData.farmManagement.fertiliserApplications?.organicFertilisersApplied && fullFarmData.farmBreakdown.animalsOnFarm && fullFarmData.biodiversityInputs) {
      var details = fullFarmData.farmBreakdown.landscapeAesthetics;
      var organicFerts = fullFarmData.farmManagement.fertiliserApplications.organicFertilisersApplied;
      var landUse = fullFarmData.farmBreakdown.landUse
      var UAA = landUse.forestry + landUse.otherCrops + landUse.permanentGrass + landUse.temporaryGrass;
      var animalsOnFarm = fullFarmData.farmBreakdown.animalsOnFarm

      var result = 0;
      // field boundaries
      if (details.hedgerowsPresentInBoundary) result = result + (1 * (details.boundaryPercentageHedgerows ?? 0) / 100);
      if (details.traditionalPresentInBoundary) result = result + (1 * (details.boundaryPercentageTraditional ?? 0) / 100);
      if (details.sheepWirePresentInBoundary) result = result + (0.5 * (details.boundaryPercentageSheepWire ?? 0) / 100);
      if (details.ditchesPresentInBoundary) result = result + (0.5 * (details.boundaryPercentageDitches ?? 0) / 100);
      if (details.otherPresentInBoundary) result = result + (0.5 * (details.boundaryPercentageOther ?? 0) / 100);

      var organicTotalKgN = organicFerts.reduce((accumulator, currentValue) => {
        var spring = currentValue.tonnesAppliedInSpring ?? 0
        var autumn = currentValue.tonnesAppliedInAutumn ?? 0
        var summer = currentValue.tonnesAppliedInSummer ?? 0
        var winter = currentValue.tonnesAppliedInWinter ?? 0
        var totalTonnes = spring + autumn + summer + winter;

        var NRates = fullFarmData.farmManagement.organicFertilisers ?? [];

        var currentRate = NRates.find(element => element.id === currentValue.fertiliser)?.kgNPerTonne ?? 1;
        // console.log(currentRate);
        return accumulator + (totalTonnes * currentRate);
      }, 0)

      var organicKgNPerHa = organicTotalKgN / UAA;
      if (organicKgNPerHa < 100) {
        result = result + 1;
      } else {
        result = result + 0.5;
      }

      if (animalsOnFarm.length > 0) result = result + 1;

      var bioInput = fullFarmData.biodiversityInputs.data;

      if (bioInput.pollinatingCropsGrown) result = result + 1;

      result = result + 1 * (details.thickHedgerowPercentage ?? 0) / 100;
      result = result + 0.75 * (details.thickHedgerowPercentage ?? 0) / 100;

      var max = indicators?.filter(item => item.id.toLocaleUpperCase() === "EFA8BBF3-F715-4DE4-A6FD-7F5E6A1DB85D")[0].maximum ?? 5;
      return (result / max) * 100;
    }
    return null;
  }

  const getManagementAdvice = (result: number | null, indicatorId: string) => {
    if (result === null || Number.isNaN(result)) return "Insufficient data";

    var indicator = indicators?.find(element => element.id === indicatorId)

    // handle climate regulation stocking rate management advice. may add green advice to data model if enough indicators return custom advice for that case
    if (["F599CF2C-EBCC-4D68-921E-531944A2D933", "036FC1F2-88C1-461C-A707-4C619CC170D5", "3ED5ECE7-3E03-4F1F-B01A-337E3408D477"].includes(indicatorId.toLocaleUpperCase())) {

      if (result < 100) return `${calculateStockingRateUAA()?.toFixed(2)} LSU/Ha. This value relates to the Utilized Agricultural Area(UAA) on your farm. ` + indicator?.amberAdvice;
      if (result === 100) return `${calculateStockingRateUAA()?.toFixed(2)} LSU/Ha. This value relates to the Utilized Agricultural Area(UAA) on your farm. ` + "This is an estimate of stocking rate density, but as the optimal this will vary by land type and animal type we recommend consulting an advisor to assess the optimal for your farm";
    }

    // grazing
    if (["39032A7C-4C47-4771-92C9-F35448E139DA"].includes(indicatorId.toLocaleUpperCase())) {

      if (result < 100) return `${calculateStockingRateGrazing()?.toFixed(2)} LSU/Ha. This value relates to the grassland on your farm. ` + indicator?.amberAdvice;
      if (result === 100) return `${calculateStockingRateGrazing()?.toFixed(2)} LSU/Ha. This value relates to the grassland on your farm. ` + "This is an estimate of stocking rate density, but as the optimal this will vary by land type and animal type we recommend consulting an advisor to assess the optimal for your farm";
    }

    var scaledResult = (result / 100) * (indicator?.maximum ?? 100)
    // handle special case for wildlife measures
    if (scaledResult > 1 && scaledResult < 2 && indicatorId.toLocaleUpperCase() === "82DCFB2F-BF2C-4D71-9854-C4672E8470F9") {
      return "Although you currently are accommodating wildlife on your farm, consider whether you can accommodate more wildlife through changing mowing practices, increasing field margins or leaving areas of permanent grassland uncut"
    }
    if (scaledResult < (indicator?.greenThreshold ?? 70)) {
      if (indicatorId.toLocaleUpperCase() === "662A89FA-F935-4C5D-8C1B-2B41CA15C974" && !fullFarmData?.farmManagement.fertiliserApplications?.fertiliserApplicationDetails?.followedNutrientManagementPlan) return indicator?.amberAdvice + " and follow a nutrient management plan"
      return indicator?.amberAdvice
    }

    return "All good here. Good job!"
  }

  const editHandler = (indicatorId: string) => {



    // forage efficiency and mineral n application level 
    if ((indicatorId.toLocaleUpperCase() === "F940377C-1C14-4B42-9A64-4683095D5ACD" || indicatorId.toLocaleUpperCase() === "66111EC6-A3D0-43B5-926B-AC0D06AD64E8") && !fullFarmData?.farmBreakdown.landUse) {
      toast.info("Complete your Stocking density inputs to unlock this");
      return;
    }

    // housing potential
    if (indicatorId.toLocaleUpperCase() === "7AC2856B-E56F-476C-A92E-053A50B348C7" && !fullFarmData?.farmBreakdown.animalsOnFarm) {
      toast.info("Complete your Stocking density inputs to unlock this");
      return;
    }

    // nitrogen use efficiency (livestock)
    if (indicatorId.toLocaleUpperCase() === "3FB43BAF-3BCF-4EE7-A354-23761E8C5D44") {
      if (!fullFarmData?.farmBreakdown.animalsOnFarm || !fullFarmData?.farmBreakdown.landUse) {
        toast.info("Complete your Stocking density inputs to unlock this");
        return;
      }
      if (!fullFarmData?.farmBreakdown.animalDiet) {
        toast.info("Complete your Forage efficiency inputs to unlock this");
        return;
      }
      if (!fullFarmData?.farmManagement.fertiliserApplications?.manufacturedFertilisersApplied || !fullFarmData?.farmManagement.fertiliserApplications?.organicFertilisersApplied) {
        toast.info("Complete your Nitrogen use efficiency (fertiliser) inputs to unlock this");
        return;
      }
    }
    var indicator = indicators?.find(element => element.id === indicatorId)

    // dry matter productivity
    if ((indicatorId.toLocaleUpperCase() === "E1801DB4-B441-40B1-924B-04D6952A4702") && fullFarmData?.farmManagement.grassSwardData) {
      setSelectedInputPath(`/input${indicator?.inputScreenPath}`)
      setSelectedIndicatorName(indicator?.name ?? "")
      // @ts-ignore
      $('#exampleModalCenter').modal('show');
      return
    }
    // stocking density
    if ((indicatorId.toLocaleUpperCase() === "39032A7C-4C47-4771-92C9-F35448E139DA" ||
      indicatorId.toLocaleUpperCase() === "3ED5ECE7-3E03-4F1F-B01A-337E3408D477" ||
      indicatorId.toLocaleUpperCase() === "036FC1F2-88C1-461C-A707-4C619CC170D5" ||
      indicatorId.toLocaleUpperCase() === "F599CF2C-EBCC-4D68-921E-531944A2D933")
      && fullFarmData?.farmBreakdown.landUse) {
      setSelectedInputPath(`/input${indicator?.inputScreenPath}`)
      setSelectedIndicatorName(indicator?.name ?? "")
      // @ts-ignore
      $('#exampleModalCenter').modal('show');
      return
    }
    // nitrogen livestock efficiency livestock production
    if ((indicatorId.toLocaleUpperCase() === "3FB43BAF-3BCF-4EE7-A354-23761E8C5D44") && fullFarmData?.farmBreakdown.animalProduction) {
      setSelectedInputPath(`/input${indicator?.inputScreenPath}`)
      setSelectedIndicatorName(indicator?.name ?? "")
      // @ts-ignore
      $('#exampleModalCenter').modal('show');
      return
    }
    // soil degradation
    if ((indicatorId.toLocaleUpperCase() === "78286A05-F36A-489B-8DB8-84D58889AA6D") && fullFarmData?.farmBreakdown.soilProfile.soilDegradation) {
      setSelectedInputPath(`/input${indicator?.inputScreenPath}`)
      setSelectedIndicatorName(indicator?.name ?? "")
      // @ts-ignore
      $('#exampleModalCenter').modal('show');
      return
    }
    // forage efficiency
    if ((indicatorId.toLocaleUpperCase() === "66111EC6-A3D0-43B5-926B-AC0D06AD64E8") && (fullFarmData?.farmBreakdown.animalDiet.length ?? 0) > 0) {
      setSelectedInputPath(`/input${indicator?.inputScreenPath}`)
      setSelectedIndicatorName(indicator?.name ?? "")
      // @ts-ignore
      $('#exampleModalCenter').modal('show');
      return
    }
    // nitrogen livestock efficiency fertilizer, mineral N input, quantities of manure applied, N input rate
    if ((indicatorId.toLocaleUpperCase() === "662A89FA-F935-4C5D-8C1B-2B41CA15C974" ||
      indicatorId.toLocaleUpperCase() === "F940377C-1C14-4B42-9A64-4683095D5ACD" ||
      indicatorId.toLocaleUpperCase() === "D065169F-7541-4213-B9E7-A8269E9D1D5D" ||
      indicatorId.toLocaleUpperCase() === "0D25C789-B717-4B08-B284-2F48C7F7BB02") && fullFarmData?.farmManagement.fertiliserApplications?.fertiliserApplicationDetails) {
      setSelectedInputPath(`/input${indicator?.inputScreenPath}`)
      setSelectedIndicatorName(indicator?.name ?? "")
      // @ts-ignore
      $('#exampleModalCenter').modal('show');
      return
    }
    // biodiversity grasslands
    if (indicatorId.toLocaleUpperCase() === "DC4BB24C-F6B0-44D6-9185-D0BD41B2A0C6" && (fullFarmData?.biodiversityInputs.grasslands.length ?? 0) > 0) {
      setSelectedInputPath(`/input${indicator?.inputScreenPath}`)
      setSelectedIndicatorName(indicator?.name ?? "")
      // @ts-ignore
      $('#exampleModalCenter').modal('show');
      return
    }
    // biodiversity data
    if ((indicatorId.toLocaleUpperCase() === "7C54F3DA-80CF-4E6C-8559-903F9D4B7D18" ||
      indicatorId.toLocaleUpperCase() === "82DCFB2F-BF2C-4D71-9854-C4672E8470F9" ||
      indicatorId.toLocaleUpperCase() === "2429E794-4DC1-46A6-BA0F-F3D317DE68B5") && fullFarmData?.biodiversityInputs.data) {
      setSelectedInputPath(`/input${indicator?.inputScreenPath}`)
      setSelectedIndicatorName(indicator?.name ?? "")
      // @ts-ignore
      $('#exampleModalCenter').modal('show');
      return
    }
    // waterway protection & risk of pollutants
    if ((indicatorId.toLocaleUpperCase() === "83C1BE5C-CA46-4CAC-B2D8-1B996B159E7F" ||
      indicatorId.toLocaleUpperCase() === "165E0B1D-2457-4027-BC00-F0E90E5621E0") && fullFarmData?.farmBreakdown.watercourses.watercourseDetails) {
      setSelectedInputPath(`/input${indicator?.inputScreenPath}`);
      setSelectedIndicatorName(indicator?.name ?? "");
      // @ts-ignore
      $('#exampleModalCenter').modal('show');
      return
    }
    // vegetation cover & total porosity
    if ((indicatorId.toLocaleUpperCase() === "D0851B36-D082-4A05-80BC-8D903A2ED69D" ||
      indicatorId.toLocaleUpperCase() === "27626459-97EF-45F2-B703-07375382752E") && fullFarmData?.farmBreakdown.soilProfile.soilAssessment) {
      setSelectedInputPath(`/input${indicator?.inputScreenPath}`);
      setSelectedIndicatorName(indicator?.name ?? "");
      // @ts-ignore
      $('#exampleModalCenter').modal('show');
      return
    }
    // soil structure
    if ((indicatorId.toLocaleUpperCase() === "99CBD9D4-D698-4304-8C95-863E1BD94DC3") && fullFarmData?.farmBreakdown.soilProfile.signsOfErosion) {
      setSelectedInputPath(`/input${indicator?.inputScreenPath}`);
      setSelectedIndicatorName(indicator?.name ?? "");
      // @ts-ignore
      $('#exampleModalCenter').modal('show');
      return
    }
    // housing potential
    if ((indicatorId.toLocaleUpperCase() === "7AC2856B-E56F-476C-A92E-053A50B348C7") && fullFarmData?.farmBreakdown.housingPotential) {
      setSelectedInputPath(`/input${indicator?.inputScreenPath}`);
      setSelectedIndicatorName(indicator?.name ?? "");
      // @ts-ignore
      $('#exampleModalCenter').modal('show');
      return
    }
    // farm appeal, conserved area, nature trails & natural heritage
    if ((indicatorId.toLocaleUpperCase() === "84B011E2-98BF-4B80-BEB5-EA5886BDD51B" ||
      indicatorId.toLocaleUpperCase() === "91FDA0A9-98A7-40B4-810D-C912123F4CA2" ||
      indicatorId.toLocaleUpperCase() === "A0B2E773-03E9-4BA2-AC06-C1C573217F78" ||
      indicatorId.toLocaleUpperCase() === "EFA8BBF3-F715-4DE4-A6FD-7F5E6A1DB85D") && fullFarmData?.farmBreakdown.landscapeAesthetics) {
      setSelectedInputPath(`/input${indicator?.inputScreenPath}`)
      setSelectedIndicatorName(indicator?.name ?? "")
      // @ts-ignore
      $('#exampleModalCenter').modal('show');
      return
    }

    navigate(`/input${indicator?.inputScreenPath}`)
  }

  return (
    <>
      <MetaData title='Report Dashboard' />
      <ReportContainer>
        <ReportTopBar>
          <div><h3 className='text-center'><strong>REPORT DASHBOARD</strong></h3></div>
          <div><h3 className='text-center'><strong>{loading ? <Skeleton height={30} /> : report?.name}</strong></h3></div>
          <div><h3 className='text-center'><strong>{loading ? <Skeleton height={30} /> : fullFarmData?.farmData?.year}</strong></h3></div>
        </ReportTopBar>
        <ReportMenuBar>
          <div>
            <ReportMenuBarButton disabled={loading}>{loading ? <Skeleton circle={true} width={30} height={30} style={{ margin: '10px' }} /> : <ReportMenuBarIcon src="/icons/download.svg" onClick={generateReportPDF} />}</ReportMenuBarButton>
          </div>
        </ReportMenuBar>
        <ReportEcosystemServicesBar>
          <div><ReportESButton disabled={loading} activeES={activeES} ES={4} onClick={() => updateActiveES(4)}>Food Production</ReportESButton></div>
          <div><ReportESButton disabled={loading} activeES={activeES} ES={1} onClick={() => updateActiveES(1)}>Biodiversity</ReportESButton></div>
          <div><ReportESButton disabled={loading} activeES={activeES} ES={6} onClick={() => updateActiveES(6)}>Climate Regulation</ReportESButton></div>
          <div><ReportESButton disabled={loading} activeES={activeES} ES={5} onClick={() => updateActiveES(5)}>Water Quality</ReportESButton></div>
          <div><ReportESButton disabled={loading} activeES={activeES} ES={3} onClick={() => updateActiveES(3)}>Flood Control</ReportESButton></div>
          <div><ReportESButton disabled={loading} activeES={activeES} ES={2} onClick={() => updateActiveES(2)}>Landscape</ReportESButton></div>
        </ReportEcosystemServicesBar>
        <ReportEcosystemServicesDropdownContainer>
          <label htmlFor="ecosystem_service_dropdown"><strong>Services:</strong></label>
          <select className='form-control' value={activeES} onChange={(e) => updateActiveES(parseInt(e.target.value))}>
            <option value="4">Food Production</option>
            <option value="1">Biodiversity</option>
            <option value="6">Climate Regulation</option>
            <option value="5">Water Quality</option>
            <option value="3">Flood Control</option>
            <option value="2">Landscape Aesthetics</option>
          </select>
        </ReportEcosystemServicesDropdownContainer>
        {activeES === undefined ? (
          <>
            <div className='my-5'>Choose an Ecosystem Service above</div>
          </>
        ) : (
          <>
            <div>
              <ReportESBar>
                <div className="w-25 text-center"><strong>{ecosystemServices?.find((element) => element.id === activeES)?.name} Score (%)</strong></div>
                <div className="w-75 text-center"><strong>Summary</strong></div>
              </ReportESBar>
              <ReportESBar>
                <div className="mx-2 w-25 text-center"><h2>{loading ? <Skeleton height={35} width={60} /> : `${score.toFixed(2)}%`}</h2></div>
                <div className="mx-2 w-75 text-center"><p>{loading ? <Skeleton count={4} /> : ecosystemServices?.find((element) => element.id === activeES)?.summary}</p></div>
              </ReportESBar>
              <ReportESBarMobile>
                <div className="w-100 text-center">
                  <strong>{ecosystemServices?.find((element) => element.id === activeES)?.name} Score: </strong>
                  <h2>{loading ? <Skeleton height={35} width={60} /> : `${score.toFixed(2)}%`}</h2>
                </div>
              </ReportESBarMobile>
              <ReportESBarMobile>
                <div className="w-100 text-center">
                  <strong>Summary: </strong>
                  <p>{loading ? <Skeleton count={4} /> : ecosystemServices?.find((element) => element.id === activeES)?.summary}</p>
                </div>
              </ReportESBarMobile>
            </div>
            <IndicatorGroup activeES={activeES} calculateIndicatorValue={calculateIndicatorValue} getManagementAdvice={getManagementAdvice} editHandler={editHandler} />
          </>
        )}

      </ReportContainer>
      <div className="modal fade" id="exampleModalCenter" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle">Are you sure?</h5>
              <button type="button" className="btn btn-secondary close" data-bs-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p>This inputs for the <strong>{selectedIndicatorName}</strong> indicator have already been entered. Would you like to go back and enter other data or edit the existing data?</p>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-outline-secondary" data-bs-dismiss="modal">Back</button>
              <button type="button" className="btn btn-warning" onClick={() => navigate(selectedInputPath)} data-bs-dismiss="modal">Yes, Edit</button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Report