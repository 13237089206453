import React, { useEffect } from 'react'
import { useAppSelector } from '../../hooks'
import { useNavigate, Outlet } from 'react-router-dom';
import { InputContainer } from '../styles/input/Input.styled';

type Props = {}

const Input = (props: Props) => {

    const { report, loading } = useAppSelector((state) => state.report);

    useEffect(() => {
        // if (!report && !loading) navigate("/reports")
    })
    return (
        <InputContainer>
            <div><h2>{report?.name} Farm Data</h2></div>
            <div className='my-4'>
                <Outlet />
            </div>
        </InputContainer>
    )
}

export default Input