import React, { Fragment } from 'react'
import { IOrganicFertiliserApplied } from '../../../../../Interfaces'
import { InputButton } from '../../../../styles/input/Input.styled'
import { useAppSelector } from '../../../../../hooks'

type Props = {
    rowsData: IOrganicFertiliserApplied[],
    deleteTableRows: (a: number) => void,
    handleChange: (index: number, e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void
}


const TableRows = (props: Props) => {

    const { fullFarmData, success, error } = useAppSelector((state) => state.farmData)

    return (
        <>
            {props.rowsData.map((data, index) => {
                const { fertiliser, isAnalysed, kgNPerTonnesAnalysed, totalTonnesProduced, monthsOfStorage, tonnesAppliedInAutumn, tonnesAppliedInSpring, tonnesAppliedInSummer, tonnesAppliedInWinter } = data
                return (
                    <Fragment key={index}>
                        <tr>
                            <td>
                                <label className='ui-table-cell-label' htmlFor='isAnalysed'>Is this fertiliser analysed?</label>
                                <input
                                    type='checkbox'
                                    checked={isAnalysed}
                                    name="isAnalysed"
                                    onChange={(e) => (props.handleChange(index, e))}>
                                </input>
                            </td>
                            <td>
                                <label className='ui-table-cell-label' htmlFor='fertiliser'>Fertiliser</label>
                                <select 
                                    value={fertiliser}
                                    onChange={(e) => (props.handleChange(index, e))}
                                    name="fertiliser"
                                    className="form-control"
                                >
                                    <option value={0}>None</option>
                                    {fullFarmData?.farmManagement?.organicFertilisers && fullFarmData.farmManagement.organicFertilisers.map(element => {
                                        return <option key={element.id} value={element.id}>{element.name}</option>
                                    })}

                                </select>
                            </td>
                            <td>
                                <label className='ui-table-cell-label' htmlFor='totalTonnesProduced'>Total Tonnes Produced</label>
                                <input
                                    type="number"
                                    value={totalTonnesProduced}
                                    onChange={(e) => (props.handleChange(index, e))}
                                    name="totalTonnesProduced"
                                    className="form-control"
                                />
                            </td>
                            <td>
                                <label className='ui-table-cell-label' htmlFor='tonnesAppliedInSpring'>Tonnes Applied In Spring</label>
                                <input
                                    type="number"
                                    value={tonnesAppliedInSpring}
                                    onChange={(e) => (props.handleChange(index, e))}
                                    name="tonnesAppliedInSpring"
                                    className="form-control"
                                />
                            </td>
                            <td>
                                <label className='ui-table-cell-label' htmlFor='tonnesAppliedInSummer'>Tonnes Applied In Summer</label>
                                <input
                                    type="number"
                                    value={tonnesAppliedInSummer}
                                    onChange={(e) => (props.handleChange(index, e))}
                                    name="tonnesAppliedInSummer"
                                    className="form-control"
                                />
                            </td>
                            <td>
                                <label className='ui-table-cell-label' htmlFor='tonnesAppliedInAutumn'>Tonnes Applied In Autumn</label>
                                <input
                                    type="number"
                                    value={tonnesAppliedInAutumn}
                                    onChange={(e) => (props.handleChange(index, e))}
                                    name="tonnesAppliedInAutumn"
                                    className="form-control"
                                />
                            </td>
                            <td>
                                <label className='ui-table-cell-label' htmlFor='tonnesAppliedInWinter'>Tonnes Applied In Winter</label>
                                <input
                                    type="number"
                                    value={tonnesAppliedInWinter}
                                    onChange={(e) => (props.handleChange(index, e))}
                                    name="tonnesAppliedInWinter"
                                    className="form-control"
                                />
                            </td>
                            <td>
                                <label className='ui-table-cell-label' htmlFor='monthsOfStorage'>Months In Storage</label>
                                <input
                                    type="number"
                                    value={monthsOfStorage}
                                    onChange={(e) => (props.handleChange(index, e))}
                                    name="monthsOfStorage"
                                    className="form-control"
                                />
                            </td>
                            <td>
                                <label className='ui-table-cell-label' htmlFor='kgNPerTonnesAnalysed'>Analysed KgN/t</label>
                                <input
                                    type="number"
                                    value={kgNPerTonnesAnalysed}
                                    onChange={(e) => (props.handleChange(index, e))}
                                    name="kgNPerTonnesAnalysed"
                                    className="form-control"
                                />
                            </td>
                            <td>
                                <InputButton className="dynamic-table-btn my-1" onClick={() => (props.deleteTableRows(index))}>
                                    -
                                </InputButton>
                            </td>
                        </tr>
                    </Fragment>
                )
            })}
        </>
    )
}

export default TableRows