import React, { useEffect, useState } from 'react'
import { IAnimalDiet } from '../../../Interfaces';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { useNavigate } from 'react-router-dom';
import { getFullFarmDataById, putAnimalDiet, putAnimalProduction, resetFarmDataId, resetUpdate } from '../../../features/farmData/farmDataSlice';
import { toast } from 'react-toastify';
import { InputButton, InputButtonsContainer } from '../../styles/input/Input.styled';
import MetaData from '../../layout/MetaData';

type Props = {}

const AnimalProduction = (props: Props) => {

    const [pulledData, setPulledData] = useState(false);
    const [dairyMilkOutput, setDairyMilkOutput] = useState(0);
    const [dairyMilkProteinPercentage, setDairyMilkProteinPercentage] = useState(0);
    const [dairyMilkFatPercentage, setDairyMilkFatPercentage] = useState(0);
    const [horseMilkOutput, setHorseMilkOutput] = useState(0);
    const [horseMilkProteinPercentage, setHorseMilkProteinPercentage] = useState(0);
    const [horseMilkFatPercentage, setHorseMilkFatPercentage] = useState(0);
    const [sheepMilkOutput, setSheepMilkOutput] = useState(0);
    const [sheepMilkProteinPercentage, setSheepMilkProteinPercentage] = useState(0);
    const [sheepMilkFatPercentage, setSheepMilkFatPercentage] = useState(0);
    const [goatMilkOutput, setGoatMilkOutput] = useState(0);
    const [goatMilkProteinPercentage, setGoatMilkProteinPercentage] = useState(0);
    const [goatMilkFatPercentage, setGoatMilkFatPercentage] = useState(0);
    const [poultryEggs, setPoultryEggs] = useState(0);
    const [backToReport, setBackToReport] = useState(false);

    const { report } = useAppSelector((state) => state.report);
    const { fullFarmData, success, error } = useAppSelector((state) => state.farmData)

    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!pulledData) {
            if (fullFarmData?.farmBreakdown?.animalProduction) {
                var data = fullFarmData?.farmBreakdown?.animalProduction
                setDairyMilkOutput(data.dairyMilkOutput)
                setDairyMilkProteinPercentage(data.dairyMilkProteinPercentage)
                setDairyMilkFatPercentage(data.dairyMilkFatPercentage)
                setHorseMilkOutput(data.horseMilkOutput)
                setHorseMilkProteinPercentage(data.horseMilkProteinPercentage)
                setHorseMilkFatPercentage(data.horseMilkFatPercentage)
                setSheepMilkOutput(data.sheepMilkOutput)
                setSheepMilkProteinPercentage(data.sheepMilkProteinPercentage)
                setSheepMilkFatPercentage(data.sheepMilkFatPercentage)
                setGoatMilkOutput(data.goatMilkOutput)
                setGoatMilkProteinPercentage(data.goatMilkProteinPercentage)
                setGoatMilkFatPercentage(data.goatMilkFatPercentage)
                setPoultryEggs(data.poultryEggs)
            }
            setPulledData(true);
        }

        if (success) {
            let farmDataId = report?.farmData;
            dispatch(getFullFarmDataById({ id: farmDataId! }))
            toast.dismiss();
            toast.success("Input saved successfully");
            dispatch(resetUpdate());
            if (backToReport) {
                dispatch(resetFarmDataId());
                navigate(`/report/${report?.id}`);
            }
        }

    }, [dispatch, error, pulledData, success, backToReport, navigate, report?.farmData, report?.id, fullFarmData?.farmBreakdown?.animalProduction])

    const saveHandler = (e?: React.MouseEvent) => {
        e?.preventDefault();

        // validation
        if (dairyMilkProteinPercentage < 0 || dairyMilkProteinPercentage > 100) {
            toast.dismiss()
            toast.error("Dairy protein percentage must be between 0 and 100")
            return
        }
        if (dairyMilkFatPercentage < 0 || dairyMilkFatPercentage > 100) {
            toast.dismiss()
            toast.error("Dairy fat percentage must be between 0 and 100")
            return
        }
        if (horseMilkProteinPercentage < 0 || horseMilkProteinPercentage > 100) {
            toast.dismiss()
            toast.error("Horse protein percentage must be between 0 and 100")
            return
        }
        if (horseMilkFatPercentage < 0 || horseMilkFatPercentage > 100) {
            toast.dismiss()
            toast.error("Horse fat percentage must be between 0 and 100")
            return
        }
        if (sheepMilkProteinPercentage < 0 || sheepMilkProteinPercentage > 100) {
            toast.dismiss()
            toast.error("Sheep protein percentage must be between 0 and 100")
            return
        }
        if (sheepMilkFatPercentage < 0 || sheepMilkFatPercentage > 100) {
            toast.dismiss()
            toast.error("Sheep fat percentage must be between 0 and 100")
            return
        }
        if (goatMilkProteinPercentage < 0 || goatMilkProteinPercentage > 100) {
            toast.dismiss()
            toast.error("Goat protein percentage must be between 0 and 100")
            return
        }
        if (goatMilkFatPercentage < 0 || goatMilkFatPercentage > 100) {
            toast.dismiss()
            toast.error("Goat fat percentage must be between 0 and 100")
            return
        }

        toast.dismiss();
        toast.info("Saving progress...");
        dispatch(putAnimalProduction(
            {
                id: fullFarmData?.farmData.id!,
                animalProduction: {
                    dairyMilkFatPercentage,
                    dairyMilkOutput,
                    dairyMilkProteinPercentage,
                    horseMilkFatPercentage,
                    horseMilkOutput,
                    horseMilkProteinPercentage,
                    sheepMilkFatPercentage,
                    sheepMilkOutput,
                    sheepMilkProteinPercentage,
                    goatMilkFatPercentage,
                    goatMilkOutput,
                    goatMilkProteinPercentage,
                    poultryEggs
                }
            })
        );
    }

    return (
        <>

            <MetaData title='Animal Production' />

            <div>
                <div><h4><strong>ABOUT YOUR FARM - ANIMAL PRODUCTION</strong></h4></div>

                {/* <FertTable rowsData={fertilisers} setRowsData={setFertilisers} /> */}
                <div className='my-3'><strong>If you produce milk or eggs on your farm, please enter the amount and quality of milk and the number of eggs produced. Meat produced is derived from the amount of animal product sold (entered in the animals on farm section)</strong></div>

                <div className='my-4'>
                    <div className='my-1'><strong>Dairy</strong> - Milk production</div>
                    <div className='row'>
                        <div className='col-md-4 col-6'>
                            <label>Output (litres)</label>
                            <input
                                className='form-control'
                                type='number'
                                value={dairyMilkOutput}
                                onChange={(e) => setDairyMilkOutput(e.target.valueAsNumber)}
                            />
                        </div>
                        <div className='col-md-4 col-6'>
                            <label>Protein (%)</label>
                            <input
                                className='form-control'
                                type='number'
                                value={dairyMilkProteinPercentage}
                                onChange={(e) => setDairyMilkProteinPercentage(e.target.valueAsNumber)}
                            />
                        </div>
                        <div className='col-md-4 col-6'>
                            <label>Fat (%)</label>
                            <input
                                className='form-control'
                                type='number'
                                value={dairyMilkFatPercentage}
                                onChange={(e) => setDairyMilkFatPercentage(e.target.valueAsNumber)}
                            />
                        </div>
                    </div>
                </div>

                <div className='my-4'>
                    <div className='my-1'><strong>Horse</strong> - Milk production</div>
                    <div className='row'>
                        <div className='col-md-4 col-6'>
                            <label>Output (litres)</label>
                            <input
                                className='form-control'
                                type='number'
                                value={horseMilkOutput}
                                onChange={(e) => setHorseMilkOutput(e.target.valueAsNumber)}
                            />
                        </div>
                        <div className='col-md-4 col-6'>
                            <label>Protein (%)</label>
                            <input
                                className='form-control'
                                type='number'
                                value={horseMilkProteinPercentage}
                                onChange={(e) => setHorseMilkProteinPercentage(e.target.valueAsNumber)}
                            />
                        </div>
                        <div className='col-md-4 col-6'>
                            <label>Fat (%)</label>
                            <input
                                className='form-control'
                                type='number'
                                value={horseMilkFatPercentage}
                                onChange={(e) => setHorseMilkFatPercentage(e.target.valueAsNumber)}
                            />
                        </div>
                    </div>
                </div>

                <div className='my-4'>
                    <div className='my-1'><strong>Sheep</strong> - Milk production</div>
                    <div className='row'>
                        <div className='col-md-4 col-6'>
                            <label>Output (litres)</label>
                            <input
                                className='form-control'
                                type='number'
                                value={sheepMilkOutput}
                                onChange={(e) => setSheepMilkOutput(e.target.valueAsNumber)}
                            />
                        </div>
                        <div className='col-md-4 col-6'>
                            <label>Protein (%)</label>
                            <input
                                className='form-control'
                                type='number'
                                value={sheepMilkProteinPercentage}
                                onChange={(e) => setSheepMilkProteinPercentage(e.target.valueAsNumber)}
                            />
                        </div>
                        <div className='col-md-4 col-6'>
                            <label>Fat (%)</label>
                            <input
                                className='form-control'
                                type='number'
                                value={sheepMilkFatPercentage}
                                onChange={(e) => setSheepMilkFatPercentage(e.target.valueAsNumber)}
                            />
                        </div>
                    </div>
                </div>

                <div className='my-4'>
                    <div className='my-1'><strong>Goat</strong> - Milk production</div>
                    <div className='row'>
                        <div className='col-md-4 col-6'>
                            <label>Output (litres)</label>
                            <input
                                className='form-control'
                                type='number'
                                value={goatMilkOutput}
                                onChange={(e) => setGoatMilkOutput(e.target.valueAsNumber)}
                            />
                        </div>
                        <div className='col-md-4 col-6'>
                            <label>Protein (%)</label>
                            <input
                                className='form-control'
                                type='number'
                                value={goatMilkProteinPercentage}
                                onChange={(e) => setGoatMilkProteinPercentage(e.target.valueAsNumber)}
                            />
                        </div>
                        <div className='col-md-4 col-6'>
                            <label>Fat (%)</label>
                            <input
                                className='form-control'
                                type='number'
                                value={goatMilkFatPercentage}
                                onChange={(e) => setGoatMilkFatPercentage(e.target.valueAsNumber)}
                            />
                        </div>
                    </div>
                </div>

                <div className='my-4'>
                    <div className='my-1'><strong>Poultry</strong> - Eggs</div>
                    <div className='row'>
                        <div className='col-md-4 col-6'>
                            <label>Output (eggs)</label>
                            <input
                                className='form-control'
                                type='number'
                                value={poultryEggs}
                                onChange={(e) => setPoultryEggs(e.target.valueAsNumber)}
                            />
                        </div>
                    </div>
                </div>

                <InputButtonsContainer className='my-2'>
                    <InputButton onClick={() => navigate(`/report/${report?.id}`)}>BACK</InputButton>
                    <InputButton onClick={() => {
                        toast.dismiss()
                        toast.info("Saving...")
                        saveHandler()
                    }}>SAVE</InputButton>
                    <InputButton onClick={() => {
                        toast.dismiss()
                        toast.info("Saving...");
                        setBackToReport(true);
                        saveHandler();
                    }}>SAVE & CONTINUE</InputButton>
                </InputButtonsContainer>

            </div>
        </>
    )
}

export default AnimalProduction