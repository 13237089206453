import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosError } from "axios";
import { IAuthState, IAuthData } from "../../Interfaces";

axios.defaults.withCredentials = true;

const initialState: IAuthState = {
    loading: false,
}

export const loginUser = createAsyncThunk(
    "auth/login",
    async (props: IAuthData, { rejectWithValue }) => {
        try {
            const { email, password, rememberMe } = props;
            const config = {
                headers: {
                    "Content-Type": "application/json"
                }
            };
            const { data } = await axios.post('api/identity/accounts/authenticate', { Email: email, Password: password, RememberMe: rememberMe }, config);
            return data;
        } catch (error) {
            if (error instanceof AxiosError) {
                return rejectWithValue(error.response?.data.signInResultMessage);
            }
            // unhandled non-AxiosError goes here
            throw error;
        }
    }
);

export const registerUser = createAsyncThunk(
    "auth/register",
    async (props: IAuthData, { rejectWithValue }) => {
        try {
            const { acceptTerms, email, password } = props;
            const config = {
                headers: {
                    "Content-Type": "application/json"
                }
            };
            const { data } = await axios.post('api/identity/accounts/register', { AcceptTerms: acceptTerms, Email: email, Password: password }, config);
            return data;
        } catch (error) {
            if (error instanceof AxiosError) {
                if (error.response?.data.errors) return rejectWithValue(error.response?.data.errors);
                return rejectWithValue(error.response?.data);
            }
            // unhandled non-AxiosError goes here
            throw error;
        }
    }
)

export const loadUser = createAsyncThunk(
    "auth/loadUser",
    async (_, { rejectWithValue }) => {
        try {
            const { data } = await axios.get("api/identity/account/management");
            return data;
            console.log(data);
        } catch (error) {
            if (error instanceof AxiosError) {
                return rejectWithValue(error.response?.data.message);
            }
            // unhandled non-AxiosError goes here
            throw error;
        }
    }
)

export const logoutUser = createAsyncThunk(
    "auth/logoutUser",
    async (_, { rejectWithValue }) => {
        try {
            const { data } = await axios.post("api/identity/account/management/log-out");
            return data;
        } catch (error) {
            if (error instanceof AxiosError) {
                return rejectWithValue(error.response?.data.message);
            }
            // unhandled non-AxiosError goes here
            throw error;
        }
    }
)

export const forgotPassword = createAsyncThunk(
    "auth/forgot-password",
    async (props: IAuthData, { rejectWithValue }) => {
        try {
            const { email } = props;
            const config = {
                headers: {
                    "Content-Type": "application/json"
                }
            };
            const { data } = await axios.post('api/identity/accounts/forgot-password', { Email: email }, config);
            return data;
        } catch (error) {
            console.log(error)
            if (error instanceof AxiosError) {
                return rejectWithValue(error.response?.data.message);
            }
            // unhandled non-AxiosError goes here
            throw error;
        }
    }
);

export const resetPassword = createAsyncThunk(
    "auth/reset-password",
    async (props: IAuthData, { rejectWithValue }) => {
        try {
            const { email, token, password } = props;
            const config = {
                headers: {
                    "Content-Type": "application/json"
                }
            };
            const encodedToken = token ? encodeURIComponent(token) : '';
            const { data } = await axios.post('api/identity/accounts/reset-password', { Email: email, Token: encodedToken, Password: password }, config);
            return data;
        } catch (error) {
            if (error instanceof AxiosError) {
                if (error.response?.data.errors) return rejectWithValue(error.response?.data.errors);
                return rejectWithValue(error.response?.data);
            }
            // unhandled non-AxiosError goes here
            throw error;
        }
    }
);

export const resendConfirmationEmail = createAsyncThunk(
    "auth/resend-confirmation-email",
    async (props: IAuthData, { rejectWithValue }) => {
        try {
            const { email } = props;
            const config = {
                headers: {
                    "Content-Type": "application/json"
                }
            };
            const { data } = await axios.post('api/identity/accounts/resend-confirmation-email', { Email: email }, config);
            return data;
        } catch (error) {
            if (error instanceof AxiosError) {
                // console.log(error)
                return rejectWithValue(error.response?.data);
            }
            // unhandled non-AxiosError goes here
            throw error;
        }
    }
);


export const confirmEmail = createAsyncThunk(
    "auth/confirm-email",
    async (props: IAuthData, { rejectWithValue }) => {
        try {
            const { userId, token } = props;
            const config = {
                headers: {
                    "Content-Type": "application/json"
                }
            };
            const encodedToken = token ? encodeURIComponent(token) : '';
            const { data } = await axios.get(`api/identity/accounts/confirm-email?userId=${userId}&token=${encodedToken}`, config);
            return data;
        } catch (error) {
            if (error instanceof AxiosError) {
                return rejectWithValue(error.response?.data.signInResultMessage);
            }
            // unhandled non-AxiosError goes here
            throw error;
        }
    }
);

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        clearErrors(state) {
            state.errors = undefined;
            state.message = undefined;
        },
        clearMessage(state) {
            state.message = undefined;
        }
    },
    extraReducers(builder) {
        builder.addCase(loginUser.pending, (state, action) => {
            state.loading = true;
            state.isAuthenticated = false;
        })
        .addCase(loginUser.fulfilled, (state, action) => {
            state.loading = false;
            state.isAuthenticated = true;
            state.user = action.payload;
        })
        .addCase(loginUser.rejected, (state, action) => {
            state.loading = false;
            state.isAuthenticated = false;
            state.user = undefined;
            state.errors = action.payload;
        })

        .addCase(registerUser.pending, (state, action) => {
            state.loading = true;
            state.isAuthenticated = false;
        })
        .addCase(registerUser.fulfilled, (state, action) => {
            state.loading = false;
            state.message = action.payload.message
        })
        .addCase(registerUser.rejected, (state, action) => {
            state.loading = false;
            state.isAuthenticated = false;
            state.user = undefined;
            state.errors = action.payload;
        })

        .addCase(forgotPassword.pending, (state, action) => {
            state.loading = true;
        })
        .addCase(forgotPassword.fulfilled, (state, action) => {
            state.loading = false;
        })
        .addCase(forgotPassword.rejected, (state, action) => {
            state.loading = false;
        })

        .addCase(resetPassword.pending, (state, action) => {
            state.loading = true;
        })
        .addCase(resetPassword.fulfilled, (state, action) => {
            state.loading = false;
            state.message = action.payload.message;
        })
        .addCase(resetPassword.rejected, (state, action) => {
            state.loading = false;
            state.errors = action.payload;
        })

        .addCase(resendConfirmationEmail.pending, (state, action) => {
            state.loading = true;
        })
        .addCase(resendConfirmationEmail.fulfilled, (state, action) => {
            state.loading = false;
            state.message = action.payload.message;
        })
        .addCase(resendConfirmationEmail.rejected, (state, action) => {
            state.loading = false;
            state.errors = action.payload;
        })

        .addCase(confirmEmail.pending, (state, action) => {
            state.loading = true;
        })
        .addCase(confirmEmail.fulfilled, (state, action) => {
            state.loading = false;
            state.confirmedEmail = true;
        })
        .addCase(confirmEmail.rejected, (state, action) => {
            state.loading = false;
            state.errors = action.payload;
        })

        builder.addCase(loadUser.pending, (state, action) => {
            state.loading = true;
            state.isAuthenticated = false;
        })
        builder.addCase(loadUser.fulfilled, (state, action) => {
            state.isAuthenticated = true;
            state.user = action.payload;
            state.loading = false;
        })
        builder.addCase(loadUser.rejected, (state, action) => {
            state.errors = action.payload;
            state.isAuthenticated = false;
            state.loading = false;
        })
        builder.addCase(logoutUser.pending, (state, action) => {
            state.loading = true;
            state.isAuthenticated = false;
        })
        builder.addCase(logoutUser.fulfilled, (state, action) => {
            state.isAuthenticated = false;
            state.user = undefined;
            state.loading = false;
        })
        builder.addCase(logoutUser.rejected, (state, action) => {
            state.errors = action.payload;
        })
    },
})

export const { clearErrors, clearMessage } = authSlice.actions;
export default authSlice.reducer;