import React, { useState, useEffect } from 'react'
import { IndicatorGrid, IndicatorProgressContainer, IndicatorProgressElement, IndicatorVisualsWrapper, Progress, ProgressLabel } from '../styles/Indicator.styled'
import { FiEdit } from "react-icons/fi";
import { IIndicator } from '../../Interfaces';
import { useNavigate } from 'react-router-dom';
import { NoDisplayOnMobile } from '../styles/Container.styled';
import { useAppSelector } from '../../hooks';
import { toast } from 'react-toastify';
import Skeleton from 'react-loading-skeleton';


type Props = {
    indicator: IIndicator,
    calculateIndicatorValue: (indicatorId: string) => number | null,
    calculateIndicatorProgressColor: (result: number) => string,
    getManagementAdvice: (result: number | null, indicatorId: string) => string | null | undefined,
    editHandler: (indicatorId: string) => void
}

const Indicator = (props: Props) => {
    const [result, setResult] = useState<number | null>(0);
    const [color, setColor] = useState("#141414");

    const navigate = useNavigate();

    const { report } = useAppSelector((state) => state.report);
    const { fullFarmData, success, error, loading } = useAppSelector((state) => state.farmData)


    useEffect(() => {
        // handle climate regulation stocking rate threshold
        if (["F599CF2C-EBCC-4D68-921E-531944A2D933", "3ED5ECE7-3E03-4F1F-B01A-337E3408D477", "39032A7C-4C47-4771-92C9-F35448E139DA", "036FC1F2-88C1-461C-A707-4C619CC170D5"].includes(props.indicator.id.toLocaleUpperCase())) {
            var temp = props.calculateIndicatorValue(props.indicator.id) ?? 0;
            if (temp === 0) {
                setResult(null);
            }
            else if (temp >= 3 || temp <= 1) {
                setResult((1 - (Math.abs(2 - temp) / 5)) * 100)
            } else {
                setResult(100)
            }
        } else {
            setResult(props.calculateIndicatorValue(props.indicator.id));
        }

        if (typeof result === 'number') {
            setColor(props.calculateIndicatorProgressColor(result));
            
        }
    }, [props, result])

    return (
        <IndicatorGrid>
            <IndicatorVisualsWrapper>

                <IndicatorProgressElement>
                    <ProgressLabel>{loading ? <Skeleton /> : <strong>{props.indicator.name}</strong>}</ProgressLabel>
                    <IndicatorProgressContainer result={result === null ? 0 : result} color={color}>
                        <Progress />
                    </IndicatorProgressContainer>
                </IndicatorProgressElement>

                <div className="d-flex align-items-center mt-4">
                    <FiEdit style={{ cursor: 'pointer' }} size="15px" onClick={() => {
                        if (!loading) props.editHandler(props.indicator.id)
                    }} />
                </div>
            </IndicatorVisualsWrapper>
            <div className='mt-3 mb-2 text-end'>
                <NoDisplayOnMobile><strong>Management Advice</strong></NoDisplayOnMobile>
                <div>{loading ? <Skeleton count={2} /> : props.getManagementAdvice(result, props.indicator.id)}</div>
            </div>
        </IndicatorGrid>
    )
}

export default Indicator