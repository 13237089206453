import React from 'react';
import { HomeBodyText, HomeBodyTextWrapper } from './styles/Container.styled';
import { MainButton, MainButtonWrapper } from './styles/Button.styled';
import { useNavigate } from 'react-router-dom';
import { FooterH20202logo } from './styles/Img.styled';
import MetaData from './layout/MetaData';

type Props = {}

const Home = (props: Props) => {

  const navigate = useNavigate();

  return (
    <>
      <MetaData title='Home' />
      <div>
        <HomeBodyTextWrapper>
          <div>
            <HomeBodyText><h1>DECISION SUPPORT <br /> BEYOND LIMITS</h1></HomeBodyText>
            <MainButtonWrapper><MainButton onClick={() => navigate("/reports")}>Get Started</MainButton></MainButtonWrapper>
          </div>
        </HomeBodyTextWrapper>
        <div className='d-flex justify-content-end'>
          <FooterH20202logo src='/assets/SuperG_Horizon2020_horizontal_cores.png' alt='Horizon 2020 EU' />
        </div>
      </div>
    </>
  )
}

export default Home