import React, { ChangeEvent } from 'react'
import { IManufacturedFertiliserApplied } from '../../../../../Interfaces'
import TableRows from './TableRows'
import { InputButton } from '../../../../styles/input/Input.styled'

type Props = {
    rowsData: IManufacturedFertiliserApplied[],
    setRowsData: (e: IManufacturedFertiliserApplied[]) => void
}

const Table = (props: Props) => {

    const addTableRows = () => {

        const rowsInput = {
            isProtectedUrea: false,
            fertiliserName: '',
            nitrogenContentPercentage: 0,
            phosphateContentPercentage: 0,
            potashContentPercentage: 0,
            tonnesAppliedInSpring: 0,
            tonnesAppliedInSummer: 0,
            tonnesAppliedInAutumn: 0,
            tonnesAppliedInWinter: 0,
        }
        props.setRowsData([...props.rowsData, rowsInput])

    }

    const deleteTableRows = (index: number) => {
        const rows = [...props.rowsData]
        rows.splice(index, 1)
        props.setRowsData(rows)
    }

    const handleChange = (index: number, e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value, checked } = e.target as { name: keyof IManufacturedFertiliserApplied, value?: number | string, checked: boolean }
        const rowsInput = [...props.rowsData]
        if (name === "isProtectedUrea") {
            rowsInput[index] = { ...rowsInput[index], [name]: checked }
            if (checked) {
                rowsInput[index] = { ...rowsInput[index], "nitrogenContentPercentage": 46, "phosphateContentPercentage": 0, "potashContentPercentage": 0 }
            }
        } else {
            rowsInput[index] = { ...rowsInput[index], [name]: value }
        }
        props.setRowsData(rowsInput)
    }

    return (
        <>
            <div className='m-3 col-6 d-flex align-items-center'><InputButton className="text-center dynamic-table-btn mx-2" onClick={addTableRows} >+</InputButton> Add another fertiliser type</div>
            <div className="container col-12 d-flex justify-content-center">

                <div className="row">
                    <div>
                        <table data-role="table" data-mode="reflow" className="ui-table ui-table-reflow dynamic-table my-custom-breakpoint">

                            <thead>
                                <tr>
                                    <th data-priority="1">Protected urea</th>
                                    <th data-priority="persist">Fertiliser name</th>
                                    <th data-priority="2">Nitrogen content (%)</th>
                                    <th data-priority="3">Phosphate content (%)</th>
                                    <th data-priority="4">Potash content (%)</th>
                                    <th data-priority="5">Tonnes applied in spring</th>
                                    <th data-priority="5">Tonnes applied in summer</th>
                                    <th data-priority="5">Tonnes applied in autumn</th>
                                    <th data-priority="5">Tonnes applied in winter</th>
                                    <th></th>
                                </tr>

                            </thead>
                            <tbody>



                                <TableRows rowsData={props.rowsData} deleteTableRows={deleteTableRows} handleChange={handleChange} />


                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Table