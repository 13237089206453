import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosError } from "axios";
import { IEcosystemServiceState } from "../../Interfaces";

axios.defaults.withCredentials = true;

const initialState: IEcosystemServiceState = {
    loading: false,
    score: 0
}

export const getAllEcosystemServices = createAsyncThunk(
    "ecosystemService/all",
    async (_, { rejectWithValue }) => {
        try {
            const { data } = await axios.get("/api/ecosystemService/all");
            return data;
        } catch (error) {
            if (error instanceof AxiosError) {
                return rejectWithValue(error.response?.data.message);
            }
            // unhandled non-AxiosError goes here
            throw error;
        }
    }
)

const ecosystemServiceSlice = createSlice({
    name: "ecosystemService",
    initialState,
    reducers: {
        setScore(state, action: PayloadAction<number>) {
            state.score = action.payload
        },
        clearErrors(state) {
            state.error = undefined;
        }
    },
    extraReducers(builder) {
        builder.addCase(getAllEcosystemServices.pending, (state, action) => {
            state.loading = true;
        })
        builder.addCase(getAllEcosystemServices.fulfilled, (state, action) => {
            state.ecosystemServices = action.payload.ecosystemServices;
            state.loading = false;
        })
        builder.addCase(getAllEcosystemServices.rejected, (state, action) => {
            state.error = action.payload;
            state.loading = false;
        })
    },
})

export const { clearErrors, setScore } = ecosystemServiceSlice.actions;
export default ecosystemServiceSlice.reducer;