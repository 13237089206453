import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosError } from "axios";
import { IById, IFarmDataUpdateById, IFarmDataState, IWatercourseAndTopographyRequest, ILandscapeAestheticsRequest } from "../../Interfaces";

axios.defaults.withCredentials = true;

const initialState: IFarmDataState = {
    loading: false,
    currentAnimalOnFarmIndex: 0,
}

export const getFullFarmDataById = createAsyncThunk(
    "farmData/full/get",
    async (props: IById, { rejectWithValue }) => {
        try {
            const { id } = props;
            const { data } = await axios.get(`/api/farmdata/${id}`); //change
            return data;
        } catch (error) {
            if (error instanceof AxiosError) {
                return rejectWithValue(error.response?.data.message);
            }
            // unhandled non-AxiosError goes here
            throw error;
        }
    }
);

export const putBiodiversityData = createAsyncThunk(
    "farmData/put/biodiversity/data",
    async (props: IFarmDataUpdateById, { rejectWithValue }) => {
        try {
            const { id, bioData } = props;
            const config = {
                headers: {
                    "Content-Type": "application/json"
                }
            };
            var body = {
                ThreatenedSpecies: bioData?.threatenedSpecies,
                PartsOfSward: bioData?.partsOfSward,
                MowingGrass: bioData?.mowingGrass,
                ReducedManagement: bioData?.reducedManagement,
                Protection: bioData?.protection,
                PollinatingCrops: bioData?.pollinatingCrops,
            };
            const { data } = await axios.put(`/api/farmdata/biodiversity/data/${id}`, body, config);
            return data;
        } catch (error) {
            if (error instanceof AxiosError) {
                return rejectWithValue(error.response?.data.message);
            }
            // unhandled non-AxiosError goes here
            throw error;
        }
    }
);

export const putBiodiversityGrasslands = createAsyncThunk(
    "farmData/put/biodiversity/grasslands",
    async (props: IFarmDataUpdateById, { rejectWithValue }) => {
        try {
            const { id, grasslands } = props;
            const config = {
                headers: {
                    "Content-Type": "application/json"
                }
            };
            var body = {
                Grasslands: grasslands
            };
            const { data } = await axios.put(`/api/farmdata/biodiversity/grasslands/${id}`, body, config);
            return data;
        } catch (error) {
            if (error instanceof AxiosError) {
                return rejectWithValue(error.response?.data.message);
            }
            // unhandled non-AxiosError goes here
            throw error;
        }
    }
);

export const putAnimalsOnFarm = createAsyncThunk(
    "farmData/put/farmBreakdown/animalsOnFarm",
    async (props: IFarmDataUpdateById, { rejectWithValue }) => {
        try {
            const { id, animalsOnFarm } = props;
            const config = {
                headers: {
                    "Content-Type": "application/json"
                }
            };
            var body = {
                Animals: animalsOnFarm
            };
            const { data } = await axios.put(`/api/farmdata/farmBreakdown/animalsOnFarm/${id}`, body, config);
            return data;
        } catch (error) {
            if (error instanceof AxiosError) {
                return rejectWithValue(error.response?.data.message);
            }
            // unhandled non-AxiosError goes here
            throw error;
        }
    }
);

export const putLandUse = createAsyncThunk(
    "farmData/put/farmBreakdown/landUse",
    async (props: IFarmDataUpdateById, { rejectWithValue }) => {
        try {
            const { id, landUse } = props;
            const config = {
                headers: {
                    "Content-Type": "application/json"
                }
            };
            var body = {
                PermanentGrass: landUse?.permanentGrass,
                TemporaryGrass: landUse?.temporaryGrass,
                Forestry: landUse?.forestry,
                OtherCrops: landUse?.otherCrops,
                BuildingsYardsAndRoadWays: landUse?.buildingsYardsAndRoadWays
            };
            const { data } = await axios.put(`/api/farmdata/farmBreakdown/landUse/${id}`, body, config);
            return data;
        } catch (error) {
            if (error instanceof AxiosError) {
                return rejectWithValue(error.response?.data.message);
            }
            // unhandled non-AxiosError goes here
            throw error;
        }
    }
);

export const putAnimalDiet = createAsyncThunk(
    "farmData/put/farmBreakdown/animalDiet",
    async (props: IFarmDataUpdateById, { rejectWithValue }) => {
        try {
            const { id, animalDiet } = props;
            const config = {
                headers: {
                    "Content-Type": "application/json"
                }
            };
            var body = {
                AnimalDiet: animalDiet
            };
            const { data } = await axios.put(`/api/farmdata/farmBreakdown/animalDiet/${id}`, body, config);
            return data;
        } catch (error) {
            if (error instanceof AxiosError) {
                return rejectWithValue(error.response?.data.message);
            }
            // unhandled non-AxiosError goes here
            throw error;
        }
    }
);

export const putSoilDegradation = createAsyncThunk(
    "farmData/put/farmBreakdown/soilDegradation",
    async (props: IFarmDataUpdateById, { rejectWithValue }) => {
        try {
            const { id, degradation } = props;
            const config = {
                headers: {
                    "Content-Type": "application/json"
                }
            };
            var body = {
                ProportionOfSevere: degradation?.proportionOfSevere,
                ProportionOfHigh: degradation?.proportionOfHigh,
                ProportionOfModerate: degradation?.proportionOfModerate,
                ProportionOfLow: degradation?.proportionOfLow
            };
            const { data } = await axios.put(`/api/farmdata/farmBreakdown/soilDegradation/${id}`, body, config);
            return data;
        } catch (error) {
            if (error instanceof AxiosError) {
                return rejectWithValue(error.response?.data.message);
            }
            // unhandled non-AxiosError goes here
            throw error;
        }
    }
);

export const putHousingPotential = createAsyncThunk(
    "farmData/put/farmBreakdown/housingPotential",
    async (props: IFarmDataUpdateById, { rejectWithValue }) => {
        try {
            const { id, sufficientHousingAllYear, proportions, proportionHoused } = props;
            const config = {
                headers: {
                    "Content-Type": "application/json"
                }
            };
            var body = {
                SufficientHousingAllYear: sufficientHousingAllYear,
                ProportionWhichCanBeHoused: proportionHoused,
                Proportions: proportions,
            };
            const { data } = await axios.put(`/api/farmdata/farmBreakdown/housingPotential/${id}`, body, config);
            return data;
        } catch (error) {
            if (error instanceof AxiosError) {
                return rejectWithValue(error.response?.data.message);
            }
            // unhandled non-AxiosError goes here
            throw error;
        }
    }
);

export const putSignsOfErosion = createAsyncThunk(
    "farmData/put/farmBreakdown/signsOfErosion",
    async (props: IFarmDataUpdateById, { rejectWithValue }) => {
        try {
            const { id, signsOfErosion } = props;
            const config = {
                headers: {
                    "Content-Type": "application/json"
                }
            };
            var body = {
                Rills: signsOfErosion?.rills,
                Poaching: signsOfErosion?.poaching,
                SeverePoaching: signsOfErosion?.severePoaching,
                PathsCreatedByLivestock: signsOfErosion?.pathsCreatedByLivestock,
                ExposedSoil: signsOfErosion?.exposedSoil,
                SurfaceCapping: signsOfErosion?.surfaceCapping,
                Wheeling: signsOfErosion?.wheeling,
                VeryFewWheeling: signsOfErosion?.veryFewWheeling,
                OccasionalWheeling: signsOfErosion?.occasionalWheeling,
                RegularWheeling: signsOfErosion?.regularWheeling,
                PoorSwardQuality: signsOfErosion?.poorSwardQuality,
            };
            const { data } = await axios.put(`/api/farmdata/farmBreakdown/signsOfErosion/${id}`, body, config);
            return data;
        } catch (error) {
            if (error instanceof AxiosError) {
                return rejectWithValue(error.response?.data.message);
            }
            // unhandled non-AxiosError goes here
            throw error;
        }
    }
);

export const putSoilAssessment = createAsyncThunk(
    "farmData/put/farmBreakdown/soilAssessment",
    async (props: IFarmDataUpdateById, { rejectWithValue }) => {
        try {
            const { id, soilAssessment } = props;
            const config = {
                headers: {
                    "Content-Type": "application/json"
                }
            };
            var body = {
                SoilSurfaceAssessment: soilAssessment?.soilSurfaceAssessment,
                SoilAssessmentScore: soilAssessment?.soilAssessmentScore,
            };
            const { data } = await axios.put(`/api/farmdata/farmBreakdown/soilAssessment/${id}`, body, config);
            return data;
        } catch (error) {
            if (error instanceof AxiosError) {
                return rejectWithValue(error.response?.data.message);
            }
            // unhandled non-AxiosError goes here
            throw error;
        }
    }
);

export const putGrassSwardData = createAsyncThunk(
    "farmData/put/farmBreakdown/grassSwardData",
    async (props: IFarmDataUpdateById, { rejectWithValue }) => {
        try {
            const { id, grassSwardData } = props;
            const config = {
                headers: {
                    "Content-Type": "application/json"
                }
            };
            var body = {
                HerbsPresentInSward: grassSwardData?.herbsPresentInSward,
                LegumesPresentInSward: grassSwardData?.legumesPresentInSward,
                ProportionOfLegumesInSward: grassSwardData?.proportionOfLegumesInSward,
                ReseedFrequency: grassSwardData?.reseedFrequency,
                ReseedForStandardManagement: grassSwardData?.reseedForStandardManagement,
                ReseedToImproveSwardCover: grassSwardData?.reseedToImproveSwardCover,
                ReseedForCompaction: grassSwardData?.reseedForCompaction,
                LegumesPresentInReseed: grassSwardData?.legumesPresentInReseed,
                ReseedToIncreaseDiversity: grassSwardData?.reseedToIncreaseDiversity
            };
            const { data } = await axios.put(`/api/farmdata/farmManagement/grassSwardData/${id}`, body, config);
            return data;
        } catch (error) {
            if (error instanceof AxiosError) {
                return rejectWithValue(error.response?.data.message);
            }
            // unhandled non-AxiosError goes here
            throw error;
        }
    }
);

export const putManufacturedFertilisers = createAsyncThunk(
    "farmData/put/farmManagement/manufacturedFertilisers",
    async (props: IFarmDataUpdateById, { rejectWithValue }) => {
        try {
            const { id, fertilisers } = props;
            const config = {
                headers: {
                    "Content-Type": "application/json"
                }
            };
            var body = {
                Fertilisers: fertilisers
            };
            const { data } = await axios.put(`/api/farmdata/farmManagement/manufacturedFertilisers/${id}`, body, config);
            return data;
        } catch (error) {
            if (error instanceof AxiosError) {
                return rejectWithValue(error.response?.data.message);
            }
            // unhandled non-AxiosError goes here
            throw error;
        }
    }
);

export const putOrganicFertilisers = createAsyncThunk(
    "farmData/put/farmManagement/organicFertilisersAndDetails",
    async (props: IFarmDataUpdateById, { rejectWithValue }) => {
        try {
            const { id, fertilisers, fertiliserDetails } = props;
            const config = {
                headers: {
                    "Content-Type": "application/json"
                }
            };
            var body = {
                Fertilisers: fertilisers,
                FertiliserDetails: fertiliserDetails
            };
            const { data } = await axios.put(`/api/farmdata/farmManagement/organicFertilisersAndDetails/${id}`, body, config);
            return data;
        } catch (error) {
            if (error instanceof AxiosError) {
                return rejectWithValue(error.response?.data.message);
            }
            // unhandled non-AxiosError goes here
            throw error;
        }
    }
);

export const putAnimalProduction = createAsyncThunk(
    "farmData/put/farmBreakdown/animalProduction",
    async (props: IFarmDataUpdateById, { rejectWithValue }) => {
        try {
            const { id, animalProduction } = props;
            const config = {
                headers: {
                    "Content-Type": "application/json"
                }
            };
            const { data } = await axios.put(`/api/farmdata/farmBreakdown/animalProduction/${id}`, animalProduction, config);
            return data;
        } catch (error) {
            if (error instanceof AxiosError) {
                return rejectWithValue(error.response?.data.message);
            }
            // unhandled non-AxiosError goes here
            throw error;
        }
    }
);

export const putWatercoursesAndTopography = createAsyncThunk(
    "farmData/put/farmBreakdown/watercourses-and-topography",
    async (props: IWatercourseAndTopographyRequest, { rejectWithValue }) => {
        try {
            const { farmData  } = props;
            const config = {
                headers: {
                    "Content-Type": "application/json"
                }
            };
            const { data } = await axios.put(`/api/farmdata/farmBreakdown/watercourses-and-topography/${farmData}`, props, config);
            return data;
        } catch (error) {
            if (error instanceof AxiosError) {
                return rejectWithValue(error.response?.data.message);
            }
            // unhandled non-AxiosError goes here
            throw error;
        }
    }
);

export const putLandscapeAesthetics = createAsyncThunk(
    "farmData/put/farmBreakdown/landscape-aesthetics",
    async (props: ILandscapeAestheticsRequest, { rejectWithValue }) => {
        try {
            const { farmData  } = props;
            const config = {
                headers: {
                    "Content-Type": "application/json"
                }
            };
            const { data } = await axios.put(`/api/farmdata/farmBreakdown/landscape-aesthetics/${farmData}`, props, config);
            return data;
        } catch (error) {
            if (error instanceof AxiosError) {
                return rejectWithValue(error.response?.data.message);
            }
            // unhandled non-AxiosError goes here
            throw error;
        }
    }
);

const farmDataSlice = createSlice({
    name: "farmData",
    initialState,
    reducers: {
        resetUpdate(state) {
            state.success = undefined;
            state.bioDataSuccess = undefined;
            state.bioGrasslandSuccess = undefined;
        },
        clearErrors(state) {
            state.error = undefined;
        },
        updateSelectedAnimalsOnFarm(state, action: PayloadAction<string[]>) {
            state.selectedAnimalsOnFarm = action.payload;
            state.currentAnimalOnFarmIndex = 0;
        },
        updateCurrentAnimalOnFarmIndex(state) {
            state.currentAnimalOnFarmIndex++;
        },
        resetFarmDataId(state) {
            if (state.fullFarmData) state.fullFarmData.farmData.id = "";
        }
    },
    extraReducers(builder) {
        builder.addCase(getFullFarmDataById.pending, (state, action) => {
            state.loading = true;
        })
        builder.addCase(getFullFarmDataById.fulfilled, (state, action) => {
            state.fullFarmData = action.payload.fullFarmData;
            state.loading = false;
        })
        builder.addCase(getFullFarmDataById.rejected, (state, action) => {
            state.error = action.payload;
            state.loading = false;
        })
        builder.addCase(putSoilDegradation.pending, (state, action) => {
            state.loading = true;
        })
        builder.addCase(putSoilDegradation.fulfilled, (state, action) => {
            state.success = action.payload.success;
            state.loading = false;
        })
        builder.addCase(putSoilDegradation.rejected, (state, action) => {
            state.error = action.payload;
            state.loading = false;
        })
        builder.addCase(putHousingPotential.pending, (state, action) => {
            state.loading = true;
        })
        builder.addCase(putHousingPotential.fulfilled, (state, action) => {
            state.success = action.payload.success;
            state.loading = false;
        })
        builder.addCase(putHousingPotential.rejected, (state, action) => {
            state.error = action.payload;
            state.loading = false;
        })
        builder.addCase(putGrassSwardData.pending, (state, action) => {
            state.loading = true;
        })
        builder.addCase(putGrassSwardData.fulfilled, (state, action) => {
            state.success = action.payload.success;
            state.loading = false;
        })
        builder.addCase(putGrassSwardData.rejected, (state, action) => {
            state.error = action.payload;
            state.loading = false;
        })
        builder.addCase(putSignsOfErosion.pending, (state, action) => {
            state.loading = true;
        })
        builder.addCase(putSignsOfErosion.fulfilled, (state, action) => {
            state.success = action.payload.success;
            state.loading = false;
        })
        builder.addCase(putSignsOfErosion.rejected, (state, action) => {
            state.error = action.payload;
            state.loading = false;
        })
        builder.addCase(putSoilAssessment.pending, (state, action) => {
            state.loading = true;
        })
        builder.addCase(putSoilAssessment.fulfilled, (state, action) => {
            state.success = action.payload.success;
            state.loading = false;
        })
        builder.addCase(putSoilAssessment.rejected, (state, action) => {
            state.error = action.payload;
            state.loading = false;
        })
        builder.addCase(putManufacturedFertilisers.pending, (state, action) => {
            state.loading = true;
        })
        builder.addCase(putManufacturedFertilisers.fulfilled, (state, action) => {
            state.success = action.payload.success;
            state.loading = false;
        })
        builder.addCase(putManufacturedFertilisers.rejected, (state, action) => {
            state.error = action.payload;
            state.loading = false;
        })
        builder.addCase(putOrganicFertilisers.pending, (state, action) => {
            state.loading = true;
        })
        builder.addCase(putOrganicFertilisers.fulfilled, (state, action) => {
            state.success = action.payload.success;
            state.loading = false;
        })
        builder.addCase(putOrganicFertilisers.rejected, (state, action) => {
            state.error = action.payload;
            state.loading = false;
        })
        builder.addCase(putBiodiversityData.pending, (state, action) => {
            state.loading = true;
        })
        builder.addCase(putBiodiversityData.fulfilled, (state, action) => {
            state.bioDataSuccess = action.payload.success;
            state.loading = false;
        })
        builder.addCase(putBiodiversityData.rejected, (state, action) => {
            state.error = action.payload;
            state.loading = false;
        })
        builder.addCase(putBiodiversityGrasslands.pending, (state, action) => {
            state.loading = true;
        })
        builder.addCase(putBiodiversityGrasslands.fulfilled, (state, action) => {
            state.bioGrasslandSuccess = action.payload.success;
            state.loading = false;
        })
        builder.addCase(putBiodiversityGrasslands.rejected, (state, action) => {
            state.error = action.payload;
            state.loading = false;
        })
        builder.addCase(putAnimalsOnFarm.pending, (state, action) => {
            state.loading = true;
        })
        builder.addCase(putAnimalsOnFarm.fulfilled, (state, action) => {
            state.success = action.payload.success;
            state.loading = false;
        })
        builder.addCase(putAnimalsOnFarm.rejected, (state, action) => {
            state.error = action.payload;
            state.loading = false;
        })
        builder.addCase(putLandUse.pending, (state, action) => {
            state.loading = true;
        })
        builder.addCase(putLandUse.fulfilled, (state, action) => {
            state.success = action.payload.success;
            state.loading = false;
        })
        builder.addCase(putLandUse.rejected, (state, action) => {
            state.error = action.payload;
            state.loading = false;
        })
        builder.addCase(putAnimalProduction.pending, (state, action) => {
            state.loading = true;
        })
        builder.addCase(putAnimalProduction.fulfilled, (state, action) => {
            state.success = action.payload.success;
            state.loading = false;
        })
        builder.addCase(putAnimalProduction.rejected, (state, action) => {
            state.error = action.payload;
            state.loading = false;
        })
        builder.addCase(putAnimalDiet.pending, (state, action) => {
            state.loading = true;
        })
        builder.addCase(putAnimalDiet.fulfilled, (state, action) => {
            state.success = action.payload.success;
            state.loading = false;
        })
        builder.addCase(putAnimalDiet.rejected, (state, action) => {
            state.error = action.payload;
            state.loading = false;
        })
        builder.addCase(putLandscapeAesthetics.pending, (state, action) => {
            state.loading = true;
        })
        builder.addCase(putLandscapeAesthetics.fulfilled, (state, action) => {
            state.success = action.payload.success;
            state.loading = false;
        })
        builder.addCase(putLandscapeAesthetics.rejected, (state, action) => {
            state.error = action.payload;
            state.loading = false;
        })
        builder.addCase(putWatercoursesAndTopography.pending, (state, action) => {
            state.loading = true;
        })
        builder.addCase(putWatercoursesAndTopography.fulfilled, (state, action) => {
            state.success = action.payload.success;
            state.loading = false;
        })
        builder.addCase(putWatercoursesAndTopography.rejected, (state, action) => {
            state.error = action.payload;
            state.loading = false;
        })
    },
})

export const { 
    resetFarmDataId,
    clearErrors,
    resetUpdate,
    updateSelectedAnimalsOnFarm,
    updateCurrentAnimalOnFarmIndex,
} = farmDataSlice.actions;
export default farmDataSlice.reducer;