import React, { useEffect, useState } from 'react'
import { IAnimalDiet } from '../../../Interfaces';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { useNavigate } from 'react-router-dom';
import { getFullFarmDataById, putAnimalDiet, resetFarmDataId, resetUpdate } from '../../../features/farmData/farmDataSlice';
import { toast } from 'react-toastify';
import { InputButton, InputButtonsContainer } from '../../styles/input/Input.styled';
import MetaData from '../../layout/MetaData';

type Props = {}

const AnimalDiet = (props: Props) => {

    const [animalDiet, setAnimalDiet] = useState<IAnimalDiet[]>([]);
    const [pulledData, setPulledData] = useState(false);

    const { report } = useAppSelector((state) => state.report);
    const { fullFarmData, success, error } = useAppSelector((state) => state.farmData)

    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!pulledData) {
            if (fullFarmData?.farmBreakdown.animalDiet && fullFarmData?.farmBreakdown.animalDiet.length > 0) setAnimalDiet(fullFarmData?.farmBreakdown.animalDiet)
            else {
                var data = fullFarmData?.farmBreakdown.defaultAnimalDiet
                var mappedData = data?.map(element => {
                    return {
                        ...element,
                        farmData: fullFarmData?.farmData.id,
                        totalTonnesFedAtGrass: 0,
                        totalTonnesFedHoused: 0
                    }
                })
                setAnimalDiet(mappedData ?? [])
            }
            setPulledData(false);
        }

        if (success) {
            let farmDataId = report?.farmData;
            dispatch(getFullFarmDataById({ id: farmDataId! }))
            toast.dismiss();
            toast.success("Input saved successfully");
            dispatch(resetUpdate());
            // switch if there is a record for animals on farm
            if (fullFarmData?.farmBreakdown.animalsOnFarm === null) {
                navigate("/input/stockingRate/cattle");
            }
            else {
                dispatch(resetFarmDataId());
                navigate(`/report/${report?.id}`);
            }
        }

    }, [dispatch, fullFarmData?.farmBreakdown.animalDiet, fullFarmData?.farmBreakdown.animalsOnFarm, navigate, pulledData, report?.farmData, report?.id, success])

    const updateAnimalDiet = (foodId: number, key: string, value: string | null | Date | number) => {
        var foodRecordExists = animalDiet.filter(element => element.foodId === foodId).length > 0
        var newAnimalDiet;

        if (foodRecordExists) {
            newAnimalDiet = animalDiet.map(item =>
                item.foodId === foodId ? { ...item, [key]: value } : item
            );
        } else {
            newAnimalDiet = [...animalDiet, { foodId, [key]: value }]
        }
        // console.log(newAnimalDiet);
        setAnimalDiet(newAnimalDiet);
    }

    // console.log(animalDiet)

    const nextHandler = () => {

        // validation
        if (animalDiet.length < 8) {
            toast.dismiss();
            toast.error("Please complete inputs for all sub groups")
            return
        }

        animalDiet.forEach(element => {
            if (element.totalTonnesFedAtGrass === undefined) {
                toast.dismiss();
                toast.error("Please complete inputs for the Tonnes fed at grass column")
                return;
            }
            if (element.totalTonnesFedHoused === undefined) {
                toast.dismiss();
                toast.error("Please complete inputs for the Tonnes fed housed column")
                return;
            }
            if (element.dmContentPercentage === undefined) {
                toast.dismiss();
                toast.error("Please complete inputs for the Mean Dm content percentage column")
                return;
            }
            if (element.meContent === undefined) {
                toast.dismiss();
                toast.error("Please complete inputs for the Me content column")
                return;
            }
            if (element.cpContent === undefined) {
                toast.dismiss();
                toast.error("Please complete inputs for the Cp content column")
                return;
            }
            if (element.ashContent === undefined) {
                toast.dismiss();
                toast.error("Please complete inputs for the Ash content column")
                return;
            }
        })

        toast.info("Saving progress...");
        dispatch(putAnimalDiet({ id: fullFarmData?.farmData.id!, animalDiet: animalDiet }))
    }

    return (
        <>
            <MetaData title='Animal Diet' />
            <div><h4><strong>ABOUT YOUR FARM - ANIMAL DIET</strong></h4></div>

            <div className='my-3'>
                <p>In this section, we want to learn about the breakdown of your farm, your land use, any changes in landuse, soil type and farm appearance</p>
            </div>

            <div className='my-3'><strong>Confirm the animal diet details on your farm for each food group</strong></div>

            <table data-role="table" data-mode="reflow" className="ui-table ui-table-reflow my-custom-breakpoint my-4">
                <thead>
                    <tr >
                        <th data-priority="1">Diet Category</th>
                        <th data-priority="2">Total Tonnes Fed At Grass</th>
                        <th data-priority="3">Total Tonnes Fed Housed</th>
                        <th data-priority="4">DM Content (%)</th>
                        <th data-priority="5">ME Content (MJ/kgDM)</th>
                        <th data-priority="6">CP Content (g/kgDM)</th>
                        <th data-priority="7">Ash Content (g/kgDM)</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td><p><strong>Concentrates</strong></p></td>
                        <td>
                            <label className='ui-table-cell-label' htmlFor='ConcentratesTotalTonnesFedAtGrass'>Total Tonnes Fed At Grass</label>
                            <input
                                type="number"
                                value={animalDiet.find(element => element.foodId === 1)?.totalTonnesFedAtGrass}
                                onChange={(e) => updateAnimalDiet(1, "totalTonnesFedAtGrass", e.target.valueAsNumber)}
                                name="ConcentratesTotalTonnesFedAtGrass"
                                className="form-control"
                                min="0"
                            />
                        </td>
                        <td>
                            <label className='ui-table-cell-label' htmlFor='ConcentratesTotalTonnesFedHoused'>Total Tonnes Fed Housed</label>
                            <input
                                type="number"
                                value={animalDiet.find(element => element.foodId === 1)?.totalTonnesFedHoused}
                                onChange={(e) => updateAnimalDiet(1, "totalTonnesFedHoused", e.target.valueAsNumber)}
                                name="ConcentratesTotalTonnesFedHoused"
                                className="form-control"
                                min="0"
                            />
                        </td>
                        <td>
                            <label className='ui-table-cell-label' htmlFor='ConcentratesDMContentPercentage'>DM Content (%)</label>
                            <input
                                type="number"
                                value={animalDiet.find(element => element.foodId === 1)?.dmContentPercentage}
                                onChange={(e) => updateAnimalDiet(1, "dmContentPercentage", e.target.valueAsNumber)}
                                name="ConcentratesDMContentPercentage"
                                className="form-control"
                                min="0"
                            />
                        </td>
                        <td>
                            <label className='ui-table-cell-label' htmlFor='ConcentratesMEContent'>ME Content (MJ/kgDM)</label>
                            <input
                                type="number"
                                value={animalDiet.find(element => element.foodId === 1)?.meContent}
                                onChange={(e) => updateAnimalDiet(1, "meContent", e.target.valueAsNumber)}
                                name="ConcentratesMEContent"
                                className="form-control"
                                min="0"
                            />
                        </td>
                        <td>
                            <label className='ui-table-cell-label' htmlFor='ConcentratesCPContent'>CP Content (g/kgDM)</label>
                            <input
                                type="number"
                                value={animalDiet.find(element => element.foodId === 1)?.cpContent}
                                onChange={(e) => updateAnimalDiet(1, "cpContent", e.target.valueAsNumber)}
                                name="ConcentratesCPContent"
                                className="form-control"
                                min="0"
                            />
                        </td>
                        <td>
                            <label className='ui-table-cell-label' htmlFor='ConcentratesAshContent'>Ash Content (g/kgDM)</label>
                            <input
                                type="number"
                                value={animalDiet.find(element => element.foodId === 1)?.ashContent}
                                onChange={(e) => updateAnimalDiet(1, "ashContent", e.target.valueAsNumber)}
                                name="ConcentratesAshContent"
                                className="form-control"
                                min="0"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td><p><strong>Forage Maize</strong></p></td>
                        <td>
                            <label className='ui-table-cell-label' htmlFor='ForageMaizeTotalTonnesFedAtGrass'>Total Tonnes Fed At Grass</label>
                            <input
                                type="number"
                                value={animalDiet.find(element => element.foodId === 2)?.totalTonnesFedAtGrass}
                                onChange={(e) => updateAnimalDiet(2, "totalTonnesFedAtGrass", e.target.valueAsNumber)}
                                name="ForageMaizeTotalTonnesFedAtGrass"
                                className="form-control"
                                min="0"
                            />
                        </td>
                        <td>
                            <label className='ui-table-cell-label' htmlFor='ForageMaizeTotalTonnesFedHoused'>Total Tonnes Fed Housed</label>
                            <input
                                type="number"
                                value={animalDiet.find(element => element.foodId === 2)?.totalTonnesFedHoused}
                                onChange={(e) => updateAnimalDiet(2, "totalTonnesFedHoused", e.target.valueAsNumber)}
                                name="ForageMaizeTotalTonnesFedHoused"
                                className="form-control"
                                min="0"
                            />
                        </td>
                        <td>
                            <label className='ui-table-cell-label' htmlFor='ForageMaizeDMContentPercentage'>DM Content (%)</label>
                            <input
                                type="number"
                                value={animalDiet.find(element => element.foodId === 2)?.dmContentPercentage}
                                onChange={(e) => updateAnimalDiet(2, "dmContentPercentage", e.target.valueAsNumber)}
                                name="ForageMaizeDMContentPercentage"
                                className="form-control"
                                min="0"
                            />
                        </td>
                        <td>
                            <label className='ui-table-cell-label' htmlFor='ForageMaizeMEContent'>ME Content (MJ/kgDM)</label>
                            <input
                                type="number"
                                value={animalDiet.find(element => element.foodId === 2)?.meContent}
                                onChange={(e) => updateAnimalDiet(2, "meContent", e.target.valueAsNumber)}
                                name="ForageMaizeMEContent"
                                className="form-control"
                                min="0"
                            />
                        </td>
                        <td>
                            <label className='ui-table-cell-label' htmlFor='ForageMaizeCPContent'>CP Content (g/kgDM)</label>
                            <input
                                type="number"
                                value={animalDiet.find(element => element.foodId === 2)?.cpContent}
                                onChange={(e) => updateAnimalDiet(2, "cpContent", e.target.valueAsNumber)}
                                name="ForageMaizeCPContent"
                                className="form-control"
                                min="0"
                            />
                        </td>
                        <td>
                            <label className='ui-table-cell-label' htmlFor='ForageMaizeAshContent'>Ash Content (g/kgDM)</label>
                            <input
                                type="number"
                                value={animalDiet.find(element => element.foodId === 2)?.ashContent}
                                onChange={(e) => updateAnimalDiet(2, "ashContent", e.target.valueAsNumber)}
                                name="ForageMaizeAshContent"
                                className="form-control"
                                min="0"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td><p><strong>Whole Crop Cereals</strong></p></td>
                        <td>
                            <label className='ui-table-cell-label' htmlFor='WholeCropCerealsTotalTonnesFedAtGrass'>Total Tonnes Fed At Grass</label>
                            <input
                                type="number"
                                value={animalDiet.find(element => element.foodId === 3)?.totalTonnesFedAtGrass}
                                onChange={(e) => updateAnimalDiet(3, "totalTonnesFedAtGrass", e.target.valueAsNumber)}
                                name="WholeCropCerealsTotalTonnesFedAtGrass"
                                className="form-control"
                                min="0"
                            />
                        </td>
                        <td>
                            <label className='ui-table-cell-label' htmlFor='WholeCropCerealsTotalTonnesFedHoused'>Total Tonnes Fed Housed</label>
                            <input
                                type="number"
                                value={animalDiet.find(element => element.foodId === 3)?.totalTonnesFedHoused}
                                onChange={(e) => updateAnimalDiet(3, "totalTonnesFedHoused", e.target.valueAsNumber)}
                                name="WholeCropCerealsTotalTonnesFedHoused"
                                className="form-control"
                                min="0"
                            />
                        </td>
                        <td>
                            <label className='ui-table-cell-label' htmlFor='WholeCropCerealsDMContentPercentage'>DM Content (%)</label>
                            <input
                                type="number"
                                value={animalDiet.find(element => element.foodId === 3)?.dmContentPercentage}
                                onChange={(e) => updateAnimalDiet(3, "dmContentPercentage", e.target.valueAsNumber)}
                                name="WholeCropCerealsDMContentPercentage"
                                className="form-control"
                                min="0"
                            />
                        </td>
                        <td>
                            <label className='ui-table-cell-label' htmlFor='WholeCropCerealsMEContent'>ME Content (MJ/kgDM)</label>
                            <input
                                type="number"
                                value={animalDiet.find(element => element.foodId === 3)?.meContent}
                                onChange={(e) => updateAnimalDiet(3, "meContent", e.target.valueAsNumber)}
                                name="WholeCropCerealsMEContent"
                                className="form-control"
                                min="0"
                            />
                        </td>
                        <td>
                            <label className='ui-table-cell-label' htmlFor='WholeCropCerealsCPContent'>CP Content (g/kgDM)</label>
                            <input
                                type="number"
                                value={animalDiet.find(element => element.foodId === 3)?.cpContent}
                                onChange={(e) => updateAnimalDiet(3, "cpContent", e.target.valueAsNumber)}
                                name="WholeCropCerealsCPContent"
                                className="form-control"
                                min="0"
                            />
                        </td>
                        <td>
                            <label className='ui-table-cell-label' htmlFor='WholeCropCerealsAshContent'>Ash Content (g/kgDM)</label>
                            <input
                                type="number"
                                value={animalDiet.find(element => element.foodId === 3)?.ashContent}
                                onChange={(e) => updateAnimalDiet(3, "ashContent", e.target.valueAsNumber)}
                                name="WholeCropCerealsAshContent"
                                className="form-control"
                                min="0"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td><p><strong>Fodder Beet</strong></p></td>
                        <td>
                            <label className='ui-table-cell-label' htmlFor='FodderBeetTotalTonnesFedAtGrass'>Total Tonnes Fed At Grass</label>
                            <input
                                type="number"
                                value={animalDiet.find(element => element.foodId === 4)?.totalTonnesFedAtGrass}
                                onChange={(e) => updateAnimalDiet(4, "totalTonnesFedAtGrass", e.target.valueAsNumber)}
                                name="FodderBeetTotalTonnesFedAtGrass"
                                className="form-control"
                                min="0"
                            />
                        </td>
                        <td>
                            <label className='ui-table-cell-label' htmlFor='FodderBeetTotalTonnesFedHoused'>Total Tonnes Fed Housed</label>
                            <input
                                type="number"
                                value={animalDiet.find(element => element.foodId === 4)?.totalTonnesFedHoused}
                                onChange={(e) => updateAnimalDiet(4, "totalTonnesFedHoused", e.target.valueAsNumber)}
                                name="FodderBeetTotalTonnesFedHoused"
                                className="form-control"
                                min="0"
                            />
                        </td>
                        <td>
                            <label className='ui-table-cell-label' htmlFor='FodderBeetDMContentPercentage'>DM Content (%)</label>
                            <input
                                type="number"
                                value={animalDiet.find(element => element.foodId === 4)?.dmContentPercentage}
                                onChange={(e) => updateAnimalDiet(4, "dmContentPercentage", e.target.valueAsNumber)}
                                name="FodderBeetDMContentPercentage"
                                className="form-control"
                                min="0"
                            />
                        </td>
                        <td>
                            <label className='ui-table-cell-label' htmlFor='FodderBeetMEContent'>ME Content (MJ/kgDM)</label>
                            <input
                                type="number"
                                value={animalDiet.find(element => element.foodId === 4)?.meContent}
                                onChange={(e) => updateAnimalDiet(4, "meContent", e.target.valueAsNumber)}
                                name="FodderBeetMEContent"
                                className="form-control"
                                min="0"
                            />
                        </td>
                        <td>
                            <label className='ui-table-cell-label' htmlFor='FodderBeetCPContent'>CP Content (g/kgDM)</label>
                            <input
                                type="number"
                                value={animalDiet.find(element => element.foodId === 4)?.cpContent}
                                onChange={(e) => updateAnimalDiet(4, "cpContent", e.target.valueAsNumber)}
                                name="FodderBeetCPContent"
                                className="form-control"
                                min="0"
                            />
                        </td>
                        <td>
                            <label className='ui-table-cell-label' htmlFor='FodderBeetAshContent'>Ash Content (g/kgDM)</label>
                            <input
                                type="number"
                                value={animalDiet.find(element => element.foodId === 4)?.ashContent}
                                onChange={(e) => updateAnimalDiet(4, "ashContent", e.target.valueAsNumber)}
                                name="FodderBeetAshContent"
                                className="form-control"
                                min="0"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td><p><strong>Swedes And Turnips</strong></p></td>
                        <td>
                            <label className='ui-table-cell-label' htmlFor='SwedesAndTurnipsTotalTonnesFedAtGrass'>Total Tonnes Fed At Grass</label>
                            <input
                                type="number"
                                value={animalDiet.find(element => element.foodId === 5)?.totalTonnesFedAtGrass}
                                onChange={(e) => updateAnimalDiet(5, "totalTonnesFedAtGrass", e.target.valueAsNumber)}
                                name="SwedesAndTurnipsTotalTonnesFedAtGrass"
                                className="form-control"
                                min="0"
                            />
                        </td>
                        <td>
                            <label className='ui-table-cell-label' htmlFor='SwedesAndTurnipsTotalTonnesFedHoused'>Total Tonnes Fed Housed</label>
                            <input
                                type="number"
                                value={animalDiet.find(element => element.foodId === 5)?.totalTonnesFedHoused}
                                onChange={(e) => updateAnimalDiet(5, "totalTonnesFedHoused", e.target.valueAsNumber)}
                                name="SwedesAndTurnipsTotalTonnesFedHoused"
                                className="form-control"
                                min="0"
                            />
                        </td>
                        <td>
                            <label className='ui-table-cell-label' htmlFor='SwedesAndTurnipsDMContentPercentage'>DM Content (%)</label>
                            <input
                                type="number"
                                value={animalDiet.find(element => element.foodId === 5)?.dmContentPercentage}
                                onChange={(e) => updateAnimalDiet(5, "dmContentPercentage", e.target.valueAsNumber)}
                                name="SwedesAndTurnipsDMContentPercentage"
                                className="form-control"
                                min="0"
                            />
                        </td>
                        <td>
                            <label className='ui-table-cell-label' htmlFor='SwedesAndTurnipsMEContent'>ME Content (MJ/kgDM)</label>
                            <input
                                type="number"
                                value={animalDiet.find(element => element.foodId === 5)?.meContent}
                                onChange={(e) => updateAnimalDiet(5, "meContent", e.target.valueAsNumber)}
                                name="SwedesAndTurnipsMEContent"
                                className="form-control"
                                min="0"
                            />
                        </td>
                        <td>
                            <label className='ui-table-cell-label' htmlFor='SwedesAndTurnipsCPContent'>CP Content (g/kgDM)</label>
                            <input
                                type="number"
                                value={animalDiet.find(element => element.foodId === 5)?.cpContent}
                                onChange={(e) => updateAnimalDiet(5, "cpContent", e.target.valueAsNumber)}
                                name="SwedesAndTurnipsCPContent"
                                className="form-control"
                                min="0"
                            />
                        </td>
                        <td>
                            <label className='ui-table-cell-label' htmlFor='SwedesAndTurnipsAshContent'>Ash Content (g/kgDM)</label>
                            <input
                                type="number"
                                value={animalDiet.find(element => element.foodId === 5)?.ashContent}
                                onChange={(e) => updateAnimalDiet(5, "ashContent", e.target.valueAsNumber)}
                                name="SwedesAndTurnipsAshContent"
                                className="form-control"
                                min="0"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td><p><strong>Peas And Beans</strong></p></td>
                        <td>
                            <label className='ui-table-cell-label' htmlFor='PeasAndBeansTotalTonnesFedAtGrass'>Total Tonnes Fed At Grass</label>
                            <input
                                type="number"
                                value={animalDiet.find(element => element.foodId === 6)?.totalTonnesFedAtGrass}
                                onChange={(e) => updateAnimalDiet(6, "totalTonnesFedAtGrass", e.target.valueAsNumber)}
                                name="PeasAndBeansTotalTonnesFedAtGrass"
                                className="form-control"
                                min="0"
                            />
                        </td>
                        <td>
                            <label className='ui-table-cell-label' htmlFor='PeasAndBeansTotalTonnesFedHoused'>Total Tonnes Fed Housed</label>
                            <input
                                type="number"
                                value={animalDiet.find(element => element.foodId === 6)?.totalTonnesFedHoused}
                                onChange={(e) => updateAnimalDiet(6, "totalTonnesFedHoused", e.target.valueAsNumber)}
                                name="PeasAndBeansTotalTonnesFedHoused"
                                className="form-control"
                                min="0"
                            />
                        </td>
                        <td>
                            <label className='ui-table-cell-label' htmlFor='PeasAndBeansDMContentPercentage'>DM Content (%)</label>
                            <input
                                type="number"
                                value={animalDiet.find(element => element.foodId === 6)?.dmContentPercentage}
                                onChange={(e) => updateAnimalDiet(6, "dmContentPercentage", e.target.valueAsNumber)}
                                name="PeasAndBeansDMContentPercentage"
                                className="form-control"
                                min="0"
                            />
                        </td>
                        <td>
                            <label className='ui-table-cell-label' htmlFor='PeasAndBeansMEContent'>ME Content (MJ/kgDM)</label>
                            <input
                                type="number"
                                value={animalDiet.find(element => element.foodId === 6)?.meContent}
                                onChange={(e) => updateAnimalDiet(6, "meContent", e.target.valueAsNumber)}
                                name="PeasAndBeansMEContent"
                                className="form-control"
                                min="0"
                            />
                        </td>
                        <td>
                            <label className='ui-table-cell-label' htmlFor='PeasAndBeansCPContent'>CP Content (g/kgDM)</label>
                            <input
                                type="number"
                                value={animalDiet.find(element => element.foodId === 6)?.cpContent}
                                onChange={(e) => updateAnimalDiet(6, "cpContent", e.target.valueAsNumber)}
                                name="PeasAndBeansCPContent"
                                className="form-control"
                                min="0"
                            />
                        </td>
                        <td>
                            <label className='ui-table-cell-label' htmlFor='PeasAndBeansAshContent'>Ash Content (g/kgDM)</label>
                            <input
                                type="number"
                                value={animalDiet.find(element => element.foodId === 6)?.ashContent}
                                onChange={(e) => updateAnimalDiet(6, "ashContent", e.target.valueAsNumber)}
                                name="PeasAndBeansAshContent"
                                className="form-control"
                                min="0"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td><p><strong>Kale</strong></p></td>
                        <td>
                            <label className='ui-table-cell-label' htmlFor='KaleTotalTonnesFedAtGrass'>Total Tonnes Fed At Grass</label>
                            <input
                                type="number"
                                value={animalDiet.find(element => element.foodId === 7)?.totalTonnesFedAtGrass}
                                onChange={(e) => updateAnimalDiet(7, "totalTonnesFedAtGrass", e.target.valueAsNumber)}
                                name="KaleTotalTonnesFedAtGrass"
                                className="form-control"
                                min="0"
                            />
                        </td>
                        <td>
                            <label className='ui-table-cell-label' htmlFor='KaleTotalTonnesFedHoused'>Total Tonnes Fed Housed</label>
                            <input
                                type="number"
                                value={animalDiet.find(element => element.foodId === 7)?.totalTonnesFedHoused}
                                onChange={(e) => updateAnimalDiet(7, "totalTonnesFedHoused", e.target.valueAsNumber)}
                                name="KaleTotalTonnesFedHoused"
                                className="form-control"
                                min="0"
                            />
                        </td>
                        <td>
                            <label className='ui-table-cell-label' htmlFor='KaleDMContentPercentage'>DM Content (%)</label>
                            <input
                                type="number"
                                value={animalDiet.find(element => element.foodId === 7)?.dmContentPercentage}
                                onChange={(e) => updateAnimalDiet(7, "dmContentPercentage", e.target.valueAsNumber)}
                                name="KaleDMContentPercentage"
                                className="form-control"
                                min="0"
                            />
                        </td>
                        <td>
                            <label className='ui-table-cell-label' htmlFor='KaleMEContent'>ME Content (MJ/kgDM)</label>
                            <input
                                type="number"
                                value={animalDiet.find(element => element.foodId === 7)?.meContent}
                                onChange={(e) => updateAnimalDiet(7, "meContent", e.target.valueAsNumber)}
                                name="KaleMEContent"
                                className="form-control"
                                min="0"
                            />
                        </td>
                        <td>
                            <label className='ui-table-cell-label' htmlFor='KaleCPContent'>CP Content (g/kgDM)</label>
                            <input
                                type="number"
                                value={animalDiet.find(element => element.foodId === 7)?.cpContent}
                                onChange={(e) => updateAnimalDiet(7, "cpContent", e.target.valueAsNumber)}
                                name="KaleCPContent"
                                className="form-control"
                                min="0"
                            />
                        </td>
                        <td>
                            <label className='ui-table-cell-label' htmlFor='KaleAshContent'>Ash Content (g/kgDM)</label>
                            <input
                                type="number"
                                value={animalDiet.find(element => element.foodId === 7)?.ashContent}
                                onChange={(e) => updateAnimalDiet(7, "ashContent", e.target.valueAsNumber)}
                                name="KaleAshContent"
                                className="form-control"
                                min="0"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td><p><strong>Grass Silage</strong></p></td>
                        <td>
                            <label className='ui-table-cell-label' htmlFor='GrassSilageTotalTonnesFedAtGrass'>Total Tonnes Fed At Grass</label>
                            <input
                                type="number"
                                value={animalDiet.find(element => element.foodId === 9)?.totalTonnesFedAtGrass}
                                onChange={(e) => updateAnimalDiet(9, "totalTonnesFedAtGrass", e.target.valueAsNumber)}
                                name="GrassSilageTotalTonnesFedAtGrass"
                                className="form-control"
                                min="0"
                            />
                        </td>
                        <td>
                            <label className='ui-table-cell-label' htmlFor='GrassSilageTotalTonnesFedHoused'>Total Tonnes Fed Housed</label>
                            <input
                                type="number"
                                value={animalDiet.find(element => element.foodId === 9)?.totalTonnesFedHoused}
                                onChange={(e) => updateAnimalDiet(9, "totalTonnesFedHoused", e.target.valueAsNumber)}
                                name="GrassSilageTotalTonnesFedHoused"
                                className="form-control"
                                min="0"
                            />
                        </td>
                        <td>
                            <label className='ui-table-cell-label' htmlFor='GrassSilageDMContentPercentage'>DM Content (%)</label>
                            <input
                                type="number"
                                value={animalDiet.find(element => element.foodId === 9)?.dmContentPercentage}
                                onChange={(e) => updateAnimalDiet(9, "dmContentPercentage", e.target.valueAsNumber)}
                                name="GrassSilageDMContentPercentage"
                                className="form-control"
                                min="0"
                            />
                        </td>
                        <td>
                            <label className='ui-table-cell-label' htmlFor='GrassSilageMEContent'>ME Content (MJ/kgDM)</label>
                            <input
                                type="number"
                                value={animalDiet.find(element => element.foodId === 9)?.meContent}
                                onChange={(e) => updateAnimalDiet(9, "meContent", e.target.valueAsNumber)}
                                name="GrassSilageMEContent"
                                className="form-control"
                                min="0"
                            />
                        </td>
                        <td>
                            <label className='ui-table-cell-label' htmlFor='GrassSilageCPContent'>CP Content (g/kgDM)</label>
                            <input
                                type="number"
                                value={animalDiet.find(element => element.foodId === 9)?.cpContent}
                                onChange={(e) => updateAnimalDiet(9, "cpContent", e.target.valueAsNumber)}
                                name="GrassSilageCPContent"
                                className="form-control"
                                min="0"
                            />
                        </td>
                        <td>
                            <label className='ui-table-cell-label' htmlFor='GrassSilageAshContent'>Ash Content (g/kgDM)</label>
                            <input
                                type="number"
                                value={animalDiet.find(element => element.foodId === 9)?.ashContent}
                                onChange={(e) => updateAnimalDiet(9, "ashContent", e.target.valueAsNumber)}
                                name="GrassSilageAshContent"
                                className="form-control"
                                min="0"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td><p><strong>Cereal Straw</strong></p></td>
                        <td>
                            <label className='ui-table-cell-label' htmlFor='CerealStrawTotalTonnesFedAtGrass'>Total Tonnes Fed At Grass</label>
                            <input
                                type="number"
                                value={animalDiet.find(element => element.foodId === 8)?.totalTonnesFedAtGrass}
                                onChange={(e) => updateAnimalDiet(8, "totalTonnesFedAtGrass", e.target.valueAsNumber)}
                                name="CerealStrawTotalTonnesFedAtGrass"
                                className="form-control"
                                min="0"
                            />
                        </td>
                        <td>
                            <label className='ui-table-cell-label' htmlFor='CerealStrawTotalTonnesFedHoused'>Total Tonnes Fed Housed</label>
                            <input
                                type="number"
                                value={animalDiet.find(element => element.foodId === 8)?.totalTonnesFedHoused}
                                onChange={(e) => updateAnimalDiet(8, "totalTonnesFedHoused", e.target.valueAsNumber)}
                                name="CerealStrawTotalTonnesFedHoused"
                                className="form-control"
                                min="0"
                            />
                        </td>
                        <td>
                            <label className='ui-table-cell-label' htmlFor='CerealStrawDMContentPercentage'>DM Content (%)</label>
                            <input
                                type="number"
                                value={animalDiet.find(element => element.foodId === 8)?.dmContentPercentage}
                                onChange={(e) => updateAnimalDiet(8, "dmContentPercentage", e.target.valueAsNumber)}
                                name="CerealStrawDMContentPercentage"
                                className="form-control"
                                min="0"
                            />
                        </td>
                        <td>
                            <label className='ui-table-cell-label' htmlFor='CerealStrawMEContent'>ME Content (MJ/kgDM)</label>
                            <input
                                type="number"
                                value={animalDiet.find(element => element.foodId === 8)?.meContent}
                                onChange={(e) => updateAnimalDiet(8, "meContent", e.target.valueAsNumber)}
                                name="CerealStrawMEContent"
                                className="form-control"
                                min="0"
                            />
                        </td>
                        <td>
                            <label className='ui-table-cell-label' htmlFor='CerealStrawCPContent'>CP Content (g/kgDM)</label>
                            <input
                                type="number"
                                value={animalDiet.find(element => element.foodId === 8)?.cpContent}
                                onChange={(e) => updateAnimalDiet(8, "cpContent", e.target.valueAsNumber)}
                                name="CerealStrawCPContent"
                                className="form-control"
                                min="0"
                            />
                        </td>
                        <td>
                            <label className='ui-table-cell-label' htmlFor='CerealStrawAshContent'>Ash Content (g/kgDM)</label>
                            <input
                                type="number"
                                value={animalDiet.find(element => element.foodId === 8)?.ashContent}
                                onChange={(e) => updateAnimalDiet(8, "ashContent", e.target.valueAsNumber)}
                                name="CerealStrawAshContent"
                                className="form-control"
                                min="0"
                            />
                        </td>
                    </tr>

                </tbody>
            </table>

            <InputButtonsContainer>
                <InputButton onClick={() => navigate(`/report/${report?.id}`)}>BACK</InputButton>
                <InputButton onClick={nextHandler}>NEXT</InputButton>
            </InputButtonsContainer>
        </>
    )
}

export default AnimalDiet