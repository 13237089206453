import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { InputButton, InputButtonsContainer } from '../../styles/input/Input.styled';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { ToastContainer, toast } from 'react-toastify';
import { putSoilDegradation, clearErrors, resetUpdate, getFullFarmDataById, putHousingPotential, resetFarmDataId } from '../../../features/farmData/farmDataSlice';
import { IProportionOfAnimalsHousedEachMonth } from '../../../Interfaces';
import ProportionOfAnimalsHousedRows from './ProportionOfAnimalsHousedRows';


type Props = {}

const HousingPotential = (props: Props) => {
    const [pulledData, setPulledData] = useState(false);
    const [sufficientHousingThroughTheYear, setSufficientHousingThroughTheYear] = useState(3);
    const [proportionHoused, setProportionHoused] = useState(0);
    const [proportionOfAnimalsHoused, setProportionOfAnimalsHoused] = useState<IProportionOfAnimalsHousedEachMonth[]>([]);
    const [backToReport, setBackToReport] = useState(false);

    const { report } = useAppSelector((state) => state.report);
    const { fullFarmData, success, error } = useAppSelector((state) => state.farmData)

    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!pulledData) {
            // if (fullFarmData?.farmBreakdown.proportionOfAnimalsHousedEachMonth) {
            //     var data = fullFarmData?.farmBreakdown.proportionOfAnimalsHousedEachMonth;
            //     // console.log(data)

            //     if (data.length === 0) {
            //         var defaultData = [];
            //         for (let i = 1; i < 7; i++) {
            //             defaultData.push({
            //                 animalCode: i,
            //                 january: 0,
            //                 february: 0,
            //                 march: 0,
            //                 april: 0,
            //                 may: 0,
            //                 june: 0,
            //                 july: 0,
            //                 august: 0,
            //                 september: 0,
            //                 october: 0,
            //                 november: 0,
            //                 december: 0,
            //             })

            //         }
            //         setProportionOfAnimalsHoused(defaultData);
            //     } else {
            //         setProportionOfAnimalsHoused(data);
            //     }
            // }
            if (fullFarmData?.farmBreakdown.housingPotential?.sufficientHousingAllYear === true) setSufficientHousingThroughTheYear(1);
            if (fullFarmData?.farmBreakdown.housingPotential?.sufficientHousingAllYear === false) setSufficientHousingThroughTheYear(0);
            if (fullFarmData?.farmBreakdown.housingPotential) setProportionHoused(fullFarmData?.farmBreakdown.housingPotential.proportionWhichCanBeHoused);
            // if (currentAnimalOnFarmIndex) setLocalAnimalsOnFarmIndex(currentAnimalOnFarmIndex);
            setPulledData(false);
        }

        if (success) {
            let farmDataId = report?.farmData;
            dispatch(getFullFarmDataById({ id: farmDataId! }))
            toast.dismiss();
            toast.success("Input saved successfully");
            dispatch(resetUpdate());
            if (backToReport) {
                dispatch(resetFarmDataId());
                navigate(`/report/${report?.id}`);
            }
        }

    }, [dispatch, error, pulledData, success, backToReport, navigate, report?.id, fullFarmData?.farmBreakdown.proportionOfAnimalsHousedEachMonth, fullFarmData?.farmBreakdown.housingPotential, report?.farmData])

    const updateProportionOfAnimalsHousedEachMonth = (animalCode: number, key: string, value: null | number) => {
        var animalRecordExists = proportionOfAnimalsHoused.filter(element => element.animalCode === animalCode).length > 0
        var newPropotionOfAnimalsHoused;

        if (animalRecordExists) {
            newPropotionOfAnimalsHoused = proportionOfAnimalsHoused.map(item =>
                item.animalCode === animalCode ? { ...item, [key]: value } : item
            );
        } else {
            newPropotionOfAnimalsHoused = [...proportionOfAnimalsHoused, { animalCode, [key]: value }]
        }
        // console.log(newPropotionOfAnimalsHoused);
        setProportionOfAnimalsHoused(newPropotionOfAnimalsHoused);
    }

    const saveHandler = (e?: React.MouseEvent) => {
        e?.preventDefault();

        // validation
        if (sufficientHousingThroughTheYear === 3) {
            toast.dismiss();
            toast.error("Please complete inputs for all sub groups")
            return
        }

        if (proportionHoused < 0 || proportionHoused > 100) {
            toast.dismiss();
            toast.error("The proportion housed must be between 0 and 100");
            return;
        }

        // var invalidPercentages = false;

        // proportionOfAnimalsHoused.forEach(element => {
        //     if (!sufficientHousingThroughTheYear && element.january === undefined) {
        //         toast.dismiss();
        //         toast.error("Please complete inputs for the January column");
        //         invalidPercentages = true;
        //         return;
        //     }
        //     if (!sufficientHousingThroughTheYear && element.january !== undefined && element.january < 0) {
        //         toast.dismiss();
        //         toast.error("Percentage for January cannot be less than 0");
        //         invalidPercentages = true;
        //         return
        //     }
        //     if (!sufficientHousingThroughTheYear && element.january !== undefined && element.january > 100) {
        //         toast.dismiss();
        //         toast.error("Percentage for January cannot be more than 100");
        //         invalidPercentages = true;
        //         return
        //     }
        //     if (!sufficientHousingThroughTheYear && element.february === undefined) {
        //         toast.dismiss();
        //         toast.error("Please complete inputs for the February column");
        //         invalidPercentages = true;
        //         return;
        //     }
        //     if (!sufficientHousingThroughTheYear && element.february !== undefined && element.february < 0) {
        //         toast.dismiss();
        //         toast.error("Percentage for February cannot be less than 0");
        //         invalidPercentages = true;
        //         return
        //     }
        //     if (!sufficientHousingThroughTheYear && element.february !== undefined && element.february > 100) {
        //         toast.dismiss();
        //         toast.error("Percentage for February cannot be more than 100");
        //         invalidPercentages = true;
        //         return
        //     }
        //     if (!sufficientHousingThroughTheYear && element.march === undefined) {
        //         toast.dismiss();
        //         toast.error("Please complete inputs for the March column")
        //         invalidPercentages = true;
        //         return;
        //     }
        //     if (!sufficientHousingThroughTheYear && element.march !== undefined && element.march < 0) {
        //         toast.dismiss();
        //         toast.error("Percentage for March cannot be less than 0");
        //         invalidPercentages = true;
        //         return
        //     }
        //     if (!sufficientHousingThroughTheYear && element.march !== undefined && element.march > 100) {
        //         toast.dismiss();
        //         toast.error("Percentage for March cannot be more than 100");
        //         invalidPercentages = true;
        //         return
        //     }
        //     if (!sufficientHousingThroughTheYear && element.april === undefined) {
        //         toast.dismiss();
        //         toast.error("Please complete inputs for the April column");
        //         invalidPercentages = true;
        //         return;
        //     }
        //     if (!sufficientHousingThroughTheYear && element.april !== undefined && element.april < 0) {
        //         toast.dismiss();
        //         toast.error("Percentage for April cannot be less than 0");
        //         invalidPercentages = true;
        //         return
        //     }
        //     if (!sufficientHousingThroughTheYear && element.april !== undefined && element.april > 100) {
        //         toast.dismiss();
        //         toast.error("Percentage for April cannot be more than 100");
        //         invalidPercentages = true;
        //         return
        //     }
        //     if (!sufficientHousingThroughTheYear && element.may === undefined) {
        //         toast.dismiss();
        //         toast.error("Please complete inputs for the May column");
        //         invalidPercentages = true;
        //         return;
        //     }
        //     if (!sufficientHousingThroughTheYear && element.may !== undefined && element.may < 0) {
        //         toast.dismiss();
        //         toast.error("Percentage for May cannot be less than 0");
        //         invalidPercentages = true;
        //         return
        //     }
        //     if (!sufficientHousingThroughTheYear && element.may !== undefined && element.may > 100) {
        //         toast.dismiss();
        //         toast.error("Percentage for May cannot be more than 100");
        //         invalidPercentages = true;
        //         return
        //     }
        //     if (!sufficientHousingThroughTheYear && element.june === undefined) {
        //         toast.dismiss();
        //         toast.error("Please complete inputs for the June column");
        //         invalidPercentages = true;
        //         return;
        //     }
        //     if (!sufficientHousingThroughTheYear && element.june !== undefined && element.june < 0) {
        //         toast.dismiss();
        //         toast.error("Percentage for June cannot be less than 0");
        //         invalidPercentages = true;
        //         return
        //     }
        //     if (!sufficientHousingThroughTheYear && element.june !== undefined && element.june > 100) {
        //         toast.dismiss();
        //         toast.error("Percentage for June cannot be more than 100");
        //         invalidPercentages = true;
        //         return
        //     }
        //     if (!sufficientHousingThroughTheYear && element.july === undefined) {
        //         toast.dismiss();
        //         toast.error("Please complete inputs for the July column");
        //         invalidPercentages = true;
        //         return;
        //     }
        //     if (!sufficientHousingThroughTheYear && element.july !== undefined && element.july < 0) {
        //         toast.dismiss();
        //         toast.error("Percentage for July cannot be less than 0");
        //         invalidPercentages = true;
        //         return
        //     }
        //     if (!sufficientHousingThroughTheYear && element.july !== undefined && element.july > 100) {
        //         toast.dismiss();
        //         toast.error("Percentage for July cannot be more than 100");
        //         invalidPercentages = true;
        //         return
        //     }
        //     if (!sufficientHousingThroughTheYear && element.august === undefined) {
        //         toast.dismiss();
        //         toast.error("Please complete inputs for the August column");
        //         invalidPercentages = true;
        //         return;
        //     }
        //     if (!sufficientHousingThroughTheYear && element.august !== undefined && element.august < 0) {
        //         toast.dismiss();
        //         toast.error("Percentage for August cannot be less than 0");
        //         invalidPercentages = true;
        //         return
        //     }
        //     if (!sufficientHousingThroughTheYear && element.august !== undefined && element.august > 100) {
        //         toast.dismiss();
        //         toast.error("Percentage for August cannot be more than 100");
        //         invalidPercentages = true;
        //         return
        //     }
        //     if (!sufficientHousingThroughTheYear && element.september === undefined) {
        //         toast.dismiss();
        //         toast.error("Please complete inputs for the September column");
        //         invalidPercentages = true;
        //         return;
        //     }
        //     if (!sufficientHousingThroughTheYear && element.september !== undefined && element.september < 0) {
        //         toast.dismiss();
        //         toast.error("Percentage for September cannot be less than 0");
        //         invalidPercentages = true;
        //         return
        //     }
        //     if (!sufficientHousingThroughTheYear && element.september !== undefined && element.september > 100) {
        //         toast.dismiss();
        //         toast.error("Percentage for September cannot be more than 100");
        //         invalidPercentages = true;
        //         return
        //     }
        //     if (!sufficientHousingThroughTheYear && element.october === undefined) {
        //         toast.dismiss();
        //         toast.error("Please complete inputs for the October column");
        //         invalidPercentages = true;
        //         return;
        //     }
        //     if (!sufficientHousingThroughTheYear && element.october !== undefined && element.october < 0) {
        //         toast.dismiss();
        //         toast.error("Percentage for October cannot be less than 0");
        //         invalidPercentages = true;
        //         return
        //     }
        //     if (!sufficientHousingThroughTheYear && element.october !== undefined && element.october > 100) {
        //         toast.dismiss();
        //         toast.error("Percentage for October cannot be more than 100");
        //         invalidPercentages = true;
        //         return
        //     }
        //     if (!sufficientHousingThroughTheYear && element.november === undefined) {
        //         toast.dismiss();
        //         toast.error("Please complete inputs for the November column");
        //         invalidPercentages = true;
        //         return;
        //     }
        //     if (!sufficientHousingThroughTheYear && element.november !== undefined && element.november < 0) {
        //         toast.dismiss();
        //         toast.error("Percentage for November cannot be less than 0");
        //         invalidPercentages = true;
        //         return
        //     }
        //     if (!sufficientHousingThroughTheYear && element.november !== undefined && element.november > 100) {
        //         toast.dismiss();
        //         toast.error("Percentage for November cannot be more than 100");
        //         invalidPercentages = true;
        //         return
        //     }
        //     if (!sufficientHousingThroughTheYear && element.december === undefined) {
        //         toast.dismiss();
        //         toast.error("Please complete inputs for the December column");
        //         invalidPercentages = true;
        //         return;
        //     }
        //     if (!sufficientHousingThroughTheYear && element.december !== undefined && element.december < 0) {
        //         toast.dismiss();
        //         toast.error("Percentage for December cannot be less than 0");
        //         invalidPercentages = true;
        //         return
        //     }
        //     if (!sufficientHousingThroughTheYear && element.december !== undefined && element.december > 100) {
        //         toast.dismiss();
        //         toast.error("Percentage for December cannot be more than 100");
        //         invalidPercentages = true;
        //         return
        //     }
        // })

        // if (invalidPercentages) return

        console.log(proportionOfAnimalsHoused)
        // dispatch(putHousingPotential({ id: fullFarmData?.farmData.id!, sufficientHousingAllYear: Boolean(sufficientHousingThroughTheYear), proportionHoused, proportions: proportionOfAnimalsHoused }));
        dispatch(putHousingPotential({ id: fullFarmData?.farmData.id!, sufficientHousingAllYear: Boolean(sufficientHousingThroughTheYear), proportionHoused }));
    }

    return (
        <>
            <div>
                <div><h4><strong>ABOUT YOUR FARM - ANIMAL HOUSING</strong></h4></div>

                <div className='my-3'>
                    <p>In this section, we want to learn about the breakdown of your farm, your land use, any changes in landuse, soil type and farm appearance</p>
                </div>

                <div className='radio my-3'>
                    <label><strong>Do have sufficient housing to accommodate all animals through the winter?</strong><br /></label>
                    <label>
                        <input type="radio" data-role="none" name="soilDegradation" checked={sufficientHousingThroughTheYear === 1} onChange={() => setSufficientHousingThroughTheYear(1)} />
                        <span className='radio-span'>Yes</span>
                    </label>
                    <label>
                        <input type="radio" data-role="none" name="soilDegradation" checked={sufficientHousingThroughTheYear === 0} onChange={() => setSufficientHousingThroughTheYear(0)} />
                        <span className='radio-span'>No</span>
                    </label>
                </div>


                {!sufficientHousingThroughTheYear && (
                    <>
                        <div className='my-4 row d-flex align-items-center'>
                            <div className='col-12 col-sm-6'>
                                <label><strong>Please enter the proportion of animals which can be housed through the winter:</strong></label>
                            </div>
                            <div className='col-6'>
                                <input
                                    value={proportionHoused}
                                    type='number'
                                    className='form-control'
                                    onChange={(e) => setProportionHoused(Number(e.target.value))}
                                />
                            </div>
                        </div>
                        {/* <div><strong>Please enter the proportion of animals which can be housed on farm each month below:</strong><br />

                            (Note, you may not choose to house animals all year, in this case please enter the housing available for grazing animals on farm)

                        </div>
                        <table data-role="table" data-mode="reflow" className="ui-table ui-table-reflow my-custom-breakpoint my-4">
                            <thead>
                                <tr >
                                    <th data-priority="1">Animals</th>
                                    <th data-priority="2">Jan</th>
                                    <th data-priority="3">Feb</th>
                                    <th data-priority="4">Mar</th>
                                    <th data-priority="5">Apr</th>
                                    <th data-priority="6">May</th>
                                    <th data-priority="7">Jun</th>
                                    <th data-priority="8">Jul</th>
                                    <th data-priority="9">Aug</th>
                                    <th data-priority="10">Sep</th>
                                    <th data-priority="11">Oct</th>
                                    <th data-priority="12">Nov</th>
                                    <th data-priority="13">Dec</th>
                                </tr>
                            </thead>
                            <tbody>
                                <ProportionOfAnimalsHousedRows updateProportionOfAnimalsHoused={updateProportionOfAnimalsHousedEachMonth} proportionOfAnimalsHoused={proportionOfAnimalsHoused} />
                            </tbody>
                        </table> */}
                    </>
                )}



                <InputButtonsContainer className='my-2'>
                    <InputButton onClick={() => navigate(`/report/${report?.id}`)}>BACK</InputButton>
                    <InputButton onClick={() => {
                        toast.dismiss()
                        toast.info("Saving...")
                        saveHandler()
                    }}>SAVE</InputButton>
                    <InputButton onClick={() => {
                        toast.dismiss()
                        toast.info("Saving...");
                        setBackToReport(true);
                        saveHandler();
                    }}>SAVE & CONTINUE</InputButton>
                </InputButtonsContainer>

            </div>
        </>
    )
}

export default HousingPotential