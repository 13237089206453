import React from 'react'
import { IAnimalsOnFarm } from '../../../../Interfaces';

type Props = {
    pigsOnFarm: IAnimalsOnFarm[];
    updatePigsOnFarm: (animalSubGroup: number, key: string, value: string | null | Date | number) => void
}

const Pigs = (props: Props) => {
    return (
        <>
            <tr>
                <td colSpan={7}><p className='mt-2'><strong><u>Pigs</u></strong></p></td>
            </tr>
            <tr>
                <td><p><strong>Intensive breeding pigs</strong></p></td>
                <td>
                    <label className='ui-table-cell-label' htmlFor='AverageNumPerMonthIntensiveBreedingPigs'>Average number per month</label>
                    <input
                        type="number"
                        value={props.pigsOnFarm.find(element => element.animalSubGroup === 14)?.averageNumPerMonth}
                        onChange={(e) => props.updatePigsOnFarm(14, "averageNumPerMonth", e.target.valueAsNumber)}
                        name="AverageNumPerMonthIntensiveBreedingPigs"
                        className="form-control"
                        min="0"
                    />
                </td>
                <td>
                    <label className='ui-table-cell-label' htmlFor='NumSoldIntensiveBreedingPigs'>Number sold</label>
                    <input
                        type="number"
                        value={props.pigsOnFarm.find(element => element.animalSubGroup === 14)?.numSold}
                        onChange={(e) => props.updatePigsOnFarm(14, "numSold", e.target.valueAsNumber)}
                        name="NumSoldIntensiveBreedingPigs"
                        className="form-control"
                        min="0"
                    />
                </td>
                <td>
                    <label className='ui-table-cell-label' htmlFor='MeanLiveWeightTakenAtSaleIntensiveBreedingPigs'>Mean live weight taken at sale</label>
                    <input
                        type="number"
                        value={props.pigsOnFarm.find(element => element.animalSubGroup === 14)?.meanLiveWeightTakenAtSale}
                        onChange={(e) => props.updatePigsOnFarm(14, "meanLiveWeightTakenAtSale", e.target.valueAsNumber)}
                        name="MeanLiveWeightTakenAtSaleIntensiveBreedingPigs"
                        className="form-control"
                        min="0"
                    />
                </td>
                <td>
                    <label className='ui-table-cell-label' htmlFor='DaysAtGrassIntensiveBreedingPigs'>Days at grass</label>
                    <input
                        type="number"
                        value={props.pigsOnFarm.find(element => element.animalSubGroup === 14)?.daysAtGrass}
                        onChange={(e) => props.updatePigsOnFarm(14, "daysAtGrass", e.target.valueAsNumber)}
                        name="DaysAtGrassIntensiveBreedingPigs"
                        className="form-control"
                        min="0"
                    />
                </td>
                <td>
                    <label className='ui-table-cell-label' htmlFor='HoursPerDayAtGrassIntensiveBreedingPigs'>Hours per day at grass</label>
                    <input
                        type="number"
                        value={props.pigsOnFarm.find(element => element.animalSubGroup === 14)?.hoursPerDayAtGrass}
                        onChange={(e) => props.updatePigsOnFarm(14, "hoursPerDayAtGrass", e.target.valueAsNumber)}
                        name="HoursPerDayAtGrassIntensiveBreedingPigs"
                        className="form-control"
                        min="0"
                    />
                </td>
            </tr>
            <tr>
                <td><p><strong>Free range pigs</strong></p></td>
                <td>
                    <label className='ui-table-cell-label' htmlFor='AverageNumPerMonthFreeRangePigs'>Average number per month</label>
                    <input
                        type="number"
                        value={props.pigsOnFarm.find(element => element.animalSubGroup === 15)?.averageNumPerMonth}
                        onChange={(e) => props.updatePigsOnFarm(15, "averageNumPerMonth", e.target.valueAsNumber)}
                        name="AverageNumPerMonthFreeRangePigs"
                        className="form-control"
                        min="0"
                    />
                </td>
                <td>
                    <label className='ui-table-cell-label' htmlFor='NumSoldFreeRangePigs'>Number sold</label>
                    <input
                        type="number"
                        value={props.pigsOnFarm.find(element => element.animalSubGroup === 15)?.numSold}
                        onChange={(e) => props.updatePigsOnFarm(15, "numSold", e.target.valueAsNumber)}
                        name="NumSoldFreeRangePigs"
                        className="form-control"
                        min="0"
                    />
                </td>
                <td>
                    <label className='ui-table-cell-label' htmlFor='MeanLiveWeightTakenAtSaleFreeRangePigs'>Mean live weight taken at sale</label>
                    <input
                        type="number"
                        value={props.pigsOnFarm.find(element => element.animalSubGroup === 15)?.meanLiveWeightTakenAtSale}
                        onChange={(e) => props.updatePigsOnFarm(15, "meanLiveWeightTakenAtSale", e.target.valueAsNumber)}
                        name="MeanLiveWeightTakenAtSaleFreeRangePigs"
                        className="form-control"
                        min="0"
                    />
                </td>
                <td>
                    <label className='ui-table-cell-label' htmlFor='DaysAtGrassFreeRangePigs'>Days at grass</label>
                    <input
                        type="number"
                        value={props.pigsOnFarm.find(element => element.animalSubGroup === 15)?.daysAtGrass}
                        onChange={(e) => props.updatePigsOnFarm(15, "daysAtGrass", e.target.valueAsNumber)}
                        name="DaysAtGrassFreeRangePigs"
                        className="form-control"
                        min="0"
                    />
                </td>
                <td>
                    <label className='ui-table-cell-label' htmlFor='HoursPerDayAtGrassFreeRangePigs'>Hours per day at grass</label>
                    <input
                        type="number"
                        value={props.pigsOnFarm.find(element => element.animalSubGroup === 15)?.hoursPerDayAtGrass}
                        onChange={(e) => props.updatePigsOnFarm(15, "hoursPerDayAtGrass", e.target.valueAsNumber)}
                        name="HoursPerDayAtGrassFreeRangePigs"
                        className="form-control"
                        min="0"
                    />
                </td>
            </tr>
        </>
    )
}

export default Pigs