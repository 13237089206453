import styled from "styled-components";

export const BioGrasslandPhotoGroup =styled.div`
    text-align: center;
    font-size: 14px;
`;

export const BioGrasslandPhotoGroupBar =styled.div`
    margin: 1rem 2rem;
    display: flex;
    justify-content: space-between;

    @media (max-width: 500px) {
        flex-direction: column;
    }

`;

export const BioGrasslandPhoto = styled.img`
    width: 80px;
    height: 80px;
    border-radius: 8px;

    @media (min-width: 900px) {
        width: 150px;
        height: 150px;
    }

    @media (min-width: 1900px) {
        width: 300px;
        height: 300px;
    }
`;

export const BioGrasslandInputRangeGroup = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 550px) {
        flex-direction: column;
        align-items: flex-start;
    }
`;

export const BioGrasslandInputRangeGroupInner = styled.div`
    display: flex;
    align-items: center;
    margin: 0 0.5rem;
    width: 100%;

    label {
        font-size: 14px;
    }

    @media (max-width: 550px) {
        justify-content: space-between;
    }
    
`;

export const BioGrasslandInputRangeGroupInnerInput = styled.input`
    width: 80px;
    margin: 0 0.5rem;
`;

export const DataFormGroup = styled.div`
    margin: 3rem 0;
`;

export const WildlifeFormGroup = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 3rem 0;
`

export const CropsGrownFormGroup = styled.div`
    display: flex;
    flex-direction: column;
    margin: 3rem 0;
`;