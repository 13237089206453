import React, { useState, useEffect } from 'react'
import { BioGrasslandPhotoGroup, BioGrasslandPhotoGroupBar, BioGrasslandPhoto, BioGrasslandInputRangeGroup, BioGrasslandInputRangeGroupInner, BioGrasslandInputRangeGroupInnerInput, DataFormGroup, WildlifeFormGroup } from '../../styles/input/Biodiversity.input.styled';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { InputButton, InputButtonsContainer } from '../../styles/input/Input.styled';
import { ToastContainer, toast } from 'react-toastify';
import { putBiodiversityData, resetUpdate, clearErrors, putBiodiversityGrasslands, resetFarmDataId } from '../../../features/farmData/farmDataSlice';
import MetaData from '../../layout/MetaData';

type Props = {}

const Grassland = (props: Props) => {

    const [proportionIntensity3, setProportionIntensity3] = useState(0);
    const [proportionIntensity2, setProportionIntensity2] = useState(0);
    const [proportionIntensity1, setProportionIntensity1] = useState(0);
    const [pulledData, setPulledData] = useState(false);
    const [backToReport, setBackToReport] = useState(false);

    const { report } = useAppSelector((state) => state.report);
    const { fullFarmData, bioDataSuccess, bioGrasslandSuccess, error } = useAppSelector((state) => state.farmData);

    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!pulledData) {
            if (fullFarmData?.biodiversityInputs.grasslands && fullFarmData?.biodiversityInputs.grasslands.length > 0) {
                var grasslands = fullFarmData?.biodiversityInputs.grasslands

                var gl;

                // 3
                gl = grasslands.filter(item => item.intensity === 3);
                if (gl.length > 0) {
                    setProportionIntensity3(gl[0].proportion);
                }

                // 2
                gl = grasslands.filter(item => item.intensity === 2);
                if (gl.length > 0) {
                    setProportionIntensity2(gl[0].proportion);
                }

                // 1
                gl = grasslands.filter(item => item.intensity === 1);
                if (gl.length > 0) {
                    setProportionIntensity1(gl[0].proportion);
                }
            }

            setPulledData(true);
        }

        if (bioGrasslandSuccess) {
            toast.dismiss();
            toast.success("Input saved successfully");
            dispatch(resetUpdate());
            if (backToReport) {
                dispatch(resetFarmDataId());
                navigate(`/report/${report?.id}`);
            }
        }

        if (error && typeof error === 'string') {
            toast.dismiss();
            toast.error(error);
            dispatch(clearErrors())
        }


    }, [bioDataSuccess, bioGrasslandSuccess, dispatch, error, fullFarmData?.biodiversityInputs.data?.pollinatingCropsGrown, fullFarmData?.biodiversityInputs.data?.threatenedSpecies, fullFarmData?.biodiversityInputs.grasslands, navigate, pulledData])

    const saveHandler = (e?: React.MouseEvent) => {
        e?.preventDefault();

        if (proportionIntensity3 + proportionIntensity2 + proportionIntensity1 !== 100) {
            toast.dismiss();
            toast.error("Grassland proportions must add up to exactly 100");
            return;
        } 

        dispatch(putBiodiversityGrasslands(
            {
                id: fullFarmData?.farmData.id!,
                grasslands: [

                    {
                        intensity: 3,
                        proportion: proportionIntensity3
                    },
                    {
                        intensity: 2,
                        proportion: proportionIntensity2
                    },
                    {
                        intensity: 1,
                        proportion: proportionIntensity1
                    },
                ]
            }
        ))

    }




    return (
        <>
            <MetaData title='Alternative forage' />
            <div><h4><strong>ABOUT YOUR FARM - PERMANENT GRASSLAND</strong></h4></div>

            <div className='my-3'>
                <p>In this section, we want to learn about the breakdown of the species richness of permanent grassland on your farm</p>
            </div>

            <div><h5><strong>Grassland Species Richness</strong></h5></div>
            <BioGrasslandPhotoGroupBar>
                <BioGrasslandPhotoGroup>
                    <div><BioGrasslandPhoto src='/assets/grassland3.jpg' /></div>
                    <div>High biodiversity, more than 15 plant species/m<sup>2</sup></div>
                </BioGrasslandPhotoGroup>
                <BioGrasslandPhotoGroup>
                    <div><BioGrasslandPhoto src='/assets/grassland2.jpg' /></div>
                    <div>Moderate Biodiversity, between 8 and 15 plant species/m<sup>2</sup></div>
                </BioGrasslandPhotoGroup>
                <BioGrasslandPhotoGroup>
                    <div><BioGrasslandPhoto src='/assets/grassland1.jpg' /></div>
                    <div>Low Biodiversity, up to 8 plant species/m<sup>2</sup></div>
                </BioGrasslandPhotoGroup>
            </BioGrasslandPhotoGroupBar>

            <DataFormGroup>
                <div><p><strong>Please state the proportion (%) of grassland with each species richness:</strong></p></div>
                <BioGrasslandInputRangeGroup>

                    <BioGrasslandInputRangeGroupInner>
                        <label>High biodiversity:</label>
                        <BioGrasslandInputRangeGroupInnerInput
                            type="number"
                            className="form-control"
                            min="0"
                            value={proportionIntensity3}
                            onChange={(e) => setProportionIntensity3(e.target.valueAsNumber)}
                        />
                    </BioGrasslandInputRangeGroupInner>
                    <BioGrasslandInputRangeGroupInner>
                        <label>Medium biodiversity:</label>
                        <BioGrasslandInputRangeGroupInnerInput
                            type="number"
                            className="form-control"
                            min="0"
                            value={proportionIntensity2}
                            onChange={(e) => setProportionIntensity2(e.target.valueAsNumber)}
                        />
                    </BioGrasslandInputRangeGroupInner>
                    <BioGrasslandInputRangeGroupInner>
                        <label>Low biodiversity:</label>
                        <BioGrasslandInputRangeGroupInnerInput
                            type="number"
                            className="form-control"
                            min="0"
                            value={proportionIntensity1}
                            onChange={(e) => setProportionIntensity1(e.target.valueAsNumber)}
                        />
                    </BioGrasslandInputRangeGroupInner>
                </BioGrasslandInputRangeGroup>
            </DataFormGroup>

            <InputButtonsContainer className='my-2'>
                <InputButton onClick={() => navigate(`/report/${report?.id}`)}>BACK</InputButton>
                <InputButton onClick={() => {
                    toast.dismiss()
                    toast.info("Saving...")
                    saveHandler()
                }}>SAVE</InputButton>
                <InputButton onClick={() => {
                    toast.dismiss()
                    toast.info("Saving...");
                    setBackToReport(true);
                    saveHandler();
                }}>SAVE & CONTINUE</InputButton>
            </InputButtonsContainer>

        </>
    )
}

export default Grassland