import { configureStore, combineReducers } from "@reduxjs/toolkit";
import authReducer from "./features/auth/authSlice";
import reportReducer from "./features/report/reportSlice";
import farmReducer from "./features/farm/farmSlice";
import farmDataReducer from "./features/farmData/farmDataSlice";
import indicatorReducer from "./features/indicator/indicatorSlice";
import ecosystemServiceReducer from "./features/ecosystemService/ecosystemServiceSlice";

export const store =  configureStore({
    reducer: combineReducers({
        auth: authReducer,
        report: reportReducer,
        farm: farmReducer,
        farmData: farmDataReducer,
        indicator: indicatorReducer,
        ecosystemService: ecosystemServiceReducer
    })
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;