import styled, { keyframes } from "styled-components";

export const IndicatorGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: 1rem 0;
    background: #fff;
    border: 1px solid #000;
    padding: 1rem 2rem;
    border-radius: 10px;

    @media (max-width: 500px) {
        grid-template-columns: 1fr;
    }
`;

const progressAnimation = (y: number) => keyframes`
    to {
        width: ${y}%;
    }
`

const progressTextAnimation = keyframes`
    to {
        --num: 65;
    }
`

export const IndicatorProgressElement = styled.div`
    --num: 0;

   
`;

type IndicatorProgressProps = {
    result: number,
    color: string,
}

export const IndicatorProgressContainer = styled.div<IndicatorProgressProps>`
    position: relative;
    background: #eee;
    height: 20px;
    border-radius: 6px;
    overflow: hidden;
    margin: 0 1rem;

    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 0;
        background: ${props => props.color};
        animation: ${props => progressAnimation(props.result)} 1s ease-in forwards;
    }
`;

export const ProgressLabel = styled.p`
    &:before {
        animation: ${progressTextAnimation} 1s ease-in forwards;
    }
`;

export const Progress = styled.progress`
    opacity: 0;
`;

export const IndicatorVisualsWrapper = styled.div`
    display: grid;
    grid-template-columns: 9fr 1fr;
`;

