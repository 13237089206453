import styled, { keyframes } from 'styled-components';

export const AppLayout = styled.div`
    
    
`

export const AppContainer = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
`;

export const BackgroundDiv = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url(/assets/background.jpg);
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: -100;
`;

export const BackgroundGlassDiv = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba( 255, 255, 255, 0.25 );
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter: blur( 5px );
    -webkit-backdrop-filter: blur( 5px );
    z-index: -90;
`;

export const fadeInFwd = keyframes`
    0% {
        -webkit-transform: translateZ(-80px);
                transform: translateZ(-80px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateZ(0);
                transform: translateZ(0);
        opacity: 1;
    }
`;

export const HomeBodyTextWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-top: 4em;
    animation: ${fadeInFwd} 1s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;

    @media (max-width: 500px) {
        padding: 12em 0;
        height: 75%;

    }

    @media (min-width: 1200px) {
        padding: 2.5em;
        height: 75%;

    }
`

export const HomeBodyText = styled.div`
    font-weight: 700;
    line-height: 77px;
`;

export const DynamicTable = styled.table`
    width: 100%;
`;

export const NoDisplayOnMobile = styled.div`

    @media (max-width: 500px) {
        display: none;
    }
`;

export const AuthInputContainer = styled.div`
    position: relative;
`;