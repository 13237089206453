import React, { useEffect, useState } from 'react'
import { IAnimalsOnFarm } from '../../../../Interfaces'

type Props = {
  cattleOnFarm: IAnimalsOnFarm[];
  updateCattleOnFarm: (animalSubGroup: number, key: string, value: string | null | Date | number) => void
}

var initialAnimalsOnFarmValues = {
  id: "",
  farmData: "",
  animalSubGroup: 0,
  averageNumPerMonth: 0,
  numSold: 0,
  meanLiveWeightTakenAtSale: 0,
  turnoutDate: new Date(),
  housingDate: new Date(),
  hoursPerDayAtGrass: 0,
}

const Cattle = (props: Props) => {

  useEffect(() => {

  }, [])

  return (
    <>
      <tr>
        <td colSpan={7}><p className='mt-2'><strong><u>Beef (Rearing and Finishing)</u></strong></p></td>
      </tr>
      <tr>
        <td><p><strong>Beef 0-1 year old</strong></p></td>
        <td>
          <label className='ui-table-cell-label' htmlFor='AverageNumPerMonthRearingBeef01'>Average number per month</label>
          <input
            type="number"
            value={props.cattleOnFarm.find(element => element.animalSubGroup === 1)?.averageNumPerMonth}
            onChange={(e) => props.updateCattleOnFarm(1, "averageNumPerMonth", e.target.valueAsNumber)}
            name="AverageNumPerMonthRearingBeef01"
            className="form-control"
            min="0" 
          />
        </td>
        <td>
          <label className='ui-table-cell-label' htmlFor='NumSoldRearingBeef01'>Number sold</label>
          <input
            type="number"
            value={props.cattleOnFarm.find(element => element.animalSubGroup === 1)?.numSold}
            onChange={(e) => props.updateCattleOnFarm(1, "numSold", e.target.valueAsNumber)}
            name="NumSoldRearingBeef01"
            className="form-control"
            min="0"
          />
        </td>
        <td>
          <label className='ui-table-cell-label' htmlFor='MeanLiveWeightTakenAtSaleRearingBeef01'>Mean live weight taken at sale</label>
          <input
            type="number"
            value={props.cattleOnFarm.find(element => element.animalSubGroup === 1)?.meanLiveWeightTakenAtSale}
            onChange={(e) => props.updateCattleOnFarm(1, "meanLiveWeightTakenAtSale", e.target.valueAsNumber)}
            name="MeanLiveWeightTakenAtSaleRearingBeef01"
            className="form-control"
            min="0"
          />
        </td>
        <td>
          <label className='ui-table-cell-label' htmlFor='DaysAtGrassRearingBeef01'>Days at grass</label>
          <input
            type="number"
            value={props.cattleOnFarm.find(element => element.animalSubGroup === 1)?.daysAtGrass}
            onChange={(e) => props.updateCattleOnFarm(1, "daysAtGrass", e.target.valueAsNumber)}
            name="DaysAtGrassRearingBeef01"
            className="form-control"
            min="0"
          />
        </td>
        <td>
          <label className='ui-table-cell-label' htmlFor='HoursPerDayAtGrassRearingBeef01'>Hours per day at grass</label>
          <input
            type="number"
            value={props.cattleOnFarm.find(element => element.animalSubGroup === 1)?.hoursPerDayAtGrass}
            onChange={(e) => props.updateCattleOnFarm(1, "hoursPerDayAtGrass", e.target.valueAsNumber)}
            name="HoursPerDayAtGrassRearingBeef01"
            className="form-control"
            min="0"
          />
        </td>
      </tr>
      <tr>
        <td><p><strong>Beef 1-2 years old</strong></p></td>
        <td>
          <label className='ui-table-cell-label' htmlFor='AverageNumPerMonthRearingBeef12'>Average number per month</label>
          <input
            type="number"
            value={props.cattleOnFarm.find(element => element.animalSubGroup === 2)?.averageNumPerMonth}
            onChange={(e) => props.updateCattleOnFarm(2, "averageNumPerMonth", e.target.valueAsNumber)}
            name="AverageNumPerMonthRearingBeef12"
            className="form-control"
            min="0"
          />
        </td>
        <td>
          <label className='ui-table-cell-label' htmlFor='NumSoldRearingBeef12'>Number sold</label>
          <input
            type="number"
            value={props.cattleOnFarm.find(element => element.animalSubGroup === 2)?.numSold}
            onChange={(e) => props.updateCattleOnFarm(2, "numSold", e.target.valueAsNumber)}
            name="NumSoldRearingBeef12"
            className="form-control"
            min="0"
          />
        </td>
        <td>
          <label className='ui-table-cell-label' htmlFor='MeanLiveWeightTakenAtSaleRearingBeef12'>Mean live weight taken at sale</label>
          <input
            type="number"
            value={props.cattleOnFarm.find(element => element.animalSubGroup === 2)?.meanLiveWeightTakenAtSale}
            onChange={(e) => props.updateCattleOnFarm(2, "meanLiveWeightTakenAtSale", e.target.valueAsNumber)}
            name="MeanLiveWeightTakenAtSaleRearingBeef12"
            className="form-control"
            min="0"
          />
        </td>
        <td>
          <label className='ui-table-cell-label' htmlFor='DaysAtGrassRearingBeef12'>Days at grass</label>
          <input
            type="number"
            value={props.cattleOnFarm.find(element => element.animalSubGroup === 2)?.daysAtGrass}
            onChange={(e) => props.updateCattleOnFarm(2, "daysAtGrass", e.target.valueAsNumber)}
            name="DaysAtGrassRearingBeef12"
            className="form-control"
            min="0"
          />
        </td>
        <td>
          <label className='ui-table-cell-label' htmlFor='HoursPerDayAtGrassRearingBeef12'>Hours per day at grass</label>
          <input
            type="number"
            value={props.cattleOnFarm.find(element => element.animalSubGroup === 2)?.hoursPerDayAtGrass}
            onChange={(e) => props.updateCattleOnFarm(2, "hoursPerDayAtGrass", e.target.valueAsNumber)}
            name="HoursPerDayAtGrassRearingBeef12"
            className="form-control"
            min="0"
          />
        </td>
      </tr>
      <tr>
        <td><p><strong>Beef 2+ years old</strong></p></td>
        <td>
          <label className='ui-table-cell-label' htmlFor='AverageNumPerMonthRearingBeef2Plus'>Average number per month</label>
          <input
            type="number"
            value={props.cattleOnFarm.find(element => element.animalSubGroup === 3)?.averageNumPerMonth}
            onChange={(e) => props.updateCattleOnFarm(3, "averageNumPerMonth", e.target.valueAsNumber)}
            name="AverageNumPerMonthRearingBeef2Plus"
            className="form-control"
            min="0"
          />
        </td>
        <td>
          <label className='ui-table-cell-label' htmlFor='NumSoldRearingBeef2Plus'>Number sold</label>
          <input
            type="number"
            value={props.cattleOnFarm.find(element => element.animalSubGroup === 3)?.numSold}
            onChange={(e) => props.updateCattleOnFarm(3, "numSold", e.target.valueAsNumber)}
            name="NumSoldRearingBeef2Plus"
            className="form-control"
            min="0"
          />
        </td>
        <td>
          <label className='ui-table-cell-label' htmlFor='MeanLiveWeightTakenAtSaleRearingBeef2Plus'>Mean live weight taken at sale</label>
          <input
            type="number"
            value={props.cattleOnFarm.find(element => element.animalSubGroup === 3)?.meanLiveWeightTakenAtSale}
            onChange={(e) => props.updateCattleOnFarm(3, "meanLiveWeightTakenAtSale", e.target.valueAsNumber)}
            name="MeanLiveWeightTakenAtSaleRearingBeef2Plus"
            className="form-control"
            min="0"
          />
        </td>
        <td>
          <label className='ui-table-cell-label' htmlFor='DaysAtGrassRearingBeef2Plus'>Days at grass</label>
          <input
            type="number"
            value={props.cattleOnFarm.find(element => element.animalSubGroup === 3)?.daysAtGrass}
            onChange={(e) => props.updateCattleOnFarm(3, "daysAtGrass", e.target.valueAsNumber)}
            name="DaysAtGrassRearingBeef2Plus"
            className="form-control"
            min="0"
          />
        </td>
        <td>
          <label className='ui-table-cell-label' htmlFor='HoursPerDayAtGrassRearingBeef2Plus'>Hours per day at grass</label>
          <input
            type="number"
            value={props.cattleOnFarm.find(element => element.animalSubGroup === 3)?.hoursPerDayAtGrass}
            onChange={(e) => props.updateCattleOnFarm(3, "hoursPerDayAtGrass", e.target.valueAsNumber)}
            name="HoursPerDayAtGrassRearingBeef2Plus"
            className="form-control"
            min="0"
          />
        </td>
      </tr>
      <tr>
        <td colSpan={7}><p className='mt-2'><strong><u>Suckler Beef (For Breeding)</u></strong></p></td>
      </tr>
      <tr>
        <td><p><strong>Suckler cows</strong></p></td>
        <td>
          <label className='ui-table-cell-label' htmlFor='AverageNumPerMonthSucklerCows'>Average number per month</label>
          <input
            type="number"
            value={props.cattleOnFarm.find(element => element.animalSubGroup === 4)?.averageNumPerMonth}
            onChange={(e) => props.updateCattleOnFarm(4, "averageNumPerMonth", e.target.valueAsNumber)}
            name="AverageNumPerMonthSucklerCows"
            className="form-control"
            min="0"
          />
        </td>
        <td>
          <label className='ui-table-cell-label' htmlFor='NumSoldSucklerCows'>Number sold</label>
          <input
            type="number"
            value={props.cattleOnFarm.find(element => element.animalSubGroup === 4)?.numSold}
            onChange={(e) => props.updateCattleOnFarm(4, "numSold", e.target.valueAsNumber)}
            name="NumSoldSucklerCows"
            className="form-control"
            min="0"
          />
        </td>
        <td>
          <label className='ui-table-cell-label' htmlFor='MeanLiveWeightTakenAtSaleSucklerCows'>Mean live weight taken at sale</label>
          <input
            type="number"
            value={props.cattleOnFarm.find(element => element.animalSubGroup === 4)?.meanLiveWeightTakenAtSale}
            onChange={(e) => props.updateCattleOnFarm(4, "meanLiveWeightTakenAtSale", e.target.valueAsNumber)}
            name="MeanLiveWeightTakenAtSaleSucklerCows"
            className="form-control"
            min="0"
          />
        </td>
        <td>
          <label className='ui-table-cell-label' htmlFor='DaysAtGrassSucklerCows'>Days at grass</label>
          <input
            type="number"
            value={props.cattleOnFarm.find(element => element.animalSubGroup === 4)?.daysAtGrass}
            onChange={(e) => props.updateCattleOnFarm(4, "daysAtGrass", e.target.valueAsNumber)}
            name="DaysAtGrassSucklerCows"
            className="form-control"
            min="0"
          />
        </td>
        <td>
          <label className='ui-table-cell-label' htmlFor='HoursPerDayAtGrassSucklerCows'>Hours per day at grass</label>
          <input
            type="number"
            value={props.cattleOnFarm.find(element => element.animalSubGroup === 4)?.hoursPerDayAtGrass}
            onChange={(e) => props.updateCattleOnFarm(4, "hoursPerDayAtGrass", e.target.valueAsNumber)}
            name="HoursPerDayAtGrassSucklerCows"
            className="form-control"
            min="0"
          />
        </td>
      </tr>
      <tr>
        <td><p><strong>Suckler beef 0-1 years old</strong></p></td>
        <td>
          <label className='ui-table-cell-label' htmlFor='AverageNumPerMonthSucklerBeef01'>Average number per month</label>
          <input
            type="number"
            value={props.cattleOnFarm.find(element => element.animalSubGroup === 5)?.averageNumPerMonth}
            onChange={(e) => props.updateCattleOnFarm(5, "averageNumPerMonth", e.target.valueAsNumber)}
            name="AverageNumPerMonthSucklerBeef01"
            className="form-control"
            min="0"
          />
        </td>
        <td>
          <label className='ui-table-cell-label' htmlFor='NumSoldSucklerBeef01'>Number sold</label>
          <input
            type="number"
            value={props.cattleOnFarm.find(element => element.animalSubGroup === 5)?.numSold}
            onChange={(e) => props.updateCattleOnFarm(5, "numSold", e.target.valueAsNumber)}
            name="NumSoldSucklerBeef01"
            className="form-control"
            min="0"
          />
        </td>
        <td>
          <label className='ui-table-cell-label' htmlFor='MeanLiveWeightTakenAtSaleSucklerBeef01'>Mean live weight taken at sale</label>
          <input
            type="number"
            value={props.cattleOnFarm.find(element => element.animalSubGroup === 5)?.meanLiveWeightTakenAtSale}
            onChange={(e) => props.updateCattleOnFarm(5, "meanLiveWeightTakenAtSale", e.target.valueAsNumber)}
            name="MeanLiveWeightTakenAtSaleSucklerBeef01"
            className="form-control"
            min="0"
          />
        </td>
        <td>
          <label className='ui-table-cell-label' htmlFor='DaysAtGrassSucklerBeef01'>Days at grass</label>
          <input
            type="number"
            value={props.cattleOnFarm.find(element => element.animalSubGroup === 5)?.daysAtGrass}
            onChange={(e) => props.updateCattleOnFarm(5, "daysAtGrass", e.target.valueAsNumber)}
            name="DaysAtGrassSucklerBeef01"
            className="form-control"
            min="0"
          />
        </td>
        <td>
          <label className='ui-table-cell-label' htmlFor='HoursPerDayAtGrassSucklerBeef01'>Hours per day at grass</label>
          <input
            type="number"
            value={props.cattleOnFarm.find(element => element.animalSubGroup === 5)?.hoursPerDayAtGrass}
            onChange={(e) => props.updateCattleOnFarm(5, "hoursPerDayAtGrass", e.target.valueAsNumber)}
            name="HoursPerDayAtGrassSucklerBeef01"
            className="form-control"
            min="0"
          />
        </td>
      </tr>
      <tr>
        <td><p><strong>Suckler beef 1-2 years old</strong></p></td>
        <td>
          <label className='ui-table-cell-label' htmlFor='AverageNumPerMonthSucklerBeef12'>Average number per month</label>
          <input
            type="number"
            value={props.cattleOnFarm.find(element => element.animalSubGroup === 6)?.averageNumPerMonth}
            onChange={(e) => props.updateCattleOnFarm(6, "averageNumPerMonth", e.target.valueAsNumber)}
            name="AverageNumPerMonthSucklerBeef12"
            className="form-control"
            min="0"
          />
        </td>
        <td>
          <label className='ui-table-cell-label' htmlFor='NumSoldSucklerBeef12'>Number sold</label>
          <input
            type="number"
            value={props.cattleOnFarm.find(element => element.animalSubGroup === 6)?.numSold}
            onChange={(e) => props.updateCattleOnFarm(6, "numSold", e.target.valueAsNumber)}
            name="NumSoldSucklerBeef12"
            className="form-control"
            min="0"
          />
        </td>
        <td>
          <label className='ui-table-cell-label' htmlFor='MeanLiveWeightTakenAtSaleSucklerBeef12'>Mean live weight taken at sale</label>
          <input
            type="number"
            value={props.cattleOnFarm.find(element => element.animalSubGroup === 6)?.meanLiveWeightTakenAtSale}
            onChange={(e) => props.updateCattleOnFarm(6, "meanLiveWeightTakenAtSale", e.target.valueAsNumber)}
            name="MeanLiveWeightTakenAtSaleSucklerBeef12"
            className="form-control"
            min="0"
          />
        </td>
        <td>
          <label className='ui-table-cell-label' htmlFor='DaysAtGrassSucklerBeef12'>Days at grass</label>
          <input
            type="number"
            value={props.cattleOnFarm.find(element => element.animalSubGroup === 6)?.daysAtGrass}
            onChange={(e) => props.updateCattleOnFarm(6, "daysAtGrass", e.target.valueAsNumber)}
            name="DaysAtGrassSucklerBeef12"
            className="form-control"
            min="0"
          />
        </td>
        <td>
          <label className='ui-table-cell-label' htmlFor='HoursPerDayAtGrassSucklerBeef12'>Hours per day at grass</label>
          <input
            type="number"
            value={props.cattleOnFarm.find(element => element.animalSubGroup === 6)?.hoursPerDayAtGrass}
            onChange={(e) => props.updateCattleOnFarm(6, "hoursPerDayAtGrass", e.target.valueAsNumber)}
            name="HoursPerDayAtGrassSucklerBeef12"
            className="form-control"
            min="0"
          />
        </td>
      </tr>
      <tr>
        <td><p><strong>Suckler beef 2+ years old</strong></p></td>
        <td>
          <label className='ui-table-cell-label' htmlFor='AverageNumPerMonthSucklerBeef2Plus'>Average number per month</label>
          <input
            type="number"
            value={props.cattleOnFarm.find(element => element.animalSubGroup === 7)?.averageNumPerMonth}
            onChange={(e) => props.updateCattleOnFarm(7, "averageNumPerMonth", e.target.valueAsNumber)}
            name="AverageNumPerMonth"
            className="form-control"
            min="0"
          />
        </td>
        <td>
          <label className='ui-table-cell-label' htmlFor='NumSoldSucklerBeef2Plus'>Number sold</label>
          <input
            type="number"
            value={props.cattleOnFarm.find(element => element.animalSubGroup === 7)?.numSold}
            onChange={(e) => props.updateCattleOnFarm(7, "numSold", e.target.valueAsNumber)}
            name="NumSoldSucklerBeef2Plus"
            className="form-control"
            min="0"
          />
        </td>
        <td>
          <label className='ui-table-cell-label' htmlFor='MeanLiveWeightTakenAtSaleSucklerBeef2Plus'>Mean live weight taken at sale</label>
          <input
            type="number"
            value={props.cattleOnFarm.find(element => element.animalSubGroup === 7)?.meanLiveWeightTakenAtSale}
            onChange={(e) => props.updateCattleOnFarm(7, "meanLiveWeightTakenAtSale", e.target.valueAsNumber)}
            name="MeanLiveWeightTakenAtSaleSucklerBeef2Plus"
            className="form-control"
            min="0"
          />
        </td>
        <td>
          <label className='ui-table-cell-label' htmlFor='DaysAtGrassSucklerBeef2Plus'>Days at grass</label>
          <input
            type="number"
            value={props.cattleOnFarm.find(element => element.animalSubGroup === 7)?.daysAtGrass}
            onChange={(e) => props.updateCattleOnFarm(7, "daysAtGrass", e.target.valueAsNumber)}
            name="DaysAtGrassSucklerBeef2Plus"
            className="form-control"
            min="0"
          />
        </td>
        <td>
          <label className='ui-table-cell-label' htmlFor='HoursPerDayAtGrassSucklerBeef2Plus'>Hours per day at grass</label>
          <input
            type="number"
            value={props.cattleOnFarm.find(element => element.animalSubGroup === 7)?.hoursPerDayAtGrass}
            onChange={(e) => props.updateCattleOnFarm(7, "hoursPerDayAtGrass", e.target.valueAsNumber)}
            name="HoursPerDayAtGrassSucklerBeef2Plus"
            className="form-control"
            min="0"
          />
        </td>
      </tr>
      <tr>
        <td><p><strong>Bulls sold for meat</strong></p></td>
        <td>
          <label className='ui-table-cell-label' htmlFor='AverageNumPerMonthSucklerBullsForMeat'>Average number per month</label>
          <input
            type="number"
            value={props.cattleOnFarm.find(element => element.animalSubGroup === 8)?.averageNumPerMonth}
            onChange={(e) => props.updateCattleOnFarm(8, "averageNumPerMonth", e.target.valueAsNumber)}
            name="AverageNumPerMonthSucklerBullsForMeat"
            className="form-control"
            min="0"
          />
        </td>
        <td>
          <label className='ui-table-cell-label' htmlFor='NumSoldSucklerBullsForMeat'>Number sold</label>
          <input
            type="number"
            value={props.cattleOnFarm.find(element => element.animalSubGroup === 8)?.numSold}
            onChange={(e) => props.updateCattleOnFarm(8, "numSold", e.target.valueAsNumber)}
            name="NumSoldSucklerBullsForMeat"
            className="form-control"
            min="0"
          />
        </td>
        <td>
          <label className='ui-table-cell-label' htmlFor='MeanLiveWeightTakenAtSaleSucklerBullsForMeat'>Mean live weight taken at sale</label>
          <input
            type="number"
            value={props.cattleOnFarm.find(element => element.animalSubGroup === 8)?.meanLiveWeightTakenAtSale}
            onChange={(e) => props.updateCattleOnFarm(8, "meanLiveWeightTakenAtSale", e.target.valueAsNumber)}
            name="MeanLiveWeightTakenAtSaleSucklerBullsForMeat"
            className="form-control"
            min="0"
          />
        </td>
        <td>
          <label className='ui-table-cell-label' htmlFor='DaysAtGrassSucklerBullsForMeat'>Days at grass</label>
          <input
            type="number"
            value={props.cattleOnFarm.find(element => element.animalSubGroup === 8)?.daysAtGrass}
            onChange={(e) => props.updateCattleOnFarm(8, "daysAtGrass", e.target.valueAsNumber)}
            name="DaysAtGrassSucklerBullsForMeat"
            className="form-control"
            min="0"
          />
        </td>
        <td>
          <label className='ui-table-cell-label' htmlFor='HoursPerDayAtGrassSucklerBullsForMeat'>Hours per day at grass</label>
          <input
            type="number"
            value={props.cattleOnFarm.find(element => element.animalSubGroup === 8)?.hoursPerDayAtGrass}
            onChange={(e) => props.updateCattleOnFarm(8, "hoursPerDayAtGrass", e.target.valueAsNumber)}
            name="HoursPerDayAtGrassSucklerBullsForMeat"
            className="form-control"
            min="0"
          />
        </td>
      </tr>
      <tr>
        <td><p><strong>Suckler beef breeding bulls</strong></p></td>
        <td>
          <label className='ui-table-cell-label' htmlFor='AverageNumPerMonthSucklerBreedingBulls'>Average number per month</label>
          <input
            type="number"
            value={props.cattleOnFarm.find(element => element.animalSubGroup === 9)?.averageNumPerMonth}
            onChange={(e) => props.updateCattleOnFarm(9, "averageNumPerMonth", e.target.valueAsNumber)}
            name="AverageNumPerMonthSucklerBreedingBulls"
            className="form-control"
            min="0"
          />
        </td>
        <td>
          <label className='ui-table-cell-label' htmlFor='NumSoldSucklerBreedingBulls'>Number sold</label>
          <input
            type="number"
            value={props.cattleOnFarm.find(element => element.animalSubGroup === 9)?.numSold}
            onChange={(e) => props.updateCattleOnFarm(9, "numSold", e.target.valueAsNumber)}
            name="NumSoldSucklerBreedingBulls"
            className="form-control"
            min="0"
          />
        </td>
        <td>
          <label className='ui-table-cell-label' htmlFor='MeanLiveWeightTakenAtSaleSucklerBreedingBulls'>Mean live weight taken at sale</label>
          <input
            type="number"
            value={props.cattleOnFarm.find(element => element.animalSubGroup === 9)?.meanLiveWeightTakenAtSale}
            onChange={(e) => props.updateCattleOnFarm(9, "meanLiveWeightTakenAtSale", e.target.valueAsNumber)}
            name="MeanLiveWeightTakenAtSaleSucklerBreedingBulls"
            className="form-control"
            min="0"
          />
        </td>
        <td>
          <label className='ui-table-cell-label' htmlFor='DaysAtGrassSucklerBreedingBulls'>Days at grass</label>
          <input
            type="number"
            value={props.cattleOnFarm.find(element => element.animalSubGroup === 9)?.daysAtGrass}
            onChange={(e) => props.updateCattleOnFarm(9, "daysAtGrass", e.target.valueAsNumber)}
            name="DaysAtGrassSucklerBreedingBulls"
            className="form-control"
            min="0"
          />
        </td>
        <td>
          <label className='ui-table-cell-label' htmlFor='HoursPerDayAtGrassSucklerBreedingBulls'>Hours per day at grass</label>
          <input
            type="number"
            value={props.cattleOnFarm.find(element => element.animalSubGroup === 9)?.hoursPerDayAtGrass}
            onChange={(e) => props.updateCattleOnFarm(9, "hoursPerDayAtGrass", e.target.valueAsNumber)}
            name="HoursPerDayAtGrassSucklerBreedingBulls"
            className="form-control"
            min="0"
          />
        </td>
      </tr>
      <tr>
        <td colSpan={7}><p className='mt-2'><strong><u>Dairy</u></strong></p></td>
      </tr>
      <tr>
        <td><p><strong>Cows</strong></p></td>
        <td>
          <label className='ui-table-cell-label' htmlFor='AverageNumPerMonthDairyCows'>Average number per month</label>
          <input
            type="number"
            value={props.cattleOnFarm.find(element => element.animalSubGroup === 10)?.averageNumPerMonth}
            onChange={(e) => props.updateCattleOnFarm(10, "averageNumPerMonth", e.target.valueAsNumber)}
            name="AverageNumPerMonthDairyCows"
            className="form-control"
            min="0"
          />
        </td>
        <td>
          <label className='ui-table-cell-label' htmlFor='NumSoldDairyCows'>Number sold</label>
          <input
            type="number"
            value={props.cattleOnFarm.find(element => element.animalSubGroup === 10)?.numSold}
            onChange={(e) => props.updateCattleOnFarm(10, "numSold", e.target.valueAsNumber)}
            name="NumSoldDairyCows"
            className="form-control"
            min="0"
          />
        </td>
        <td>
          <label className='ui-table-cell-label' htmlFor='MeanLiveWeightTakenAtSaleDairyCows'>MeanLiveWeightTakenAtSale</label>
          <input
            type="number"
            value={props.cattleOnFarm.find(element => element.animalSubGroup === 10)?.meanLiveWeightTakenAtSale}
            onChange={(e) => props.updateCattleOnFarm(10, "meanLiveWeightTakenAtSale", e.target.valueAsNumber)}
            name="MeanLiveWeightTakenAtSaleDairyCows"
            className="form-control"
            min="0"
          />
        </td>
        <td>
          <label className='ui-table-cell-label' htmlFor='DaysAtGrassDairyCows'>Days at grass</label>
          <input
            type="number"
            value={props.cattleOnFarm.find(element => element.animalSubGroup === 10)?.daysAtGrass}
            onChange={(e) => props.updateCattleOnFarm(10, "daysAtGrass", e.target.valueAsNumber)}
            name="DaysAtGrassDairyCows"
            className="form-control"
            min="0"
          />
        </td>
        <td>
          <label className='ui-table-cell-label' htmlFor='HoursPerDayAtGrassDairyCows'>Hours per day at grass</label>
          <input
            type="number"
            value={props.cattleOnFarm.find(element => element.animalSubGroup === 10)?.hoursPerDayAtGrass}
            onChange={(e) => props.updateCattleOnFarm(10, "hoursPerDayAtGrass", e.target.valueAsNumber)}
            name="HoursPerDayAtGrass"
            className="form-control"
            min="0"
          />
        </td>
      </tr>
      <tr>
        <td><p><strong>Youngstock</strong></p></td>
        <td>
          <label className='ui-table-cell-label' htmlFor='AverageNumPerMonthDairyYoungstock'>Average number per month</label>
          <input
            type="number"
            value={props.cattleOnFarm.find(element => element.animalSubGroup === 11)?.averageNumPerMonth}
            onChange={(e) => props.updateCattleOnFarm(11, "averageNumPerMonth", e.target.valueAsNumber)}
            name="AverageNumPerMonthDairyYoungstock"
            className="form-control"
            min="0"
          />
        </td>
        <td>
          <label className='ui-table-cell-label' htmlFor='NumSoldDairyYoungstock'>Number sold</label>
          <input
            type="number"
            value={props.cattleOnFarm.find(element => element.animalSubGroup === 11)?.numSold}
            onChange={(e) => props.updateCattleOnFarm(11, "numSold", e.target.valueAsNumber)}
            name="NumSoldDairyYoungstock"
            className="form-control"
            min="0"
          />
        </td>
        <td>
          <label className='ui-table-cell-label' htmlFor='MeanLiveWeightTakenAtSaleDairyYoungstock'>Mean live weight taken at sale</label>
          <input
            type="number"
            value={props.cattleOnFarm.find(element => element.animalSubGroup === 11)?.meanLiveWeightTakenAtSale}
            onChange={(e) => props.updateCattleOnFarm(11, "meanLiveWeightTakenAtSale", e.target.valueAsNumber)}
            name="MeanLiveWeightTakenAtSale"
            className="form-control"
            min="0"
          />
        </td>
        <td>
          <label className='ui-table-cell-label' htmlFor='DaysAtGrassDairyYoungstock'>Days at grass</label>
          <input
            type="number"
            value={props.cattleOnFarm.find(element => element.animalSubGroup === 11)?.daysAtGrass}
            onChange={(e) => props.updateCattleOnFarm(11, "daysAtGrass", e.target.valueAsNumber)}
            name="DaysAtGrassDairyYoungstock"
            className="form-control"
            min="0"
          />
        </td>
        <td>
          <label className='ui-table-cell-label' htmlFor='HoursPerDayAtGrassDairyYoungstock'>Hours per day at grass</label>
          <input
            type="number"
            value={props.cattleOnFarm.find(element => element.animalSubGroup === 11)?.hoursPerDayAtGrass}
            onChange={(e) => props.updateCattleOnFarm(11, "hoursPerDayAtGrass", e.target.valueAsNumber)}
            name="HoursPerDayAtGrassDairyYoungstock"
            className="form-control"
            min="0"
          />
        </td>
      </tr>
      <tr>
        <td><p><strong>Breeding bulls</strong></p></td>
        <td>
          <label className='ui-table-cell-label' htmlFor='AverageNumPerMonthDairyBreedingBulls'>Average number per month</label>
          <input
            type="number"
            value={props.cattleOnFarm.find(element => element.animalSubGroup === 12)?.averageNumPerMonth}
            onChange={(e) => props.updateCattleOnFarm(12, "averageNumPerMonth", e.target.valueAsNumber)}
            name="AverageNumPerMonthDairyBreedingBulls"
            className="form-control"
            min="0"
          />
        </td>
        <td>
          <label className='ui-table-cell-label' htmlFor='NumSoldDairyBreedingBulls'>Number sold</label>
          <input
            type="number"
            value={props.cattleOnFarm.find(element => element.animalSubGroup === 12)?.numSold}
            onChange={(e) => props.updateCattleOnFarm(12, "numSold", e.target.valueAsNumber)}
            name="NumSold"
            className="form-control"
            min="0"
          />
        </td>
        <td>
          <label className='ui-table-cell-label' htmlFor='MeanLiveWeightTakenAtSaleDairyBreedingBulls'>Mean live weight taken at sale</label>
          <input
            type="number"
            value={props.cattleOnFarm.find(element => element.animalSubGroup === 12)?.meanLiveWeightTakenAtSale}
            onChange={(e) => props.updateCattleOnFarm(12, "meanLiveWeightTakenAtSale", e.target.valueAsNumber)}
            name="MeanLiveWeightTakenAtSaleDairyBreedingBulls"
            className="form-control"
            min="0"
          />
        </td>
        <td>
          <label className='ui-table-cell-label' htmlFor='DaysAtGrassDairyBreedingBulls'>Days at grass</label>
          <input
            type="number"
            value={props.cattleOnFarm.find(element => element.animalSubGroup === 12)?.daysAtGrass}
            onChange={(e) => props.updateCattleOnFarm(12, "daysAtGrass", e.target.valueAsNumber)}
            name="DaysAtGrassDairyBreedingBulls"
            className="form-control"
            min="0"
          />
        </td>
        <td>
          <label className='ui-table-cell-label' htmlFor='HoursPerDayAtGrassDairyBreedingBulls'>Hours per day at grass</label>
          <input
            type="number"
            value={props.cattleOnFarm.find(element => element.animalSubGroup === 12)?.hoursPerDayAtGrass}
            onChange={(e) => props.updateCattleOnFarm(12, "hoursPerDayAtGrass", e.target.valueAsNumber)}
            name="HoursPerDayAtGrassDairyBreedingBulls"
            className="form-control"
            min="0"
          />
        </td>
      </tr>
    </>
  )
}

export default Cattle