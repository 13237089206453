import React, { useEffect } from 'react';
import { ThemeProvider } from 'styled-components';
import GlobalStyles from './components/styles/Global';
import { AppLayout, AppContainer } from './components/styles/Container.styled';
import ErrorPage from './components/ErrorPage';
import Home from './components/Home';
import { Routes, Route, Navigate } from 'react-router-dom'
import './App.css';
import Header from './components/layout/Header';
import Background from './components/layout/Background';
import Login from './components/user/Login';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Register from './components/user/Register';
import { useAppDispatch, useAppSelector } from './hooks';
import Reports from './components/report/Reports';
import { loadUser } from './features/auth/authSlice';
import NewReport from './components/report/NewReport';
import Report from './components/report/Report';
import Input from './components/input/Input';
import WildlifeMeasuresAndOther from './components/input/biodiversity/WildlifeMeasuresAndOther';
import Cattle from './components/input/farmBreadown/stockingRate/Cattle';
import AllEquidae from './components/input/farmBreadown/stockingRate/AllEquidae';
import Sheep from './components/input/farmBreadown/stockingRate/Sheep';
import Deer from './components/input/farmBreadown/stockingRate/Deer';
import LandUse from './components/input/farmBreadown/LandUse';
import AnimalDiet from './components/input/farmBreadown/AnimalDiet';
import Pigs from './components/input/farmBreadown/stockingRate/Pigs';
import Goats from './components/input/farmBreadown/stockingRate/Goats';
import Poultry from './components/input/farmBreadown/stockingRate/Poultry';
import StockingRate from './components/input/farmBreadown/StockingRate';
import Grassland from './components/input/biodiversity/Grassland';
import ForgotPassword from './components/user/ForgotPassword';
import ResetPassword from './components/user/ResetPassword';
import SoilDegradation from './components/input/farmBreadown/SoilDegradation';
import HousingPotential from './components/input/farmBreadown/HousingPotential';
import SignsOfErosion from './components/input/farmBreadown/SignsOfErosion';
import SoilProfile from './components/input/farmBreadown/SoilProfile';
import GrassSward from './components/input/farmManagement/GrassSward';
import ArtificialFertilisers from './components/input/farmManagement/ArtificialFertilisers';
import OrganicFertilisers from './components/input/farmManagement/OrganicFertilisers';
import FertiliserApplicationDetails from './components/input/farmManagement/FertiliserApplicationDetails';
import AnimalProduction from './components/input/farmBreadown/AnimalProduction';
import WatercoursesAndTopography from './components/input/farmBreadown/WatercoursesAndTopography';
import 'react-loading-skeleton/dist/skeleton.css'
import ConfirmEmail from './components/user/ConfirmEmail';
import LandscapeAesthetics from './components/input/farmBreadown/LandscapeAesthetics';
import 'tippy.js/dist/tippy.css';

const theme = {
  colors: {

  }
}

function App() {

    const dispatch = useAppDispatch();

    const { isAuthenticated } = useAppSelector((state) => state.auth);

    useEffect(() => {
      dispatch(loadUser());

    }, [dispatch])



  return (
    <>
      <ToastContainer
        position='top-center'
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <ThemeProvider theme={theme}>
        <>
          <GlobalStyles />
          <AppLayout>
            <Header />
            <AppContainer>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/reset-password" element={<ResetPassword />} />
                <Route path="/confirm-email" element={<ConfirmEmail />} />

                <Route path="/reports" element={
                  isAuthenticated ? (<Reports />) : (<Navigate replace to={"/login"} />)
                } />

                <Route path="/report/:id" element={<Report />} />

                <Route path="/report/new" element={<NewReport />} />

                <Route path="/input" element={<Input />} >
                  <Route path="biodiversity/grassland" element={<Grassland />} />
                  <Route path="biodiversity/data" element={<WildlifeMeasuresAndOther />} />
                  <Route path="stockingRate" element={<StockingRate />} />
                  <Route path="stockingRate/cattle" element={<Cattle />} />
                  <Route path="stockingRate/equidae" element={<AllEquidae />} />
                  <Route path="stockingRate/pigs" element={<Pigs />} />
                  <Route path="stockingRate/sheep" element={<Sheep />} />
                  <Route path="stockingRate/goats" element={<Goats />} />
                  <Route path="stockingRate/deer" element={<Deer />} />
                  <Route path="stockingRate/poultry" element={<Poultry />} />
                  <Route path="landUse" element={<LandUse />} />
                  <Route path="animalDiet" element={<AnimalDiet />} />
                  <Route path="soilDegradation" element={<SoilDegradation />} />
                  <Route path="housingPotential" element={<HousingPotential />} />
                  <Route path="signsOfErosion" element={<SignsOfErosion />} />
                  <Route path="soilProfile" element={<SoilProfile />} />
                  <Route path="grassSward" element={<GrassSward />} />
                  <Route path="artificialFertilisers" element={<ArtificialFertilisers />} />
                  <Route path="organicFertilisers" element={<OrganicFertilisers />} />
                  <Route path="fertiliserDetails" element={<FertiliserApplicationDetails />} />
                  <Route path="animalProduction" element={<AnimalProduction />} />
                  <Route path="watercourses-and-topography" element={<WatercoursesAndTopography />} />
                  <Route path='landscapeAesthetics' element={<LandscapeAesthetics />} />
                </Route>

                <Route path="*" element={<ErrorPage />} />
              </Routes>
            </AppContainer>
          </AppLayout>

        </>
      </ThemeProvider>
    </>
  );
}

export default App;
