import React from 'react';
import { HeaderContainer, HeaderLayout, HeaderLinks, HeaderNav, StyledHeader, StyledLink, HeaderRightSection, UserIconWrapper, UserIcon, HeaderLeftSection, HeaderCenterSection, LogoImg } from '../styles/Header.styled';
import { Link, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { IoIosLogIn, IoIosLogOut } from "react-icons/io"
import { logoutUser } from '../../features/auth/authSlice';
import { toast } from 'react-toastify';
import tippy from 'tippy.js';

type Props = {}

const Header = (props: Props) => {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { user } = useAppSelector((state) => state.auth);

  tippy('.logout-button', {
    content: "Log out",
  });

  tippy('.login-button', {
    content: "Log in",
  });

  const logoutHandler = () => {
    dispatch(logoutUser());
    toast.info("Logout successful");
    navigate('/')
  }

  return (
    <>
      <StyledHeader>
        <HeaderContainer>
          <HeaderLayout>
            <HeaderNav>
              {/* collapsible menu that shows on mobile screens */}
              <HeaderLeftSection className="navbar navbar-expand-lg navbar-light">
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#header-collapse" aria-controls="header-collapse" aria-expanded="false" aria-label="Toggle navigation">
                  <span className="navbar-toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse" id="header-collapse">
                  <ul className="navbar-nav mt-2 mt-lg-0">
                    <StyledLink to={'/reports'}>
                      Reports
                    </StyledLink>
                    {/* <StyledLink to={'/about'}>
                      About
                    </StyledLink> */}
                  </ul>
                </div>
              </HeaderLeftSection>

              <HeaderCenterSection className='col-auto'>
                <StyledLink to={'/'}>
                  <LogoImg src='/assets/logo.png' alt='Logo' />
                </StyledLink>
              </HeaderCenterSection>

              <HeaderRightSection>
                <HeaderLinks>
                  <StyledLink to={'/reports'}>
                    Reports
                  </StyledLink>
                  {/* <StyledLink to={'/about'}>
                    About
                  </StyledLink> */}
                </HeaderLinks>
                <UserIconWrapper>
                  {user ?
                    <IoIosLogOut className='logout-button' size="30px" onClick={logoutHandler} /> :
                    <IoIosLogIn className='login-button' size="30px" onClick={() => navigate("/login")} />
                  }
                </UserIconWrapper>
              </HeaderRightSection>
            </HeaderNav>
          </HeaderLayout>
        </HeaderContainer>
      </StyledHeader>
    </>
  )
}

export default Header