import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { InputButton, InputButtonsContainer } from '../../styles/input/Input.styled';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { ILandUse } from '../../../Interfaces';
import { updateCurrentAnimalOnFarmIndex, updateSelectedAnimalsOnFarm } from '../../../features/farmData/farmDataSlice';
import { ToastContainer, toast } from 'react-toastify';
import MetaData from '../../layout/MetaData';

type Props = {}

const StockingRate = (props: Props) => {

    const [pulledData, setPulledData] = useState(false);
    const [cattleOnFarm, setCattleOnFarm] = useState(false);
    const [equidaeOnFarm, setEquidaeOnFarm] = useState(false);
    const [pigsOnFarm, setPigsOnFarm] = useState(false);
    const [sheepOnFarm, setSheepOnFarm] = useState(false);
    const [goatsOnFarm, setGoatsOnFarm] = useState(false);
    const [deerOnFarm, setDeerOnFarm] = useState(false);
    const [poultryOnFarm, setPoultryOnFarm] = useState(false);

    const { report } = useAppSelector((state) => state.report);
    const { fullFarmData, success, error } = useAppSelector((state) => state.farmData)

    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    useEffect(() => {
        // check from stored inputs if there are any animals recorded
        if (!pulledData) {
            var cattle = fullFarmData?.farmBreakdown.animalsOnFarm.find((element) => element.animalSubGroup < 13);
            if (cattle) setCattleOnFarm(true);

            var equidae = fullFarmData?.farmBreakdown.animalsOnFarm.find((element) => element.animalSubGroup === 13);
            if (equidae) setEquidaeOnFarm(true);

            var pigs = fullFarmData?.farmBreakdown.animalsOnFarm.find((element) => element.animalSubGroup === 14 || element.animalSubGroup === 15);
            if (pigs) setPigsOnFarm(true);

            var sheep = fullFarmData?.farmBreakdown.animalsOnFarm.find((element) => element.animalSubGroup > 15 && element.animalSubGroup < 22);
            if (sheep) setSheepOnFarm(true);

            var goats = fullFarmData?.farmBreakdown.animalsOnFarm.find((element) => element.animalSubGroup === 22 || element.animalSubGroup === 23);
            if (goats) setGoatsOnFarm(true);

            var deer = fullFarmData?.farmBreakdown.animalsOnFarm.find((element) => element.animalSubGroup === 24);
            if (deer) setDeerOnFarm(true);

            var poultry = fullFarmData?.farmBreakdown.animalsOnFarm.find((element) => element.animalSubGroup > 24);
            if (poultry) setPoultryOnFarm(true);
            setPulledData(true);
        }
    }, [fullFarmData?.farmBreakdown.animalsOnFarm, pulledData])

    const nextHandler = () => {
        var animalsOnFarmData = []

        if (cattleOnFarm) animalsOnFarmData.push('cattle');
        if (equidaeOnFarm) animalsOnFarmData.push('equidae');
        if (pigsOnFarm) animalsOnFarmData.push('pigs');
        if (sheepOnFarm) animalsOnFarmData.push('sheep');
        if (goatsOnFarm) animalsOnFarmData.push('goats');
        if (deerOnFarm) animalsOnFarmData.push('deer');
        if (poultryOnFarm) animalsOnFarmData.push('poultry');

        if (animalsOnFarmData.length === 0) {
            navigate('/input/landUse');
            return;
        }

        dispatch(updateSelectedAnimalsOnFarm(animalsOnFarmData));
        navigate(`/input/stockingRate/${animalsOnFarmData[0]}`)

    }

    return (
        <>

            <MetaData title='Animals On Farm' />
            <div><h4><strong>ABOUT YOUR FARM - ANIMALS ON FARM</strong></h4></div>

            <div className='my-3'>
                <p>In this section, we want to learn about the breakdown of your farm, your land use, any changes in landuse, soil type and farm appearance</p>
            </div>

            <div>
                <p><strong>Please check all the animals present on your farm over the reporting period below:</strong></p>

                <div className='d-flex col-3'>
                    <input type='checkbox' checked={cattleOnFarm} onChange={(e) => setCattleOnFarm(e.target.checked)} id='cattle_on_farm_field' />
                    <label className='ms-3' htmlFor='cattle_on_farm_field'>Cattle</label>
                </div>
                <div className='d-flex col-3'>
                    <input type='checkbox' checked={equidaeOnFarm} onChange={(e) => setEquidaeOnFarm(e.target.checked)} id='equidae_on_farm_field' />
                    <label className='ms-3' htmlFor='equidae_on_farm_field'>Equidae</label>
                </div>
                <div className='d-flex col-3'>
                    <input type='checkbox' checked={pigsOnFarm} onChange={(e) => setPigsOnFarm(e.target.checked)} id='pigs_on_farm_field' />
                    <label className='ms-3' htmlFor='pigs_on_farm_field'>Pigs</label>
                </div>
                <div className='d-flex col-3'>
                    <input type='checkbox' checked={sheepOnFarm} onChange={(e) => setSheepOnFarm(e.target.checked)} id='sheep_on_farm_field' />
                    <label className='ms-3' htmlFor='sheep_on_farm_field'>Sheep</label>
                </div>
                <div className='d-flex col-3'>
                    <input type='checkbox' checked={goatsOnFarm} onChange={(e) => setGoatsOnFarm(e.target.checked)} id='goats_on_farm_field' />
                    <label className='ms-3' htmlFor='goats_on_farm_field'>Goats</label>
                </div>
                <div className='d-flex col-3'>
                    <input type='checkbox' checked={deerOnFarm} onChange={(e) => setDeerOnFarm(e.target.checked)} id='deer_on_farm_field' />
                    <label className='ms-3' htmlFor='deer_on_farm_field'>Deer</label>
                </div>
                <div className='d-flex col-3'>
                    <input type='checkbox' checked={poultryOnFarm} onChange={(e) => setPoultryOnFarm(e.target.checked)} id='poultry_on_farm_field' />
                    <label className='ms-3' htmlFor='poultry_on_farm_field'>Poultry</label>
                </div>
            </div>

            <InputButtonsContainer className='mt-5'>
                <InputButton onClick={() => navigate(`/report/${report?.id}`)}>BACK</InputButton>
                <InputButton onClick={nextHandler}>NEXT</InputButton>
            </InputButtonsContainer>
        </>
    )
}

export default StockingRate