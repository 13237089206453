import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosError } from "axios";
import { IById, INewReportData, IReportState } from "../../Interfaces";

axios.defaults.withCredentials = true;

const initialState: IReportState = {
    loading: false,
    activeES: 1
}

export const getFarmerReports = createAsyncThunk(
    "report/farmerReports",
    async (_, { rejectWithValue }) => {
        try {
            const { data } = await axios.get("/api/report/farmer");
            return data;
        } catch (error) {
            if (error instanceof AxiosError) {
                return rejectWithValue(error.response?.data.message);
            }
            // unhandled non-AxiosError goes here
            throw error;
        }
    }
);

export const getReportById = createAsyncThunk(
    "report/get",
    async (props: IById, { rejectWithValue }) => {
        try {
            const { id } = props;
            const { data } = await axios.get(`/api/report/${id}`);
            return data;
        } catch (error) {
            if (error instanceof AxiosError) {
                return rejectWithValue(error.response?.data.message);
            }
            // unhandled non-AxiosError goes here
            throw error;
        }
    }
);

export const deleteReportById = createAsyncThunk(
    "report/delete",
    async (props: IById, { rejectWithValue }) => {
        try {
            const { id } = props;
            const { data } = await axios.delete(`/api/report/${id}`);
            const temp = { data, id };
            return temp;
        } catch (error) {
            if (error instanceof AxiosError) {
                return rejectWithValue(error.response?.data.message);
            }
            // unhandled non-AxiosError goes here
            throw error;
        }
    }
);

export const createReport = createAsyncThunk(
    "report/new",
    async (props: INewReportData, { rejectWithValue }) => {
        try {
            const { name, farmId, isNewFarm, year, farmBackground } = props;
            const config = {
                headers: {
                    "Content-Type": "application/json"
                }
            };
            if (isNewFarm) {
                const { data } = await axios.post("/api/report/new", { Name: name, IsNewFarm: isNewFarm, Year: year, farmBackground }, config);
                return data;
            } else {
                const { data } = await axios.post("/api/report/new", { Name: name, IsNewFarm: isNewFarm, Year: year, FarmId: farmId }, config);
                return data;
            }
        } catch (error) {
            if (error instanceof AxiosError) {
                return rejectWithValue(error.response?.data.message);
            }
            // unhandled non-AxiosError goes here
            throw error;
        }
    }
)


const reportSlice = createSlice({
    name: "report",
    initialState,
    reducers: {
        setActiveES(state, action: PayloadAction<number>) {
            state.activeES = action.payload
        },
        resetNewReport(state) {
            state.success = undefined;
        },
        clearErrors(state) {
            state.error = undefined;
        }
    },
    extraReducers(builder) {
        builder.addCase(getFarmerReports.pending, (state, action) => {
            state.loading = true;
        })
        builder.addCase(getFarmerReports.fulfilled, (state, action) => {
            state.reports = action.payload.reports;
            state.loading = false;
        })
        builder.addCase(getFarmerReports.rejected, (state, action) => {
            state.error = action.payload;
            state.loading = false;
        })
        builder.addCase(createReport.pending, (state, action) => {
            state.loading = true;
        })
        builder.addCase(createReport.fulfilled, (state, action) => {
            state.report = action.payload.report;
            state.success = action.payload.success;
            state.loading = false;
        })
        builder.addCase(createReport.rejected, (state, action) => {
            state.error = action.payload;
            state.loading = false;
        })
        builder.addCase(getReportById.pending, (state, action) => {
            state.loading = true;
        })
        builder.addCase(getReportById.fulfilled, (state, action) => {
            state.report = action.payload.report;
            state.loading = false;
        })
        builder.addCase(getReportById.rejected, (state, action) => {
            state.error = action.payload;
            state.loading = false;
        })
        builder.addCase(deleteReportById.pending, (state, action) => {
            state.loading = true;
        })
        builder.addCase(deleteReportById.fulfilled, (state, action) => {
            state.reports = state.reports?.filter((report) => report.id !== action.payload.id);
            state.loading = false;
        })
        builder.addCase(deleteReportById.rejected, (state, action) => {
            state.error = action.payload;
            state.loading = false;
        })
    },
})

export const { resetNewReport, clearErrors, setActiveES } = reportSlice.actions;
export default reportSlice.reducer;