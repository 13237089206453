import React, { Fragment } from 'react'
import { IManufacturedFertiliserApplied } from '../../../../../Interfaces'
import { InputButton } from '../../../../styles/input/Input.styled'

type Props = {
    rowsData: IManufacturedFertiliserApplied[],
    deleteTableRows: (a: number) => void,
    handleChange: (index: number, e: React.ChangeEvent<HTMLInputElement>) => void
}

const TableRows = (props: Props) => {
    return (
        <>
            {props.rowsData.map((data, index) => {
                const { isProtectedUrea, fertiliserName, nitrogenContentPercentage, phosphateContentPercentage, potashContentPercentage, tonnesAppliedInAutumn, tonnesAppliedInSpring, tonnesAppliedInSummer, tonnesAppliedInWinter } = data
                return (
                    <Fragment key={index}>
                        <tr>
                            <td>
                                <label className='ui-table-cell-label' htmlFor='isProtectedUrea'>Protected Urea</label>
                                <input
                                    type='checkbox'
                                    checked={isProtectedUrea}
                                    name="isProtectedUrea"
                                    onChange={(e) => (props.handleChange(index, e))}>
                                </input>
                            </td>
                            <td>
                                <label className='ui-table-cell-label' htmlFor='fertiliserName'>Fertiliser Name</label>
                                <input
                                    type="text"
                                    value={fertiliserName}
                                    onChange={(e) => (props.handleChange(index, e))}
                                    name="fertiliserName"
                                    className="form-control"
                                />
                            </td>
                            <td>
                                <label className='ui-table-cell-label' htmlFor='nitrogenContentPercentage'>Nitrogen Content (%)</label>
                                <input
                                    disabled={isProtectedUrea}
                                    type="number"
                                    value={nitrogenContentPercentage}
                                    onChange={(e) => (props.handleChange(index, e))}
                                    name="nitrogenContentPercentage"
                                    className="form-control"
                                />
                            </td>
                            <td>
                                <label className='ui-table-cell-label' htmlFor='phosphateContentPercentage'>Phosphate Content (%)</label>
                                <input
                                    disabled={isProtectedUrea}
                                    type="number"
                                    value={phosphateContentPercentage}
                                    onChange={(e) => (props.handleChange(index, e))}
                                    name="phosphateContentPercentage"
                                    className="form-control"
                                />
                            </td>
                            <td>
                                <label className='ui-table-cell-label' htmlFor='potashContentPercentage'>Potash Content (%)</label>
                                <input
                                    disabled={isProtectedUrea}
                                    type="number"
                                    value={potashContentPercentage}
                                    onChange={(e) => (props.handleChange(index, e))}
                                    name="potashContentPercentage"
                                    className="form-control"
                                />
                            </td>
                            <td>
                                <label className='ui-table-cell-label' htmlFor='tonnesAppliedInSpring'>Tonnes Applied In Spring</label>
                                <input
                                    type="number"
                                    value={tonnesAppliedInSpring}
                                    onChange={(e) => (props.handleChange(index, e))}
                                    name="tonnesAppliedInSpring"
                                    className="form-control"
                                />
                            </td>
                            <td>
                                <label className='ui-table-cell-label' htmlFor='tonnesAppliedInSummer'>Tonnes Applied In Summer</label>
                                <input
                                    type="number"
                                    value={tonnesAppliedInSummer}
                                    onChange={(e) => (props.handleChange(index, e))}
                                    name="tonnesAppliedInSummer"
                                    className="form-control"
                                />
                            </td>
                            <td>
                                <label className='ui-table-cell-label' htmlFor='tonnesAppliedInAutumn'>Tonnes Applied In Autumn</label>
                                <input
                                    type="number"
                                    value={tonnesAppliedInAutumn}
                                    onChange={(e) => (props.handleChange(index, e))}
                                    name="tonnesAppliedInAutumn"
                                    className="form-control"
                                />
                            </td>
                            <td>
                                <label className='ui-table-cell-label' htmlFor='tonnesAppliedInWinter'>Tonnes Applied In Winter</label>
                                <input
                                    type="number"
                                    value={tonnesAppliedInWinter}
                                    onChange={(e) => (props.handleChange(index, e))}
                                    name="tonnesAppliedInWinter"
                                    className="form-control"
                                />
                            </td>
                            <td>
                                <InputButton className="dynamic-table-btn my-1" onClick={() => (props.deleteTableRows(index))}>
                                    -
                                </InputButton>
                            </td>
                        </tr>
                    </Fragment>
                )
            })}
        </>
    )
}

export default TableRows