import React from 'react'
import { IAnimalsOnFarm } from '../../../../Interfaces';

type Props = {
    poultryOnFarm: IAnimalsOnFarm[];
    updatePoultryOnFarm: (animalSubGroup: number, key: string, value: string | null | Date | number) => void
}

const Poultry = (props: Props) => {
    return (
        <>
            <tr>
                <td colSpan={7}><p className='mt-2'><strong><u>Poultry</u></strong></p></td>
            </tr>
            <tr>
                <td><p><strong>Poultry for meat</strong></p></td>
                <td>
                    <label className='ui-table-cell-label' htmlFor='AverageNumPerMonthPoultryForMeat'>Average number per month</label>
                    <input
                        type="number"
                        value={props.poultryOnFarm.find(element => element.animalSubGroup === 25)?.averageNumPerMonth}
                        onChange={(e) => props.updatePoultryOnFarm(25, "averageNumPerMonth", e.target.valueAsNumber)}
                        name="AverageNumPerMonthPoultryForMeat"
                        className="form-control"
                        min="0"
                    />
                </td>
                <td>
                    <label className='ui-table-cell-label' htmlFor='NumSoldPoultryForMeat'>Number sold</label>
                    <input
                        type="number"
                        value={props.poultryOnFarm.find(element => element.animalSubGroup === 25)?.numSold}
                        onChange={(e) => props.updatePoultryOnFarm(25, "numSold", e.target.valueAsNumber)}
                        name="NumSoldPoultryForMeat"
                        className="form-control"
                        min="0"
                    />
                </td>
                <td>
                    <label className='ui-table-cell-label' htmlFor='MeanLiveWeightTakenAtSalePoultryForMeat'>Mean live weight taken at sale</label>
                    <input
                        type="number"
                        value={props.poultryOnFarm.find(element => element.animalSubGroup === 25)?.meanLiveWeightTakenAtSale}
                        onChange={(e) => props.updatePoultryOnFarm(25, "meanLiveWeightTakenAtSale", e.target.valueAsNumber)}
                        name="MeanLiveWeightTakenAtSalePoultryForMeat"
                        className="form-control"
                        min="0"
                    />
                </td>
                <td>
                    <label className='ui-table-cell-label' htmlFor='DaysAtGrassPoultryForMeat'>Days at grass</label>
                    <input
                        type="number"
                        value={props.poultryOnFarm.find(element => element.animalSubGroup === 25)?.daysAtGrass}
                        onChange={(e) => props.updatePoultryOnFarm(25, "daysAtGrass", e.target.valueAsNumber)}
                        name="DaysAtGrassPoultryForMeat"
                        className="form-control"
                        min="0"
                    />
                </td>
                <td>
                    <label className='ui-table-cell-label' htmlFor='HoursPerDayAtGrassPoultryForMeat'>Hours per day at grass</label>
                    <input
                        type="number"
                        value={props.poultryOnFarm.find(element => element.animalSubGroup === 25)?.hoursPerDayAtGrass}
                        onChange={(e) => props.updatePoultryOnFarm(25, "hoursPerDayAtGrass", e.target.valueAsNumber)}
                        name="HoursPerDayAtGrassPoultryForMeat"
                        className="form-control"
                        min="0"
                    />
                </td>
            </tr>
            <tr>
                <td><p><strong>Poultry for laying</strong></p></td>
                <td>
                    <label className='ui-table-cell-label' htmlFor='AverageNumPerMonthPoultryForLaying'>Average number per month</label>
                    <input
                        type="number"
                        value={props.poultryOnFarm.find(element => element.animalSubGroup === 26)?.averageNumPerMonth}
                        onChange={(e) => props.updatePoultryOnFarm(26, "averageNumPerMonth", e.target.valueAsNumber)}
                        name="AverageNumPerMonthPoultryForLaying"
                        className="form-control"
                        min="0"
                    />
                </td>
                <td>
                    <label className='ui-table-cell-label' htmlFor='NumSoldPoultryForLaying'>Number sold</label>
                    <input
                        type="number"
                        value={props.poultryOnFarm.find(element => element.animalSubGroup === 26)?.numSold}
                        onChange={(e) => props.updatePoultryOnFarm(26, "numSold", e.target.valueAsNumber)}
                        name="NumSoldPoultryForLaying"
                        className="form-control"
                        min="0"
                    />
                </td>
                <td>
                    <label className='ui-table-cell-label' htmlFor='MeanLiveWeightTakenAtSalePoultryForLaying'>Mean live weight taken at sale</label>
                    <input
                        type="number"
                        value={props.poultryOnFarm.find(element => element.animalSubGroup === 26)?.meanLiveWeightTakenAtSale}
                        onChange={(e) => props.updatePoultryOnFarm(26, "meanLiveWeightTakenAtSale", e.target.valueAsNumber)}
                        name="MeanLiveWeightTakenAtSalePoultryForLaying"
                        className="form-control"
                        min="0"
                    />
                </td>
                <td>
                    <label className='ui-table-cell-label' htmlFor='DaysAtGrassPoultryForLaying'>Days at grass</label>
                    <input
                        type="number"
                        value={props.poultryOnFarm.find(element => element.animalSubGroup === 26)?.daysAtGrass}
                        onChange={(e) => props.updatePoultryOnFarm(26, "daysAtGrass", e.target.valueAsNumber)}
                        name="DaysAtGrassPoultryForLaying"
                        className="form-control"
                        min="0"
                    />
                </td>
                <td>
                    <label className='ui-table-cell-label' htmlFor='HoursPerDayAtGrassPoultryForLaying'>Hours per day at grass</label>
                    <input
                        type="number"
                        value={props.poultryOnFarm.find(element => element.animalSubGroup === 26)?.hoursPerDayAtGrass}
                        onChange={(e) => props.updatePoultryOnFarm(26, "hoursPerDayAtGrass", e.target.valueAsNumber)}
                        name="HoursPerDayAtGrassPoultryForLaying"
                        className="form-control"
                        min="0"
                    />
                </td>
            </tr>
        </>
    )
}

export default Poultry