import styled from "styled-components";
import { fadeInFwd } from "../Container.styled";

export const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 80%;
    animation: ${fadeInFwd} 1s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
`;

export const InputOutletContainer = styled.div`
    
`;

export const CheckboxInput = styled.input`
    width: 20px;
    height: 20px;
`;


export const InputButtonsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    
`;

export const InputButton = styled.div`
    background: #FFFFFF;
    border: 1px solid #000000;
    border-radius: 10px;
    padding 0.5rem 1rem;
    color: black;
    cursor: pointer;

    &:hover {
    transform: scale(0.98);
    }

    @media (max-width: 550px) {
        padding 0.25rem 0.5rem;
    }
`;

export const AuthInput = styled.input`
    outline: none;
    border: 1px solid #e5e7eb;
    margin: 8px 0;
    background-color: #fff;
    padding: 1rem;
    padding-right: 3rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
    width: 300px;
    border-radius: 0.5rem;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
`;