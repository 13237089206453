import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { InputButton, InputButtonsContainer } from '../../styles/input/Input.styled';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { ToastContainer, toast } from 'react-toastify';
import { clearErrors, resetUpdate, getFullFarmDataById, putSignsOfErosion, resetFarmDataId } from '../../../features/farmData/farmDataSlice';


type Props = {}

const SignsOfErosion = (props: Props) => {

    const [pulledData, setPulledData] = useState(false);
    const [rills, setRills] = useState(3);
    const [poaching, setPoaching] = useState(3);
    const [severePoaching, setSeverePoaching] = useState(3);
    const [pathsCreatedByLivestock, setPathsCreatedByLivestock] = useState(3);
    const [exposedSoil, setExposedSoil] = useState(3);
    const [surfaceCapping, setSurfaceCapping] = useState(3);
    const [wheeling, setWheeling] = useState(3);
    const [veryFewWheeling, setVeryFewWheeling] = useState(false);
    const [occasionalWheeling, setOccasionalWheeling] = useState(false);
    const [regularWheeling, setRegularWheeling] = useState(false);
    const [poorSwardQuality, setPoorSwardQuality] = useState(3);
    const [backToReport, setBackToReport] = useState(false);


    const { report } = useAppSelector((state) => state.report);
    const { fullFarmData, success, error } = useAppSelector((state) => state.farmData)

    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!pulledData) {
            if (fullFarmData?.farmBreakdown.soilProfile.signsOfErosion) {
                var signs = fullFarmData.farmBreakdown.soilProfile.signsOfErosion
                if (signs.rills === true) setRills(1);
                if (signs.rills === false) setRills(0);
                if (signs.poaching === true) setPoaching(1);
                if (signs.poaching === false) setPoaching(0);
                if (signs.severePoaching === true) setSeverePoaching(1);
                if (signs.severePoaching === false) setSeverePoaching(0);
                if (signs.pathsCreatedByLivestock === true) setPathsCreatedByLivestock(1);
                if (signs.pathsCreatedByLivestock === false) setPathsCreatedByLivestock(0);
                if (signs.exposedSoil === true) setExposedSoil(1);
                if (signs.exposedSoil === false) setExposedSoil(0);
                if (signs.surfaceCapping === true) setSurfaceCapping(1);
                if (signs.surfaceCapping === false) setSurfaceCapping(0);
                if (signs.wheeling === true) setWheeling(1);
                if (signs.wheeling === false) setWheeling(0);
                setVeryFewWheeling(signs.veryFewWheeling ?? false)
                setOccasionalWheeling(signs.occasionalWheeling ?? false)
                setRegularWheeling(signs.regularWheeling ?? false)
                if (signs.poorSwardQuality === true) setPoorSwardQuality(1);
                if (signs.poorSwardQuality === false) setPoorSwardQuality(0);
            }
            setPulledData(false);
        }

        if (success) {
            let farmDataId = report?.farmData;
            dispatch(getFullFarmDataById({ id: farmDataId! }))
            toast.dismiss();
            toast.success("Input saved successfully");
            dispatch(resetUpdate());
            if (backToReport) {
                dispatch(resetFarmDataId());
                navigate(`/report/${report?.id}`);
            }
        }

    }, [dispatch, error, pulledData, success, backToReport, navigate, report?.farmData, fullFarmData?.farmBreakdown.soilProfile.signsOfErosion, report?.id])


    const saveHandler = (e?: React.MouseEvent) => {
        e?.preventDefault();

        // validation
        if (wheeling && veryFewWheeling && occasionalWheeling) {
            toast.error("Please choose only one between very few wheeling & occasional wheeling")
            return;
        }

        if (rills === 3) {
            toast.dismiss();
            toast.error("Please indicate whether there are rills on your farm");
            return
        }
        if (poaching === 3) {
            toast.dismiss();
            toast.error("Please indicate whether there is poaching on your farm");
            return
        }
        if (poaching === 1 && severePoaching === 3) {
            toast.dismiss();
            toast.error("Please indicate whether there is severe poaching on your farm");
            return
        }
        if (pathsCreatedByLivestock === 3) {
            toast.dismiss();
            toast.error("Please indicate whether there are paths created by livestock on your farm");
            return
        }
        if (exposedSoil === 3) {
            toast.dismiss();
            toast.error("Please indicate whether there is exposed soil on your farm");
            return
        }
        if (surfaceCapping === 3) {
            toast.dismiss();
            toast.error("Please indicate whether there is surface capping on your farm");
            return
        }
        if (wheeling === 3) {
            toast.dismiss();
            toast.error("Please indicate whether there is wheeling on your farm");
            return
        }
        if (poorSwardQuality === 3) {
            toast.dismiss();
            toast.error("Please indicate whether there is poor sward quality on your farm");
            return
        }

        toast.info("Saving progress...");
        dispatch(putSignsOfErosion(
            {
                id: fullFarmData?.farmData.id!,
                signsOfErosion: {
                    rills: Boolean(rills),
                    poaching: Boolean(poaching),
                    severePoaching: Boolean(poaching) ? Boolean(severePoaching) : false,
                    pathsCreatedByLivestock: Boolean(pathsCreatedByLivestock),
                    exposedSoil: Boolean(exposedSoil),
                    surfaceCapping: Boolean(surfaceCapping),
                    wheeling: Boolean(wheeling),
                    veryFewWheeling: Boolean(wheeling) ? Boolean(veryFewWheeling) : false,
                    occasionalWheeling: Boolean(wheeling) ? Boolean(occasionalWheeling) : false,
                    regularWheeling: Boolean(wheeling) ? Boolean(regularWheeling) : false,
                    poorSwardQuality: Boolean(poorSwardQuality)
                }
            }));
    }

    return (
        <>
            <div>
                <div><h4><strong>ABOUT YOUR FARM - SIGNS OF EROSION</strong></h4></div>

                <div><strong>Are any of the following signs of erosion present on your permanent grassland? </strong></div>

                <div>
                    You can learn more about soil erosion at the following links: <br />
                    <a href="https://www.fas.scot/downloads/know-your-soils/" target='blank'>Know your soils</a> <br />
                    <a href="https://aura.abdn.ac.uk/bitstream/handle/2164/20843/Milazzo_etal_AEE_The_Role_of_Grassland_VOR.pdf;jsessionid=27FA3EEFF743775EFA146EF6298D75BB?sequence=1" target='blank'>The role of grassland</a><br />
                    <a href="https://dspace.lib.cranfield.ac.uk/bitstream/handle/1826/1945/soil%20structural%20degradation-2000%20floods-2003.pdf?sequence=1&isAllowed=y" target='blank'>Structural degradation</a>
                </div>

                <div className='radio my-3'>
                    <label><strong>Rills</strong><br /></label>
                    <label>
                        <input type="radio" name="rills" checked={rills === 1} onChange={() => setRills(1)} />
                        <span className='radio-span'>Yes</span>
                    </label>
                    <label>
                        <input type="radio" name="rills" checked={rills === 0} onChange={() => setRills(0)} />
                        <span className='radio-span'>No</span>
                    </label>
                </div>

                <div className='radio my-3'>
                    <label><strong>Poaching</strong><br /></label>
                    <label>
                        <input type="radio" name="poaching" checked={poaching === 1} onChange={() => setPoaching(1)} />
                        <span className='radio-span'>Yes</span>
                    </label>
                    <label>
                        <input type="radio" name="poaching" checked={poaching === 0} onChange={() => setPoaching(0)} />
                        <span className='radio-span'>No</span>
                    </label>
                </div>

                {poaching === 1 && (
                    <div className='radio my-3'>
                        <label><strong>Is poaching severe?</strong><br /></label>
                        <label>
                            <input type="radio" name="severePoaching" checked={severePoaching === 1} onChange={() => setSeverePoaching(1)} />
                            <span className='radio-span'>Yes</span>
                        </label>
                        <label>
                            <input type="radio" name="severePoaching" checked={severePoaching === 0} onChange={() => setSeverePoaching(0)} />
                            <span className='radio-span'>No</span>
                        </label>
                    </div>
                )}

                <div className='radio my-3'>
                    <label><strong>Paths created by livestock</strong><br /></label>
                    <label>
                        <input type="radio" name="pathsCreatedByLivestock" checked={pathsCreatedByLivestock === 1} onChange={() => setPathsCreatedByLivestock(1)} />
                        <span className='radio-span'>Yes</span>
                    </label>
                    <label>
                        <input type="radio" name="pathsCreatedByLivestock" checked={pathsCreatedByLivestock === 0} onChange={() => setPathsCreatedByLivestock(0)} />
                        <span className='radio-span'>No</span>
                    </label>
                </div>

                <div className='radio my-3'>
                    <label><strong>Exposed soil</strong><br /></label>
                    <label>
                        <input type="radio" name="exposedSoil" checked={exposedSoil === 1} onChange={() => setExposedSoil(1)} />
                        <span className='radio-span'>Yes</span>
                    </label>
                    <label>
                        <input type="radio" name="exposedSoil" checked={exposedSoil === 0} onChange={() => setExposedSoil(0)} />
                        <span className='radio-span'>No</span>
                    </label>
                </div>

                <div className='radio my-3'>
                    <label><strong>Surface capping</strong><br /></label>
                    <label>
                        <input type="radio" name="surfaceCapping" checked={surfaceCapping === 1} onChange={() => setSurfaceCapping(1)} />
                        <span className='radio-span'>Yes</span>
                    </label>
                    <label>
                        <input type="radio" name="surfaceCapping" checked={surfaceCapping === 0} onChange={() => setSurfaceCapping(0)} />
                        <span className='radio-span'>No</span>
                    </label>
                </div>

                <div className='radio my-3'>
                    <label><strong>Wheelings</strong><br /></label>
                    <label>
                        <input type="radio" name="wheeling" checked={wheeling === 1} onChange={() => setWheeling(1)} />
                        <span className='radio-span'>Yes</span>
                    </label>
                    <label>
                        <input type="radio" name="wheeling" checked={wheeling === 0} onChange={() => setWheeling(0)} />
                        <span className='radio-span'>No</span>
                    </label>
                </div>

                {wheeling === 1 && (
                    <>
                        <label><strong>How widespread?</strong><br /></label>


                        <div className='d-flex'>
                            <input data-role='none' type='checkbox' checked={veryFewWheeling} onChange={() => {
                                setVeryFewWheeling(!veryFewWheeling)
                                
                                setOccasionalWheeling(false);
                                setRegularWheeling(false);
                            }} />
                            <label>Very few wheelings</label>

                        </div>
                        <div className='d-flex'>
                            <input data-role='none' type='checkbox' checked={occasionalWheeling} onChange={() => {
                                setOccasionalWheeling(!occasionalWheeling)
                                
                                setVeryFewWheeling(false);
                                setRegularWheeling(false);
                            }} />
                            <label>Occasional wheelings</label>

                        </div>
                        <div className='d-flex'>
                            <input data-role='none' type='checkbox' checked={regularWheeling} onChange={() => {
                                setRegularWheeling(!regularWheeling)
                                
                                setOccasionalWheeling(false);
                                setVeryFewWheeling(false);
                            }} />
                            <label>Regular wheelings (at regular intervals across the field)</label>

                        </div>

                    </>
                )}

                <div className='radio my-3'>
                    <label><strong>Poor sward quality</strong><br /></label>
                    <label>
                        <input type="radio" name="poorSwardQuality" checked={poorSwardQuality === 1} onChange={() => setPoorSwardQuality(1)} />
                        <span className='radio-span'>Yes</span>
                    </label>
                    <label>
                        <input type="radio" name="poorSwardQuality" checked={poorSwardQuality === 0} onChange={() => setPoorSwardQuality(0)} />
                        <span className='radio-span'>No</span>
                    </label>
                </div>

                <InputButtonsContainer className='my-2'>
                    <InputButton onClick={() => navigate(`/report/${report?.id}`)}>BACK</InputButton>
                    <InputButton onClick={() => {
                        toast.dismiss()
                        toast.info("Saving...")
                        saveHandler()
                    }}>SAVE</InputButton>
                    <InputButton onClick={() => {
                        toast.dismiss()
                        toast.info("Saving...");
                        setBackToReport(true);
                        saveHandler();
                    }}>SAVE & CONTINUE</InputButton>
                </InputButtonsContainer>

            </div>
        </>
    )
}

export default SignsOfErosion