import React, { useEffect, useState } from 'react';
import { AuthForm, AuthFormLink, AuthFormTitle, StyledAuthFormLink } from '../styles/Form.styled';
import { AuthInput } from '../styles/input/Input.styled';
import { AuthButton } from '../styles/Button.styled';
import { AuthSpan, AuthSvg } from '../styles/Span.styled';
import { AuthInputContainer } from '../styles/Container.styled';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { clearErrors, forgotPassword, loginUser } from '../../features/auth/authSlice';

type Props = {}

const ForgotPassword = (props: Props) => {
    const [email, setEmail] = useState("");
    const [requestSent, setRequestSent] = useState(false);

    // const { isAuthenticated, user, loading, error } = useAppSelector((state) => state.auth);

    // const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const submitHandler = (e: React.FormEvent) => {
        e.preventDefault();

        if (email === "") {
            toast.error("Enter your email");
            return
        }

        toast.info("Request sent");

        dispatch(forgotPassword({ email }));
        setRequestSent(true);
    }
    return (
        <>
            <div>
                <AuthForm>
                    <AuthFormTitle>Forgot your password?</AuthFormTitle>
                    {requestSent ? (
                        <div className='text-center'>Thank you for submitting your email. If it matches with an account in our records, you will receive an email with instructions on how you can reset your password.</div>
                    ) : (
                        <>
                            <AuthInputContainer>
                                <AuthInput placeholder="Enter email" type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                                <AuthSpan>
                                    <AuthSvg stroke="currentColor" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207" strokeWidth="2" strokeLinejoin="round" strokeLinecap="round"></path>
                                    </AuthSvg>
                                </AuthSpan>
                            </AuthInputContainer>
                            <AuthButton type="submit" onClick={submitHandler}>
                                Submit
                            </AuthButton>

                            <AuthFormLink>
                                Already have an account?
                                <StyledAuthFormLink to={'/login'}>Sign in</StyledAuthFormLink>
                            </AuthFormLink>
                        </>
                    )}
                </AuthForm>

            </div>
        </>
    )
}

export default ForgotPassword