import React, { useState, useEffect } from 'react'
import { WildlifeFormGroup } from '../../styles/input/Biodiversity.input.styled';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { InputButton, InputButtonsContainer } from '../../styles/input/Input.styled';
import { ToastContainer, toast } from 'react-toastify';
import { putBiodiversityData, resetUpdate, clearErrors, resetFarmDataId } from '../../../features/farmData/farmDataSlice';
import MetaData from '../../layout/MetaData';


type Props = {}

const WildlifeMeasuresAndOther = (props: Props) => {

    const [partsOfSward, setPartsOfSward] = useState(3);
    const [mowingGrass, setMowingGrass] = useState(3);
    const [reducedManagement, setReducedManagement] = useState(3);
    const [protection, setProtection] = useState(3);
    const [threatenedSpecies, setThreatenedSpecies] = useState(3);
    const [cropsGrown, setCropsGrown] = useState(3);
    const [pulledData, setPulledData] = useState(false);
    const [backToReport, setBackToReport] = useState(false);

    const { report } = useAppSelector((state) => state.report);
    const { fullFarmData, bioDataSuccess, error } = useAppSelector((state) => state.farmData);

    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!pulledData) {
            if (fullFarmData?.biodiversityInputs.data?.partsOfSwardLeftUncut === true) setPartsOfSward(1);
            if (fullFarmData?.biodiversityInputs.data?.partsOfSwardLeftUncut === false) setPartsOfSward(0);
            if (fullFarmData?.biodiversityInputs.data?.mowingGrasslandCenterToOutside === true) setMowingGrass(1);
            if (fullFarmData?.biodiversityInputs.data?.mowingGrasslandCenterToOutside === false) setMowingGrass(0);
            if (fullFarmData?.biodiversityInputs.data?.reducedManagementIntensityOfFieldMargins === true) setReducedManagement(1);
            if (fullFarmData?.biodiversityInputs.data?.reducedManagementIntensityOfFieldMargins === false) setReducedManagement(0);
            if (fullFarmData?.biodiversityInputs.data?.protectionOfPatchesWithBirds === true) setProtection(1);
            if (fullFarmData?.biodiversityInputs.data?.protectionOfPatchesWithBirds === false) setProtection(0);
            if (fullFarmData?.biodiversityInputs.data?.threatenedSpecies === true) setThreatenedSpecies(1);
            if (fullFarmData?.biodiversityInputs.data?.threatenedSpecies === false) setThreatenedSpecies(0);
            if (fullFarmData?.biodiversityInputs.data?.pollinatingCropsGrown === true) setCropsGrown(1);
            if (fullFarmData?.biodiversityInputs.data?.pollinatingCropsGrown === false) setCropsGrown(0);

            setPulledData(true);
        }

        if (bioDataSuccess) {
            toast.dismiss();
            toast.success("Input saved successfully");
            dispatch(resetUpdate());
            if (backToReport) {
                dispatch(resetFarmDataId());
                navigate(`/report/${report?.id}`);
            }
        }

        if (error && typeof error === 'string') {
            toast.dismiss();
            toast.error(error);
            dispatch(clearErrors())
        }


    }, [dispatch, error, fullFarmData?.biodiversityInputs.data?.mowingGrasslandCenterToOutside, fullFarmData?.biodiversityInputs.data?.partsOfSwardLeftUncut, fullFarmData?.biodiversityInputs.data?.protectionOfPatchesWithBirds, fullFarmData?.biodiversityInputs.data?.reducedManagementIntensityOfFieldMargins, pulledData, bioDataSuccess])

    const saveHandler = (e?: React.MouseEvent) => {
        e?.preventDefault();

        if (partsOfSward === 3) {
            toast.dismiss();
            toast.error("Please indicate whether parts of the sward are left uncut during a cut");
            return
        }
        if (mowingGrass === 3) {
            toast.dismiss();
            toast.error("Please indicate whether you mow the grassland from the center to outside");
            return
        }
        if (reducedManagement === 3) {
            toast.dismiss();
            toast.error("Please indicate whether there is reduced management intensity of field margins");
            return
        }
        if (protection === 3) {
            toast.dismiss();
            toast.error("Please indicate whether there is protection of patches with ground-breeding birds");
            return
        }
        if (cropsGrown === 3) {
            toast.dismiss();
            toast.error("Please indicate whether there are pollinated crops on your farm");
            return
        }
        if (threatenedSpecies === 3) {
            toast.dismiss();
            toast.error("Please indicate whether there are threatened species on your farm");
            return
        }

        dispatch(putBiodiversityData(
            {
                id: fullFarmData?.farmData.id!,
                bioData: {
                    threatenedSpecies: Boolean(threatenedSpecies),
                    pollinatingCrops: Boolean(cropsGrown),
                    partsOfSward: Boolean(partsOfSward),
                    mowingGrass: Boolean(mowingGrass),
                    reducedManagement: Boolean(reducedManagement),
                    protection: Boolean(protection)
                }
            }
        ))
    }

    return (
        <>
            <MetaData title='Alternative forage' />
            <div><h4><strong>ABOUT YOUR FARM - WILDLIFE MANAGEMENT</strong></h4></div>

            <div>
                <div className='radio my-3'>
                    <label><strong>Are parts of the sward left uncut during a cut (so called insect refuges)?</strong></label>
                    <label>
                        <input type="radio" name="partsOfSward" checked={partsOfSward === 1} onChange={() => setPartsOfSward(1)} />
                        <span className='radio-span'>Yes</span>
                    </label>
                    <label>
                        <input type="radio" name="partsOfSward" checked={partsOfSward === 0} onChange={() => setPartsOfSward(0)} />
                        <span className='radio-span'>No</span>
                    </label>
                </div>

                <div className='radio my-3'>
                    <label><strong>When mowing, is the first pass across the middle of the field (allowing wildlife to escape to neighbouring habitats)?</strong></label>
                    <label>
                        <input type="radio" name="mowingGrass" checked={mowingGrass === 1} onChange={() => setMowingGrass(1)} />
                        <span className='radio-span'>Yes</span>
                    </label>
                    <label>
                        <input type="radio" name="mowingGrass" checked={mowingGrass === 0} onChange={() => setMowingGrass(0)} />
                        <span className='radio-span'>No</span>
                    </label>
                </div>

                <div className='radio my-3'>
                    <label><strong>Is there reduced management intensity of field margins (2-5 m of field margin)?</strong></label>
                    <label>
                        <input type="radio" name="reducedManagement" checked={reducedManagement === 1} onChange={() => setReducedManagement(1)} />
                        <span className='radio-span'>Yes</span>
                    </label>
                    <label>
                        <input type="radio" name="reducedManagement" checked={reducedManagement === 0} onChange={() => setReducedManagement(0)} />
                        <span className='radio-span'>No</span>
                    </label>
                </div>

                <div className='radio my-3'>
                    <label><strong>Is there protection (no use) of patches with ground-breeding birds?</strong></label>
                    <label>
                        <input type="radio" name="protection" checked={protection === 1} onChange={() => setProtection(1)} />
                        <span className='radio-span'>Yes</span>
                    </label>
                    <label>
                        <input type="radio" name="protection" checked={protection === 0} onChange={() => setProtection(0)} />
                        <span className='radio-span'>No</span>
                    </label>
                </div>

                <div className='radio my-3'>
                    <label><strong>Are there pollinated crops on your farm?</strong> <br /></label>
                    <div>To see list of pollinated crops, click <a href='/documents/grouped_pollinators.xlsx' download target="_blank" rel="noreferrer"> here </a></div>
                    <label>
                        <input type="radio" name="pollinatingCrops" checked={cropsGrown === 1} onChange={() => setCropsGrown(1)} />
                        <span className='radio-span'>Yes</span>
                    </label>
                    <label>
                        <input type="radio" name="pollinatingCrops" checked={cropsGrown === 0} onChange={() => setCropsGrown(0)} />
                        <span className='radio-span'>No</span>
                    </label>

                </div>

                <div className='radio my-3'>
                    <label><strong>Are there any known threatened species associated with permanent grassland on your farm?</strong> <br /></label>
                    <div>(eg ground nesting birds or animals which use the area to forage)</div>
                    <label>
                        <input type="radio" name="threatenedSpecies" checked={threatenedSpecies === 1} onChange={() => setThreatenedSpecies(1)} />
                        <span className='radio-span'>Yes</span>
                    </label>
                    <label>
                        <input type="radio" name="threatenedSpecies" checked={threatenedSpecies === 0} onChange={() => setThreatenedSpecies(0)} />
                        <span className='radio-span'>No</span>
                    </label>
                </div>

                <InputButtonsContainer className='my-2'>
                    <InputButton onClick={() => navigate(`/report/${report?.id}`)}>BACK</InputButton>
                    <InputButton onClick={() => {
                        toast.dismiss()
                        toast.info("Saving...")
                        saveHandler()
                    }}>SAVE</InputButton>
                    <InputButton onClick={() => {
                        toast.dismiss()
                        toast.info("Saving...");
                        setBackToReport(true);
                        saveHandler();
                    }}>SAVE & CONTINUE</InputButton>
                </InputButtonsContainer>
            </div>
        </>
    )
}

export default WildlifeMeasuresAndOther