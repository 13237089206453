import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { InputButton, InputButtonsContainer } from '../../styles/input/Input.styled';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { ToastContainer, toast } from 'react-toastify';
import { clearErrors, resetUpdate, getFullFarmDataById, putSignsOfErosion, putSoilAssessment, resetFarmDataId } from '../../../features/farmData/farmDataSlice';
import MetaData from '../../layout/MetaData';

type Props = {}

const SoilProfile = (props: Props) => {

    const [pulledData, setPulledData] = useState(false);
    const [surfaceAssessment, setSurfaceAssessment] = useState(0);
    const [assessmentScore, setAssessmentScore] = useState(0);
    const [backToReport, setBackToReport] = useState(false);

    const { report } = useAppSelector((state) => state.report);
    const { fullFarmData, success, error } = useAppSelector((state) => state.farmData)

    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!pulledData) {
            if (fullFarmData?.farmBreakdown.soilProfile.soilAssessment) {
                setSurfaceAssessment(fullFarmData?.farmBreakdown.soilProfile.soilAssessment.soilSurfaceAssessment ?? 0)
                setAssessmentScore(fullFarmData?.farmBreakdown.soilProfile.soilAssessment.soilAssessmentScore ?? 0)
            }
            setPulledData(false);
        }

        if (success) {
            let farmDataId = report?.farmData;
            dispatch(getFullFarmDataById({ id: farmDataId! }))
            toast.dismiss();
            toast.success("Input saved successfully");
            dispatch(resetUpdate());
            if (backToReport) {
                dispatch(resetFarmDataId());
                navigate(`/report/${report?.id}`);
            }
        }

    }, [dispatch, error, pulledData, success, backToReport, navigate, report?.farmData, report?.id, fullFarmData?.farmBreakdown.soilProfile.soilAssessment])

    const saveHandler = (e?: React.MouseEvent) => {
        e?.preventDefault();

        // validation
        if (surfaceAssessment < 0 || surfaceAssessment > 100) {
            toast.dismiss();
            toast.error("The proportion of permanent grassland with grass cover must be between 0 and 100");
            return;
        }

        toast.info("Saving progress...");
        dispatch(putSoilAssessment(
            {
                id: fullFarmData?.farmData.id!,
                soilAssessment: {
                    soilSurfaceAssessment: surfaceAssessment,
                    soilAssessmentScore: assessmentScore
                }
            }));
    }

    return (
        <>
            <MetaData title='Soil Profile' />
            <div>
                <div><h4><strong>ABOUT YOUR PERMANENT GRASSLAND - VEGETATION COVER & SOIL CONDITION</strong></h4></div>

                <div className='my-3'>
                    <label><strong>During the grazing season, what proportion of total farm PG has {'<'}90% grass cover</strong></label>
                    <input
                        className='form-control'
                        value={surfaceAssessment}
                        type='number'
                        min={0}
                        max={100}
                        onChange={(e) => setSurfaceAssessment(e.target.valueAsNumber ?? 0)}
                    />
                </div>

                <div className='radio my-3'>
                    <label><strong>In the middle of your most intensively managed permanent grassland field, take a spade sized block of soil approximately 30 cm deep. Examine the condition of the soil and select the appropriate score below. For full instructions, please read <a target='blank' href='https://projectblue.blob.core.windows.net/media/Default/Beef%20&%20Lamb/HealthyGrasslandSoils2735_190430_WEB.pdf'>here</a><br /></strong></label>
                    <label>
                        <input type="radio" name="soilAssessment" checked={assessmentScore === 1} onChange={() => setAssessmentScore(1)} />
                        <span className='radio-span'>Score 1 – Crumbly (aggregates readily crumble with fingers) - Good soil structure; Highly porous; Numerous, well-distributed roots; Sweet, earthy smell; Small, rounded aggregates</span>
                    </label>
                    <label>
                        <input type="radio" name="soilAssessment" checked={assessmentScore === 2} onChange={() => setAssessmentScore(2)} />
                        <span className='radio-span'>Score 2 – Intact (aggregates easily break apart) - Good soil structure; Porous; Earthy smell; Some indication of larger aggregates; Good root distribution</span>
                    </label>
                    <label>
                        <input type="radio" name="soilAssessment" checked={assessmentScore === 3} onChange={() => setAssessmentScore(3)} />
                        <span className='radio-span'>Score 3 – Firm (most aggregates break down) - Adequate soil structure; Larger aggregates, some angular; Moderate root distribution; no strong smell; Less visible pores</span>
                    </label>
                    <label>
                        <input type="radio" name="soilAssessment" checked={assessmentScore === 4} onChange={() => setAssessmentScore(4)} />
                        <span className='radio-span'>Score 4 – Compact (effort needed to break down aggregates) - Large, angular aggregates ({'>'}5 cm across) with low pore numbers; Some red/orange mottling may be present (sign of poor drainage); Roots clustered in large pores, worm channels and cracks between aggregates; May have sulphur smell (i.e. bad eggs)</span>
                    </label>
                    <label>
                        <input type="radio" name="soilAssessment" checked={assessmentScore === 5} onChange={() => setAssessmentScore(5)} />
                        <span className='radio-span'>Score 5 – Very compact (aggregates are compact, difficult to pull apart and platy) - Very large, angular aggregates ({'>'}10 cm), with very few pores; Any roots seen mainly at the surface or
                            clustered down large pores or cracks; May have grey colour with red/orange mottling (sign of poor drainage); May have strong sulphur smell (i.e. bad eggs)</span>
                    </label>
                </div>

                <InputButtonsContainer className='my-2'>
                    <InputButton onClick={() => navigate(`/report/${report?.id}`)}>BACK</InputButton>
                    <InputButton onClick={() => {
                        toast.dismiss()
                        toast.info("Saving...")
                        saveHandler()
                    }}>SAVE</InputButton>
                    <InputButton onClick={() => {
                        toast.dismiss()
                        toast.info("Saving...");
                        setBackToReport(true);
                        saveHandler();
                    }}>SAVE & CONTINUE</InputButton>
                </InputButtonsContainer>

            </div>
        </>
    )
}

export default SoilProfile