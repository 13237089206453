import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { InputButton, InputButtonsContainer } from '../../styles/input/Input.styled';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { ToastContainer, toast } from 'react-toastify';
import { clearErrors, resetUpdate, getFullFarmDataById, putOrganicFertilisers, resetFarmDataId } from '../../../features/farmData/farmDataSlice';
import MetaData from '../../layout/MetaData';

type Props = {}

const FertiliserApplicationDetails = (props: Props) => {

    const [pulledData, setPulledData] = useState(false);
    const [followedNutrientPlan, setFollowedNutrientPlan] = useState(3);
    const [proportionUsingBroadcast, setProportionUsingBroadcast] = useState(0);
    const [proportionUsingBandSpreading, setProportionUsingBandSpreading] = useState(0);
    const [proportionUsingShallowInjection, setProportionUsingShallowInjection] = useState(0);
    const [proportionUsingLiquid, setProportionUsingLiquid] = useState(0);
    const [proportionUsingOther, setProportionUsingOther] = useState(0);
    const [poultryManureDM, setPoultryManureDM] = useState("40");
    const [cattleSlurryDM, setCattleSlurryDM] = useState("2");
    const [backToReport, setBackToReport] = useState(false);

    const { report } = useAppSelector((state) => state.report);
    const { fullFarmData, success, error } = useAppSelector((state) => state.farmData)

    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!pulledData) {
            if (fullFarmData?.farmManagement.fertiliserApplications?.fertiliserApplicationDetails) {
                var data = fullFarmData.farmManagement.fertiliserApplications.fertiliserApplicationDetails
                if (data?.followedNutrientManagementPlan === true) setFollowedNutrientPlan(1);
                if (data?.followedNutrientManagementPlan === false) setFollowedNutrientPlan(0);
                setProportionUsingBandSpreading(data.proportionAppliedUsingBandSpreading ?? 0)
                setProportionUsingBroadcast(data.proportionAppliedUsingBroadcast ?? 0)
                setProportionUsingLiquid(data.proportionAppliedUsingLiquid ?? 0)
                setProportionUsingOther(data.proportionAppliedUsingOther ?? 0)
                setProportionUsingShallowInjection(data.proportionApppliedUsingShallowInjection ?? 0)
            }

            if (fullFarmData?.farmManagement.fertiliserApplications?.organicFertilisersApplied) {
                var organicFerts = fullFarmData.farmManagement.fertiliserApplications?.organicFertilisersApplied
                var cattleSlurryRecord = organicFerts.find(element => element.fertiliser === 8)
                if (cattleSlurryRecord && cattleSlurryRecord.dmContentPercentage) {
                    setCattleSlurryDM(String(cattleSlurryRecord.dmContentPercentage))
                }
                var poultryManureRecord = organicFerts.find(element => element.fertiliser === 7)
                if (poultryManureRecord && poultryManureRecord.dmContentPercentage) {
                    setCattleSlurryDM(String(poultryManureRecord.dmContentPercentage))
                }
            }
            setPulledData(true);
        }

        if (success) {
            let farmDataId = report?.farmData;
            dispatch(getFullFarmDataById({ id: farmDataId! }))
            toast.dismiss();
            toast.success("Details saved successfully");
            dispatch(resetUpdate());
            if (backToReport) {
                dispatch(resetFarmDataId());
                navigate(`/report/${report?.id}`);
            }
        }

    }, [dispatch, error, pulledData, success, backToReport, navigate, report?.farmData, report?.id, fullFarmData?.farmManagement?.fertiliserApplications?.fertiliserApplicationDetails, fullFarmData?.farmManagement?.fertiliserApplications?.organicFertilisersApplied])

    const saveHandler = (e?: React.MouseEvent) => {
        e?.preventDefault();

        var organicFerts = fullFarmData?.farmManagement.fertiliserApplications?.organicFertilisersApplied

        var cattleSlurryRecord = organicFerts?.find(element => element.fertiliser === 8)
        if (cattleSlurryRecord) {
            cattleSlurryRecord = { ...cattleSlurryRecord, dmContentPercentage: Number(cattleSlurryDM) }
            organicFerts = organicFerts?.filter(element => element.fertiliser !== 8)
            organicFerts?.push(cattleSlurryRecord)
        }

        var poultryManureRecord = organicFerts?.find(element => element.fertiliser === 7)
        if (poultryManureRecord) {
            poultryManureRecord = { ...poultryManureRecord, dmContentPercentage: Number(poultryManureDM) }
            organicFerts = organicFerts?.filter(element => element.fertiliser !== 7)
            organicFerts?.push(poultryManureRecord)
        }

        if ((proportionUsingBandSpreading + proportionUsingBroadcast + proportionUsingLiquid + proportionUsingOther + proportionUsingShallowInjection) > 100) {
            toast.dismiss();
            toast.error("Cannot have percentages sum to more than 100");
            return;
        }

        if (proportionUsingBroadcast < 0) {
            toast.dismiss();
            toast.error("Cannot have percentage using broadcast less than 0");
            return;
        }
        if (proportionUsingLiquid < 0) {
            toast.dismiss();
            toast.error("Cannot have percentage using liquid less than 0");
            return;
        }
        if (proportionUsingBandSpreading < 0) {
            toast.dismiss();
            toast.error("Cannot have percentage using band spreading less than 0");
            return;
        }
        if (proportionUsingShallowInjection < 0) {
            toast.dismiss();
            toast.error("Cannot have percentage using shallow injection less than 0");
            return;
        }
        if (proportionUsingOther < 0) {
            toast.dismiss();
            toast.error("Cannot have percentage using other less than 0");
            return;
        }

        if (followedNutrientPlan === 3) {
            toast.dismiss();
            toast.error("Please indicate whether you followed a nutrient management plan");
            return
        }

        toast.dismiss();
        toast.info("Saving progress...");
        dispatch(putOrganicFertilisers(
            {
                id: fullFarmData?.farmData.id!,
                fertiliserDetails: {
                    followedNutrientManagementPlan: Boolean(followedNutrientPlan),
                    proportionAppliedUsingBroadcast: proportionUsingBroadcast,
                    proportionAppliedUsingBandSpreading: proportionUsingBandSpreading,
                    proportionAppliedUsingLiquid: proportionUsingLiquid,
                    proportionAppliedUsingOther: proportionUsingOther,
                    proportionApppliedUsingShallowInjection: proportionUsingShallowInjection
                },
                fertilisers: organicFerts
            })
        );
    }

    const checkPoultryManurePresent = () => {
        if (fullFarmData?.farmManagement.fertiliserApplications?.organicFertilisersApplied) {
            var data = fullFarmData?.farmManagement.fertiliserApplications?.organicFertilisersApplied

            var record = data.find(element => element.fertiliser === 7)

            if (record) return true
        }

        return false
    }

    const checkCattleSlurryPresent = () => {
        if (fullFarmData?.farmManagement.fertiliserApplications?.organicFertilisersApplied) {
            var data = fullFarmData?.farmManagement.fertiliserApplications?.organicFertilisersApplied

            var record = data.find(element => element.fertiliser === 8)

            if (record) return true
        }

        return false
    }

    return (
        <>
            <>
                <div>
                    <div><h4><strong>FARM MANAGEMENT - FERTILISER APPLICATION DETAILS</strong></h4></div>

                    <div className='my-3'>
                        <p>In this section, we want to find out about  management on your farm, mainly focussed on inputs. This includes concentrates bought to feed to animals, pesticides applied and fertiliser (both organic and inorganic) applied to grassland</p>
                    </div>

                    <div className='radio my-3'>
                        <label><strong>Do you follow a nutrient management plan/scheme?</strong><br /></label>
                        <label>
                            <input data-role='none' type="radio" name="reseedFrequency" checked={followedNutrientPlan === 1} onChange={() => setFollowedNutrientPlan(1)} />
                            <span className='radio-span'>Yes</span>
                        </label>
                        <label>
                            <input data-role='none' type="radio" name="reseedFrequency" checked={followedNutrientPlan === 0} onChange={() => setFollowedNutrientPlan(0)} />
                            <span className='radio-span'>No</span>
                        </label>
                    </div>


                    <div className='mb-1'><strong>Please state the proportion of organic fertiliser applied using each method:</strong></div>

                    <div className='row'>
                        <div className='col-md-4 col-6 mb-1'>
                            <label>Broadcast</label>
                            <input
                                className='form-control'
                                value={proportionUsingBroadcast}
                                type='number'
                                name='proportionUsingBroadcast'
                                onChange={(e) => setProportionUsingBroadcast(e.target.valueAsNumber)}
                            />
                        </div>
                        <div className='col-md-4 col-6 mb-1'>
                            <label>Band spreading</label>
                            <input
                                className='form-control'
                                value={proportionUsingBandSpreading}
                                type='number'
                                name='proportionUsingBandSpreading'
                                onChange={(e) => setProportionUsingBandSpreading(e.target.valueAsNumber)}
                            />
                        </div>
                        <div className='col-md-4 col-6 mb-1'>
                            <label>Shallow injection</label>
                            <input
                                className='form-control'
                                value={proportionUsingShallowInjection}
                                type='number'
                                name='proportionUsingShallowInjection'
                                onChange={(e) => setProportionUsingShallowInjection(e.target.valueAsNumber)}
                            />
                        </div>
                        <div className='col-md-4 col-6 mb-1'>
                            <label>Liquid</label>
                            <input
                                className='form-control'
                                value={proportionUsingLiquid}
                                type='number'
                                name='proportionUsingLiquid'
                                onChange={(e) => setProportionUsingLiquid(e.target.valueAsNumber)}
                            />
                        </div>
                        <div className='col-md-4 col-6 mb-1'>
                            <label>Other</label>
                            <input
                                className='form-control'
                                value={proportionUsingOther}
                                type='number'
                                name='proportionUsingOther'
                                onChange={(e) => setProportionUsingOther(e.target.valueAsNumber)}
                            />
                        </div>
                    </div>



                    {/* <div className='radio my-3'>
                        <label>How often do you reseed your improved permanent grassland?<br /></label>
                        <label>
                            <input data-role='none' type="radio" name="reseedFrequency" checked={reseedFrequency === 0} onChange={() => setReseedFrequency(0)} />
                            <span className='radio-span'>Less than or equal to every 5 years</span>
                        </label>
                        <label>
                            <input data-role='none' type="radio" name="reseedFrequency" checked={reseedFrequency === 1} onChange={() => setReseedFrequency(1)} />
                            <span className='radio-span'>Between every 5 to 9 years</span>
                        </label>
                        <label>
                            <input data-role='none' type="radio" name="reseedFrequency" checked={reseedFrequency === 2} onChange={() => setReseedFrequency(2)} />
                            <span className='radio-span'>Between every 10 to 14 years</span>
                        </label>
                        <label>
                            <input data-role='none' type="radio" name="reseedFrequency" checked={reseedFrequency === 3} onChange={() => setReseedFrequency(3)} />
                            <span className='radio-span'>Every 15 or more years</span>
                        </label>
                    </div> */}

                    {checkPoultryManurePresent() && (
                        <div>
                            <label><strong>Which of the following best describes the dry matter content percentage of the applied poultry manure?</strong></label>
                            <select
                                className='form-control'
                                value={poultryManureDM}
                                onChange={(e) => setPoultryManureDM(e.target.value)}
                            >
                                <option>--</option>
                                {fullFarmData?.farmManagement.fertiliserApplications?.organicFertiliserDryMatterContentPercentages.filter(element => element.fertiliserId === 7).map(element => {
                                    return (
                                        <option key={element.id} value={element.percentage} >{element.percentage}</option>
                                    )
                                })}

                            </select>
                        </div>
                    )}

                    {checkCattleSlurryPresent() && (
                        <div>
                            <label><strong>Which of the following best describes the dry matter content percentage of the applied cattle slurry?</strong></label>
                            <select
                                className='form-control'
                                value={cattleSlurryDM}
                                onChange={(e) => setCattleSlurryDM(e.target.value)}
                            >
                                <option>--</option>
                                {fullFarmData?.farmManagement.fertiliserApplications?.organicFertiliserDryMatterContentPercentages.filter(element => element.fertiliserId === 8).map(element => {
                                    return (
                                        <option key={element.id} value={element.percentage} >{element.percentage}</option>
                                    )
                                })}

                            </select>
                        </div>
                    )}



                    <InputButtonsContainer className='my-2'>
                        <InputButton onClick={() => navigate(`/report/${report?.id}`)}>BACK</InputButton>
                        <InputButton onClick={() => {
                            toast.dismiss()
                            toast.info("Saving...")
                            saveHandler()
                        }}>SAVE</InputButton>
                        <InputButton onClick={() => {
                            toast.dismiss()
                            toast.info("Saving...");
                            setBackToReport(true);
                            saveHandler();
                        }}>SAVE & CONTINUE</InputButton>
                    </InputButtonsContainer>

                </div>
            </>
        </>
    )
}

export default FertiliserApplicationDetails