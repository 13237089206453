import styled from 'styled-components';

export const FooterH20202logo = styled.img`
    width: 40%;
    margin: 2.5em 1em;
`;


export const SoilDegradationPhoto = styled.img`
    max-width: 150px;
    object-fit: cover;
`;