import React, { useEffect, useState } from 'react'
import { useAppSelector, useAppDispatch } from '../../hooks'
import { useNavigate, } from 'react-router-dom'
import { getFarmerReports } from '../../features/report/reportSlice'
import { AddReportsButton, AddReportsButtonWrapper, ReportDescriptionIcon, ReportDescriptionWrapper, ReportListItemInnerRightIcon, ReportsBody, ReportsContainer, ReportsHeader, ReportsTitle, ReportsTitleWrapper } from '../styles/Report.styled'
import ReportListItem from './ReportListItem'
import { deleteReportById } from '../../features/report/reportSlice';
import { toast } from 'react-toastify';
import Loader from '../layout/Loader'
import MetaData from '../layout/MetaData'
import tippy from 'tippy.js'


type Props = {}

const Reports = (props: Props) => {

    const [fetchedReports, setFetchedReports] = useState(false)
    const [selectedReportForDelete, setSelectedReportForDelete] = useState("");

    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { reports, loading } = useAppSelector((state) => state.report)

    useEffect(() => {

        tippy('.new-report-button', {
            content: "New report",
        });

        window.onpopstate = () => {
            navigate('/');
        }

        if (!fetchedReports) {
            dispatch(getFarmerReports())
            setFetchedReports(true)
        }
    }, [dispatch, fetchedReports, navigate, reports]);

    const updateSelectedReportForDelete = (id: string) => {
        setSelectedReportForDelete(id);
    }

    const deleteSelectedReport = () => {
        if (selectedReportForDelete !== "") {
            toast.info("Report deleted")
            dispatch(deleteReportById({ id: selectedReportForDelete }))
        }
    }

    return (
        <>
            <MetaData title='Reports' />
            <ReportsContainer>
                <ReportsHeader>
                    <ReportsTitleWrapper>
                        <ReportsTitle>REPORTS</ReportsTitle>
                    </ReportsTitleWrapper>
                    <AddReportsButtonWrapper>
                        <AddReportsButton className='new-report-button' onClick={() => navigate("/report/new")} src='/icons/add-reports.svg' alt='New report' />
                    </AddReportsButtonWrapper>
                </ReportsHeader>
                <ReportsBody>
                    <ReportDescriptionWrapper>
                        Use this page to add new and edit reports on your farm or areas within your farm. You may also delete reports you no longer require
                    </ReportDescriptionWrapper>
                    {loading ? (
                        <div className='w-100'>
                            <ReportListItem name={"report.name"} id={"report.id"} selectReportForDelete={updateSelectedReportForDelete} />
                            <ReportListItem name={"report.name"} id={"report.id"} selectReportForDelete={updateSelectedReportForDelete} />
                            <ReportListItem name={"report.name"} id={"report.id"} selectReportForDelete={updateSelectedReportForDelete} />
                            <ReportListItem name={"report.name"} id={"report.id"} selectReportForDelete={updateSelectedReportForDelete} />
                            <ReportListItem name={"report.name"} id={"report.id"} selectReportForDelete={updateSelectedReportForDelete} />
                        </div>
                    )
                        : (
                            <>
                                {reports && reports?.length > 0 ? (
                                    <>
                                        <div className='w-100'>
                                            {reports.map(report => {
                                                return (
                                                    <ReportListItem
                                                        key={report.id}
                                                        name={report.name}
                                                        id={report.id}
                                                        location={report.location}
                                                        year={report.year}
                                                        selectReportForDelete={updateSelectedReportForDelete} />
                                                )
                                            })}
                                        </div>
                                    </>
                                ) : (
                                    <p>Click on the plus button to create a report</p>
                                )}
                            </>
                        )}

                </ReportsBody>

            </ReportsContainer>
            <div className="modal fade" id="exampleModalCenter" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLongTitle">Are you sure?</h5>
                            <button type="button" className="btn btn-secondary close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <p>This action <strong>CANNOT</strong> be undone. This would permanently delete the report along with the corresponding farm data.</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-outline-secondary" data-bs-dismiss="modal">Back</button>
                            <button type="button" className="btn btn-danger" onClick={deleteSelectedReport} data-bs-dismiss="modal">Yes, Delete</button>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default Reports