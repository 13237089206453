import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { InputButton, InputButtonsContainer } from '../../styles/input/Input.styled';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { ToastContainer, toast } from 'react-toastify';
import { clearErrors, resetUpdate, getFullFarmDataById, putManufacturedFertilisers } from '../../../features/farmData/farmDataSlice';
import MetaData from '../../layout/MetaData';
import { IManufacturedFertiliserApplied } from '../../../Interfaces';
import FertTable from './dynamicTables/artilificialFertilisers/Table';

type Props = {}

const ArtificialFertilisers = (props: Props) => {

    const [pulledData, setPulledData] = useState(false);
    const [fertilisers, setFertilisers] = useState<IManufacturedFertiliserApplied[]>([{
        isProtectedUrea: false,
        fertiliserName: '',
        nitrogenContentPercentage: 0,
        phosphateContentPercentage: 0,
        potashContentPercentage: 0,
        tonnesAppliedInSpring: 0,
        tonnesAppliedInSummer: 0,
        tonnesAppliedInAutumn: 0,
        tonnesAppliedInWinter: 0
    }]);
    const [nextPage, setNextPage] = useState(false);

    const { report } = useAppSelector((state) => state.report);
    const { fullFarmData, success, error } = useAppSelector((state) => state.farmData)

    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!pulledData) {
            if (fullFarmData?.farmManagement.fertiliserApplications?.manufacturedFertilisersApplied) {
                var data = fullFarmData.farmManagement.fertiliserApplications.manufacturedFertilisersApplied
                if (data.length > 0) setFertilisers(data)
            }
            setPulledData(true);
        }

        if (success) {
            let farmDataId = report?.farmData;
            dispatch(getFullFarmDataById({ id: farmDataId! }))
            toast.dismiss();
            toast.success("Input saved successfully");
            dispatch(resetUpdate());
            if (nextPage) navigate(`/input/organicFertilisers`);
        }

    }, [dispatch, error, pulledData, success, nextPage, navigate, report?.farmData, report?.id, fullFarmData?.farmManagement?.fertiliserApplications?.manufacturedFertilisersApplied])

    const saveHandler = (e?: React.MouseEvent) => {
        e?.preventDefault();

        // validation
        // only one protected urea record
        var ureaCount = 0
        fertilisers.forEach(element => {
            if (element.isProtectedUrea) ureaCount++
        })
        if (ureaCount > 1) {
            toast.dismiss();
            toast.error("Cannot have more than one protected urea record");
            return;
        }

        // sum of percentage content must be less than or equal to 100
        var invalidPercentages = false;
        fertilisers.forEach(element => {
            if (Number(element.nitrogenContentPercentage ?? 0) + Number(element.phosphateContentPercentage ?? 0) + Number(element.potashContentPercentage ?? 0) > 100) {
                // console.log(`nitrogen content: ${element.nitrogenContentPercentage}`)
                // console.log(`phosphate content: ${element.phosphateContentPercentage}`)
                // console.log(`potash content: ${element.potashContentPercentage}`)
                toast.dismiss();
                toast.error("Cannot have percentages sum to more than 100");
                invalidPercentages = true;
                return;
            }
        })

        fertilisers.forEach(element => {
            if (Number(element.nitrogenContentPercentage ?? 0) < 0) {
                toast.dismiss();
                toast.error("Cannot have nitrogen percentage less than 0");
                invalidPercentages = true;
                return;
            }
            if (Number(element.phosphateContentPercentage ?? 0) < 0) {
                toast.dismiss();
                toast.error("Cannot have phosphate percentage less than 0");
                invalidPercentages = true;
                return;
            }
            if (Number(element.potashContentPercentage ?? 0) < 0) {
                toast.dismiss();
                toast.error("Cannot have potash percentage less than 0");
                invalidPercentages = true;
                return;
            }
        })

        if (invalidPercentages) return;

        toast.dismiss();
        toast.info("Saving progress...");
        dispatch(putManufacturedFertilisers(
            {
                id: fullFarmData?.farmData.id!,
                fertilisers
            }));
    }

    return (
        <>

            <MetaData title='Artificial Fertilisers' />

            <div>
                <div><h4><strong>FARM MANAGEMENT - MANUFACTURED FERTILISERS</strong></h4></div>

                <div className='my-3'>
                    <p>In this section, we want to find out about management on your farm, mainly focussed on inputs. This includes concentrates bought to feed to animals, pesticides applied and fertiliser (both organic and inorganic) applied to grassland</p>
                </div>

                <FertTable rowsData={fertilisers} setRowsData={setFertilisers} />

                <InputButtonsContainer className='my-2'>
                    <InputButton onClick={() => navigate(`/report/${report?.id}`)}>BACK</InputButton>
                    <InputButton onClick={() => {
                        toast.dismiss()
                        toast.info("Saving...")
                        saveHandler()
                    }}>SAVE</InputButton>
                    <InputButton onClick={() => {
                        toast.dismiss()
                        toast.info("Saving...");
                        setNextPage(true);
                        saveHandler();
                    }}>SAVE & CONTINUE</InputButton>
                </InputButtonsContainer>

            </div>
        </>
    )
}

export default ArtificialFertilisers